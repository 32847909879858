<template>
<div class="all">
    <b-row>
        <b-col lg="5" class="my-1">
            <b-form-group label="From Date" label-for="from-date" label-cols-sm="2.6" label-align-sm="left" label-size="md" class="mb-0">
                <b-input-group size="md">
                    <b-form-input id="from-date" class="from-date" v-model="reportForm.startDate" type="date" :value="reportForm.startDate" :placeholder="reportForm.startDate"></b-form-input>
                </b-input-group>
            </b-form-group>
            <b-form-group label="To Date" label-for="to-date" label-cols-sm="2.6" label-align-sm="left" label-size="md" class="mb-0">
                <b-input-group size="md">
                    <b-form-input id="to-date" class="to-date" v-model="reportForm.endDate" type="date" :value="reportForm.endDate" :placeholder="reportForm.endDate"></b-form-input>
                </b-input-group>
            </b-form-group>

            <b-form-group label="Filter" label-for="filter-input" label-cols-sm="2.6" label-align-sm="left" label-size="md" class="mb-0">
                <b-input-group size="md">
                    <b-form-input id="filter-input" class="filter-input" v-model="filter" type="search" placeholder="Type to Search SL"></b-form-input>

                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>
        <b-col lg="5" class="my-1">
            <div class="div-1412">
                <button :disabled='reportLoading' @click='onReportSubmit' class="btn btn-success">Update Stats</button>
                <i class="spinner fa fa-circle-o-notch fa-spin" v-if='reportLoading'></i>
            </div>
        </b-col>
    </b-row>

    <b-table class="upper-table" sticky-header="650px" :items="slList" :fields="fields" :filter="filter" outlined show-empty responsive="md" striped hover :busy="reportLoading">
        <template v-if="sumRow != []" #top-row="{item}">

            <b-td colspan="5" variant="success">{{''}}</b-td>
            <b-th colspan="1" variant="success">{{ sumRow.total_requests| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.served_total| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.blocked_total| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.blocked_total_percentage| percentageFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.idfa_frq_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.ip_frq_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.bl_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.isp_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.connection_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.subs_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.other_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.group_pause_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.idfa_only_errors| numFormat}}</b-th>
            <b-th colspan="1" variant="success">{{ sumRow.delivery_frq_errors| numFormat}}</b-th>

        </template>

        <template #cell(id)="{item}">
            <a :href="'/apps/smartlinksVTA/campaigns/' + item.id">{{ item.id }}</a>
        </template>

        <template #cell(name)="{item}">
            <a class="link-to-camp" :href="'/apps/smartlinksVTA/campaigns/' + item.id">{{ item.name }}</a>
        </template>

        <template #cell(requests)="{item}">
            {{ item.stats?.total_requests  | numFormat }}
            <button class="info-button" v-show='userHasPermission("all-requests-report") && item.stats.total_requests > 0 && item.status' @click='generateReport(item.id, "requests")' type="button">
                <b-icon class="info-icon" icon="download"></b-icon>
            </button>
        </template>

        <template #cell(served)="{item}">
            {{ item.stats.served_total | numFormat }}
        </template>

        <template #cell(status)="{item}">
            <b-button v-if="(groupIdActive(item.group_id)  && !item.group_forced)" disabled size="sm">
                <span>SCHEDULE</span>
            </b-button>
            <b-button variant="danger" v-if="(groupIdActive(item.group_id) && !item.group_forced)" size="sm" @click="toggleForceGroup(item)">
                <span>FORCE</span>
            </b-button>
            <button v-if="(item.group_forced || item.group_id === 0 || !groupIdActive(item.group_id))" id="status_btn_modal" type="button" class="status-btn modal_input" :class='{active: item.status==="ACTIVE", paused: item.status==="PAUSED"}' @click="toggleSlStatus(item)">
                {{ item.status }}
            </button>
            <b-button variant="success" v-if="item.group_forced" class="def-cursor" size="sm" @click="toggleForceGroup(item)">
                <span>UNFORCE</span>
            </b-button>
        </template>

        <template #cell(group_id)="{item}"  @click="onSortByGroup">
            <b-form-select size="sm" id="itemnput-6" v-model="item.group_id" :options="groupList" value-field="id" text-field="name" @change="setGroupHandler(item)"></b-form-select>
            <div v-if="(groupIdActive(item.group_id) && !item.group_forced )">
                <div v-if="getGroupStatus(item.group_id) === 'ACTIVE'" style="color: green; font-size: 10px;">
                    Group is {{ getGroupStatus(item.group_id) }}
                </div>
                <div v-if="getGroupStatus(item.group_id) !== 'ACTIVE'" style="color: red; font-size: 10px;">
                    Group is {{ getGroupStatus(item.group_id) }}
                </div>
            </div>
        </template>

        <template #cell(lost)="{item}">
            {{ item.stats.blocked_total| numFormat }}
            <button class="info-button" v-show='item.stats.blocked_total > 0 && item.status' @click='generateReport(item.id, "blocked")' type="button">
                <b-icon class="info-icon" icon="download"></b-icon>
            </button>
        </template>

        <template #cell(lost_percentage)="{item}">
            {{ item.stats.blocked_total_percentage | percentageFormat }}
        </template>

        <template #cell(IDFA_FRQ_Blocked)="{item}">
            {{ item.stats.idfa_frq_errors  | numFormat }}
        </template>

        <template #cell(IP_FRQ_Blocked)="{item}">
            {{ item.stats.ip_frq_errors  | numFormat }}
        </template>

        <template #cell(BL_Blocked)="{item}">
            {{ item.stats.bl_errors  | numFormat }}
        </template>

        <template #cell(Isp_Blocked)="{item}">
            {{ item.stats.isp_errors  | numFormat }}
        </template>

        <template #cell(Connection_Blocked)="{item}">
            {{ item.stats.connection_errors  | numFormat }}
        </template>
        <template #cell(Subs_Blocked)="{item}">
            {{ item.stats.subs_errors  | numFormat }}
        </template>

        <template #cell(Other_Errs)="{item}">
            {{ item.stats.other_errors | numFormat }}
        </template>

        <template #cell(group_pause_errors)="{item}">
            {{ item.stats.group_pause_errors | numFormat }}
        </template>

        <template #cell(idfa_only_errors)="{item}">
            {{ item.stats.idfa_only_errors | numFormat }}
        </template>

        <template #cell(delivery_frq_errors)="{item}">
            {{ item.stats.delivery_frq_errors | numFormat }}
        </template>
        <template #cell(actions)="{item}">
            <button class="info-button" v-if="item.stats?.offers_stats" size="sm" @click="info(item)" ref="btnShow">
                <b-icon class="info-icon" icon="info-circle-fill" scale="2" variant="info"></b-icon>
            </button>
        </template>

    </b-table>

    <b-modal size="lg" modal-class="my-class" :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <b-table sticky-header :items="infoModal.content" :fields="fields3" outlined show-empty responsive="sm">
            <template #cell(name)="{item}">
                {{ item.name}}
                <span v-if='item.name.includes("VTA")' class="r-vta-mark">(VTA)</span>
                <span v-else class="r-cta-mark">(CTA)</span>
            </template>
        </b-table>
    </b-modal>

    <button ref="btnHide" v-if='userHasPermission("smartlinks.edit")' type="button" @click='newCampFormDisplay = !newCampFormDisplay' class="btn btn-primary smartlinks-toggle-form-display-button">New Smart Link VTA</button>
    <NewCampaign v-if='newCampFormDisplay' :isVta='true' />

</div>
</template>

<script>
import NewCampaign from '../NewCampaign.smartlinks'
import _, { groupBy } from 'lodash';
const SL_STATUS_ACTIVE = "ACTIVE"
const SL_STATUS_PAUSED = "PAUSED"

export default {
    components: {
        NewCampaign
    },
    data() {
        let yes = new Date()
        var date = yes.setDate(yes.getDate() - 1)
        var today = date.toString()

        return {
            flag: false,
            fromDate: null,
            toDate: null,
            filter: null,
            reportLoading: false,
            newCampFormDisplay: 0,
            sort: {
                field: "id",
                asc: false
            },
            slList: [],
            sumRow: {},
            openAdsets: {},
            approvedStatusToggle: 0,
            reportForm: {
                startDate: today,
                endDate: today
            },
            filterForm: {
                id: null
            },
            groupList: [],
            groups: [],
            options: [],
            fields2: _.drop(this.fields, 2),
            fields3: [{
                    key: 'id',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true
                },
                {
                    key: 'name',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true
                },
                {
                    key: 'total_requests',
                    label: 'requests',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'served',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'total_blocked',
                    label: 'lost',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'blocked_total_percentage',
                    label: 'lost %',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (v.toFixed(0) + "%")
                },
                {
                    key: 'idfa_frq_errors',
                    label: 'IDFA FRQ Blocked',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'ip_frq_errors',
                    label: 'IP FRQ Blocked',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'bl_errors',
                    label: 'BL Blocked',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'other_errors',
                    label: 'Other Errs',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },
                {
                    key: 'delivery_frq_errors',
                    label: 'Delivery FRQ Errs',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    formatter: (v) => (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
                },

            ],
            fields: [{
                    key: 'actions',
                    label: '',
                },
                {
                    key: 'id',
                    sortable: true,
                },
                {
                    key: 'name',
                    thStyle: {
                        width: "8%"
                    },
                },
                {
                    key: 'status',
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    thStyle: {
                        width: "12%"
                    },
                },
                {
                    key: 'group_id',
                    label: 'Group',
                    sortable: true,
                    thStyle: {
                        width: "8%"
                    },
                },
                {
                    key: 'requests',
                    sortable: true,
                },
                {
                    key: 'served',
                    sortable: true,
                },
                {
                    key: 'lost',
                    sortable: true,
                },
                {
                    key: 'lost_percentage',
                    label: 'lost %',
                    sortable: true,
                },
                {
                    key: 'IDFA_FRQ_Blocked',
                    label: 'IDFA FRQ Blocked',
                    sortable: true,
                },
                {
                    key: 'IP_FRQ_Blocked',
                    label: 'IP FRQ Blocked',
                    sortable: true,
                },
                {
                    key: 'BL_Blocked',
                    label: 'BL Blocked',
                    sortable: true,
                },
                {
                    key: 'Isp_Blocked',
                    label: 'Isp Blocked',
                    sortable: true,
                },
                {
                    key: 'Connection_Blocked',
                    label: 'Connection Blocked',
                    sortable: true,
                },
                {
                    key: 'Subs_Blocked',
                    label: 'Subs Blocked',
                    sortable: true,
                },
                {
                    key: 'Other_Errs',
                    label: 'Other Errs',
                    sortable: true,
                },
                {
                    key: 'group_pause_errors',
                    label: 'Group Pause Errors',
                    sortable: true,
                },
                {
                    key: 'idfa_only_errors',
                    label: 'IDFA Only Block',
                    sortable: true,
                },
                {
                    key: 'delivery_frq_errors',
                    label: 'Delivery FRQ Errors',
                    sortable: true,
                },

            ],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: []
            }
        }
    },
    watch: {
        ["filterForm.id"](val) {
            this.filterData()
            this.calculateRowsTotal()
        }
    },
    filters: {
        numFormat(v) {
            return (Number(v).toLocaleString() !== NaN ? Number(v).toLocaleString() : 0)
        },
        percentageFormat(v) {
            if (!v) {
                v = 0
            }
            return v.toFixed(0) + "%"
        }
    },
    computed: {

    },
    methods: {
        toggleForceGroup: async function (item) {
            try {
                item.group_forced = !item.group_forced
                await this.$http.put(this.resources.SmartLinksCampaigns.setForcedCampaignStatus(item.id), {
                    ...item
                })
                if (item.group_forced) {
                    this.notifySuccess(`Campaign ${item.name} is no longer managed by its group scheduler`)
                } else {
                    this.notifySuccess(`Campaign ${item.name} is now managed by its group scheduler`)
                }
            } catch (err) {
                this.notifyError(`could not toggle force status button`)
            }
        },
        groupIdActive(groupId) {
            if (groupId === 0 || groupId === undefined) {
                return false
            }
            if (this.groups.find(x => x.id === groupId) ?.isActive) {
                return true
            } else
                return false
        },
        getGroupStatus(groupId) {
            if (groupId === 0 || groupId === undefined) {
                return ''
            }
            for (let k in this.groups) {
                if (this.groups[k].id === groupId) {
                    return this.groups[k].status
                }
            }
            return ''
        },
        info(item) {
            this.infoModal.title = `Offers Stats for Smart Link ${item.id} : ${item.name}`

            item.stats.offers_stats.forEach(elem => {
                elem = {
                    ..._.omit(elem, ['offer_id', 'offer_name']),
                    id: elem.offer_id,
                    name: elem.offer_name,
                    _rowVariant: elem.offer_name.includes("CTA") ? "info" : "warning"
                }
                this.infoModal.content.push(elem)
            })

            this.$root.$emit('bv::show::modal', this.infoModal.id, "#btnShow")
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = []
        },
        generateReport: async function (id, type) {
            try {
                await this.$http.post(this.resources.SmartLinksCampaigns.getRequestBlockedsReport(id), undefined, {
                    params: {
                        date: this.reportForm.startDate,
                        end_date: this.reportForm.endDate,
                        request: type === "requests" ? "true" : "false"
                    },
                    timeout: 1000
                })
            } catch (err) {
                let status = err.status
                if (status !== 0) {
                    this.notifyError("Could not generate report");
                } else {
                    this.notifySuccess("The report will be sent to your email when ready", 10000);
                }
            }
        },
        filterData() {
            let filterID = this.filterForm.id
            this.slList.forEach(v => v.show = true)
            if (filterID) {
                filterID = filterID.toLowerCase()
                this.slList.forEach(v => v.show = v.id == filterID || v.name.toLowerCase().includes(filterID))
            }
        },
        onColumnClick(field) {
            if (this.sort.field === field) {
                this.sort.asc = !this.sort.asc
            } else {
                this.sort.field = field
                this.sort.asc = false
            }
            this.sortReport()
        },
        sortReport() {
            let camps = this.slList

            let sort = this.sort
            if (!sort.field) {
                return
            }

            let sortField = sort.field

            camps.sort((a, b) => {
                if (!["id", "name"].includes(sortField)) {
                    a = a.stats
                    b = b.stats
                }
                a = a[sortField] || 0
                b = b[sortField] || 0

                let f
                if (a > b) {
                    f = 1
                } else if (a < b) {
                    f = -1
                } else {
                    f = 0
                }

                if (!sort.asc) {
                    f = f * -1
                }

                return f
            })
        },
        onReportSubmit: async function () {
            if (this.reportLoading) {
                return
            }
            this.reportLoading = true
            try {
                const r = await this.$http.get(this.resources.SmartLinksCampaigns.getCampaigns, {
                    params: {
                        start_date: this.reportForm.startDate ? this.reportForm.startDate : yes,
                        end_date: this.reportForm.endDate ? this.reportForm.endDate : yes
                    }
                })

                const data = await r.json()
                var arr = data.filter(item => item.is_vta === true)
                arr.forEach((elem, index) => {
                    if (elem.stats.blocked_total_percentage > 20) {
                        arr[index] = {
                            ...elem,
                            _cellVariants: {
                                lost_percentage: 'danger'
                            },
                            expanded: false
                        };
                    }
                })
                this.slList = arr
                this.filterData()
                this.calculateRowsTotal()
                this.sortReport()
                this.reportLoading = false
            } catch (err) {
                this.notifyError("Could not fetch smartlinks")
            }
        },
        toggleAdsetExpand(id) {
            this.$set(this.openAdsets, id, !this.openAdsets[id])
        },
        onReportDownloadClick: async function () {
            let epPath = this.resources.SmartLinksCampaigns.slStatsReport

            let {
                startDate,
                endDate
            } = this.reportForm
            try {
                const r = await this.$http.get(epPath, {
                    params: {
                        startDate,
                        endDate
                    }
                })
                const data = await r.json()
                let filepath = data.filepath
                let rootURL = this.$http.options.root
                let epPath = this.resources.SmartLinksCampaigns.slStatsDownloadReport(filepath)
                let fileTitle = "report-sl-" + startDate + "-" + endDate
                let url = rootURL + epPath + "?file_title=" + fileTitle
            } catch (err) {
                return
            }
        },
        onDatesChange(v) {
            let {
                from,
                to
            } = v
            this.reportForm.startDate = from
            this.reportForm.endDate = to
        },
        toggleSlStatus(sl) {
            if (!this.userHasPermission("smartlinks.status-change")) {
                return
            }
            let toStatus
            if (sl.status === SL_STATUS_ACTIVE) {
                toStatus = SL_STATUS_PAUSED
            } else {
                toStatus = SL_STATUS_ACTIVE
            }
            if (this.approvedStatusToggle) {
                if (!window.confirm(`Are you sure that you want to change campaign "${sl.name}" status to: ${toStatus}?`)) {
                    return
                }
                this.approvedStatusToggle++
            }
            this.$http.put(this.resources.SmartLinksCampaigns.setCampaignStatus(sl.id), {
                    status: toStatus
                })
                .then(() => {
                    sl.status = toStatus
                })
        },
        calculateRowsTotal() {
            let data = this.slList
            let r = {
                total_requests: 0,
                served_total: 0,
                blocked_total: 0,
                idfa_frq_errors: 0,
                ip_frq_errors: 0,
                bl_errors: 0,
                isp_errors: 0,
                connection_errors: 0,
                subs_errors: 0,
                other_errors: 0,
                group_pause_errors: 0,
                idfa_only_errors: 0,
                delivery_frq_errors: 0,
                _rowVariant: 'success'
            }

            data.filter(v => v.show).forEach(row => {
                let stats = row.stats
                r.total_requests += stats.total_requests
                r.served_total += stats.served_total
                r.blocked_total += stats.blocked_total
                r.idfa_frq_errors += stats.idfa_frq_errors
                r.ip_frq_errors += stats.ip_frq_errors
                r.bl_errors += stats.bl_errors
                r.isp_errors += stats.isp_errors
                r.connection_errors += stats.connection_errors
                r.subs_errors += stats.subs_errors
                r.other_errors += stats.other_errors
                r.group_pause_errors += stats.group_pause_errors
                r.idfa_only_errors += stats.idfa_only_errors 
                r.delivery_frq_errors += stats.delivery_frq_errors

            })

            if (r.total_requests > 0) {
                r.blocked_total_percentage = (r.blocked_total / r.total_requests) * 100
            }

            this.sumRow = r
        },
        onRowClick(item) {
            this.$router.push({
                name: "SmartLinksVTACampaignView",
                params: {
                    campId: item.id
                }
            })
        },
        getGroupsData: async function () {
            try {
                const r = await this.$http.get(this.resources.SmartLinksCampaigns.getSlGroups);
                const data = await r.json();
                this.groups = await r.json()
                this.groupList = data;

                this.groupList.unshift({
                    id: 0,
                    name: "none"
                })
            } catch (err) {
                this.notifyError("Could fetch groups.");
            }
        },
        setGroupHandler: async function (sl) {
            try {
                sl.group_forced = false
                const r = await this.$http.put(this.resources.SmartLinksCampaigns.setCampaignGroup(sl.id), {
                    group_id: sl.group_id
                })
                this.notifySuccess(`Campaign ${sl.name} group changed succesfully.`)
            } catch (err) {
                this.notifyError(`Campaign ${sl.name} group didn't change. please try again`)
            }
        },
    },
    created() {
        this.getGroupsData()
        let yes = new Date()
        yes.setDate(yes.getDate() - 1)

        this.reportForm = {
            startDate: yes.toISOString().substring(0, 10),
            endDate: yes.toISOString().substring(0, 10)
        }

        this.reportLoading = true
        var data = this.appState.smartlinks.campaigns
        var arr = data.filter(item => item.is_vta === true)
        arr.forEach((elem, index) => {
            if (elem.stats.blocked_total_percentage > 20) {
                arr[index] = {
                    ...elem,
                    _cellVariants: {
                        lost_percentage: 'danger'
                    },
                    expanded: false
                };
            }
        })
        this.slList = arr
        this.calculateRowsTotal()
        this.filterData()
        this.sortReport()
        this.reportLoading = false
    },
}
</script>

<style scoped>
.upper-table {
    margin-top: 5%;
}

.r-vta-mark {
    color: firebrick;
    font-size: 10px;
}

.r-cta-mark {
    color: #00a1ff;
    font-size: 10px;
}

/deep/ .modal-lg {
    max-width: 1300px;
}

.info-button,
.info-button:hover {
    background-color: transparent;
    border-color: transparent;
}

.info-icon:hover {
    transform: scale(1.5) perspective(1px)
}

.info-button:active {
    transform: translate(0px, 5px);
    -webkit-transform: translate(0px, 5px);
    box-shadow: 0px 1px 0px 0px;
}

.sticky-column {
    position: sticky;
    background-color: #fff;
    z-index: 1;
}
</style>
