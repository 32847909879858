<template lang="html">
  <div class="all">

    <h3 class="replace-top-br-advId">Sources</h3>

    <Table v-if='sources'
    tableClass="table-12341"
    :search='false'
    :apiUrl='this.resources.sourcesControl.advSources(advId)'
    :data='sources'
    :autoFetch='false'
    :fields='{id: "Source ID"}'
    :askBeforeDelete='1'
    :permissions='{delete:1}'
     />

   <h3 class="replace-top-br-advId">Add new sources</h3>
   <hr>
   <h5>Custom Source</h5>
    <form class="form-section" @submit.prevent='submit'>
      <div class="form-div">
        <label >Source ID:</label>
        <input class="def" v-model='form.id'>
        <button type="submit" class="btn btn-success">Create</button>
      </div>
    </form>

  <hr>
  <h5>Multiple Sources with csv file</h5>

  <form enctype="multipart/form-data">
    <label >Upload CSV File</label>
    <input type="file" accept=".csv" class="input-file" @change='filesChange' ref='file-input-2'
    >
  </form>

  <Modal v-if='fileUpload.content'>
    <div class="fileupload">
      <h3>Advertiser "{{advId}}" new sources</h3>
      <ul>
        <li v-for='c, i in fileUpload.content'>
          <span class="index-2131">
          {{i+1}}
        </span>
        <span>
          {{c}}
        </span>
        </li>
      </ul>

      <div class="btns-2">
        <button type="button" class="btn" @click='cancelFileUpload'>Cancel</button>
        <button type="button" class="btn btn-success" @click='saveCsvFileContent'>Save</button>
      </div>
    </div>
  </Modal>

  </div>
</template>

<script>
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  components: {
  },
  data() {
    return {
      form: {
        id: null
      },
      sources: null,
      fileUpload: {
        currentStatus: null,
        content: null
      }
    }
  },
  computed: {
    advId() {
      return this.$route.params.advId
    }
  },
  watch: {
    advId() {
      this.updateAdv()
    }
  },
  methods: {
    saveCsvFileContent() {

      this.sendSourcesToServer(this.fileUpload.content)

      let f = this.$refs['file-input-2']
      f.value = ''
      this.fileUpload.content = null
    },
    cancelFileUpload() {
      let f = this.$refs['file-input-2']
      f.value = ''
      this.fileUpload.content = null
    },
    filesChange(e) {
      let files = e.target.files
      if(files.length == 0) {
        return
      }

      let file = files[0]
      var reader = new FileReader()
      reader.onload = () => {
        let content = reader.result
        this.fileUpload.content = content.split('\n').filter(v => v !== '')
      }
      reader.readAsBinaryString(file)
    },
    submit() {
      let form = this.form

      if(!form.id) {
        this.notifyError("Please fill in Source ID")
        return
      }

      this.$http.post(this.resources.sourcesControl.advSources(this.advId), [form.id])
        .then(() => {
          this.sources.push({id: form.id})
          this.form = {
            id: null
          }
          this.notifySuccess("Inserted new source")
        })
        .catch(err => {
          console.log(err);
          this.notifyError("Error occured")
        })
    },
    sendSourcesToServer(sources) {
      this.$http.post(this.resources.sourcesControl.advSources(this.advId), sources)
        .then(() => {
          Array.prototype.push.apply(this.sources, sources.map(v => ({id: v})))
          this.form = {
            id: null
          }
          this.notifySuccess(`Inserted ${sources.length} new sources`)
        })
        .catch(err => {
          console.log(err);
          this.notifyError("Error occured")
        })
    },
    updateAdv() {
      let advs = this.$store.state.sourcesControl.advs
      let foundAdv = advs.find(v => v.id === this.advId)
      if(!foundAdv) {
        this.sources = []
        return
      }
      this.sources = foundAdv.sources || []
    }
  },
  created() {
    this.updateAdv()
  },
}
</script>

<style lang="css" scoped>
.fileupload {

}
.fileupload ul {
  max-height: 67vh;
  overflow-y: auto;
}
.fileupload ul {
  font-size: 14px;
  border: 1px solid #d8d8d8;
  padding: 6px;
}
.fileupload .btns-2 {
  margin-top: 4px;
  /* border-top: 1px solid #949494; */
  padding-top: 9px;
}
.index-2131 {
  width: 42px;
  display: inline-block;
  color: #929292;
}


.replace-top-br-advId, .form-div{
  margin-top: 20px;
}
</style>
