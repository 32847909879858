import Campaigns from './views/Home.smartlinks.vue'
import CampaignEdit from './in-view/EditCampaign.smartlinks.vue'
import CampaignView from './views/Campaign.smartlinks.vue'
import Placement from './views/Placement.smartlinks.vue'
import Offer from './views/Offer.smartlinks.vue'
import Home from './loaders/Campaigns.smartlinks.vue'
import CampaignsV2 from './views/Home.smartlinksV2.vue'
import CampId from './loaders/CampId.smartlinks.vue'
import PlacementId from './loaders/PlacementId.smartlinks.vue'
import VTACampaigns from './views/VTA.smartlinks.vue'
import blacklisting from './blacklisting'
import SlGroups from './views/SlGroups.smartlinks.vue'
import GroupId from './loaders/GroupId.smartlinks.vue'
import GroupView from './views/Group.smartlinks.vue'
import RelatedOffers from './groups-related-offers-detals/SlGroups.RelatedOffers.vue'
import OfferSearch from './views/OfferSearch.smartlinks.vue'
import GlobalOffers from './global-offers/Home.global-offers.vue'
import GlobalOffersEdit from './global-offers/Home.global-offers-edit.vue'
import GlobalOffersUpdateRelated from './global-offers/Home.global-offers-update-related.vue'
import GlobalOfferMain from './views/GlobalOfferMain.smartlinks.vue'
import GlobalOfferAdvanced from './views/GlobalOfferAdvanced.vue'
import GlobalOfferScraper from './views/GlobalOfferScraper.vue'
import HomeGlobalOfferScraper from './global-offers/Home.global-offers-scraper.vue'
import GlobalOfferV2 from './global-offers/global-offers-v2.vue'
export default {
  Campaigns, SlGroups, CampaignEdit, Placement, Home, CampId, GroupId, PlacementId, CampaignView, GroupView, Offer,
  blacklisting, VTACampaigns, RelatedOffers, OfferSearch, GlobalOffers, GlobalOffersEdit, GlobalOffersUpdateRelated,
  GlobalOfferMain, GlobalOfferAdvanced, CampaignsV2, GlobalOfferScraper, HomeGlobalOfferScraper, GlobalOfferV2
}
