<template>
    <div>
        <div v-if="is_loading">
            <b>Loading ...</b>
        </div>
        <div v-else>
            <div>
                <a href="/dom/domains-view">Back</a>
                <h1 v-if="!is_edit">Create Domain</h1>
                <h1 v-if="is_edit">Edit Domain</h1>
                <br><br>
                Domain ID: <b>{{ id }}</b>
                <br><br>
            </div>
            
            <b-modal v-model="open_info" title="IP Rules"  @hide="open_info = false" hide-footer>
                <div>
                    <strong>Please Double Check IP</strong> 
                </div>
            </b-modal> 

            <form @submit.prevent="">
                <div id="general-fields">
                    <h4>General Fields</h4>
                    <br>
                    <div class="field-container">
                        <!-- hardcoded list  Serving, Postback, CDN, Affise Tracking, Admin -->
                        <label >Type</label>
                        <b-form-select :disabled="is_edit && form.type !== '' && id !== 0" class="date-picker pub-id-input" v-model="form.type" :options="types"></b-form-select>
                    </div>
                    
                    <div class="field-container">
                        <label>Name</label>
                        <input v-model.trim='form.name' class="pub-id-input form-control">
                    </div>

                    <div class="field-container">
                        <!-- required -->
                        <label>Status</label>
                        <b-form-select aria-required="true" class="date-picker pub-id-input" v-model="form.status" :options="[{text:'Active', value: 'active'}, {text:'Pause', value: 'pause'}, {text:'Pending', value: 'pending'}]"></b-form-select><br /><br />
                    </div>


                    <div class="field-container">
                        <label >Date</label>
                        <b-form-datepicker locale="en" size="sm" class="date-picker pub-id-input" v-model="form.date"></b-form-datepicker>
                    </div>

                    <br>
                </div>

                <div id="admin-fields" v-if="form.type === 'admin'">
                    <h4>Admin Fields</h4>
                    <br>

                    <div class="field-container">
                        <label >Comments</label>
                        <input v-model='form.admin_comments' class="pub-id-input form-control">
                    </div>
                    <br>
                </div>

                <div id="affise-tacking-fields"  v-if="form.type === 'affise_trackin'">
                    <h4>Affise Tracking Fields</h4>
                    <br>
                    <div class="field-container">
                        <label >Default DSP</label>
                        <!-- multi select -->
                 
                        <div class="multi-checkbox-div" v-for="dsp in dsps" :key="dsp.id">
                            <input :checked="default_dsp_map[dsp.value]" :id="dsp.id" type="checkbox" class="date-picker pub-id-input form-control" @click="selectDsp(dsp)">
                            <label :for="dsp.id">{{ dsp.value }}</label>
                        </div>
                           

                            
                    </div>

                    <div class="field-container">
                        <label >Comments</label>
                        <input v-model='form.dsp_comments' class="pub-id-input form-control">
                    </div>
             
                    <br>
                </div>


                <div id="postback-fields" v-if="form.type === 'postback'">
                    <h4>Postback Fields</h4>
                    <div class="field-container">
                        <label >Adjust Status</label>
                        <!-- multi select -->
                        <b-form-select class="date-picker pub-id-input" v-model="form.adjust_status" :options="[{text:'WL', value: 'wl'}, {text:'BL', value: 'bl'}]"></b-form-select>
                        <div v-if="form.adjust_status_by_user.length !== 0 && form.adjust_status_ts.length !== 0">
                           Last changed on <span>{{ form.adjust_status_ts }}</span> by  <span>{{ form.adjust_status_by_user }}</span>
                        </div>
                    </div>

                    <div class="field-container">
                        <label>Comments</label>
                        <input v-model='form.affise_tracking_comments' class="pub-id-input form-control">
                    </div>
                    <br>
                </div>

                <div id="cdn-fields" v-if="form.type === 'cdn'">
                    <h4>CDN Fields</h4>
                    <br>
                    <div class="field-container">
                        <label >Default CDN</label>
                        <input type="checkbox" class="date-picker pub-id-input form-control"  @click="selectDefaultCdn" v-model="default_cdn" :value="true" :unchecked-value="false">
                        <div v-if="form.default_cdn_by_user.length !== 0 && form.default_cdn_ts.length !== 0">
                           Last changed on <span>{{ form.default_cdn_ts }}</span> by  <span>{{ form.default_cdn_by_user }}</span>
                        </div>
                    </div>

                    <div class="field-container">
                        <label >Associated Serving Domain</label>
                        <!-- should be disabled input only for displayng the related domain -->
                        <input v-model='form.domain_name' :disabled="true" class="pub-id-input form-control">
                    </div>

                    <div class="field-container">
                        <label>Comments</label>
                        <input v-model='form.cdn_comments' class="pub-id-input form-control">
                    </div>
                    <br>
                </div>

                <div id="serving-fileds" v-if="form.type === 'serving'">
                    <h4>Serving Fields</h4>
                    <div class="field-container">
                        <label ><span @click="open_info = !open_info" ><b-icon class="icon-info" icon="exclamation-circle-fill" variant="warning"></b-icon></span>  IP</label>
                        <b-form-select class="date-picker pub-id-input" v-model="form.ip" :options="ips"></b-form-select>
                    </div>

                    <div class="field-container">
                        <label >TMT Status</label>
                        <b-form-select class="date-picker pub-id-input" v-model="form.tmt_status" :options="[{text:'WL', value: 'wl'}, {text:'BL', value: 'bl'}]"></b-form-select>
                        <div v-if="form.tmt_status_by_user.length !== 0 && form.tmt_status_ts.length !== 0">
                            Last changed on <span>{{ form.tmt_status_ts }}</span> by  <span>{{ form.tmt_status_by_user }}</span>
                        </div>
                    </div>

                    <div class="field-container">
                        <label >Traffic Type</label>
                        <b-form-select class="date-picker pub-id-input" v-model="form.traffic_type" :options="traffic_types"></b-form-select>
                    </div>

                    <div class="field-container">
                        <label >DSP</label>
                        <!-- render list by ip type - use watch based on ip change to change the rendered list -->
                        <b-form-select class="date-picker pub-id-input" v-model="form.dsp" :options="dsp_list"></b-form-select>
                    </div>

                    <div class="field-container" style="position: relative;">
                        <label >SSP</label>
                        <input type="text" v-model.trim="form.ssp" @keyup="autoc" @focus="autoc" class="def input-c-141 pub-id-input">
                        <div class="autoc" v-if="show_autoc">
                            <div v-for="row in autoc_res">
                                <div @click="autoc_select" class="autoc_item">
                                    {{ row }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field-container">
                        <!-- omit the selected cdn by id from the list -->
                        <label >CDN</label>
                        <b-form-select class="date-picker form-control pub-id-input" v-model="form.cdn" :options="cdns"></b-form-select> 
                    </div>

                    <div class="field-container">
                        <!-- omit the selected cdn by id from the list -->
                        <label >Affise Tracking</label>
                        <b-form-select class="date-picker pub-id-input" v-model="form.affise_tracking" :options="affise_tracking_list"></b-form-select>
                    </div>

                    <div class="field-container">
                        <label >Tag Version</label>
                        <b-form-select class="date-picker pub-id-input" v-model="form.stags_version" :options="stags_versions"></b-form-select>
                    </div>

                    <div class="field-container">
                        <label>TMT Tag Name</label>
                        <input v-model='form.tmt_tag_name' class="pub-id-input form-control">
                    </div>

                    <div class="field-container">
                        <label>Comments</label>
                        <input v-model='form.serving_comments' class="pub-id-input form-control">
                    </div>
                    <br>
                </div>

                <br>
                <button class="btn-success" @click="saveDomainData('leave')">Save</button>
                <button class="btn-success" @click="saveDomainData('stay')">Save & Stay</button>
            </form>
        </div>
    </div>
</template>
<script>
import moment  from 'moment';
    export default {
        data() {
            let today = moment().format('YYYY-MM-DD');
            return {
                is_loading: false,
                form: {
                    dsp: '',
                    ssp: '',
                    stags_version: '',
                    cdn: '',
                    cdn_name: '',
                    comments: '',
                    status: 'pending',
                    traffic_type: '',
                    ip: '15.197.194.50',
                    tmt_status: 'wl',
                    type: 'serving',
                    default_dsp: '',
                    dsp_comments: '',
                    admin_comments: '',
                    adjust_status: 'wl',
                    affise_tracking_comments: '',
                    default_cdn: false,
                    default_cdn_ts: '',
                    default_cdn_by_user: '',
                    cdn_comments: '',
                    serving_cdn: '',
                    affise_tracking: '',
                    date: today,
                    adjust_status_by_user: '',
                    adjust_status_ts: '',
                    tmt_status_ts: '',
                    tmt_status_by_user: '',
                    domain_name: '',
                },
                default_cdn: false,
                default_cdn_name: '',
                default_dsp_map: {},
                is_edit: false,
                traffic_types: [],
                affise_tracking_by_dsp: [],
                dsps: [],
                ssps: [],
                stags_versions: [],
                cdns: [],
                autoc_res: [],
                show_autoc: false,
                open_info: false,
                ips: [
                    {
                        text: 'Default - 15.197.194.50',
                        value: '15.197.194.50'
                    },
                    {
                        text: '75.2.32.48',
                        value: '75.2.32.48'
                    },
                    {
                        text: '99.83.141.3',
                        value: '99.83.141.3'
                    },
            
                ],
                types: [
                    {
                        text: 'Serving',
                        value: 'serving'
                    },
                    {
                        text: 'Postback',
                        value: 'postback'
                    },
                    {
                        text: 'CDN',
                        value: 'cdn'
                    },
                    {
                        text: 'Affise Trackin',
                        value: 'affise_trackin'
                    },
                    {
                        text: 'Admin',
                        value: 'admin'
                    },
                ],
                type: '',
                all_cdns: [],
            }
        },
        async created() {
            this.is_loading = true;
            let { id, type } = this.$route.query;
            this.id = parseInt(id);
            this.type = type;
            if (typeof this.id === 'undefined' || isNaN(this.id)) {
               return;
            }
           
            await this.getOptions();
            if (this.id > 0) {
                this.is_edit = true;
                await this.fetchDomainData();
            } else {
                await this.fetchUnusedCdns();
            }

            this.is_loading = false;
        },
        computed: {
            dsp_list() {
                if (this.form.ip === '15.197.194.50') {
                    return this.dsps;
                }
                if (this.form.ip === '75.2.32.48') {
                    return this.dsps.filter(val => {
                        return val.text === 'Kayzen'
                    })
                }

                if (this.form.ip === '99.83.141.3') {
                    return this.dsps.filter(val => {
                        return (val.text === 'Apptimus' || val.text === 'Bucksense')
                    })
                }
            },
            affise_tracking_list() {
                if (this.form.dsp !== '') {
                    return this.affise_tracking_by_dsp[this.form.dsp];
                }
            }
        },
        methods: {
            isValidDomain(domain) {
                domain = domain.replace(/^https:\/\//, '');
                const domainRegex = /^(?!:\/\/)([a-zA-Z0-9\.]+\.)?[a-zA-Z0-9\.][a-zA-Z0-9-\.]+\.[a-zA-Z]{2,11}?$/;
                return domainRegex.test(domain);
            },
            async fetchUnusedCdns() {
                try {
                    const r = await this.$http.post(this.resources.Domains.getUsedCdns, {
                        id: this.id,
                        type: this.type
                    });
                    const data = await r.json();
                    this.cdns = data.unused_cdns;

                } catch (err) {
                    console.log(err);
                }
            },
            async fetchDomainData() {
                try {
                    const r = await this.$http.post(this.resources.Domains.getSmartlinksDomainbyId, {
                        id: this.id,
                        type: this.type
                    });
                    const d = await r.json();
                    const data = d.domain;
                    this.cdns = d.unused_cdns;
                    this.default_cdn_name = d.default_cdn_name;
                    this.form = data;
                    if (this.form.default_dsp) {
                        let tmp = this.form.default_dsp.split(',');
                        for (const k in tmp) {
                            this.$set(this.default_dsp_map, tmp[k], true);
                        }
                    }
                    this.default_cdn = this.form.default_cdn ? true : false;
                } catch (err) {
                    console.log(err);
                }
            },
            async getOptions() {        
                const r = await this.$http.get(this.resources.Domains.getDomainsOptions, {});
                this.ssps = r.data.ssps;
                this.dsps = r.data.dsps;
                this.traffic_types = r.data.traffic_types;
                this.stags_versions = r.data.stags_versions;
                this.all_cdns = r.data.cdns;
                this.affise_tracking_by_dsp = r.data.affise_tracking_by_dsp;
            },
            async saveDomainData(action) {

                // validate domain name 
                const regex = /^https:\/\//;
                if (!regex.test(this.form.name)) {
                    this.notifyError('Domain name should start with https://');
                    return;
                }
                if (this.form.name.includes(' ')) {
                    this.notifyError('Domain name should not contain spaces');
                    return;
                }
                if (! this.isValidDomain(this.form.name)) {
                    this.notifyError('Invalid domain name');
                    return;
                }

                if (Object.keys(this.default_dsp_map).length !== 0) {
                    let tmp = [];
                    for (const k in this.default_dsp_map) {
                        tmp.push(k);
                    }
                    this.form.default_dsp = tmp.join(',');
                }

                try {
                    const r = await this.$http.post(this.resources.Domains.saveSmartlinksDomain, {
                        ...this.form
                    });

                    const data = await r.json();
                    console.log(data);
                    if (data.res === 'OK' ) {
                        this.notifySuccess('Domain saved successfully');
                        if (action === 'stay') {
                            if (data.id === 0 && this.id !== 0) {
                            window.location.reload();
                            } 
                            if (data.id !== 0 && this.id === 0) {
                                window.location.href = '/dom/domains-edit?id=' + data.id +'&type=' + this.form.type;
                            }
                        } else if (action === 'leave'){
                            this.$router.push('/dom/domains-view');
                        }
                    } else {
                        this.notifyError('Error saving domain - ' + data.msg);
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            selectDsp(dsp) {
                this.$nextTick(() => {
                   
                    if (this.default_dsp_map[dsp.value] && typeof this.default_dsp_map[dsp.value] !== 'undefined') {
                        Reflect.deleteProperty(this.default_dsp_map, dsp.value);
                    } else {
                        this.$set(this.default_dsp_map, dsp.value, true);
                    }
                });
               
            },
            autoc: async function(e) {
                this.autoc_res = []
                for (let k in this.ssps) {
                    if (this.ssps[k].text.startsWith(e.target.value)) {
                        this.autoc_res.push(this.ssps[k].text)
                    }
                }
                if (this.autoc_res.length > 0) {
                    this.show_autoc = true
                } else {
                    this.show_autoc = false
                }
            },
            autoc_select: function(e) {
                this.show_autoc = false
                this.form.ssp = e.target.innerHTML.trim()
            },
            selectDefaultCdn(e) {
                if (e.target.checked) {
                    const is_confirm = confirm('Are you sure you want to change default CDN from ' + this.default_cdn_name + '?');
                    if (is_confirm) {
                        this.default_cdn = true;
                        this.form.default_cdn = true;
                    } else {
                        e.target.checked = false;
                        this.default_cdn = false;
                        this.form.default_cdn = false;
                    }
                }
            },
        }

    }

</script>
<style scoped>

    .autoc {
        position: absolute; 
        top: 30px; 
        left: 270px; 
        background-color: white; 
        cursor: pointer; 
        padding: 5px; 
        border: 1px solid gray;
        max-height: 200px;
        overflow-y: auto;
    }

    .autoc_item {
        padding: 2px;
    }

    .autoc_item:hover {
        background-color: rgb(207, 223, 249);
        color: white;
    }

    .selected{
        background-color:rgb(207, 223, 249);
    }

    .not-selected{
        background-color: transparent;
    }

    .icon-info {
        cursor: pointer;
    }

    .multi-checkbox-div {
        display: contents;
        margin-left: 10px;
    }

    .multi-checkbox-div input {
        margin-right: 5px;
        margin-left: 15px;
    }

    button {
        margin-right: 10px;
    }
</style>