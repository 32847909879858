<template lang="html">
        <div>
            <a href="/apps/script-tags/advertisers-manager">Back</a>
            <div v-if="process_status === 'Loading'">
                Loading...
            </div>
            <div v-if="process_status === 'Done'">
                Done!
            </div>
            <form v-if="process_status === ''"  @submit.prevent='saveAdvertiser'>
                Company Name:<br />
                <b-form-input :type="'text'" class="input-2" placeholder="" v-model="advertiserForm.adv_company_name"></b-form-input><br/>

                <!-- Company Logo:<br />
                <input type="file" accept="image/*" class="def-file" ref='logo_file' @change="saveCampLogo"/>
                <a href='javascript:void(0);' target="_blank">
                    <img class="img" :src='advertiserForm.adv_company_logo_link'>
                </a><br /><br /> -->
                CSM:<br />
                <b-form-select v-model="advertiserForm.adv_csm_id" :options="csmRows"></b-form-select><br /><br />
                <br/>

                Affise Advertiser Name:<br />
                <b-form-input v-model="advertiserForm.affise_adv_name" type="text" placeholder="Please copy Advertiser name as it in Affise"/> <br />

                <div class="var_adv_input">
                    Var Advertiser:<br />
                    <input style="margin-left: 2%;" v-model="advertiserForm.new_var_adv" type="checkbox" :value="true" /> 
                </div><br />

                Affise adv ID:<br />
                <b-form-input v-model="advertiserForm.affise_adv_id" class="input-2" type="text" placeholder="Please copy Advertiser ID as it in Affise"/> <br />
                <hr />
                <br />
                Platform Login:<br />
                <b-form-input class="input-2" placeholder="" v-model="advertiserForm.adv_platform_login"></b-form-input>
                <a v-if="id > 0" :href="advertiserForm.adv_platform_login" target="_blank" rel="noreferrer noopener">
                    Go To Link
                </a><br/>

                User Name:<br />
                <b-form-input class="input-2" placeholder="" v-model="advertiserForm.adv_user_name"></b-form-input><br/>

                Password:<br />
                <b-form-input :type="'text'" class="input-2" placeholder="" v-model="advertiserForm.adv_password"></b-form-input><br/>
                <hr />
                <b>Contact: </b> <br /><br />

                Name:<br />
                <b-form-input class="input-2" placeholder="" v-model="advertiserForm.adv_name"></b-form-input><br/>

                Email:<br />
                <b-form-input :type="'text'" class="input-2" placeholder="" v-model="advertiserForm.adv_email"></b-form-input><br/>

                Skype:<br />
                <b-form-input class="input-2" placeholder="" v-model="advertiserForm.adv_skype"></b-form-input><br/>

                Address:<br />
                <b-form-input :type="'text'" class="input-2" placeholder="" v-model="advertiserForm.adv_address"></b-form-input><br/>

                Notes: <br />
                <b-form-textarea v-model="advertiserForm.adv_notes" placeholder="Enter something..." rows="3" max-rows="6"></b-form-textarea><br />

                <br />
                <br />
                <b-button id="input-5" type="submit" variant="success">Save</b-button>

                
            </form>
        </div>
</template>
<script>
    
export default {
    data() {
        return {
            process_status: '',
            advertiserForm: {
                id: this.id,
                adv_company_name:'',
                adv_company_logo_link: '',
                adv_csm_id: 0,
                adv_platform_login: '',
                adv_user_name: '',
                adv_password: '',
                adv_name: '',
                adv_email: '',
                adv_skype: '',
                adv_address: '',
                adv_notes: '',
                adv_var_adv: true,
                affise_adv_id: '',
                affise_adv_name: '',
                new_var_adv: false

            },
            id: 0,
            csmRows: [],
        }
    },
    async created(){
        await this.getData() 
        this.id = this.$route.query.id

        if (this.id !== 0) {
            await this.getAdvData(this.id)
        }
    },
    methods: {
        async getAdvData(id) {
            try {
                const data = await this.$http.post(this.resources.Management.getAdvertisers(id))
                if (data.body.res === 'OK') {
                    let res = await data.body.data
                    this.advertiserForm = res
                }
            } catch (err) {
                console.log(err);
            }
            
        },
        async getData() {
            try {
                const data = await this.$http.post(this.resources.Management.getCsmList)
                if (data.body.res === 'OK') {
                
                    let res = await data.body.data
                    this.csmRows = res.map(csmObj => {
                        return {
                            value: csmObj.id,
                            text: csmObj.csm
                        }
                    })
                    this.csmRows.unshift({
                        value: 0,
                        text: 'None'
                    })
                }
            } catch (err) {
                console.log(err);
            }
            
        },
        async validateAffiseApi() {

            this.process_status = 'Loading';

            // Send a requests trught be and donat allow saving befor an adv id is valid
            try {
                console.log(this.resources.Management.validateAffiseAdvertiser);
                let response = await this.$http.post(this.resources.Management.validateAffiseAdvertiser, {
                    affise_adv_id: this.advertiserForm.affise_adv_id
                });
               
                let data = await response.json();
                if (data.res !== 'OK') {
                    this.affise_adv_id_validation = false;
                    this.notifyError('\'Affise Adv ID\' Field - Error occured while sending requests to Affise API.');
                    return false;
                } else {

                    if (typeof data.data.error !== 'undefined') {
                        this.affise_adv_id_validation = false;
                        this.notifyError('\'Affise Adv ID\' Field -' + data.data.error);
                        return false;
                    }

                    if (typeof data.data.advertiser !== 'undefined') {
                        // FE Branch 1315 
                        // this.notifySuccess(`\'Affise Adv ID\' Field - is valid in Affise with status ${data.data.status === 1 ? 'Active' : 'Not Active'}`);
                        return true;
                    }
                }

            } catch (err) {
                console.log(err);
            } finally {
                this.process_status = '';
            }
        },
        async saveCampLogo() {
            try {
                const formData = new FormData();
                formData.append('file', this.$refs.logo_file.files[0]);
                const res = await this.$http.post(this.resources.Management.saveAdvertiserLogo, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (res.body.logo_link) {
                    this.advertiserForm.adv_company_logo_link = res.body.logo_link;
                }
            } catch (err) {
                console.log(err)
            }
        },
        async saveAdvertiser(e) {
            e.preventDefault()
            // “Company Name”, “Affise advertiser name”, “CSM”,
            let validate_field = '';
            if (this.advertiserForm.adv_company_name === '') {
                validate_field = 'Company Name';
            } else if (this.advertiserForm.adv_csm_id === '') {
                validate_field = 'CSM';
            } else if (this.advertiserForm.affise_adv_name === '') {
                validate_field = 'Affise Advertiser Name';
            }

            if (validate_field.length > 0) {
                this.notifyError('Please fill in advertiser data - ' + validate_field);
                return;
            }

            // In case Var Advertiser is checked “Affise adv ID” is also mandatory
            if (this.advertiserForm.adv_var_adv) {
                if (this.advertiserForm.affise_adv_id === '') {
                    this.notifyError('Please enter \'Affise Adv ID\' since you are using Var Advertiser.');
                    return ;
                }
                let affise_adv_id_validation = await this.validateAffiseApi();
                if (!affise_adv_id_validation) {
                    return;
                }
            }

            try {
                this.process_status = 'Loading';
                const data = await this.$http.post(this.resources.Management.saveAdvertiser, {
                    ...this.advertiserForm,
                    adv_var_adv: this.advertiserForm.adv_var_adv === true ? 1 : 0
                });

                if (data.body.res === 'NOK') {
                    this.process_status = '';
                    if (data.body.msg.length > 0) {
                        this.notifyError(data.body.msg);
                        return;
                    }
                }
                
                if (data.body.res === 'OK') {
                    // go to datatable
                    this.process_status = 'Done';
                    await new Promise(r => setTimeout(r, 1500))
                    window.location =  '/apps/script-tags/advertisers-manager';
                }
                
            } catch (err) {
                console.log(err);
            }
           
        },
    },    
}
</script>
<style scoped>

form {
    width: 50%;
}

.img {
    max-width: 350px;
}

.var_adv_input {
    display: flex;
    flex-direction: row;
}

</style>