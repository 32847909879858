<template lang="html">
  <div class="" v-if='loaded'>
    <h1>Advertisers</h1>

    <Table v-if='advs'
    tableClass="table-12341"
    :search='false'
    :apiUrl='this.resources.sourcesControl.advs'
    :data='advs'
    :autoFetch='false'
    :fields='{id: "Adv ID", name: "Name", description: "Description"}'
    :askBeforeDelete='1'
    :onRowClick='onRowClick'
    :isRowActive='isRowActive'
    :permissions='{delete:1}'
    :onDelete='afterAdvDelete'
     />

    <br>
    <h3>Create a new Advertiser</h3>

    <button type="button" class="btn btn-outline-primary" @click='newAdvDisplayClick'>New Advertiser</button>

    <AdvForm :onCreate='onAdvCreate' v-if='newAdvDisplay' />

    <router-view></router-view>
  </div>
</template>

<script>
import AdvForm from '../shared/AdvForm.sources'
export default {
  components: {
    AdvForm
  },
  data() {
    return {
      advs: [],
      newAdvDisplay: false,
      loaded: false
    }
  },
  created() {
    this.$http.get(this.resources.sourcesControl.advs)
      .then(r => r.json())
      .then(data => {
        data = data || []
        this.advs = data
        this.$store.state.sourcesControl.advs = this.advs
        this.loaded = true
      })
  },
  computed: {
    advId() {
      return this.$route.params.advId
    }
  },
  methods: {
    afterAdvDelete() {
      this.$router.push({ name: "SourcesControl"})
    },
    newAdvDisplayClick() {
      this.newAdvDisplay = !this.newAdvDisplay
      if(this.advId) {
        this.$router.push({name:"SourcesControl"})
      }
    },
    isRowActive(v) {
      return v.id == this.advId
    },
    onAdvCreate(form) {
      this.advs.push(form)
    },
    onRowClick(v) {
      this.$router.push({ name: "SourceControlAdvId", params: {advId: v.id}})
      this.newAdvDisplay = false
    },
  }
}
</script>

<style lang="css" scoped>
</style>
