<script>
import { Bar } from 'vue-chartjs'
export default {
    extends: Bar,
    props: {
        chartDataProp: {},
        chartOptionsProp: {}
    },
    data() {
        return {
            chartData: this.chartDataProp,
            chartOptions: this.chartOptionsProp
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.chartOptions)
    }
}
</script>