<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td>
                            CDN Base Url
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.cdn_url }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.cdn_url" placeholder="CDN Base Url" />
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRow(row.id)">Edit</a><a class="btn btn-primary" href="javascript:void(0);" @click="deleteEntity(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.cdn_url" placeholder="CDN Base Url" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list_id: this.$route.params.id,
            newRow: {
                id: 0,
                cdn_url: '',
            },
            rows: [],
            showNewRow: true,
            isAdmin: true
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    methods: {
        async save(e) {
            e.preventDefault();
            // let validationMsg = await this.validateRowData(this.newRow.dm_appsflyer_name, this.newRow.dm_appsflyer_click_cap, this.newRow.dm_appsflyer_notification_click_cap);
            // if (validationMsg != "") {
            //     this.notifyError(validationMsg);
            //     this.getListData();
            //     return;
            // }

            const rowReq = this.newRow

            const data = await this.$http.post(this.resources.Management.saveDmCdn, {
                ...this.newRow
            });
            this.newRow.cdn_url = "";
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getCdnData, {});
            for (let k in data.body.data) {
                data.body.data[k].edit = false
            }
            this.rows = data.body.data
        },
        async deleteEntity(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteDmCdn, {
                id,
            });
            this.getListData();
        },
        async editRow(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    editRowData = this.rows[k];
                }
            }
            // let validationMsg = await this.validateRowData(editRowData.dm_appsflyer_name, editRowData.dm_appsflyer_click_cap, editRowData.dm_appsflyer_notification_click_cap);
            // if (validationMsg != "") {
            //     this.notifyError(validationMsg);
            //     return;
            // } 
            
            this.showNewRow = true;
            const data = await this.$http.post(this.resources.Management.saveDmCdn, {
                ...editRowData
            });
            this.getListData()
            
        },
    },
    components: {  }
}
</script>

<style scoped>
.link-div {
    padding-top: 40px;
    text-align: center;
}
</style>
