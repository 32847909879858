<template>
    <div>
        <b-modal v-model="showDeleteModal" @ok="deleteGlobalOffer(globalOfferSelected)">Are you sure that you want to delete global offer - {{ globalOfferSelected.name}}</b-modal>
        <div style="width: 100%;">
            <h2>Global Offers</h2>
            <br>
            <br>
            <!-- date range -->
            <form @submit.prevent=''>
                <div>
                    <div>
                        <date-range-picker
                            ref="picker"
                            v-model="dateRange"
                            opens="right"
                            :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                            :ranges="ranges"
                            @update="logEvent"
                            @toggle="logEvent('toggle', $event)"
                            @start-selection="logEvent('event: startSelection', $event)"
                            @finish-selection="logEvent('event: finishSelection', $event)"
                            :maxDate="maxDate"
                        >
                        </date-range-picker>
                    </div> 
                </div>
                <div class="div-1412">
                    <i class="spinner fa fa-circle-o-notch fa-spin" v-if='loading'></i>
                </div>
            </form>
            <br>
            <div>
                <a href="/apps/smartlinks/global-offers-edit?id=0&isVta=0">Create New Global Offer</a>
            </div>
            <div>
                <a href="/apps/smartlinks/global-offers-edit?id=0&isVta=1">Create New VTA Global Offer</a>
            </div>
        </div>
        <br>
        <GlobalOfferDataTable :tableHeight="'600px'" v-if="!loading" :rows_data="globalOffers"  :fetchGlobalOffers="fetchGlobalOffersData" :advertisers="advList" :csms="csmlist"></GlobalOfferDataTable>
        <div v-if="loading">Loading ...</div>

    </div>
</template>

<script>

import moment from 'moment'
import GlobalOfferDataTable from '../components/GlobalOfferDataTable.vue'
import DateRangePicker from 'vue2-daterange-picker'

export default {
    components:{GlobalOfferDataTable, DateRangePicker},
    
    data() {
        let today = new Date()
        let yesterday = today.setDate(today.getDate() - 1)

        let dd = new Date()
        let startDate = dd.setDate(today.getDate() - 1)
        let endDate = dd

        let yesterdayx = new Date();
        yesterdayx.setDate(yesterdayx.getDate() - 1);
        let todayx = new Date();

        return {
            globalOffers: [],
            globalOfferSelected:{},
            showDeleteModal: false,
            advList: [],
            csmlist: [],
            loading: false,
            reportLoading: false,
            reportForm: {
                startDate: yesterday,
                endDate: yesterday
            },
            dateRange: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            autoApply: false,
            ranges: {
                'Today': [todayx, todayx],
                'Yesterday': [yesterdayx, yesterdayx],
                'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
                'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
                'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            },
            dateRangeDelta: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            maxDate: new Date()
        }
    },
    async created() {
        this.loading = true;
        await this.fetchGlobalOffersData();
        this.loading = false;
     
    },
    methods: {
        async validateDates(date_from , date_to) {
            // validate that end date cant be bigger then today
            const now = new Date();
            now.setDate(now.getDate() + 1);
            now.setHours(0,0,0,0);

            if (date_to > now) {
                this.notifyError("End date can't be bigger then today");
                return false;
            }

            // validatethat start date is bot bigger then end datet
            if (date_from > date_to) {
                this.notifyError("Start date can't be bigger then end date");
                return false
            }

            return true;
        },

        async fetchGlobalOffersData(date_from = null, date_to = null) {
            try {
                this.loading = true;
                let r;

                if (!date_from || !date_to) {
                    r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOffersV2);
                } else {
                    if (! await this.validateDates(date_from, date_to)) {
                        this.loading = false;
                        return;
                    }
                    // Send data as unix timestamp

                    r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOffersV2, {
                        params: {
                            start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                            end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD')
                        }
                    });
                }
                const data = await JSON.parse(r.bodyText);
                
                if (data.res !== 'OK') {
                    this.notifyError("Can\'t fetch offers");
                    return
                }
                this.advList = data.data.advertisers;
                this.csmlist = data.data.csms;
                this.globalOffers = data.data.global_offers
                for (const k in this.globalOffers) {
                    let isVta = this.globalOffers[k].is_vta;
                    isVta = isVta ? 1 : 0;
                    this.globalOffers[k].href = `/apps/smartlinks/global-offers-edit?id=${this.globalOffers[k].id}&isVta=${isVta}`
                    this.globalOffers[k].updateHref = `/apps/smartlinks/global-offers-update?id=${this.globalOffers[k].id}`
                    this.globalOffers[k].show = true;
                    this.globalOffers[k].actions = false;
                    this.globalOffers[k].revenue = this.globalOffers[k].revenue.toFixed(1);
                    this.globalOffers[k].event_1_valid = this.globalOffers[k].event_1.length > 0 ? true : false;
                    this.globalOffers[k].event_2_valid = this.globalOffers[k].event_2.length > 0 ? true : false;
                }
                
                this.loading = false;

            } catch (err) {
                console.log(err);
                this.notifyError("Can\'t fetch offers")
            }
        },
        onDatesChange(v) {
            let {
                from,
                to
            } = v
            this.reportForm.startDate = from
            this.reportForm.endDate = to
        },
        logEvent(x, y) {
            if (x === 'toggle' && !y) {
                setTimeout(() => {
                    if (this.dateRangeDelta.startDate !== this.dateRange.startDate || this.dateRangeDelta.endDate !== this.dateRange.endDate) {
                        this.dateRangeDelta.startDate = this.dateRange.startDate
                        this.dateRangeDelta.endDate = this.dateRange.endDate
                        this.fetchGlobalOffersData(this.dateRange.startDate, this.dateRange.endDate)
                    }
                }, 100);
            }
        }
    }
}
</script>

<style scoped>

.global-offers-view { 
    padding-top: 40px;
    width: 100%;
}

td,th {
    width: 150px;
}

.tr {
    padding-top: 10px;
}

/* button {
background-color: tomato;

} */

</style>
