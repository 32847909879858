<template lang="html">
  <form @submit.prevent='submit' class="">
    <h4>Click Offer</h4>

    <div class="field-container">
      <label >Click Offer Name</label>
      <input :disabled='!userHasPermission("smartlinks.edit")' class='form-control pub-id-input' v-model.trim='form.name'>
    </div>

    <div class="field-container">
      <label >Click Affise ID</label>
      <input :disabled='!userHasPermission("smartlinks.edit")' class='form-control pub-id-input' type="number" v-model.number='form.affiseID'>
    </div>

    <div class="field-container">
      <label >Click Pub ID</label>
      <input :disabled='!userHasPermission("smartlinks.edit")' class='form-control pub-id-input' v-model='form.pid'>
    </div>


    <!-- <button type="submit" class="btn btn-success">Save Changes</button> -->

  </form>
</template>

<script>
export default {
  props: {
    data: {}
  },
  data() {
    return {
      form: {}
    }
  },
  created() {
    let data = this.data
    if(data) {
      Object.assign(this.form, data)
    }
    if(this.form.affiseID === 0) {
      this.form.affiseID = null
    }
  },
  methods: {
    submit() {
        this.$http.put(this.resources.SmartLinksCampaigns.updateOfferDirect(this.form.id), this.form)
          .then(() => {
            this.notifySuccess("Successfully updated offer")
          })
          .catch(() => {
            this.notifyError("Error occured")
          })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
