<template lang="html">
  <div class="" v-if='loaded'>
    <h1>Variable Offers</h1>

    <Table v-if='vars'
    tableClass="table-12341"
    :search='false'
    :apiUrl='this.resources.affiseVars.vars'
    :data='vars'
    :autoFetch='false'
    :fields='{id: "ID", title: "Title", advId: "Adv ID", payout: "Payout"}'
    :askBeforeDelete='1'
    :onRowClick='onRowClick'
    :isRowActive='isRowActive'
    :permissions='{delete: 1}'
     />

    <br>
    <h3>Create a new Variable</h3>
    <br>

    <button v-if='userHasPermission("smartlinks.edit")' type="button" class="btn btn-outline-primary" @click='newVarDisplayClick'>New Var</button>


    <VarForm :onCreate='onVarCreate' v-if='newVarDisplay' />

    <router-view></router-view>
  </div>
</template>

<script>
import VarForm from '../shared/VarForm.affise_vars'
export default {
  components: {
    VarForm
  },
  data() {
    return {
      vars: [],
      newVarDisplay: false,
      loaded: false
    }
  },
  created() {
    this.$http.get(this.resources.affiseVars.vars)
      .then(r => r.json())
      .then(data => {
        data = data || []
        data.forEach(v => {
          v.patterns = v.patterns || []
          v.timings = v.timings || []
          v.manualPatterns = v.patterns.map(v => v.search + ":" + v.replaceWith).join("\n")
          v.timings = v.timings.map(t => t.substr(0,5)).join("\n")
        })
        this.vars = data
        this.$store.state.affiseVars.vars = this.vars
        this.loaded = true
      })
  },
  computed: {
    varId() {
      return this.$route.params.varId
    }
  },
  methods: {
    newVarDisplayClick() {
      this.newVarDisplay = !this.newVarDisplay
      if(this.varId) {
        this.$router.push({name:"VarsOffers"})
      }
    },
    isRowActive(v) {
      return v.id == this.varId
    },
    onVarCreate(form) {
      this.vars.push(form)
    },
    onRowClick(v) {
      this.$router.push({ name: "AffiseVarId", params: {varId: v.id}})
      this.newVarDisplay = false
    },
  }
}
</script>

<style lang="css" scoped>
</style>
