<template lang="html">
  <div class="input-box">
    <span class="title-5">{{title}}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {}
  }
}
</script>
<style>
.title-5 {
  margin-bottom: 1px;
  text-align: center;
}
.input-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #cecece;
  padding: 3px;
}
</style>
