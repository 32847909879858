<template>
<div class="all">
    <div class="txt">
        <div v-if="!loading">{{ msg }}</div>
        <div v-else><b>{{ loadingMsg }}</b></div>
    </div>

    <div class="btn">
        <b-button v-if="!loading" variant="outline-primary" @click="getData">Generate Report</b-button>
        <div v-else>
            <div class="loader"></div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            msg: 'After you click on “generate report” the report will be sent to your Email',
            loadingMsg: 'Please wait for the loading to end before leaving the page.'
        }
    },
    methods: {
        getData: async function () {
            this.loading = !this.loading
            if (!this.userHasPermission("stags.tag-monthly-report")) {
                this.notifyError(`Not Authorized`)
                return
            }
            try {
                const response = await this.$http.get(this.resources.ScriptTags.requestReport)
                const data = await response.json()
                this.loading = !this.loading
                this.notifySuccess(`Report generated and is waiting at  ${data.email}`)
            } catch {
                this.notifyError(`Tag Monthly Report request failed!`)
            }
        }
    },
}
</script>

<style scoped>
.all {
    display: flex;
    flex-direction: column;
}

.txt{
  align-self:center;
}
.btn {
    margin-top: 2%;
}

.loader {

    margin: auto;
    border: 20px solid #EAF0F6;
    border-radius: 50%;
    border-top: 15px solid lightskyblue;
    width: 100px;
    height: 100px;
    animation: spinner 4s linear infinite;

}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
