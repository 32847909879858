<template lang="html">
    <button type="button" class="btn btn-success" @click='onClick'>
      <i class="fa fa-refresh"></i>
      Generate
    </button>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function
    }
  }
}
</script>

<style lang="css">
</style>
