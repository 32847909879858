<template lang="html">
  <div class="all">
    <VarForm v-if='affiseVar' :data='affiseVar' :onEdit='onVarEdit' />
  </div>
</template>

<script>
import VarForm from '../shared/VarForm.affise_vars'

export default {
  components: {VarForm},
  data() {
    return {
      affiseVar: null
    }
  },
  created() {
    this.setVar()
  },
  watch: {
    varId(val) {
      this.setVar()
    }
  },
  computed: {
    varId() {
      return this.$route.params.varId
    }
  },
  methods: {
    onVarEdit(form) {
      Object.assign(this.affiseVar, form)
    },
    setVar() {
      this.affiseVar = null
      this.$nextTick(() => {
        let varId = this.varId
        let vars = this.$store.state.affiseVars.vars
        this.affiseVar = vars.find(v => v.id == varId)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
