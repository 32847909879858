<template>
  <header class="app-header navbar">
    <button class="navbar-toggler mobile-sidebar-toggler d-lg-none" type="button" @click="mobileSidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <b-link class="navbar-brand" to="#"></b-link> -->
    <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" @click="sidebarToggle">
      <span class="navbar-toggler-icon"></span>
    </button>
    <img src="/img/apptimus.png" alt="" class="logo-s mr-auto">
    <div v-if="devenv" style="margin: 0 auto; color: red;">
      <h1>TEST ENVIRONMENT ONLY</h1>
    </div>
    <button class="navbar-toggler aside-menu-toggler d-md-down-none right-box" type="button" @click="asideToggle">
      <img class="profile-thumbnail" src="@/assets/default-profile.jpg">
      <span class='user-username'>
        {{ user.name }}
      </span>
    </button>
  </header>
</template>
<script>
import moment from 'moment'

export default {
  name: 'c-header',
  data() {
    return {
      devenv: 0,
      idleTime: 0,
      timer: {},
      now: '',
      refreshInterval: 60 * 5
    }
  },
  mounted() {
    this.now = moment();
    localStorage.setItem('idleTime', this.now)
    
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
       // console.log('Visible');
        let currentTime = moment();
        if (currentTime.diff(this.now, 'seconds') > this.refreshInterval) {
        //  console.log('Visible - Reload');
          location.reload()
        }
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          let currentTime = moment();
         // console.log(currentTime.diff(this.now, 'seconds'))
          if (currentTime.diff(this.now, 'seconds') > this.refreshInterval) {
            location.reload()
          }
        }, 1000)
      }
    })

    this.devenv = process.env.VUE_APP_DEV_ENV
    document.body.addEventListener('mouseover', () => {
      this.clearI()
    });
    document.body.addEventListener('keyup', () => {
      this.clearI()
    });

    // happans evey 1 milisec
    this.timer = setInterval(() => {
      let currentTime = moment();
      //console.log(currentTime.diff(this.now, 'seconds'))
      if (currentTime.diff(this.now, 'seconds') > this.refreshInterval) {
        location.reload()
      }
    }, 1000)
  },
  methods: {
    sidebarToggle(e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-hidden')
    },
    sidebarMinimize(e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-minimized')
    },
    mobileSidebarToggle(e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-mobile-show')
    },
    asideToggle(e) {
      e.preventDefault()
      document.body.classList.toggle('aside-menu-hidden')
    },
    clearI() {
      this.now = moment();
      localStorage.setItem('idleTime', this.now)
    }
  }
}
</script>

<style scoped>
.profile-thumbnail {
  width: 40px;
  border-radius: 50%;
}

.right-box {
  margin-right: 20px;
}

.user-username {
  text-transform: capitalize;
}

.logo-s {
  height: 25px;
}
</style>
