    <template lang="html">
    <div class="all">
        <FrqModal :is-show.sync=showLegend></FrqModal>
        <b-alert variant=success  >
            {{"hello"}}
        </b-alert>
        <form @submit.prevent='submit' class="form-container">
            <template>
                <div v-if="isGlobalOffer || form.is_global_offer" class="field-container" >
                    <label>Advertiser</label>
                    <b-form-select class="select-w" v-model="form.advertiser_id" :options="advRows"></b-form-select><br /><br />
                </div>
                <div class="field-container" v-if="!is_new_offer && placement.is_offers_rotation">
                <b-form-checkbox v-model="form.exclude_offer_rotation" @change="updateOfferRotation">
                    Exclude from rotation
                </b-form-checkbox>
                </div>
            </template>
            <template v-if='form.click_offer'>
                <h5>Click Offer</h5>
                <div class="field-container">
                    <label>Click Offer Affise ID</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' v-model.number='form.click_offer.affiseID' class="pub-id-input form-control">
                </div>
                <div class="field-container">
                    <label>Click Offer Offer Name</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.click_offer.name' class="pub-id-input form-control">
                </div>
                <div class="field-container">
                    <label>CTR %</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' min="0" max="100" step="0.01" class='form-control pub-id-input' type="number" v-model.number='form.click_ctr'>
                </div>
                <div class="field-container">
                    <label>Click Offer Frequency</label>

                    <section v-for='i in [0,24,12,6,3,1]'>
                        <label :for='"frq-c-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                        <input :disabled='!userHasPermission("smartlinks.edit")' :id='"frq-c-hours-"+i' type="radio" v-model.number='form.click_offer.frq' :value='i'>
                    </section>
                </div>

                <div class="field-container">
                    <label>Click Offer IP Frequency</label>

                    <section v-for='i in [0,24,12,6,3,1]'>
                        <label :for='"ip-frq-c-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                        <input :disabled='!userHasPermission("smartlinks.edit")' :id='"ip-frq-c-hours-"+i' type="radio" v-model.number='form.click_offer.ip_frq' :value='i'>
                    </section>

                </div>
                <div class="field-container">
                    <label>IP Frequency Level:</label>
                    <select v-model='form.click_offer.ip_frq_level'>
                        <option value="l1">Level 1</option>
                        <option value="l2">Level 2</option>
                        <option value="l3">Level 3</option>
                        <option value="l4">Level 4</option>
                        <option value="l5">Level 5</option>
                    </select>
                    <div>
                        <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
                    </div>
                </div>
                <h5>VTA Offer</h5>
            </template>

            <div class="field-container">
                <label>Affise ID</label>
                <input :disabled='customOffer || !userHasPermission("smartlinks.edit")' v-model.number='form.affiseID' class="pub-id-input form-control">
            </div>
            <div class="field-container">
                <label>Offer Name</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.name' class="pub-id-input form-control">
            </div>
            <div class="field-container" v-if="isGlobalOffer">
                <label>Bundle ID</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.goffer_bundle_id' class="pub-id-input form-control">
            </div>
            <div class="field-container">
                <label>Offer Region - Not functional</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.region' class="pub-id-input form-control">
            </div>
            <div class="field-container" v-if="!disable_weight">
                <label>Weight</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' type="number" min="0" max="99999" class='form-control pub-id-input' v-model.number='form.weight'>
                <strong>{{percent | percentage}}</strong>
            </div>
            <div class="field-container" v-if="! isGlobalOffer">
                <label>Pause</label>
                <div>
                    <div v-if="! offer_pause">
                        <b-form-input style="display: inline-block; width: 100px;" size="sm" id="input-2" v-model.number="pause_amount"></b-form-input>
                        <b-form-select style="display: inline-block; width: 100px;" size="sm" id="input-3" v-model="pause_period" :options="time" required></b-form-select>
                        <b-button :disabled="is_new_offer" @click="pauseOffer()" style="display: inline-block; margin-left: 3px;">Pause</b-button>
                    </div>
                    <div v-if="offer_pause">
                        <b-button @click="resetOffer()" style="display: inline-block; margin-left: 3px;">Reset</b-button> Pasused since {{ offer_pause_ts }}  by {{ offer_pause_by_user }} - Release in {{ offer_timer }}
                    </div>
                    <div style="font-size: 10px;">
                        Please allways refresh before using this feature to make sure that data is updated
                    </div>
                </div>
            </div>
            
            <div class="field-container">
                <label>Comments</label>
                <input :disabled='!userHasPermission("smartlinks.sl_comments")' v-model='form.sl_comments' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <label>Advertiser Requests</label>
                <input :disabled='!userHasPermission("smartlinks.sl_comments")' v-model='form.advertiser_requests' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <label>Clicks Cap(0=infinite)</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.cap' class="pub-id-input form-control">
            </div>
            <div class="field-container">
                <label>Frequency</label>
                <section v-for='i in [0,24,12,6,3,1]'>
                    <label :for='"frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' :id='"frq-hours-"+i' type="radio" v-model.number='form.frq' :value='i'>
                </section>
            </div>

            <div class="field-container">
                <label>IP Frequency</label>

                <section v-for='i in [0,24,12,6,3,1]'>
                    <label :for='"ip-frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' :id='"ip-frq-hours-"+i' type="radio" v-model.number='form.ip_frq' :value='i'>
                </section>

            </div>

            <div class="field-container">
            <label>IP Frequency Level:</label>
            <select v-model='form.ip_frq_level'>
                <option value="l1">Level 1</option>
                <option value="l2">Level 2</option>
                <option value="l3">Level 3</option>
                <option value="l4">Level 4</option>
                <option value="l5">Level 5</option>
            </select>
            <div>
                <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
            </div>
            </div>

            <div class="field-container">
                <label>Delivery Frequency</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model.number='form.delivery_frq' class="pub-id-input delivery_frq form-control" @keyup="validateDeliveryPrecent">
                <strong v-if="valid_delivery_frq">{{ this.form.delivery_frq }}% </strong>
            </div>

            <div class="field-container" v-if="!form.is_global_offer_pid_rotating">
                <label>Pub ID</label>
                <input :disabled='customOffer || !userHasPermission("smartlinks.edit") || form.is_rotating === 1' v-model='form.pid' class="pub-id-input form-control">
                <div class="rotating">
                    <b-form-checkbox id="checkbox-1" v-model="form.is_rotating" name="checkbox-1" :value=1 :unchecked-value=0>
                        Is rotating?
                    </b-form-checkbox>
                </div>
                <div class="rotating-div" v-if="form.is_rotating">
                    
                    <b-dropdown id="dropdown-form" text="PID List" ref="dropdown" size="sm">
                        <b-dropdown-form>
                            <b-form-input id="dropdown-form-email" size="sm" placeholder="search" @keyup="pidFilterChange" v-model="pidFilter"></b-form-input>
                        </b-dropdown-form>
                        <div style="max-height: 300px; overflow: scroll;">
                            <b-dropdown-item-button v-for="row of pidListsNames" :value="row.value" @click="selectPid(row)" :key="row.id">{{ row.text }}</b-dropdown-item-button>
                        </div>
                    </b-dropdown>
                
                    <!--<b-form-select @change="listIdChange" size="sm" id="input-6" v-model="form.list_id" :options="pidListsNames" required></b-form-select>-->
                    <b-form-input size="sm" id="input-2" v-model.number="form.ttl" required></b-form-input>
                    <b-form-select size="sm" id="input-3" v-model="time_chosen" :options="time" required></b-form-select>
                    <b-button v-if="form.is_rotating == 1 && form.ttl >= 0" class="rotate-now-btn" size="sm" variant="secondary" @click="rotateNowHandler()">Rotate Now</b-button>
                </div>

            </div>
            <div v-if="!form.is_global_offer_pid_rotating">
                <div class="field-container" v-if="form.is_new_pid === '1'" style="font-size: 10px;">
                    Please make sure to save the offer if you switched PIDs list bfore you can use rotate now
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <div style="font-weight: bold;">
                        List Name: {{ form.currently_selected_list_name }}
                    </div>
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <div>
                        Currently Selected PID: {{ form.currently_selected_pid }}
                    </div>
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1' && !isGlobalOffer">
                    <div>
                        Last Rotate: {{ form.last_rotate }}
                    </div>
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <a :href="form.list_id_link">Go To List</a>
                </div>
            </div>

            <div v-if="form.is_global_offer_pid_rotating">
                <div class="field-container">
                    <b-form-checkbox v-model="form.is_global_offer_pid_rotating" :value="true" :unchecked-value="false" :disabled="true">
                       PID Rotator
                    </b-form-checkbox>
                </div>
                <div class="field-container">
                    <div>
                        Currently Selected PID: {{ form.currently_selected_pid }}
                    </div>
                </div>
                <div class="field-container">
                    <div>
                        Last Rotate: {{ form.last_rotate}}
                    </div>
                </div>
                <div class="field-container">
                    <div>
                        Rotating Every : <b>{{ form.global_offer_pid_rotating_period}}</b> Hours.
                    </div>
                </div>
            </div>
            <!-- <div class="field-container">
                <b-form-checkbox v-model="form.af_cb" @change="afCbClick">
                    AppsFlyer Account
                </b-form-checkbox>
                <div v-if="form.af_cb">
                    <select v-model='form.appsflyer_account_id' class="def select-234">
                        <option value=""></option>
                        <option v-for="r in appsflyer_list" :value="r.id"> {{ r.dm_appsflyer_name }}</option>
                    </select>
                </div>
            </div> -->

            <div class="field-container" v-if='placement.os'>
                <span>Os Version</span>
                <label>{{placement.os}}</label>
                <select v-show='form.os_version_v' v-model='form.os_version_operator' class="def select-234" name="">
                    <option v-for='op in operators'>{{op}}</option>
                </select>
                <select :disabled='!userHasPermission("smartlinks.edit")' v-model='form.os_version_v' class="def select-234">
                    <option v-for='osv in osVersions' :value='osv'>{{osv}}</option>
                </select>
            </div>

            <div class="field-container">
                <label>Connection Type</label>
                <Select class='select' :options='connectionTypes' v-model="form.connection_type" />
            </div>

            <div class="field-container" v-if='placement.geo != null && placement.geo.length > 0 && form.connection_type == "3G" '>
                <label>Mobile Carrier</label>
                <cool-select v-if='isps' v-model="carrierSelected" :items="isps" />
                <ul class='selected-items'>
                    <template v-if='userHasPermission("smartlinks.edit")'>
                        <li @click='onCarrierDel(index)' v-for='(isps, index) in form.isps'>
                            <i class="fa fa-times del-x" aria-hidden="true"></i>
                            <span>
                                {{isps}}
                            </span>
                        </li>
                    </template>
                    <template v-else>
                        <li v-for='(isps, index) in form.isps'>
                            <span>
                                {{isps}}
                            </span>
                        </li>
                    </template>
                </ul>
                <div class="isp-checkbox">
                    <input type="checkbox" v-model="form.isp_bl" name="BL" >
                    <label for="scales">Blacklist</label>
                </div>

            </div>

            <div class="field-container">
                <label>Custom Subs Query</label>
                <input :disabled='customOffer || !userHasPermission("smartlinks.edit")' v-model='form.subs_query' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <label>Device ID Traffic Only</label>
                <b-form-checkbox v-model="form.is_device_id_traffic"></b-form-checkbox>
            </div>

            <div class="field-container">
                <label>Custom URL</label>
                <input :disabled='affiseOffer || !userHasPermission("smartlinks.edit")' v-model='form.custom_url' class="pub-id-input form-control">
                <span class="span-415">Placholders: {date_q}, {hour_letter}</span>
            </div>

            <div class="result-url">
                <span>{{resultURL}}</span>
            </div>

            <hr>
        <hr>

        <div v-if='data'>
            <h4>IDFA Blacklisting</h4>
            <Select :disabled='!userHasPermission("smartlinks.edit")' v-if='all_blFiles' class='select' :options='blFiles' :onChange='onBlacklistFileAdd' />

            <Table tableClass="table-bl-2165" :search='false' :fields='{id: "File ID", title: "Title"}' :data='form.blFiles' :apiUrl='resources.blacklisting.offerBlFiles(form.id)' :apiUrl2='form.click_offer?.id ? resources.blacklisting.offerBlFiles(form.click_offer.id) : ""' :permissions='{delete: 1}' :askBeforeDelete='1' />
        </div>

        <hr>
        <div v-if="subsValidationError.length > 0" class="alert">
            <a class="close" @click="subsValidationError = []">&times;</a>
            <li v-for="msg in subsValidationError">{{ msg }}</li>
        </div>

        <div>
            <h4>Subs Blacklisting</h4>
            <div class="blacklist-container" v-show='userHasPermission("smartlinks.edit")'>
                <div class="inline-container">
                    <label>Sub:</label>
                    <Select class="select" :options="unselectedSubs" v-model="new_sub_bl.sub" @change="changeSelectedSub"/>
                    <input id="new-sub-bl-wl" v-model='new_sub_bl.whitelist' type="checkbox">
                    <label for="new-sub-bl-wl">Whitelist</label>
                    <i class="fa fa-plus edit-btn" aria-hidden="true" @click='onSubsBlacklistAdd'></i>
                </div>
                <div>
                    <span v-if="selectedSub === 'sub2' || selectedSub === 'sub2' || selectedSub === 'sub2'" style="color: red;">Case sensitive</span>
                    <span v-if="selectedSub === 'sub4'" style="color: red;">Not case sensitive</span>
                </div>
                <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="new_sub_bl.list"></LimitedTextarea>
            </div>
            <br class="flat-breaks"><br>
            <div>
                <div v-for="(s,i) in form.blacklisting_subs">
                    <div class="inline-container">
                        <i class="fa fa-trash trash-btn" aria-hidden="true" @click='onSubsBlacklistRemove(i)'></i>
                        <h5>{{s.sub}}</h5>
                    </div>
                    <div>
                        <input :disabled='!userHasPermission("smartlinks.edit")' v-model='s.whitelist' type="checkbox">
                        <label>Whitelist</label>
                    </div>
                    <div>
                        <span v-if="s.sub === 'sub2' || s.sub === 'sub3' || s.sub === 'sub7'" style="color: red;">Case sensitive</span>
                        <span v-if="s.sub === 'sub4'" style="color: red;">Not case sensitive</span>
                    </div>
                    <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="s.list"></LimitedTextarea>
                    <br class="flat-breaks"><br>
                </div>
            </div>
        </div>

        <!-- buttons -->
        <a v-if="form.global_offer_assoc_id > 0" href="javascript:void(0);" @click="resetToGlobalOfferDefaults()">Load Default</a><br>
        <button v-if='userHasPermission("smartlinks.edit")'  :disabled="globalOfferSaved" type="submit" class="btn btn-success btn-31214">{{data ? 'Save Offer Changes' : 'Create Offer'}}</button>
    </form>
    </div>
</template>

<script>
import versions from '../../../../../osVersions.json'
import _ from 'lodash';
import FrqModal from '../../../../components/FrqModal.vue';
const Vue = window.vue;
import {
    CoolSelect
} from "vue-cool-select";
import {
    async
} from 'q';
import {
    stat
} from 'fs';
import moment from 'moment';

export default {
    props: {
        data: {},
        onSubmit: {},
        placement: {
            default: e => ({})
        },
        isVta: {
            default: false
        },
        isGlobalOffer: {
            default: false
        },
        keepGlobalOfferData: {
            default: false
        },
    },
    components: {
        CoolSelect,
        FrqModal
    },
    data() {
        return {
            form: {
                affiseID: null,
                name: null,
                goffer_bundle_id: null,
                sl_comments: null,
                weight: 100,
                cap: '0',
                frq: 24,
                ip_frq: 24,
                ip_frq_level: 'l3',
                pid: null,
                subs_query: null,
                custom_url: null,
                os_version: null,
                os_version_v: "  all",
                os_version_operator: ">=",
                connection_type: "all",
                isps: [],
                blFiles: [],
                blacklisting_subs: [],
                list_id: 'NEW_PID_102',
                ttl: 6,
                is_rotating: 1,
                delivery_frq: 100,
                exclude_offer_rotation: false,
                is_global_offer_pid_rotating: false,
                global_offer_pid_rotating_period: 2,
                click_offer: {
                    affiseID: null,
                    name: null,
                    weight: null,
                    cap: '0',
                    frq: 24,
                    ip_frq: 24,
                    ip_frq_level: 'l3',
                    pid: null,
                    subs_query: null,
                    custom_url: null,
                    os_version: null,
                    isps: [],
                    blFiles: [],
                    blacklisting_subs: [],
                    ttl: null,
                    list_id: null,
                    is_rotating: 1,
                    isp_bl: 0,
                    advertiser_id: 0,
                },
                is_global_offer: false,
                list_id_link: '',
                currently_selected_pid: '',
                currently_selected_list_name: '',
                last_rotate: '',
                is_new_pid: '1',
                af_cb: false,
                appsflyer_account_id: 0,
                isp_bl: 0,
                advertiser_id: 0,
                is_device_id_traffic: false,
                region: '',
                advertiser_requests: '',
            },
            advRows: [],
            globalOfferSaved: false,
            selectedSub: '',
            subsValidationError: [],
            list_name: "",
            pidListsNamesToId: null,
            pidListsNames: null,
            pidListsNamesOrig: null,
            carrierSelected: null,
            connectionTypes: ["all", "wifi", "3G"],
            iosVersionsFull: [],
            androidVersionsFull: [],
            new_sub_bl: {},
            all_isps: null,
            all_blFiles: null,
            all_subs: [{
                    val: "sub1",
                    label: "Sub1"
                },
                {
                    val: "sub2",
                    label: "Sub2"
                },
                {
                    val: "sub3",
                    label: "Sub3"
                },
                {
                    val: "sub4",
                    label: "Sub4"
                },
                {
                    val: "sub6",
                    label: "Sub6"
                },
                {
                    val: "sub7",
                    label: "Sub7"
                },
                {
                    val: "sub8",
                    label: "Sub8"
                },
            ],
            operators: [
                ">=", "=", "<="
            ],
            time_number: 0,
            time: ["Hours", "Days"],
            time_chosen: "Hours",
            showLegend: false,
            pidFilter: '',
            appsflyer_list: [],
            valid_delivery_frq: false,
            is_new_offer: true,
            disable_weight: false,
            pause_amount: '1',
            pause_period: 'Hours',
            offer_pause: false,
            offer_pause_ts: '',
            offer_pause_duration_minutes: 0,
            offer_timer: '',
            interval_inst: {},
            offer_pause_by_user: ''
        }
    },
    watch: {
        carrierSelected(value) {
            if (value != null) {
                if (this.form.isps == null) {
                    this.form.isps = []
                }
                this.form.isps.push(value)
            }
        },
        ['form.cap'](newValue) {
            if (newValue !== null) {
                const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.$set(this.form,'cap' ,result);
            }
        }, 
        ['placement.geo'](val) {
            this.fetchIsps()
        }
    },
    created: async function () {
        this.getVersions()
        if (this.isGlobalOffer || this.form.is_global_offer) {
            this.getAdvertisersList()
        }
        let form = this.form
        let data = this.data || {}
        Object.assign(form, {
            offers: {}
        }, JSON.parse(JSON.stringify(data)))

        if (data.offer_id) {
            form.name = data.offer_id
            form.offers = {
                [data.offer_id]: data.offer_id
            }
        }

        if (!this.form.blFiles) {
            this.form.blFiles = []
        }

        if (form.os_version) {
            let i = isNaN(form.os_version[1]) ? 1 : 0
            form.os_version_operator = form.os_version.substring(0, i + 1)
            form.os_version_v = form.os_version.substring(i + 1)
        }

        if (!this.isVta) {
            form.click_offer = null
        }
        try {
            const r = await this.$http.get(this.resources.blacklisting.getFiles)
            const data = await r.json()
            var files = data || []
            this.all_blFiles = files.map(v => ({
                val: v.id,
                label: v.title
            }))
        } catch (err) {
            this.notifyError("cant fetch black listing files")
        }
        this.fetchIsps()
        this.getPidToListName()
        this.getAppsflyerList()
        if (this.form.appsflyer_account_id != 0) {
            this.form.af_cb = true
        }

        if (this.form.delivery_frq != 0) {
            this.valid_delivery_frq = true
        }
        this.disable_weight = this.placement.is_offers_rotation
        this.is_new_offer = typeof this.$route.params.offerId !== 'undefined' ? false : true;

        if (data.offer_pause) {
            this.offer_pause = data.offer_pause
            this.offer_pause_by_user = data.offer_pause_by_user
            this.offer_pause_ts = moment(data.offer_pause_ts).format('DD.MM.YYYY HH:mm:ss')
            this.offer_pause_duration_minutes = data.offer_pause_duration_minutes
            let release_time = moment(data.offer_pause_ts).add(data.offer_pause_duration_minutes, 'minute');
            if (this.interval_inst) {
                clearInterval(this.interval_inst)
            }
            this.interval_inst = setInterval(() => {
                let now = moment()
                let time_to_release = moment.duration(moment(release_time).diff(now))
                const h_split = this.toHoursAndMinutes(time_to_release.asSeconds())
                if (time_to_release.asSeconds() < 0) {
                    this.offer_timer = "00:00:00"
                    this.offer_pause = false
                } else {
                    this.offer_timer = h_split.h + ":" + h_split.m + ":" + Math.floor(h_split.s)
                }
            }, 1000)
        }
    },
    computed: {
        ttl: {
            get() {
                if (this.form.is_rotating) {
                    if (this.time_chosen === "Days") {
                        return this.form.ttl * 24
                    } else {
                        return this.form.ttl
                    }
                }
            },
            set(newVal) {
             return newVal
           } 
        },
        iosVersions: {
            get() {
                return this.iosVersionsFull.map((val) => val.version)
            },
            set() {
                return []
            }
        },
        androidVersions: {
            get() {
                return this.androidVersionsFull.map((val) => val.version)
            },
            set() {
                return []
            }
        },
        isps() {
            return this.all_isps
        },
        osVersions() {
            let placementOs = this.placement.os && this.placement.os.toLowerCase()
            if (!placementOs) {
                return []
            }
            return placementOs === 'ios' ? this.iosVersions : placementOs === 'android' ? this.androidVersions : []
        },
        unselectedSubs() {
            if (!this.all_subs || !this.all_subs.length) {
                return
            }
            return this.all_subs.filter(v => !this.form.blacklisting_subs.find(v2 => v2.sub == v.val))
        },
        blFiles() {
            if (!this.all_blFiles) {
                return
            }

            return this.all_blFiles.filter(v => !this.form.blFiles.find(v2 => v2.id == v.val)) 
        },
        affiseOffer() {
            let form = this.form
            return !!(form.affiseID || form.subs_query)
        },
        customOffer() {
            let form = this.form
            return !!form.custom_url
        },
        resultURL() {
            if (this.customOffer) {
                return this.form.custom_url
            }
            let {
                subs_query,
                affiseID,
                pid
            } = this.form
            let defaultSubsQuery =
                "pid={pid}&sub1={sub1}&sub2={sub2}&sub4={sub4}&sub5={sub5}&sub7={sub7}&sub8={sub8}&sub6={sub6}&sub3={sub3}"
            let basicURL = `https://go.g2app.net/click?offer_id=${affiseID}`
            if (!subs_query) {
                subs_query = defaultSubsQuery
            }
            if (pid) {
                subs_query = subs_query.replace("{pid}", pid)
            }

            return basicURL + "&" + subs_query
        },
        percent() {
            let {
                campId,
                placementId,
                waterfallId
            } = this.$route.params
            
            let placement = {}
            try {
                placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
            } catch (ex) {
                return 0
            }
            
            let offers = placement.offers
            let totalWeight = 0
            let form = this.form
            offers.forEach(v => {
                if (v.id == form.id) {
                    return
                }
                totalWeight += v.weight
            })
            totalWeight += form.weight
            return (100 / totalWeight) * form.weight
        }
    },
    methods: {
        async validateDeliveryPrecent() {
            if (this.form.delivery_frq > 0) {
                if (this.form.delivery_frq > 0 && this.form.delivery_frq <= 100) {
                    this.valid_delivery_frq = true

                } else {
                    this.valid_delivery_frq = false
                    this.form.delivery_frq = 0
                    this.notifyError('Delivery frequency expect number between 0 to 100')
                }
            } else {
                this.valid_delivery_frq = false
            }
        },
        async resetToGlobalOfferDefaults() {
            let ok = confirm(`Are you sure you want to reset ${this.form.name} to its global offer settings ?`)
            if (ok) {
                const data =  await this.$http.post(this.resources.GlobalOffers.resetGlobalOfferRelatedSetting, [this.form.id])
                if (data.body.res === 'OK') {
                    var currentLocation = window.location;
                    window.location.href = `${currentLocation}`;
                } else {
                    this.notifyError("Offer did not load from default due to an error.")
                }  
            }
        },
        async updateOfferRotation() {
            return true
        },
        async getAdvertisersList() {
            try {
                const res = await this.$http.post(this.resources.Management.getAdvertisersList)
                if (res.body.data) {
                    this.advRows = res.body.data.map(advItem => {
                    return {
                        value: advItem.id,
                        text: advItem.adv_company_name
                    }
                })
                }
            } catch (err) {
                console.log(err);
            }
        },
        changeSelectedSub() {
            if (!this.new_sub_bl || !this.new_sub_bl.sub) {
                return
            } else {
                this.selectedSub = this.new_sub_bl.sub.val
            }
        },
        getVersions: async function () {
            try {
                const r = await this.$http.get(this.resources.Management.getOsVersions("ios"))
                const ios = await r.json()
                this.iosVersionsFull = ios

                const i = await this.$http.get(this.resources.Management.getOsVersions("android"))
                const android = await i.json()
                this.androidVersionsFull = android
            } catch (err) {
                this.notifyError("can't fetch os versions")
            }
        },
        rotateNowHandler: async function () {
            try {
                if (typeof this.form.id === 'undefined') {
                    this.notifyError("Please save the offer first")
                    return
                }
                const r = await this.$http.post(this.resources.SmartLinksCampaigns.rotateOfferNow, undefined, {
                    params: {
                        offer_id: this.form.id,
                        offer_ttl: this.form.ttl,
                        list_id: this.form.list_id
                    }
                })
                const data = await r.json()
                this.notifySuccess("Offer pid is now succesfuly rotated!")
                if (this.form.list_id.includes("NEW_PID_")) {
                    this.form.currently_selected_pid = data.pid
                    this.form.last_rotate = data.last_rotate
                    this.$forceUpdate();
                }
            } catch (err) {
                this.notifyError("Rotate now didnt actioned. Please try again")
            }
        },
        onCarrierDel(index) {
            this.form.isps.splice(index, 1)
        },
        fetchIsps: async function () {
            if (this.placement.geo && this.placement.geo.length > 0) {
                try {
                    const r = await this.$http.get(this.resources.SmartLinksCampaigns.getISPs, {
                        params: {
                            countries: this.placement.geo
                        }
                    })
                    const isps = await r.json()
                    this.all_isps = isps
                } catch (err) {
                    this.notifyError("can't fetch isps")
                }
            }
        },
        blFileById(id) {
            return this.all_blFiles.find(v => v.val == id)
        },
        onSubsBlacklistAdd() {
            if (!this.new_sub_bl || !this.new_sub_bl.sub) {
                return
            }
            this.selectedSub = ''
            let newsub = this.new_sub_bl
            this.new_sub_bl = {}
            newsub.sub = newsub.sub.val
            this.form.blacklisting_subs.unshift(newsub)
        },
        onSubsBlacklistRemove(i) {
            this.form.blacklisting_subs.splice(i, 1);
        },
        onBlacklistFileAdd: async function (v) {
            if (!v) {
                return
            }
            if (!window.confirm(`Are you sure that you want to add blacklisting file "${v.label}" to this offer?`)) {
                return
            }

            let params = this.$route.params
            let data = {
                blfile_id: v.val,
                offer_placement_id: Number(params.placementId),
                offer_campaign_id: Number(params.campId)
            }
            try {
                await this.$http.post(this.resources.blacklisting.offerBlFiles(this.form.id), data)

                this.form.blFiles.push({
                    id: v.val,
                    title: v.label
                })

            } catch (err) {
                this.notifyError(`Error in adding ${v.label} file to blacklisting VTA`)
            }
            if (this.form.click_offer?.id) {
                try {
                    await this.$http.post(this.resources.blacklisting.offerBlFiles(this.form.click_offer.id), data)
                    if (typeof this.form.click_offer.blFiles === 'object') {
                        this.form.click_offer.blFiles = []
                    }
                } catch (err) {

                    this.notifyError(`Error in adding ${v.label} file to blacklisting CTA`)
                }
            }
        },
        onBlfileDel(index) {
            this.form.blFiles.splice(index, 1)
          //  this.form.click_offer.blFiles.splice(index, 1)
        },
        onWaterfallSelect(offerId) {
            this.form.offer_id = Number(offerId)
        },
        searchOffers: async function () {
            let name = this.form.name
            try {
                const r = await this.$http.get(this.resources.SmartLinksCampaigns.searchOffers, {
                    params: {
                        q: name
                    }
                })
                const offers = await r.json()
                if (!offers) {
                    throw new Error()
                }
                let form = this.form
                let offersList = form.offers = {}
                offers.forEach(v => {
                    offersList[v.id] = `${v.name} (${v.id})`
                })
            } catch (err) {
                this.form.offers = {}
            }
        },
        getPidToListName: async function () {
            try {
                const r = await this.$http.get(this.resources.Management.pidListIdToName)
                let data = await r.json()

                this.pidListsNamesToId = data
                this.pidListsNames = this.pidListsNamesToId.map(item => {
                    if (item.list_version === '2') {
                        if (typeof this.form.list_id === 'string') {
                            this.form.list_id_link = '/man/pid-pool-lists-details?id=' + this.form.list_id.replace('NEW_PID_', '')
                        }
                        return {
                            value: 'NEW_PID_' + item.list_id,
                            text: item.list_name
                        }    
                    }
                    return {
                        value: item.list_id,
                        text: item.list_name
                    }
                })
                for (let k in this.pidListsNames) {
                    if (this.form.is_new_pid === '1') {
                        this.form.list_id = this.form.list_id + ''
                        if (this.pidListsNames[k].value.trim() == this.form.list_id.trim()) {
                            this.form.currently_selected_list_name = this.pidListsNames[k].text
                        }
                    }
                }
                this.pidListsNamesOrig = this.pidListsNames
            } catch (err) {
                this.notifyError("Could not fetch Pid's names")
            }
        },
        async validateBlacklistingSubs() {
            let subsMsgArray = []
            let matchedArray = []
            for (let k in this.form.blacklisting_subs) {
                matchedArray = this.form.blacklisting_subs[k].list.match(/[\r\n\t\f\v\s]/g) || []
                let lastMatch = 0
                let msgArray = []
        
                for (const i in matchedArray) {
                    lastMatch =  this.form.blacklisting_subs[k].list.indexOf(matchedArray[i],lastMatch)
                    msgArray.push({
                        str : this.form.blacklisting_subs[k].list.substring(lastMatch-10, lastMatch+10),
                        char: matchedArray[i]
                    })
                    lastMatch ++
                }
                if (matchedArray.length > 0) {
                    subsMsgArray[this.form.blacklisting_subs[k].sub] = msgArray
                } 
            }
            return subsMsgArray
        },
        ThrowSubsAlerst(subsMsgArray) {
            let msg = []
            for (const k in subsMsgArray) {
                for (const i in subsMsgArray[k]) {
                    let txt = ''
                    switch (subsMsgArray[k][i].char) {
                        case ' ':
                            txt = `There is a space in ${k} between - ${subsMsgArray[k][i].str}`
                            break
                        case '\n':
                            txt = `There is a line break in ${k} between - '${subsMsgArray[k][i].str}''`
                            break
                        case '\t': 
                            txt = `There is a tab in ${k} between - ${subsMsgArray[k][i].str}`
                            break

                    }
                    msg.push(txt)
                }
            }
            this.subsValidationError = msg 
        },
        submit: async function () {
       
            for (let k in this.form.blacklisting_subs) {
                if (this.form.blacklisting_subs[k].list.length > 65000) {
                    this.notifyError (`Sub BL/WL Can't be more than 65000 characters`)
                    return
                }
            }
            
            this.subsValidationError = []
            if (!this.userHasPermission("smartlinks.edit")) {
                return
            }
            if (this.resultURL.includes(" ")) {
                this.notifyError("Space in result URL")
                return false
            }

            const subsMsgArray = await this.validateBlacklistingSubs() 
            if (Object.keys(subsMsgArray).length > 0) {
                this.ThrowSubsAlerst(subsMsgArray)
                return
            }
            
            if (this.carrierSelected) {
                this.carrierSelected = null
            }
        
            let cap = this.form.cap.toString().replace(/[,]*/g, '')
          
            const form = {
                ..._.omit(this.form, ['is_rotating', 'cap']),
                is_rotating: this.form.is_rotating,
                cap: parseInt(cap),
            }

            form.os_version = form.os_version_v && form.os_version_operator + form.os_version_v
            if (form.os_version_v && !form.os_version_operator) {
                this.notifyError("Please choose OS Version operator")
                return
            }

            if (this.isVta) {
                form.click_offer = {
                    ..._.omit(form.click_offer, ['connection_type', 'blacklisting_subs', 'isps', 'subs_query', 'pid', 'blFiles', 'ttl', 'cap', 'advertiser_id']),
                    connection_type: this.form.connection_type,
                    blacklisting_subs: this.form.blacklisting_subs,
                    isps: this.form.isps,
                    isp_bl: this.form.isp_bl,
                    subs_query: this.form.subs_query,
                    pid: this.form.pid,
                    //blFiles: this.form.blFiles,
                    is_rotating: this.form.is_rotating,
                    ttl: this.ttl,
                    list_id: this.form.list_id,
                    os_version: form.os_version,
                    cap: parseInt(cap),
                    advertiser_id: this.form.advertiser_id,
                    region: this.form.region,
                    advertiser_requests: this.form.region,
                }
            }

            if (this.form.is_rotating) {
                form.ttl = this.ttl
                
            } else {
                form.ttl = 0
                form.list_id = 0
                this.form.ttl = 0
                this.ttl = 0
                if (this.isVta) {
                    form.click_offer.ttl = 0
                    form.click_offer.list_id = 0
                    this.form.click_offer.ttl = 0
                }
            }

            if (!form.frq) {
                form.frq = 0
            }
            if (!form.cap) {
                form.cap = 0
            }
            if (form.connection_type == 'wifi') {
                form.isps = []
            }
            delete form.offers
            if (false && Object.values(form).findIndex(v => v == undefined || v == '' || (Array.isArray(v) && !v
                    .length)) >
                -1) {
                return
            }
            let offerType = this.affiseOffer ? "affiseOffer" : "customOffer"
            
            if (this.keepGlobalOfferData) {
                // this.globalOfferSaved = true
            }

            let {campId, placementId} = this.$route.params
            let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
            if (form.isps !== null && form.isps.length !== 0) {
                if (placement.isps !== null && placement.isps.length !== 0) {
                    window.confirm('Pay attention that ISP Targeted in Adset level')
                }
            }

            try {
                form.is_new_pid = "0"
                var tListId = ''
                if (String(form.list_id).includes("NEW_PID_")) {
                    tListId = form.list_id
                    form.list_id = parseInt(form.list_id.replace("NEW_PID_", ""))
                    form.is_new_pid = "1"
                }
            
                if (typeof form.connection_type !== 'string' || form.connection_type === '') {
                    form.connection_type = 'All'
                }
                await this.$validate.smartlinks[offerType](form)
             
                if (! this.keepGlobalOfferData) {
                    this.onSubmit(form) && this.form.nullify()  
                } else {
                    this.onSubmit(form)
                }

                if (this.data === undefined && ! this.keepGlobalOfferData) {
                    this.setDefaultForm()
                }

                form.list_id = tListId
            } catch (err) {
                if (err.msg.includes("Please fill")) {
                    this.notifyError("Make sure to fill affise ID and an offer name")
                }
                else {
                    this.notifyError("An error Occured. Please refresh the page")
                }
            }
        },
        setDefaultForm(){
            this.form.ip_frq_level = 'l3'
            this.form.os_version_v = "  all"
            this.form.os_version_operator = ">="
            this.form.connection_type = "all"
            this.form.is_rotating = 1
            this.form.frq = 24
            this.form.ip_frq = 24
            this.form.list_id = 'NEW_PID_102'
            this.form.ttl = 6
            this.form.is_new_pid = '1'
            this.form.weight = 100
            this.form.cap = '0'
            this.form.isp_bl = 0
            this.carrierSelected = null
            this.form.region = ''
            this.form.advertiser_requests = ''
            this.getPidToListName()
            if(this.isVta){
                this.form.click_offer.ip_frq_level = 'l3'
                this.form.click_offer.frq = 24
                this.form.click_offer.ip_frq = 24
            }
        },
        async listIdChange(v) {
            if (typeof v === 'string' && v.includes('NEW_PID_')) {
                this.form.is_new_pid = '1'
                this.form.list_id_link = '/man/pid-pool-lists-details?id=' + this.form.list_id.replace('NEW_PID_', '')
                this.form.currently_selected_pid = 'Please save and rotate'
                // this.$forceUpdate();
            } else {
                this.form.is_new_pid = '0'
            }
            this.$forceUpdate();
        },
        pidFilterChange() {
            this.pidListsNames = []
            for (let k in this.pidListsNamesOrig) {
                if (this.pidListsNamesOrig[k].text.toLowerCase().includes(this.pidFilter.toLowerCase())) {
                    this.pidListsNames.push(this.pidListsNamesOrig[k])
                }
            }
        },
        selectPid(row) {
            this.form.currently_selected_list_name = row.text
            this.form.list_id = row.value
            this.listIdChange(row.value)
        },
        async getAppsflyerList() {
            const r = await this.$http.post(this.resources.Management.getAfData, {})
            this.appsflyer_list = r.body.data
        },
        afCbClick() {
            this.form.appsflyer_account_id = 0
        },
        async pauseOffer() {
            const pause_req = {
                id: this.form.id,
                pause_amount: parseInt(this.pause_amount),
                pause_period: this.pause_period,
                new_status: 'paused'
            }
            await this.$http.post(this.resources.SmartLinksCampaigns.pauseResetOffer, pause_req)
            this.offer_pause = true
            this.offer_pause_ts = moment().format('DD.MM.YYYY HH:mm:ss')
            this.offer_pause_duration_minutes = 0
            if (this.pause_period === 'Hours') {
                this.offer_pause_duration_minutes = this.pause_amount * 60
            }
            if (this.pause_period === 'Days') {
                this.offer_pause_duration_minutes = this.pause_amount * 60 * 24
            }
            let release_time = moment().add(this.offer_pause_duration_minutes, 'minute');
            if (this.interval_inst) {
                clearInterval(this.interval_inst)
            }

            let now = moment()
            let time_to_release = moment.duration(moment(release_time).diff(now))
            const h_split = this.toHoursAndMinutes(time_to_release.asSeconds())
            this.offer_timer = h_split.h + ":" + h_split.m + ":" + Math.floor(h_split.s)
            this.offer_pause_by_user = 'you'
            this.interval_inst = setInterval(() => {
                let now = moment()
                let time_to_release = moment.duration(moment(release_time).diff(now))
                const h_split = this.toHoursAndMinutes(time_to_release.asSeconds())
                if (time_to_release.asSeconds() < 0) {
                    this.offer_timer = "00:00:00"
                    this.offer_pause = false
                } else {
                    this.offer_timer = h_split.h + ":" + h_split.m + ":" + Math.floor(h_split.s)
                }
            }, 1000)
        },
        async resetOffer() {
            const pause_req = {
                id: this.form.id,
                pause_amount: parseInt(this.pause_amount),
                pause_period: this.pause_period,
                new_status: 'active'
            }
            await this.$http.post(this.resources.SmartLinksCampaigns.pauseResetOffer, pause_req)
            this.offer_pause = false
        },
        toHoursAndMinutes(totalSeconds) {
            const totalMinutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return { h: hours, m: minutes, s: seconds };
        }
    }
}
</script>

<style>
.table-bl-2165 .fields>li:nth-child(2),
.table-bl-2165 .table-row>li:nth-child(2) {
    max-width: 50px;
    width: 30px;
}
</style><style lang="css" scoped>
.del-x {
    cursor: pointer;
    color: #505050;
    font-size: 14px;
    margin-right: 4px;
}

.offer {
    border: 1px solid #cacaca;
    margin: 16px 0;
    padding: 40px 18px 20px;
    position: relative;
}

.offer-remove {
    position: absolute;
    top: 2%;
    right: 1%;
    cursor: pointer;
}

.result-url {
    margin-bottom: 19px;
    padding: 6px;
    border: 1px solid gainsboro;
    border-radius: 3px;
    background: #f7f7f7;
}

.selected-items-31 {
    margin: 17px 0;
    border: 1px solid gray;
    border-radius: 4px;
    background: #dedede;
}

.selected-items-31 li {
    border-bottom: 1px solid gray;
    padding: 9px 11px;
}

.selected-items-31 li:hover {
    cursor: pointer;
    background-color: #d0d0d0;
}

.selected-items-31 li:last-child {
    border-bottom: 0;
}

.btn-31214 {
    margin-top: 23px;
}

.btn-plus {
    color: white;
    font-size: 19px;
    padding: 0px 7px;
    border-radius: 7px;
}

.btn-x {
    color: white;
    font-size: 19px;
    padding: 0px 7px;
    border-radius: 7px;
}

.max-textarea {
    width: 100%;
}

.inline-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.inline-container>* {
    margin-right: 9px;
}

.span-415 {
    color: gray;
    font-size: 14px;
    font-style: italic;
    margin-left: 15px;
}

.flat-breaks br {
    content: "";
}

.flat-breaks br:after {
    content: " ";
}

.rotating-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.rotate-now-btn {
    margin-left: 150px;
}

.alert {
    padding: 20px;
    margin-bottom: 15px;
    position: fixed;
    top: 5%;
    left: 25%;
    right: 0;
    z-index: 999999;
    width: 50%;
    box-shadow: 0 0 15px 5px #ccc;
    border-radius: 5px;
    background-color: lightcoral;
}
.isp-checkbox{
    padding-left: 20px;
}

.select-w {
    max-width: 400px;
}
</style>
