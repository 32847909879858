import TMPlacements from './TMPlacements.man'
import EditTemplates from './EditTemplates.tmplacements'
import EditDomainsPrefix from './EditDomainsPrefix.domains'
import AdvertiserOfferWhitelist from './advertiser-offer-whitelist/AdvertiserOfferWhitelist.man'
import Banners from './banners'
import AppsflyerLinkGenerator from './appsflyer-link-generator'
import Campaigns from './campaigns'
import PidLists from './pid-lists'
import PidBank from './pid-bank'
import PidBankList from './pid-bank-lists'
import PidBankListsDetails from './pid-bank-lists-details'
import PidBankBulk from './pid-bank-bulk'
import BundlePool from './bundle-pool'
import BundlePoolEdit from './bundle-pool-edit'
import BundlePoolBulk from './bundle-pool-bulk'
import AfData from './af-data'
import SyncPostback from './sync-postback'
import AjdustLinkGenerator from './adjust-link-generator'
export default {
  TMPlacements, EditTemplates, EditDomainsPrefix, AdvertiserOfferWhitelist, Banners, AppsflyerLinkGenerator,Campaigns,PidLists, PidBank, PidBankList, PidBankListsDetails, PidBankBulk, BundlePool, BundlePoolEdit, BundlePoolBulk, AfData, SyncPostback, AjdustLinkGenerator
}
