<template>
    <div>
        <div v-if="!yesterday">
            Today | <a href="/man/af-data?yesterday=1">Yesterday</a>
        </div>
        <div v-if="yesterday">
            <a href="/man/af-data">Today</a> | Yesterday
        </div>
        <h2>AppsFlyer Data - 24H</h2>
        <div>
            <div style="width: 100%;">
                <AfChart v-if="showChart" :chartDataProp="chartData" :chartOptionsProp="chartOptions" />
            </div>
        </div>
        <div>
            <div class="last_run_div">
                Last update: {{ af_last_run }}
            </div>
            <table class="tbl-s table b-table table-striped">
                <template v-for="r of dataRaw">
                    <tr>
                        <td>
                            <div v-if="r.showOffers && r.offers_data.length !== 0">
                                <a href="javascript:void(0);" @click="onToggleOffers(r.id)" class="rtoggle">-</a>    
                            </div>
                            <div v-if="!r.showOffers && r.offers_data.length !== 0">
                                <a href="javascript:void(0);" @click="onToggleOffers(r.id)" class="rtoggle">+</a>    
                            </div>
                        </td>
                        <td>
                            <div>
                                {{ r.dm_appsflyer_name }}
                            </div>
                            <div>
                              <!--   <div v-if="r.processing">
                                    Processing...
                                </div>
                                <div v-else>
                                    <i v-on:click="pp_ac(r.id, true)" v-if="! r.active" style="cursor: pointer;" class="icon-control-play"></i>
                                    <i v-on:click="pp_ac(r.id, false)" v-if="r.active" style="cursor: pointer;" class="icon-control-pause"></i>
                                    <div>
                                        {{ r.active_change_dt_str }}
                                    </div>
                                </div> -->
                            </div>
                        </td>
                        <td>
                            <span v-if="r.is_vta">Imperssions: {{ r.impressions | numFormat}}<br /></span>    
                        </td>
                        <td>
                            Clicks: {{ r.clicks | numFormat}}   
                        </td>
                        <!-- <td>
                            24H Click Cap: {{ r.dm_appsflyer_click_cap | numFormat}}
                        </td>
                        <td>
                            24H Click Cap Notification: {{ r.dm_appsflyer_notification_click_cap | numFormat}}
                        </td>
                        <td>
                            %{{ ((r.clicks / r.dm_appsflyer_click_cap) * 100).toFixed(2) | numFormat}} of Click Cap
                        </td> -->
                    </tr>
                    <tr>
                        <td colspan="6" v-if="r.showOffers">
                            <div class="offers-tbl-div">
                                <h4>Active offers</h4>
                                <div v-if="r.active_filtered.active.length === 0">
                                    There is not data
                                </div>
                                <table class="tbl-s">
                                    <tr v-for="offer of r.active_filtered.active">
                                        <td>
                                            <a target="_blank" :href="offer.offer_deep_link">{{ offer.offer_name }}</a>
                                        </td>
                                        <td>
                                            Imperssions: {{ offer.impressions | numFormat }}
                                        </td>
                                        <td>
                                            Clicks: {{ offer.clicks | numFormat}}
                                        </td>
                                        <td>
                                            <span v-if="offer.clicks !== 0">%{{ ((offer.clicks / r.clicks) * 100).toFixed(2) | numFormat }} of total clicks</span>
                                        </td>
                                        <!-- <td>
                                            <span>%{{ ((offer.clicks / r.dm_appsflyer_click_cap) * 100).toFixed(2) | numFormat }} of click cap</span>
                                        </td> -->
                                    </tr>
                                </table>    
                            </div>
                            <div v-if="! r.showInactiveOffers">
                                <a href="javascript:void(0);" @click="onToggleInactiveOffers(r.id)">Show inactive offers</a>
                            </div>
                            <div class="offers-tbl-div" v-if="r.showInactiveOffers">
                                <h4>Inactive offers - got no traffic</h4>
                                <div v-if="r.active_filtered.inactive.length === 0">
                                    There is not data
                                </div>
                                <table class="tbl-s">
                                    <tr v-for="offer of r.active_filtered.inactive">
                                        <td>
                                            <a target="_blank" :href="offer.offer_deep_link">{{ offer.offer_name }}</a>
                                        </td>
                                        <td>
                                            Imperssions: {{ offer.impressions | numFormat }}
                                        </td>
                                        <td>
                                            Clicks: {{ offer.clicks | numFormat}}
                                        </td>
                                        <td>
                                            <span v-if="offer.clicks !== 0">%{{ ((offer.clicks / r.clicks) * 100).toFixed(2) | numFormat }} of total clicks</span>
                                            <!-- <span v-if="offer.clicks === 0">%0 of total clicks</span> -->
                                        </td>
                                        <!-- <td>
                                            <span>%{{ ((offer.clicks / r.dm_appsflyer_click_cap) * 100).toFixed(2) | numFormat }} of click cap</span>
                                        </td> -->
                                    </tr>
                                </table>    
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import AfChart from './AfChart.vue'

export default {
    components: {
        AfChart
    },
    data() {
        return {
            chartData: {
                labels: [ 'January', 'February', 'March' ],
                datasets: [ 
                    { 
                        data: [4, 2, 1], 
                        label: 'Clicks',
                        backgroundColor: 'rgb(124,181,236)' 
                    }, 
                    { 
                        data: [5, 3, 2], 
                        label: 'Impressions',
                        backgroundColor: 'rgb(67,67,72)'
                    } 
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio:false,
                tooltips: {
                callbacks: {
                        label: function(tooltipItem, data) {
                            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            value = value.toString();
                            value = value.split(/(?=(?:...)*$)/);
                            value = value.join(',');
                            return value;
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true,
                            userCallback: function(value, index, values) {
                                // Convert the number to a string and splite the string every 3 charaters from the end
                                value = value.toString();
                                value = value.split(/(?=(?:...)*$)/);
                                value = value.join(',');
                                return value;
                            }
                        }
                    }],
                    xAxes: [{
                        ticks: {
                        }
                    }]
                } 
            },
            showChart: false,
            dataRaw: [],
            yesterday: false,
            af_last_run: ""
        }
    },
    filters: {
        numFormat(v) {
            let i = isNaN(v) || !isFinite(v) ? 0 : v
            return (Number(i).toLocaleString())
        }
    },
    async created() {
        if (typeof this.$route.query.yesterday !== 'undefined') {
            this.yesterday = true
        }
        await this.getData()
    },
    methods: {
        async getData() {
            let q = ''
            if (this.yesterday) {
                q = '?yesterday=1'
            }
            const data = await this.$http.get(this.resources.Management.getAfStatsData + q, {})
            for (let k in data.body.af_data) {
                data.body.af_data[k].showOffers = false
                data.body.af_data[k].showInactiveOffers = false
            }
            const af_data = data.body.af_data

            // Sort by clicks
            af_data.sort((a,b) => (a.clicks < b.clicks) ? 1 : ((b.clicks < a.clicks) ? -1 : 0))
            for (let k in af_data) {
                // Sort by clicks - disabled
                // af_data[k].offers_data.sort((a,b) => (a.clicks < b.clicks) ? 1 : ((b.clicks < a.clicks) ? -1 : 0))
                af_data[k].processing = false
                af_data[k].active_filtered = { active: [], inactive: [] }
                for (let k1 in af_data[k].offers_data) {
                    if (af_data[k].offers_data[k1].clicks != 0) {
                        af_data[k].active_filtered.active.push(af_data[k].offers_data[k1])
                    } else {
                        af_data[k].active_filtered.inactive.push(af_data[k].offers_data[k1])
                    }
                }
                af_data[k].active_filtered.active.sort((a,b) => (a.offer_name.toLowerCase() > b.offer_name.toLowerCase().trim()) ? 1 : ((b.offer_name.toLowerCase().trim() > a.offer_name.toLowerCase().trim()) ? -1 : 0))
                af_data[k].active_filtered.inactive.sort((a,b) => (a.offer_name.toLowerCase() > b.offer_name.toLowerCase().trim()) ? 1 : ((b.offer_name.toLowerCase().trim() > a.offer_name.toLowerCase().trim()) ? -1 : 0))
            }

            this.dataRaw = af_data
            this.af_last_run = data.body.af_last_run

            const labels = []
            const datasets = [
                { 
                    data: [], 
                    label: 'Clicks',
                    backgroundColor: 'blue' 
                }, 
                { 
                    data: [], 
                    label: 'Impressions',
                    backgroundColor: 'red'
                }
            ]
            
            for (let k in af_data) {
                labels.push(af_data[k].dm_appsflyer_name)
                datasets[0].data.push(af_data[k].clicks)
                datasets[1].data.push(af_data[k].impressions)
            }
            this.chartData.labels = labels
            this.chartData.datasets = datasets
            this.showChart = true
        },
        onToggleOffers (id) {
            for (let k in this.dataRaw) {
                if (this.dataRaw[k].id == id) {
                    this.dataRaw[k].showOffers = !this.dataRaw[k].showOffers 
                }
            }
        },
        onToggleInactiveOffers(id) {
            for (let k in this.dataRaw) {
                if (this.dataRaw[k].id == id) {
                    this.dataRaw[k].showInactiveOffers = !this.dataRaw[k].showInactiveOffers 
                }
            }
        },
        showRowLoader(id) {
            for (let k in this.dataRaw) {
                if (this.dataRaw[k].id == id) {
                    this.dataRaw[k].processing = true
                }
            }
        },
        async pp_ac(rid, active) {
            this.showRowLoader(rid)
            const data = await this.$http.post(this.resources.Management.setStatusByAfAccount, {id: rid, active})
            await this.getData()
        }
    },
}
</script>

<style scoped>
.tbl-s {
    padding-bottom: 50px;
    width: 100%;
}

.offers-tbl-div {
    padding: 15px;
}

.rtoggle {
    font-size: 20px;
}

.last_run_div {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>
