<template>
    <div>
        <div>
            <a :href="'/media-analyst/dsp-schedule'">Back</a>
            <h2>Edit</h2>
        </div>
        <div v-if="isLoading">
            Loading ...
        </div>
        <b-modal
            id="modal-prevent-closing"
            v-model="closeInfoModal"
            title="Info regarding Campaign IDs"
            @ok="closeInfoModal = false"
        >
            Campaign ID only. separated by comma
        </b-modal>

        <div v-if="camps_unique_err" v-html="formattedString">
        </div>
        <br v-if="camps_unique_err">
        <div v-if="!isLoading">
            <div class="form-r">
                <div>
                    <div>
                        <div class="form-l">Name</div>
                        <div class="form-r">
                            <input v-model="name" class='form-control pub-id-input dsp-form-inp'>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="form-l">* GEO</div>
                        <div class="form-r">
                            <b-form-input :required="true" v-model="geo" list="geo" type="text" placeholder="" name="geo_select"
                                class='form-control pub-id-input dsp-form-inp'></b-form-input>
                            <datalist id="geo">
                                <option  v-for="country in countries">{{ country }}</option>
                            </datalist>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="form-l">* OS</div>
                        <div class="form-r">
                            <b-form-select :required="true" v-model="os" type="text" placeholder="OS" :options="os_options"
                                class='form-control pub-id-input dsp-form-inp'></b-form-select>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="form-l">* MA</div>
                        <div class="form-r">
                            <b-form-select v-model="ma" type="text" :disabled="true" placeholder="Media Analyst" :options="media_analyst_options"
                                class='form-control pub-id-input dsp-form-inp'></b-form-select>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="form-l">Description</div>
                        <div class="form-r">
                            <textarea v-model="description" class='form-control pub-id-input dsp-form-inp' style="resize: none;"></textarea>
                        </div>
                    </div>
                </div>
                <div>
                    <DspScheduler :value="timeTable" v-on:submit="save_schedule" @update-tsArr="updateTimeTable"/>
                </div>
            </div>
            <div class="form-l">
                <div>
                    <div class="i-icon-div">
                        <b-icon @click="closeInfoModal = true" icon="info-circle"></b-icon>
                    </div>
                    <div class="form-l form-ul">Kayzen</div>
                    <div class="form-r">
                        <table>
                            <tr>
                                <td>
                                    <textarea v-model="kayzen_cmps_str" class='form-control pub-id-input dsp-form-inp' style="resize: none;"></textarea>
                                </td>
                                <td>
                                    <button class="btn btn-primary" :disabled="id === '0'" @click="save_schedule('kayzen')">Update</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                <div class="form-l form-ul">Bucksense</div>
                <div class="form-r">
                        <table>
                            <tr>
                                <td>
                                    <textarea v-model="bucksense_cmps_str" class='form-control pub-id-input dsp-form-inp' style="resize: none;"></textarea>
                                </td>
                                <td>
                                    <button class="btn btn-primary" :disabled="id === '0'" @click="save_schedule('bucksense')">Update</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    <div class="form-l form-ul">Apptimus</div>
                    <div class="form-r">
                        <table>
                            <tr>
                                <td>
                                    <textarea v-model="apptimus_cmps_str" class='form-control pub-id-input dsp-form-inp' style="resize: none;"></textarea>
                                </td>
                                <td>
                                    <button class="btn btn-primary" :disabled="id === '0'" @click="save_schedule('apptimus')">Update</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div style="text-align: center;">
                <button class="btn btn-primary" style="width: 300px;" @click="save_schedule('')">Save</button>
            </div>
        </div>
    </div>
</template>
<script>
import countries from '@/assets/countries.js';
import { CoolSelect } from "vue-cool-select";
import DspScheduler from '../../components/DspScheduler.vue';

export default {
    components: {
        CoolSelect,
        DspScheduler
    },
    data() {
        return {
            id: '0',
            name:'',
            ma: '',
            geo: '',
            os: '',
            description: '',
            kayzen_cmps_str: '',
            bucksense_cmps_str: '',
            apptimus_cmps_str: '',
            countries: countries,
            all_geos: [],
            os_options: [],
            media_analyst_options: [],
   
            schedule_on: {},
            timeArray: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "19", "18", "20", "21", "22", "23"],
            dayTable: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            timeTable: {},
            isLoading: false,
            country_code: '',
            ma_rows:[],
            edit: false,
            closeInfoModal: false,
            camps_unique_err: false,
            camps_unique_err_mag: '',
        }
    },
    async created() {
        this.isLoading = true;
        this.getListData();
        if (this.$route.query.id !== undefined && this.$route.query.id != '0') {
            this.id = this.$route.query.id;
            this.edit = true;
            await this.getSchedulerGroup();
        }

        if (!this.edit) {
            for (let k1 of this.dayTable) {
                for (let k2 of this.timeArray) {
                    this.timeTable[k1 + '_' + k2] = {};
                    this.timeTable[k1 + '_' + k2]['checked'] = false;
                    this.timeTable[k1 + '_' + k2]['classes'] = ['ts_td'];
                }
            }
            this.isLoading = false;
        }

    },
    computed:{
        formattedString() {
            if (this.camps_unique_err_mag !== '') {
                return this.camps_unique_err_mag.replace(/\n/g, '<br>');
            }
           
        }
    },
    watch: {
        ['geo']: async function(val) {
            await this.selectGeo();
            await this.getMediaAnalyst();
        },
        ['os']: async function (val) {
            await this.getMediaAnalyst();
            console.log(val);
        }
    },
    methods: {
        async selectGeo() {
            for (const key in countries) {
               if (countries[key] === this.geo) {
                    this.country_code = key;
               }
            }
        },
        async getMediaAnalyst() {
            if (this.geo !== '' && this.os !== '') {
                const data = await this.$http.post(this.resources.Management.getMediaAnalystByOsCountry, {
                    os: this.os,
                    geo: this.country_code
                })

                const res = await data.json();
                if (res.res === 'OK') {
                    this.ma = res.data.media_analyst_manager;
                } else {
                    this.ma = 'None';
                }
            }
        },
        async getSchedulerGroup() {
            if (!this.edit) {
                return 
            }
            try {
                let res = await this.$http.get(this.resources.Management.getSchedulerById(this.id));
                console.log(res.data);
                if (res.data.res === 'OK') {

                    let scheduler = res.data.data;
                    this.name = scheduler.name;
                    this.geo = scheduler.geo;
                    this.os = scheduler.os;
                    this.description = scheduler.description;
                    this.ma = scheduler.ma_name;
                    this.kayzen_cmps_str = scheduler.kayzen_cmps_str;
                    this.bucksense_cmps_str = scheduler.bucksense_cmps_str;
                    this.apptimus_cmps_str = scheduler.apptimus_cmps_str;
                    // parse the scheduler data
                    let dayTable =  ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                    for (let k1 of dayTable) {
                        for (let k2 of this.timeArray) {
                            const firstLetter = k1.substring(0, 1).toUpperCase();
                            const k1_key = firstLetter + k1.substring(1,3);
                            if (scheduler.schedule_on[k1].includes(parseInt(k2))) {
                                this.timeTable[k1_key + '_' + k2] = {};
                                this.timeTable[k1_key + '_' + k2]['checked'] = true;
                                this.timeTable[k1_key + '_' + k2]['classes'] = ['ts_td', 'ts_active'];
                            } else {
                                this.timeTable[k1_key + '_' + k2] = {};
                                this.timeTable[k1_key + '_' + k2]['checked'] = false;
                                this.timeTable[k1_key + '_' + k2]['classes'] = ['ts_td'];
                            }
                        }
                    }

                    this.isLoading = false;
                } else {
                    this.notifyError('Error fetching data');
                    this.isLoading = true;
                }
           
                
            } catch (err) {
                console.log(err);
                this.isLoading = true;
            }
        },
        async getListData() {
            // validate that scheduler cant be empty


            try {
                const options_data = await this.$http.get(this.resources.Management.getSchedulerMetadata);
                console.log(options_data.data);
                if (options_data.data.res === 'OK') {

                    let ma_rows = options_data.data.data.ma_list;
                    ma_rows.push({ id: 0, media_analyst_manager: 'None'})
                    this.ma_rows = ma_rows;
                    this.media_analyst_options = ma_rows.map((row) => {
                        return {
                            value: row.media_analyst_manager,
                            text: row.media_analyst_manager
                        };
                    });
            
                    for (const key in this.countries) {
                        this.all_geos.push(this.countries[key]);
                    }

                    // Filter countries based on geo list
                    const geo_code_list = [];
                    for (let k in options_data.data.data.geo_list) {
                        geo_code_list.push(options_data.data.data.geo_list[k].geo);
                    }

                    const filtered_countries = [];
                    for (let k in this.countries) {
                        if (geo_code_list.includes(k)) {
                            filtered_countries.push(this.countries[k]);
                        }
                    }
                    this.countries = filtered_countries;

                    // get media analyst
                    this.os_options = [
                        { value: '', text: '' },
                        { value: 'iOS', text: 'iOS' },
                        { value: 'Android', text: 'Android' },
                    ];

                    // let listData = options_data.data.geo_list;
                    // for (let k in listData) {
                    //     listData[k].country = this.countries[listData[k].geo] === undefined ? '' : this.countries[listData[k].geo];
                    //     let ma_obj = ma_rows.filter(row => {
                    //         if (row.id === listData[k].media_analyst_manager_id) {
                    //             return row;
                    //         }
                    //     });
                    // }
                    // this.geo_list = listData;
                }
            } catch (err) {
                console.log(err);
            }
            // !! need to add csn name !! 
            // get all rows
            
                // let om_obj = this.om_rows.filter((row) => {
                //     if (row.value === listData.body.data[k].om_manager_id) {
                //         return row;
                //     }
                // })
                // listData.body.data[k].media_analyst_manager = ma_obj[0].media_analyst_manager;
                // listData.body.data[k].om = typeof om_obj[0]?.text !== 'undefined' ? om_obj[0]?.text : '';
            
        },
        updateTimeTable(updatedTimeTable) {
            this.timeTable = updatedTimeTable;
        },
        async save_schedule(action) {
            this.camps_unique_err = false;
            this.camps_unique_err_mag = '';

            this.isLoading = true;
            // validate that all days have an array and not null 
            let filled = false;
            for (const k2 in Object.keys(this.timeTable)) {
                if (this.timeTable[Object.keys(this.timeTable)[k2]]['checked']) {
                    filled = true;
                }

            }
            if (!filled) {
                this.notifyError('Please select at least one time slot');
                this.isLoading = false;
                return;
            }

            if (this.geo === '' || this.os === '' || this.ma === '') {
                this.notifyError('Please fill all mandatory fields');
                this.isLoading = false;
                return;
            }

            // add validation before submitting !!
            // only on update
            console.log('action ', action);
            switch (action) {
                case 'kayzen':
                    if (this.kayzen_cmps_str === '') {
                        this.notifyError('Please fill the Kayzen field');
                        this.isLoading = false;
                        return;
                    } else {
                        // validate string 
                        if (! /^(?:(?!0$)(?!0\d)\d+)(,(?:(?!0$)(?!0\d)\d+))*$/.test(this.kayzen_cmps_str)) {
                            this.notifyError('Please enter a valid Kayzen string containing only numbers and commas without spaces between them');
                            this.isLoading = false;
                            return;
                        }
                    }
                    break;
                case 'bucksense':
                    if (this.bucksense_cmps_str === '') {
                        this.notifyError('Please fill the Bucksense field');
                        this.isLoading = false;
                        return;
                    } else {
                        if (! /^(?:(?!0$)(?!0\d)\d+)(,(?:(?!0$)(?!0\d)\d+))*$/.test(this.bucksense_cmps_str)) {
                            this.notifyError('Please enter a valid Bucksense string containing only numbers and commas without spaces between them');
                            this.isLoading = false;
                            return;
                        }
                    }
                    break;
                case 'apptimus':
                    if (this.apptimus_cmps_str === '') {
                        this.notifyError('Please fill the Apptimus field');
                        this.isLoading = false;
                        return;
                    } else {
                        if (! /^(?:(?!0$)(?!0\d)\d+)(,(?:(?!0$)(?!0\d)\d+))*$/.test(this.apptimus_cmps_str)) {
                            this.notifyError(`Please enter a valid Apptimus string containing only numbers and commas without spaces between them`);
                            this.isLoading = false;
                            return;
                        }
                    }
                    break;
                default:
                    break;
            } 

            // On save - validate all fields
            if (action === '') {
                    if (this.apptimus_cmps_str !== '') {
                        if (! /^(?:(?!0$)(?!0\d)\d+)(,(?:(?!0$)(?!0\d)\d+))*$/.test(this.apptimus_cmps_str)) {
                            this.notifyError(`Please enter a valid Apptimus string containing only numbers and commas without spaces between them`);
                            this.isLoading = false;
                            return;
                        }
                    }

                    if (this.bucksense_cmps_str !== '') {
                        if (! /^(?:(?!0$)(?!0\d)\d+)(,(?:(?!0$)(?!0\d)\d+))*$/.test(this.bucksense_cmps_str)) {
                            this.notifyError(`Please enter a valid Bucksense string containing only numbers and commas without spaces between them`);
                            this.isLoading = false;
                            return;
                        }
                    }

                    if (this.kayzen_cmps_str !== '') {
                        if (! /^(?:(?!0$)(?!0\d)\d+)(,(?:(?!0$)(?!0\d)\d+))*$/.test(this.kayzen_cmps_str)) {
                            this.notifyError(`Please enter a valid Kayzen string containing only numbers and commas without spaces between them`);
                            this.isLoading = false;
                            return;
                        }
                    }
            }

            if (action !== '') {
                if (!confirm('Are you sure you want to run ' + action + ' campaigns ?')) {
                    this.isLoading = false;
                    return;
                }
            }

            let scheduled = {
                sunday: [],
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
            }

            for (const k1 in Object.keys(scheduled)) {
                for (const k2 in Object.keys(this.timeTable)) {
                    let key = Object.keys(this.timeTable)[k2].substring(0, 3).toLocaleLowerCase();
                    if (typeof key === 'string' && (key === Object.keys(scheduled)[k1].substring(0, 3))) {
                        if (this.timeTable[Object.keys(this.timeTable)[k2]]['checked']) {
                            scheduled[Object.keys(scheduled)[k1]].push(parseInt(Object.keys(this.timeTable)[k2].substring(4)));
                        }
                    }
                }
            }
            
            // get ma id 
            let ma_id = this.ma_rows.filter(row => {
                if (row.media_analyst_manager === this.ma) {
                    return row;
                }
            })[0].id;

            // process table data - then save
            const req_data = {
                id: this.id,
                name: this.name,
                geo: this.geo,
                os: this.os,
                media_analyst_manager_id: ma_id,
                description: this.description,
                ma_name: this.ma,
                schedule_on:  scheduled,
                kayzen_cmps_str: this.kayzen_cmps_str,
                bucksense_cmps_str: this.bucksense_cmps_str,
                apptimus_cmps_str: this.apptimus_cmps_str,
            }

            try {
                let res = await this.$http.post(this.resources.Management.addScheduler, {
                    data: req_data,
                    action: typeof action === 'string' ? action : ''
                });

                if (res.data.res === 'OK') {
                    if (action !== '') {
                        this.notifySuccess('Data has been saved and ' + action + ' campaigns added to run queue');
                    } else {
                        this.notifySuccess('Data has been saved');
                    }
                    
                    if (this.id === '0') {
                        this.$router.push('/media-analyst/dsp-schedule/edit?id=' +  res.data.data.id);
                        this.id = res.data.data.id;
                    }
                    this.isLoading = false;
                } else {
                    if (res.data.err === 'name is not unique') {
                        this.notifyError('Group name is already exists');
                        this.isLoading = false;
                    } 
                    
                    if (res.data.err === 'Camps are not unique') {
                        let kayzenCampsErrStr = '';
                        let bucksenseCampsErrStr = '';
                        let apptimusCampsErrStr = '';
                        if (Array.isArray(res.data.data.kayzen) && res.data.data.kayzen.length > 0) {
                            kayzenCampsErrStr += 'Kayzen: ' + res.data.data.kayzen.join(', ');
                        }
                        if (Array.isArray(res.data.data.bucksense) && res.data.data.bucksense.length > 0) {
                            bucksenseCampsErrStr += 'Bucksense: ' + res.data.data.bucksense.join(', ');
                        }
                        if (Array.isArray(res.data.data.apptimus) && res.data.data.apptimus.length > 0) {
                            apptimusCampsErrStr += 'Apptimus: ' + res.data.data.apptimus.join(', ');
                        }

                        let campsErrStr = '';
                        if (kayzenCampsErrStr !== '') {
                            campsErrStr += kayzenCampsErrStr + ', ';
                        }
                        if (bucksenseCampsErrStr !== '') {
                            campsErrStr += bucksenseCampsErrStr + ', ';
                        }
                        if (apptimusCampsErrStr !== '') {
                            campsErrStr += apptimusCampsErrStr + ', ';
                        }
                        campsErrStr = campsErrStr.substring(0, campsErrStr.length - 2);

                        this.notifyError('Camps are not unique - ' + campsErrStr, 1000*60*5);
                        this.isLoading = false;
                    }
                    else {
                        this.notifyError('Error saving data - ' + res.data.err);
                    }
                }

                console.log(res);
            } catch (err) {
                console.log(err);
            }
            // console.log('save_schedule');
        }
    },

}
</script>
<style scoped>
.form-l {
    display: inline-block;
    width: 150px;
    vertical-align: top;
}

.form-r {
    display: inline-block;
    padding-bottom: 20px;
}

.dsp-form-inp {
    width: 790px;
}
.form-ul {
    text-decoration: underline;
}
.i-icon-div {
    position: absolute; 
    left: 1115px; 
    cursor: pointer;
}
</style>