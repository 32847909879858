<template>
    <div>
        <div v-if="success">
            <a href="/apps/script-tags/data-manager-bundle-wl-details">Back</a> <br/>
            You can go back to bundles view to see or edit the items.
        </div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-if="showForm">
            <div v-if="inputErr">
                Please check your input...
            </div>
            <form @submit="addBulk">
                <div>
                    <textarea v-model="bundlesRawData" style="width: 100%; resize: none; height: 300px;"></textarea>
                </div>
             
                <div style="text-align: center;">
                    <input @click="addBulk" class="btn" type="submit" value="Add Bulk" />
                </div>
            </form>
            <div>
                Please add bundles separated by commas.
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent
} from '@vue/composition-api'
export default {
    data() {
        return {
            bundlesRawData: '',
            showForm: true,
            loading: false,
            inputErr: false,
            success: false,
        }
    },
    methods: {
        async addBulk(e) {
            e.preventDefault()
            let isDataValid = await this.validateBulk()
            if (!isDataValid) {
                this.notifyError('Bundles length can not exeed 2000 ids.')
                return
            } 
            
            this.loading = true
            this.showForm = false
            const data = await this.$http.post(this.resources.Management.addBundlesBulk, {
                bundles_raw_data: String(this.bundlesRawData),
            })
            if (data.body.res !== 'OK') {
                this.showForm = true
                this.loading = false
                this.inputErr = true
                this.success = false
                this.notifyError('Please remove dupliactions and try again.')
                return
            } else 
            this.showForm = false
            this.loading = false
            this.inputErr = false
            this.success = true

        },
        async validateBulk() {
            let data = this.bundlesRawData.split(',')
            if (data.length > 2000) {
                return false
            }
            
            return true
        }
    },
}
</script>

<style scoped>

</style>
