import Home from './views/Home.stags'
import Camp from './views/Camp.stags'
import Wl from './views/Wl.stags'
import DiscrepancyReport from './views/DiscrepancyReport.stags'
import RequestReport from './views/RequestReport.stags.vue'
import Bl from './views/Bl.stags'
import TagGenerator from './tag-generator'
import DataManager from './data-manager'
import DataManagerEditDsp from './data-manager-edit-dsp'
import DataManagerDsps from './data-manager-dsps'
import DataManagerOs from './data-manager-os'
import DataManagerEditOs from './data-manager-edit-os'
import DataManagerEditOsVersion from './data-manager-edit-os-version'
import DataManagerStagsVersions from './data-manager-stags-versions'
import DataManagerStagsVersionsEdit from './data-manager-stags-versions-edit'
import DataManagerAppsFlyer from './data-manager-appsflyer'
import DataManagerCdn from './data-manager-cdn'
import DataManagerAfPrtPid from './data-manager-af-prt-pid'
import AdvertiserPage from './advertiser-page'
import AddAdvertise from './add-advertise-page'
import DataManagerVarAdv from './data-manager-var-adv'
import DataManagerBundleWl from './data-manager-bundle-wl'
import DataManagerBundleWlDetails from './data-manager-bundle-wl-details'
import DataManagerAfAppName from './data-manager-af-app-name'
import DataManagerOperationCsm from './data-manager-operation-csm'
import DataManagerGeoList from './data-manager-geo-list'
import DataManagerVertical from './data-manager-vertical'

export default {
  Home, Wl, Camp, DiscrepancyReport, RequestReport, Bl, TagGenerator, DataManager, DataManagerEditDsp, DataManagerDsps, DataManagerOs, DataManagerEditOs,
  DataManagerEditOsVersion, DataManagerStagsVersions, DataManagerStagsVersionsEdit, DataManagerAppsFlyer, DataManagerCdn, AdvertiserPage, 
  AddAdvertise, DataManagerVarAdv, DataManagerAfPrtPid, DataManagerBundleWl, DataManagerBundleWlDetails, DataManagerAfAppName, DataManagerOperationCsm,
  DataManagerGeoList, DataManagerVertical
}
