<template lang="html">
  <div class="flex-aligned-center">
    <Checkbox :id='htmlId' :onChange='v => {isDisabled = !v; _onChange()}' />
    <label class="show-only-text" :for='htmlId'>Show only records until:</label>
    <DatePicker :disabled='isDisabled' :value='date' class='datepicker' :onChange='v => { date = v; _onChange() }' />
  </div>
</template>

<script>
import * as act from '@/act-types'
import moment from 'moment'
export default {
  props: ['onChange'],
  data() {
    return {
        htmlId: null,
        isDisabled: true,
        date: new Date()
    }
  },
  created() {
    this.$store.dispatch(act.GET_NEXT_ID)
      .then(id => {
        this.htmlId = id
      })
  },
  methods: {
    _onChange() {
      this.onChange(!this.isDisabled && moment(this.date).format('YYYY-MM-DD'))
    }
  }
}
</script>

<style lang="css" scoped>
.show-only-text {
  margin-left: 6px;
  margin-right: 8px;
}
</style>
