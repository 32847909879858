<template lang="html">
  <div class="home-container">
    <h4>Apptimus Media Admin</h4>
    <div>
      Insights:<br />
      <div>
        <button v-on:click="refreshInshgts" class="btn btn-primary">Refresh Insights</button>
      </div>
      <div class="mt-1">
        <button v-on:click="deleteAllInsights" class="btn btn-primary">Delete All Insights</button>
      </div>
       
      <div v-if="loading">
        Loading...
      </div>
      <div v-if="!loading">
        <div v-for="row in data" class="mt-3">
          <div>
            {{ row.ts }}
            <div v-html="row.msg"></div>
          </div>
          <div>
            <a href="javascript:void(0);" v-on:click="deleteInsight(row.id)">Delete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      data: []
    }
  },
  created: async function () {
    await this.refreshInshgts()
  },
  methods: {
    async refreshInshgts() {
      let id=26
      try {
        this.loading = true
        const r = await this.$http.post(this.resources.Management.getServiceUrl("insights","getInsights" ),{
        id: String(id)
      })
        const data = await r.json()
        this.data = data.data
        this.loading = false
      } catch (e) {
        this.loading = false
        this.notifyError(`Can't connect to service`)
      }
    },
    async deleteAllInsights() {
      const r = await this.$http.post(this.resources.Management.getServiceUrl("insights","deleteAll"))
      this.refreshInshgts()
    },
    async deleteInsight(id) {
      console.log(id)
      const r = await this.$http.post(this.resources.Management.getServiceUrl("insights","delete"), {
        id: String(id)
      })
      this.refreshInshgts()
    }
  }
}
</script>
