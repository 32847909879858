<template>
  <div>
    <div v-if="show_loader">
      <h1>Loading...</h1>
    </div>
    <div v-if="!show_loader">
      <div class="back">
        <a href="/man/update-manual-revenue">
          <i class="fa fa-chevron-circle-left back-btn-2" aria-hidden="true"></i>
        </a>
      </div>
      <div>
        <date-range-picker ref="picker" v-model="dateRange" opens="right"
          :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }" :ranges="ranges" @update="logEvent"
          @toggle="logEvent('toggle', $event)" @start-selection="logEvent('event: startSelection', $event)"
          @finish-selection="logEvent('event: finishSelection', $event)" :maxDate="maxDate">
        </date-range-picker>
      </div>
      <div>
        <div v-if="Object.keys(table_data).length === 0">
          No data available
        </div>
        <div class="history_table" v-if="Object.keys(table_data).length !== 0">
          <table>
            <tr>
              <td>

              </td>
              <td v-for="(v, k) in dates_arr">
                {{ v.df }}
              </td>
            </tr>
            <tr v-for="(v, k) in table_data">
              <td>{{ k }}</td>
              <td v-for="(v2, k2) in v">
                {{ v2 }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResizableDataTable from '@/components/shared/ResizableDataTable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

export default {
  components: { ResizableDataTable, DateRangePicker },
  data() {
    let today = new Date();
    let todayx = new Date();
    let yesterday = today.setDate(today.getDate() - 1);

    let dd = new Date();
    let startDate = dd.setDate(today.getDate() - 7);
    let endDate = dd.setDate(today.getDate());

    let yesterdayx = new Date();
    yesterdayx.setDate(yesterdayx.getDate() - 1);

    return {
      dateRange: {
        startDate: startDate,
        endDate: endDate
      },
      ranges: {
        'Today': [todayx, todayx],
        'Yesterday': [yesterdayx, yesterdayx],
        'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
        'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
        'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
        'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      },
      show_loader: true,
      dateRangeDelta: {
        startDate: startDate,
        endDate: endDate
      },
      dates_arr: [],
      table_data: {},
      maxDate: new Date()
    }
  },
  async created() {
    await this.getData();
  },
  methods: {
    logEvent(x, y) {
      if (x === 'toggle' && !y) {
        setTimeout(async () => {
          if (this.dateRangeDelta.startDate !== this.dateRange.startDate || this.dateRangeDelta.endDate !== this.dateRange.endDate) {
            this.dateRangeDelta.startDate = this.dateRange.startDate
            this.dateRangeDelta.endDate = this.dateRange.endDate
            await this.getData();
          }
        }, 100);
      }
    },
    async getData() {
      let r = await this.$http.post(this.resources.Dash.getPresetHistory, {
        preset_id: this.$route.query.id,
        start_date: moment(this.dateRange.startDate).format('DD.MM.YYYY'),
        end_date: moment(this.dateRange.endDate).format('DD.MM.YYYY')
      });
      this.show_loader = false;

      this.dates_arr = [];
      const start_date = moment(this.dateRange.startDate);
      const end_date = moment(this.dateRange.endDate);
      for (let m = moment(start_date); m.isBefore(end_date); m.add(1, 'days')) {
        this.dates_arr.push({ m, df: m.format('DD.MM.YYYY') });
      }
      const m = moment(end_date);
      if (m.format('DD.MM.YYYY') !== this.dates_arr[this.dates_arr.length - 1].df) {
        this.dates_arr.push({ m, df: m.format('DD.MM.YYYY') });
      }

      const geos_table = {};
      for (let k1 in r.body.revenue_table_data) {
        if (!geos_table[r.body.revenue_table_data[k1].geo]) {
          geos_table[r.body.revenue_table_data[k1].geo] = {};
          for (let k2 in this.dates_arr) {
            geos_table[r.body.revenue_table_data[k1].geo][this.dates_arr[k2].df] = 0;
          }
        }
      }

      for (let k1 in geos_table) {
        for (let k2 in geos_table[k1]) {
          for (let k3 in r.body.revenue_table_data) {
            if (r.body.revenue_table_data[k3].geo === k1 && r.body.revenue_table_data[k3].dd_str === k2) {
              geos_table[k1][k2] = r.body.revenue_table_data[k3].revenue;
            }
          }
        }
      }
      this.table_data = geos_table;
    }
  },
}
</script>
<style scoped>
.back-btn-2 {
  font-size: 41px;
  color: #3b67a0;
  cursor: pointer;
}

.history_table {
  overflow: auto;
}
.history_table td {
  border: 1px solid lightgray;
  text-align: center;
  padding: 8px;
}
</style>