<template lang="html">
  <div class="date-range">
    <div class="date-container">
      <label >From Date:</label>
      <DatePicker :disabled='disabled' :onChange='v=> { from = v; invokeOnChange() } ' :value='from' />
    </div>
    <div class="date-container">
      <label >To Date:</label>
      <DatePicker :disabled='disabled' :onChange='v=> { to = v; invokeOnChange() } ' :value='to' />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    onChange: {
      type: Function
    },
    from_: {
    },
    to_: {
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      from: (() => {
        let d = new Date()
        return d.setMonth(d.getMonth() - 1)
      })(),
      to: new Date()
    }
  },
  methods: {
    invokeOnChange() {
      let {from, to} = this
      this.onChange({from, to})
    }
  },
  created() {
    if(this.from_) {
      this.from = this.from_
    }
    if(this.to_) {
      this.to = this.to_
    }
  },
  mounted() {
    this.invokeOnChange()
  }
}
</script>

<style lang="css" scoped>
.date-range {
  display: flex;
}
.date-container {
  margin-right: 20px;
}
</style>
