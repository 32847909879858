

<template lang="html">
    <div>
        <div>
            <a v-if="!show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Show Columns Selector</a>
            <a v-if="show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Hide Columns Selector</a>
            <div v-if="show_columns_selector">
                <input type="checkbox" @click="toggle_column(1)" :checked="show_c[1]"> Active
                <input type="checkbox" @click="toggle_column(2)" :checked="show_c[2]"> Offer ID
                <input type="checkbox" @click="toggle_column(3)" :checked="show_c[3]"> App Name
                <input type="checkbox" @click="toggle_column(4)" :checked="show_c[4]"> Agency
                <input type="checkbox" @click="toggle_column(5)" :checked="show_c[5]"> Advertiser
                <input type="checkbox" @click="toggle_column(6)" :checked="show_c[6]"> Geo
                <input type="checkbox" @click="toggle_column(7)" :checked="show_c[7]"> Bundle
                <input type="checkbox" @click="toggle_column(8)" :checked="show_c[8]"> OS
                <input type="checkbox" @click="toggle_column(9)" :checked="show_c[9]"> Event 1
                <input type="checkbox" @click="toggle_column(10)" :checked="show_c[10]"> Event 2
                <input type="checkbox" @click="toggle_column(11)" :checked="show_c[11]"> Payout
            </div>
            <span style="display: none;">{{ r }}</span>
        </div>
        <table class="offers_tbl"  :style="{ height: tableHeight }" >
            <tr class="table_header_row bottom_border  sticky_header">
                <!-- <td style="width: 30px;">

                </td> -->
                <td class="resize_td selectable_columns left_border" v-if="show_c[1]">
                    <div>
                        <div class="th-title" @click="sortByField('active')">
                            Active
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'active'">
                            <i :class='sortOptions.sortOrder === "desc" && sortOptions.sortByField === "active" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[2]">
                    <div class="th-title" @click="sortByField('offer_id')">
                        Offer ID
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'offer_id'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[3]">
                    <div class="th-title" @click="sortByField('app_name')">
                        App Name
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'app_name'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[4]">
                    <div class="th-title" @click="sortByField('agency_name')">
                        Agency
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'agency_name'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[5]">
                    <div class="th-title" @click="sortByField('adv_name')">
                        Advertiser
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'adv_name'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[6]">
                    <div class="th-title" @click="sortByField('geo')">
                        Geo
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'geo'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[7]">
                    <div class="th-title" @click="sortByField('goffer_bundle_id')">
                        Bundle
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'goffer_bundle_id'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[8]">
                    <div class="th-title" @click="sortByField('os')">
                        OS
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'os'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[9]">
                    <div class="th-title" @click="sortByField('event_1')">
                        Event 1
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'event_1'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[10]">
                    <div class="th-title" @click="sortByField('event_2')">
                        Event 2
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'event_2'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[11]">                    
                    <div class="th-title" @click="sortByField('payout')">
                        Payout
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'payout'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
            </tr>
            <tr v-for="r in rows_data_c" :class="r.i%2 == 0 ? 'grey-bg-td' : ''">
                <td class="resize_td left_border" v-if="show_c[1]">
                   <!--  {{ r.active }} -->
                   <div class="centered_div">
                    <input type="checkbox" v-model="r.active" :value="true" :unchecked-value="false" @change="updateRowStatus(r)">
                   </div>
                </td>
                <td class="resize_td left_border" v-if="show_c[2]">
                    <a :href="r.href">{{ r.id }}</a>
                </td>
                <td class="resize_td left_border" v-if="show_c[3]">
                    {{ r.app_name }}
                </td>
                <td class="resize_td left_border" v-if="show_c[4]" style="text-align: center;">
                    {{ r.agency_name }}
                </td>
                <td class="resize_td left_border" v-if="show_c[5]">
                    {{ r.adv_name }}
                </td>
                <td class="resize_td left_border" v-if="show_c[6]">
                    {{ r.geo }}
                </td>
                <td class="resize_td left_border" v-if="show_c[7]">
                    {{ r.goffer_bundle_id }}
                </td>
                <td class="resize_td left_border" v-if="show_c[8]">
                    {{ r.os }}
                </td>
                <td class="resize_td left_border" v-if="show_c[9]">
                    {{ r.event_1 }}
                </td>
                <td class="resize_td left_border" v-if="show_c[10]">
                    {{ r.event_2 }}
                </td>
                <td class="resize_td left_border" v-if="show_c[11]">
                    {{ r.payout }}
                </td>
            </tr>
        </table>
    </div>
</template>
  
<script>
export default {
    props: {
        rows_data: [],
        tableHeight: ''
    },
    components: {

    },
    data() {
        return {
            show_columns_selector: false,
            show_c: {
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
                7: true,
                8: true,
                9: true,
                10: true,
                11: true,
            },
            selected_row: -1,
            selected_offer: 0,
            r: 0,
            sortOptions: { 
                sortOrder: 'desc',
                sortByField: 'id'
            }
        }
    },
    created() {
        const t_arr = window.location.href.split('/')
        if (typeof t_arr[10] !== 'undefined') {
            this.selected_offer = t_arr[10]
        }
       
        if (localStorage.getItem('show_c')) {
            this.show_c = JSON.parse(localStorage.getItem('show_c'));
        }
    },
    methods: {
        async updateRowStatus(row) {
            try {
                let r = await this.$http.post(this.resources.GlobalOffers.setScraperStatus, {
                    id: row.id,
                    active: row.active
                })
                console.log(r.data.res);
                if (r.data.res !== 'OK') {
                    this.notifyError(r.data.msg)
                    return
                }
            } catch (err) {
                console.log(err);
            }
        },
        toggle_columns_selector() {
            this.show_columns_selector = !this.show_columns_selector
        },
        toggle_column(i) {
            this.show_c[i] = !this.show_c[i]
            localStorage.setItem('show_c', JSON.stringify(this.show_c));
            this.$forceUpdate();
        },
        onRowDeleteClick(r) {
            this.handleDeleteOffer(r.id)
        },
        sortByField(fieldName) {
            this.sortOptions.sortByField = fieldName
            if (this.sortOptions.sortOrder === 'desc') {
                this.sortOptions.sortOrder = 'asc'
            } else {
                this.sortOptions.sortOrder = 'desc'
            }
            const nonIntFields = ['agency_name', 'adv_name', 'app_name', 'geo', 'os', 'event_1', 'event_2']
            const floatFields = ['payout']
            const booleanFields = ['active'];
            if (booleanFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1)
                }
            } else if (! nonIntFields.includes(fieldName) && ! floatFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => ((a[fieldName]) < (b[fieldName])) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => ((a[fieldName]) > (b[fieldName])) ? 1 : -1)
                }

            } else if (floatFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1)
                }
            } else {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) ? 1 : -1)
                }
            }
        }
    },
    computed: {
        rows_data_c() {
            for (let i in this.rows_data) {
                this.rows_data[i].i = i;
            }
            return this.rows_data
        }
    },
}
</script>
  
<style lang="css" scoped>
.offers_tbl {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

table {
  display: block;
  overflow-y: scroll;
}

.resize_td {
    resize: both;
    overflow: auto;
    padding: 10px;
    word-break: break-word;
}

.noresize_td {
    padding: 10px;
}

td {
    font-size: 14px;
}

.left_border {
    border-left: 1px dotted gray;
}

.bottom_border {
    border-bottom: 1px solid gray;
}
.table_header_row {
    font-weight: bold;
}

.clickable_row {
    cursor: pointer;
}

.selected_row {
    background-color: #f2f2f2;
}

.th-title {
    display: inline-block;
    cursor: pointer;
}

.th-icon {
    display: inline-block; 
    padding-left: 2px;
}

.excluded-offer-span {
    font-size: 7px;
    color: rgb(157, 0, 0);
}

.sticky_header {
    position: sticky;
    top: 0;
    background: white;
}

.centered_div {
    text-align: center;
}

.grey-bg-td {
    background-color: rgb(209, 209, 209);
}
</style>
