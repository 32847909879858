<template lang="html">
  <div class="add-perm">
    <b-button id="plus-btn" size="sm" variant="outline-primary" class="mb-2" @click="addNewPerm">
      <b-icon font-scale="3" icon="plus" aria-label="Help"></b-icon>
    </b-button>
    <div class="sl-list" v-if='permsList'>

      <div v-if='permsList'>
        <b-table id="bootsrap-table" sticky-header="700px" striped hover :fields="fields" :items="permsList" fixed
          responsive :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(edit)="data" class="edit-tab">
            <button id="edit_btn" @click='editPermItem(data.item)'>
              <b-icon icon="pencil"></b-icon>
            </button>
          </template>

        </b-table>
      </div>

      <Modal class="modal-edit" :modalShow.sync=modalShowEdit :operation="'edit'" v-if="modalShowEdit" :permsList="permsList"
        :opCode.sync="opCode" :currentPerm.sync=currentPerm 
        :text.sync=modalText></Modal>
      <Modal class="modal-add" :modalShow.sync=modalShowAdd :operation="'add'" v-if="modalShowAdd" :opCode.sync="opCode"
        :permsList="permsList" :currentPerm.sync=currentPerm :date="''" :text="''"></Modal>

    </div>
  </div>
</template>
<script>

  import Modal from '../../components/Permissions Managment/modal.vue'

  export default {
    components: {
      Modal
    },
    data() {
      return {
        fields: [{
          key: 'Edit',
          thStyle: {
            width: "5%"
          },
        }, {
          key: 'id',
          sortable: true,
          label: 'ID',
          thStyle: {
            width: "5%"
          },
        }, {
          key: 'name',
          sortable: true,
          label: 'Permissions Name',
          thStyle: {
            width: "10%"
          },
        },{
          key: 'description',
          thStyle: {
            width: "30%"
          },
        }],
        isBusy: false,
        currentPerm: {},
        modalShowEdit: false,
        modalShowAdd: false,
        performSubmit: 0,
        modalText: "",
        modalName: "",

        selectedPerm: null,
        loaded: false,
        opCode: 0,

        newPerm: {
          name: null
        },
        assignedPerms: {},
        notAssignedPerms: {},
        permsList: [],

        loaded: false
      }
    },
    watch: {
      currentPerm(newVal, oldVal) {
        if (this.modalShowAdd == false && this.modalShowEdit == false) {
          this.isBusy = !this.isBusy
          setTimeout(this.getData, 3000)
        }

      },
    },
    created() {
      this.isBusy = !this.isBusy
      this.getData()
    },
    methods: {
      getData: async function () {
        try {
          const r = await this.$http.get(this.resources.Admin.newPerm)
          const data = await r.json()
          this.permsList = data
          this.isBusy = !this.isBusy
        } catch (err) {
          this.notifyError("Could fetch permissions.")
        }

      },
      addPerm: async function (NewPerm) {
        try {
          const data = await this.$http.post(this.resources.Admin.newRole, NewPerm)

          this.notifySuccess(`New permission '${NewPerm.name}' has been created`)
          this.NewPerm.name = null
          this.permsList.push(NewPerm)
          this.selectedPerm = NewPerm

        } catch (err) {
          this.notifyError("Could not insert permission.")
        }
      },
      addNewPerm() {
        this.modalShowAdd = !this.modalShowAdd;
        this.currentPerm = {
          id: 0,
          name: '',
          Permissions: [],
        }
      },
        editPermItem(perm) {
          this.currentPerm = perm

          this.modalShowEdit = !this.modalShowEdit
          this.modalText = ` ${perm.name}  ?`
        },
      editRole: async function (perm) {
        try {
          const data = await this.$http.put(this.resources.Admin.editRole(perm.id), perm)
          this.notifySuccess(`Permission has been edited`)
        } catch (err) {
          this.notifyError("Could not edit permission.")
        }
      },
      deletePerm: async function (perm) {
        try {
          await this.$http.delete(this.resources.Admin.deleteRole(perm.id))
          this.notifySuccess(`Permission ${perm.name} has been deleted`)
        } catch (err) {
          this.notifyError(`Could not delete permission ${perm.name}.`)
        }
      },
    }
  }
</script>

<style lang="css" scoped> #edit_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
 
</style>