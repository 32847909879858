<template lang="html">
    <button @click='onClick' type="button" class="btn btn-dark export-button"
    :disabled='disabled' title="Export to CSV">
      <i class="fa fa-file-excel-o"></i>
      Export CSV
    </button>
</template>

<script>
export default {
  props: {
    onClick: {
      default: () => () => {}
    },
    disabled: {
      type: [Boolean, Number],
      default: false
    }
  }
}
</script>

<style lang="css">
.export-button {
  border-radius: 2px;
}
</style>
