<template>
    <div>
        <div class="header">
            <h1>Global Offers Scraper</h1>
        </div>
        <GlobalOffersScraperDataTable :tableHeight="'600px'" v-if="!loading" :rows_data="globalOffers"></GlobalOffersScraperDataTable>
        <div v-if="loading">Loading ...</div>
    </div>
</template>

<script>
import GlobalOffersScraperDataTable from '../components/GlobalOffersScraperDataTable.vue';
export default {
    components:{GlobalOffersScraperDataTable},
    data() {
        return {
            globalOffers: [],
            globalOfferSelected:{},
            showDeleteModal: false,
            advList: [],
            loading: false,
            afPrtList: [],
        }
    },
    async created() {
        this.loading = true;
        await this.getAfPrt()
        await this.getAdvertisersList()
        await this.fetchGlobalOffers()
        // fetch af prt list 
        //dm/getAfPart
        //
        this.loading = false;
    },
    methods: {
        async getAfPrt() {
            try {
                const res = await this.$http.post(this.resources.Management.getAfPart)
                if (res.body.data) {
                    this.afPrtList = res.body.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getAdvertisersList() {
            try {
                const res = await this.$http.post(this.resources.Management.getAdvertisersList)
                if (res.body.data) {
                    this.advList = res.body.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        async fetchGlobalOffers() {
            try {
                this.loading = true;
                const r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOffers + '?af_only=1')
                const data = await JSON.parse(r.bodyText)
                
                if (data.res !== 'OK') {
                    this.notifyError("Can\'t fetch offers")
                    return
                }
                this.globalOffers = data.data 
                for (const k in this.globalOffers) {
                    let isVta = this.globalOffers[k].ctr_id !== 0 ? 1 : 0
                    this.globalOffers[k].href = `/apps/smartlinks/global-offers-edit?id=${this.globalOffers[k].id}&isVta=${isVta}`
                    this.globalOffers[k].show = true;
                    this.globalOffers[k].actions = false;
                    if (this.globalOffers[k].advertiser_id !== 0) {
                        let adv_obj = this.advList.filter(item => item.id === this.globalOffers[k].advertiser_id)[0]
                        if (adv_obj === undefined) {
                           this.globalOffers[k].adv_name = 'none'
                        } else {
                            let adv_name = adv_obj.adv_company_name
                            this.globalOffers[k].adv_name = adv_name
                        }
                       
                       
                    } else {
                        this.globalOffers[k].adv_name = 'none'
                    } 

                    if (this.globalOffers[k].appsflyer_agency_id !== 0) {
                        let af_prt_obj = this.afPrtList.filter(item => item.id === this.globalOffers[k].appsflyer_agency_id)[0]
                        let af_prt = af_prt_obj.af_part_text
                        this.globalOffers[k].agency_name = af_prt
                       
                    } else {
                        this.globalOffers[k].agency_name = 'none'
                    } 
                }
                this.loading = false;

            } catch (err) {
                console.log(err);
                this.notifyError("Can\'t fetch offers")
            }
        },
    }
}
</script>

<style scoped>

.global-offers-view { 
    padding-top: 40px;
    width: 100%;
}

td,th {
    width: 150px;
}

.tr {
    padding-top: 10px;
}

button {
    background-color: tomato;

}

.header {
    margin-bottom: 50px;
}

</style>
