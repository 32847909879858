

<template lang="html">
    <div style="overflow: auto;">
        <div style="width: 2500px;">
            <div>
                <a v-if="!show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Show Columns Selector</a>
                <a v-if="show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Hide Columns Selector</a>
                <div v-if="show_columns_selector">
                    <input type="checkbox" @click="toggle_column(1)" :checked="show_c[1]"> ID
                    <input type="checkbox" @click="toggle_column(2)" :checked="show_c[2]"> Type
                    <input type="checkbox" @click="toggle_column(3)" :checked="show_c[3]"> Name
                    <input type="checkbox" @click="toggle_column(4)" :checked="show_c[4]"> Status
                    <input type="checkbox" @click="toggle_column(5)" :checked="show_c[5]"> Traffic
                    <input type="checkbox" @click="toggle_column(6)" :checked="show_c[6]"> TMT Status
                    <input type="checkbox" @click="toggle_column(7)" :checked="show_c[7]"> DSP
                    <input type="checkbox" @click="toggle_column(8)" :checked="show_c[8]"> SSP
                    <input type="checkbox" @click="toggle_column(9)" :checked="show_c[9]"> CDN
                    <input type="checkbox" @click="toggle_column(10)" :checked="show_c[10]"> IP
                    <input type="checkbox" @click="toggle_column(11)" :checked="show_c[11]"> Affise Tracking
                    <input type="checkbox" @click="toggle_column(12)" :checked="show_c[12]"> Tag V
                    <input type="checkbox" @click="toggle_column(13)" :checked="show_c[13]"> Trcacking  Domain
                    <input type="checkbox" @click="toggle_column(14)" :checked="show_c[14]"> Adjust Status
                    <input type="checkbox" @click="toggle_column(15)" :checked="show_c[15]"> Date
                    <input type="checkbox" @click="toggle_column(16)" :checked="show_c[16]"> TMT Tag Name
                    <input type="checkbox" @click="toggle_column(17)" :checked="show_c[17]"> Comments
                </div>
                <span style="display: none;">{{ r }}</span>
            </div>
            <table class="offers_tbl"  :style="{ height: tableHeight }" >
                <tr class="table_header_row bottom_border fixed-header">
                    <td style="width: 30px;">
                        Edit
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[1]">
                        <div>
                            <div class="th-title" @click="sortByField('id')">
                                ID
                            </div>
                            <div class="th-icon" v-if="sortOptions.sortByField === 'id'">
                                <i :class='sortOptions.sortOrder === "desc" && sortOptions.sortByField === "active" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[2]">
                        <div class="th-title" @click="sortByField('type')">
                            Type
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'type'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[3]">
                        <div class="th-title" @click="sortByField('name')">
                            Name
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'name'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[4]">
                        <div class="th-title" @click="sortByField('status')">
                            Status
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'status'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[5]">
                        <div class="th-title" @click="sortByField('requests')">
                            Traffic
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'requests'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[6]">
                        <div class="th-title" @click="sortByField('tmt_status')">
                            TMT Status
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'tmt_status'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[7]">
                        <div class="th-title" @click="sortByField('dsp')">
                            DSP
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'dsp'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[8]">
                        <div class="th-title" @click="sortByField('ssp')">
                            SSP
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'ssp'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[9]">
                        <div class="th-title" @click="sortByField('cdn_name')">
                            CDN
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'cdn_name'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[10]">
                        <div class="th-title" @click="sortByField('ip')">
                            IP
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'ip'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[11]">                    
                        <div class="th-title" @click="sortByField('affise_tracking')">
                            Affise Tracking
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'affise_tracking'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[12]">                    
                        <div class="th-title" @click="sortByField('stags_version')">
                            Tag V
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'stags_version'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[13]">                    
                        <div class="th-title" @click="sortByField('tracking_domain')">
                            Trcacking  Domain
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'tracking_domain'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[14]">                    
                        <div class="th-title" @click="sortByField('adjust_status')">
                            Adjust Status
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'adjust_status'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[15]">                    
                        <div class="th-title" @click="sortByField('date')">
                            Date
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'date'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[16]">                    
                        <div class="th-title" @click="sortByField('tmt_tag_name')">
                            TMT Tag Name
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'tmt_tag_name'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                    <td class="resize_td selectable_columns left_border" v-if="show_c[17]">                    
                        <div class="th-title" @click="sortByField('comments')">
                            Comments
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'comments'">
                            <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </td>
                </tr>
                <tr v-for="r,i in rows_data_c" :class="i%2 === 0 ? 'grey-bg-row' : ''">
                    <td class="noresize_td">
                        <a :href="r.href">Edit</a>
                    </td>
                    <td class="resize_td left_border" v-if="show_c[1]">
                        {{ r.id }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[2]">
                        {{ r.type }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[3]">
                        {{ r.name }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[4]" style="text-align: center;">
                        {{ r.status }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[5]">
                        {{ r.requests }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[6]">
                        {{ r.tmt_status }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[7]">
                        {{ r.dsp }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[8]">
                        {{ r.ssp }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[9]">
                        {{ r.cdn_name }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[10]">
                        {{ r.ip }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[11]">
                        {{ r.affise_tracking }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[12]">
                        {{ r.stags_version }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[13]">
                        {{ r.tracking_domain }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[14]">
                        {{ r.adjust_status }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[15]">
                        {{ r.date }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[16]">
                        {{ r.tmt_tag_name }}
                    </td>
                    <td class="resize_td left_border" v-if="show_c[17]">
                        {{ r.comments }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
  
<script>
import moment from 'moment';
export default {
    props: {
        rows_data: [],
        tableHeight: ''
    },
    components: {

    },
    data() {
        return {
            show_columns_selector: false,
            show_c: {
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
                7: true,
                8: true,
                9: true,
                10: true,
                11: true,
            },
            selected_row: -1,
            selected_offer: 0,
            r: 0,
            sortOptions: { 
                sortOrder: 'desc',
                sortByField: 'id'
            },
        }
    },
    created() {
        const t_arr = window.location.href.split('/')
        if (typeof t_arr[10] !== 'undefined') {
            this.selected_offer = t_arr[10]
        }
       
        if (localStorage.getItem('show_c')) {
            this.show_c = JSON.parse(localStorage.getItem('show_c'));
        }
    },
    methods: {
        async updateRowStatus(row) {
            try {
                let r = await this.$http.post(this.resources.GlobalOffers.setScraperStatus, {
                    id: row.id,
                    active: row.active
                })
                console.log(r.data.res);
                if (r.data.res !== 'OK') {
                    this.notifyError(r.data.msg)
                    return
                }
            } catch (err) {
                console.log(err);
            }
        },
        toggle_columns_selector() {
            this.show_columns_selector = !this.show_columns_selector
        },
        toggle_column(i) {
            this.show_c[i] = !this.show_c[i]
            localStorage.setItem('show_c', JSON.stringify(this.show_c));
            this.$forceUpdate();
        },
        onRowDeleteClick(r) {
            this.handleDeleteOffer(r.id)
        },
        sortByField(fieldName) {
            this.sortOptions.sortByField = fieldName
            if (this.sortOptions.sortOrder === 'desc') {
                this.sortOptions.sortOrder = 'asc'
            } else {
                this.sortOptions.sortOrder = 'desc'
            }

            const nonIntFields = ['version', 'type', 'status', 'stags_version', 'ssp', 'name', 'dsp', 'comments', 'cdn_name', 'tmt_status', 'tracking_domain', 'adjust_status', 'tmt_tag_name', 'affise_tracking', 'ip']
            const floatFields = []
            const dateFields = ['date']
            // date
            if (dateFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => {
                        let dateA = moment(a[fieldName], 'DD.MM.YYYY');
                        let dateB = moment(b[fieldName], 'DD.MM.YYYY');
                        return dateA.isBefore(dateB) ? 1 : -1;
                    });
                } else {
                    this.rows_data.sort((a, b) => {
                        let dateA = moment(a[fieldName], 'DD.MM.YYYY');
                        let dateB = moment(b[fieldName], 'DD.MM.YYYY');
                        return dateA.isAfter(dateB) ? 1 : -1;
                    });
                }
            // int
            } else if (! nonIntFields.includes(fieldName) && ! floatFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (parseInt(a[fieldName]) < parseInt(b[fieldName])) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (parseInt(a[fieldName]) > parseInt(b[fieldName])) ? 1 : -1)
                }
            // float
            } else if (floatFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1)
                }
            // string
            } else {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) ? 1 : -1)
                }
            }
        }
    },
    computed: {
        rows_data_c() {
            console.log('rows_data_c')
            return this.rows_data
        }
    },
    filters: {
        numFormat(v) {
            let i = isNaN(v) ? 0 : v
            return(Number(i).toLocaleString())
            },
        percentageFormat(v) {
            if (!v) {
                v = 0
            }
            return v.toFixed(0) + "%"
        },
    },
}
</script>
  
<style lang="css" scoped>
.offers_tbl {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

table {
  display: block;
  overflow-y: scroll;
}

.resize_td {
    resize: both;
    overflow: auto;
    padding: 10px;
}

.noresize_td {
    padding: 10px;
}

td {
    font-size: 14px;
}

.left_border {
    border-left: 1px dotted gray;
}

.bottom_border {
    border-bottom: 1px solid gray;
}
.table_header_row {
    font-weight: bold;
}

.clickable_row {
    cursor: pointer;
}

.selected_row {
    background-color: #f2f2f2;
}

.th-title {
    display: inline-block;
    cursor: pointer;
}

.th-icon {
    display: inline-block; 
    padding-left: 2px;
}

.excluded-offer-span {
    font-size: 7px;
    color: rgb(157, 0, 0);
}

.fixed-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.grey-bg-row {
    background-color: #dbdbdb;
}
</style>
