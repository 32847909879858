<template lang="html">
<div class="add-perm">
    <b-button id="plus-btn" size="sm" variant="outline-primary" class="mb-2" @click="isShow = !isShow">
        <b-icon font-scale="3" icon="plus" aria-label="Help"></b-icon>
    </b-button>
    <div>
        Filter by operation manager:
        <select @change="onFilterByOm" v-model="omFilter">
            <option value="0">All</option>
            <option v-for="om of omList" :value="om.id">{{ om.om }}</option>
        </select>
    </div>
    <div>
        <AddToListModal :is-show.sync=isShow title="New Group List" input-title="Group Name" v-on:add="addNewGroup"></AddToListModal>
    </div>

    <div class="sl-list" v-if='groupList'>
        <div v-if='groupList.length === 0'>
            <div class="no_groups_div">
                Can't find any groups
            </div>
        </div>
        <div v-if='groupList.length !== 0'>
            <template>
                <b-table id="bootsrap-table" sticky-header="700px" striped hover :fields="fields" :items="groupList" fixed responsive :busy="isBusy">
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>

                    <template v-slot:cell(schedule)="{item}" class="edit-row-btn">
                        <button  @click="schedulerHandler(item)">
                            <span>SCHEDULE</span>
                        </button>
                    </template>

                    <template v-slot:cell(edit)="{item}" class="edit-tab">
                        <button v-if="!item.isEditable" class="edit_btn" @click="item.isEditable = true">
                            <b-icon icon="pencil"></b-icon>
                        </button>
                        <button v-else class="edit_btn paused-btn">
                            <b-icon icon="pencil"></b-icon>
                        </button>
                    </template>

                    <template v-slot:cell(delete)="{item}" class="edit-row-btn">
                        <button class="edit_btn" @click="deleteGroup(item)">
                            <b-icon icon="trash"></b-icon>
                        </button>
                    </template>

                    <template v-slot:cell(name)="{item}">
                        <b-row>
                            <b-col>
                                <div @input="onInput" :contenteditable="item.isEditable" :class='{editable: item.isEditable===true, uneditable: item.isEditable===false}'>
                                    {{ item.name }}
                                </div>
                            </b-col>
                            <b-col v-if="item.isEditable">
                                <b-button variant="success" v-if="item.isEditable" @click="updateGroupName(item)"> Save </b-button>
                                <b-button variant="secondary" v-if="item.isEditable" @click="item.isEditable = !item.isEditable"> Cancel </b-button>
                            </b-col>
                        </b-row>
                    </template>

                    <template v-slot:cell(isScheduled)="{item}">
                        <div class="checkbox">
                            <b-form-checkbox v-model="item.isScheduled" :disabled="item.isActiveStatus === 'ACTIVE' ? false : true" name="check-button" size="lg" :value=true :unchecked-value=false @change="toggleSchedulerActivation(item)"></b-form-checkbox>
                        </div>
                    </template>

                    <template v-slot:cell(isActiveStatus)="{item}">
                        <div class="checkbox">
                            <b-form-checkbox v-model="item.isActiveStatus" name="check-button" size="lg" :value="'ACTIVE'" :unchecked-value="'PAUSED'" @change="toggleGroupsActivation(item)"></b-form-checkbox>
                        </div>
                    </template>

                    <template v-slot:cell(offers)="{item}" class="edit-row-btn">
                        <button  class="edit_btn offers">
                            <a :href="('/apps/smartlinks/RelatedOffers?id=' + item.id)">
                                <b-icon font-scale="2" icon="plus-circle-fill" variant="success"></b-icon>
                            </a>
                        </button>
                    </template>

                    <template #cell(status)="{item}">
                        <span v-if="item.status" :class='{active: item.status==="ACTIVE", paused: item.status==="PAUSED"}'>
                            {{ item.status }}
                        </span>
                    </template>

                    <template #cell(group_om)="{item}">
                        <select @change="onOmChange($event, item)" v-model="item.omId">
                            <option value=""></option>
                            <option v-for="om of omList" :value="om.id">{{ om.om }}</option>
                        </select>
                    </template>

                </b-table>
            </template>
        </div>
    </div>
</div>
</template>

<script>
import AddToListModal from '../../../../components/AddToListModal.vue';

import Scheduler from '../../../../components/Scheduler.vue';
const SL_STATUS_ACTIVE = "ACTIVE"
const SL_STATUS_PAUSED = "PAUSED"

export default {
    components: {
        AddToListModal,
        Scheduler
    },
    data() {
        return {
            fields: [{
                    key: "Delete",
                    thStyle: {
                        width: "2%"
                    },
                }, {
                    key: "Edit",
                    thStyle: {
                        width: "2%"
                    },
                }, {
                    key: "name",
                    sortable: true,
                    label: "Group Name",
                    thStyle: {
                        width: "5%"
                    },
                }, {
                    key: "status",
                    label: "Current Status",
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                    stickyColumn: true,
                    thStyle: {
                        width: "5%"
                    },
                }, {
                    key: "isActiveStatus",
                    label: "Is Group Active",
                    stickyColumn: true,
                    thStyle: {
                        width: "3%"
                    },
                }, {
                    key: "isScheduled",
                    label: "Is Schedule Active",
                    stickyColumn: true,
                    thStyle: {
                        width: "3%"
                    },
                },
                {
                    key: "Schedule",
                    label: "",
                    thStyle: {
                        width: "4%"
                    },
                }, 
                {
                    key: "offers",
                    label: "Related Offers",
                    stickyColumn: true,
                    thStyle: {
                        width: "4%"
                    }
                },
                {
                    key: "group_om",
                    label: "OM",
                    stickyColumn: true,
                    thStyle: {
                        width: "4%"
                    }
                }
            ],
            newName: "",
            isShow: false,
            isBusy: false,
            groupList: [],
            groupListOrig: [],
            loaded: false,
            omList: [],
            omFilter: '0'
        };
    },
    created() {
        this.isBusy = !this.isBusy;
        this.getData();
    },
    methods: {
        onInput(e) {
            this.newName = e.target.innerText
        },
        getData: async function () {
            try {
                let state = this.$store.state
                state.smartlinks = {}
                const r = await this.$http.get(this.resources.SmartLinksCampaigns.getSlGroups);
                const data = await r.json()
                state.smartlinks.groups = data
                this.groupList = data.map(item => ({
                    ...item,
                    isEditable: false
                }));
                this.groupListOrig = this.groupList

                const omsRes = await this.$http.post(this.resources.Management.getDmOms);
                const omsResData = await omsRes.json()
                this.omList = omsResData.data

                this.isBusy = !this.isBusy
                
                if (this.omFilter !== '0') {
                    await this.filterByOm(this.omFilter)
                }
            } catch (err) {
                this.notifyError("Could not fetch groups.");
            }
        },
        addNewGroup: async function (NewGroup) {
            this.isBusy = !this.isBusy;
            try {
                const data = await this.$http.post(this.resources.SmartLinksCampaigns.newGroup, {
                    id: 0,
                    name: NewGroup,
                    status: 'active'
                })

                this.notifySuccess(`New group '${NewGroup}' has been created`)
                this.getData();

            } catch (err) {
                this.notifyError("Could not insert group.")
            }
        },
        updateGroupName: async function (item) {
            if (this.newName === '') {
                item.isEditable = !item.isEditable
                return
            }

            try {
                item.isEditable = !item.isEditable
                const r = await this.$http.put(this.resources.SmartLinksCampaigns.updateGroupName(item.id), {
                    ..._.omit(item, ['isEditable', 'name']),
                    name: this.newName,
                })

                this.notifySuccess(`Group ${item.name} has been updated succesfully`)

            } catch (err) {
                console.log(err);
                this.notifyError(`Could not update ${item.name} groups name`)
            }
        },
        deleteGroup: async function (group) {
            try {
                this.isBusy = !this.isBusy;
                await this.$http.delete(this.resources.SmartLinksCampaigns.deleteGroupById(group.id))
                this.notifySuccess(`Group ${group.name} has been deleted succesfully implies all campaigns connected to it are now ungroupped`)
                this.getData();
            } catch (err) {
                this.notifyError(`Could not delete group ${group.name}.`)
            }
        },
        toggleSlStatus: async function (slGroup) {
            if (!this.userHasPermission("smartlinks.status-change")) {
                return;
            }
            let toStatus;
            if (slGroup.status === SL_STATUS_ACTIVE) {
                toStatus = SL_STATUS_PAUSED;
            } else {
                toStatus = SL_STATUS_ACTIVE;
            }
            if (!window.confirm(`Are you sure that you want to change campaign "${slGroup.name}" status to: ${toStatus}?`)) {
                return;
            }
            try {
                const r = await this.$http.put(this.resources.SmartLinksCampaigns.setGroupStatus(slGroup.id), {
                    status: toStatus
                });
                slGroup.status = toStatus;
            } catch (err) {
                console.log(err);
            }
        },
        toggleSchedulerActivation: async function (group) {
            try {
                this.isBusy = !this.isBusy;
                const r= await this.$http.put(this.resources.SmartLinksCampaigns.setGroupActiveScheduler(group.id), JSON.stringify({
                    ...group
                }))
                const data = await r.json()
                this.notifySuccess(data.Res)
                this.getData()
            } catch(err) {
                this.notifyError(err);
            }
        },
        toggleGroupsActivation: async function (group) {
            try {
                this.isBusy = !this.isBusy;
                const r = await this.$http.put(this.resources.SmartLinksCampaigns.setGroupActive(group.id), JSON.stringify({
                    status: group.isActiveStatus
                }))
                const data = await r.json()
                this.notifySuccess(data.Res)
                this.getData();
            } catch(err) {
                this.notifyError(err);
            }
        },
        schedulerHandler(item) {
            this.$router.push({
                name: "SmartLinksGroupView",
                params: {
                    groupId: item.id
                }
            })
        },
        async onOmChange(e, item) {
            const req = { group_id: item.id, om_id: parseInt(e.target.value) }
            await this.$http.post(this.resources.Management.updateDmSlOm, req)
            if (this.omFilter !== '0') {
                const t = []
                for (let k in this.groupList) {
                    if (this.groupList[k].omId === this.omFilter) {
                        t.push(this.groupList[k])
                    }
                }
                this.groupList = t
            }
        },
        async onFilterByOm(e) {
            await this.filterByOm(e.target.value)
        },
        async filterByOm(om) {
            if (om === '0') {
                this.groupList = this.groupListOrig
            } else {
                this.groupList = []
                for (let k in this.groupListOrig) {
                    if (this.groupListOrig[k].omId === parseInt(om)) {
                        this.groupList.push(this.groupListOrig[k])
                    }
                }
            }
        }
    },

}
</script>

<style lang="css" scoped>
.edit_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.editable {
    border: 2px solid dodgerblue;
    background-color: white;
    border-radius: 5%;
    height: 90%;
    background-origin: border-box;
    background-clip: content-box, border-box, padding-box;
    background-size: cover;
    box-sizing: border-box;
    box-shadow: 0 0 3px 3px dodgerblue;
    padding-left: 2%;
    background-image: linear-gradient(to bottom, white 0%, white 100%), linear-gradient(to bottom, white 0%, white 100%);
}

.uneditable {
    border: none transparent;
    background-color: transparent;
}

.paused-btn {
    pointer-events: none;
    opacity: 50%;
}

.checkbox{
    margin-left: 50%;
}

.offers{
    margin-left: 10%;
}

.no_groups_div {
    font-size: 20px;
    padding: 10px;
}

.sl-list {
    border: none;
}
</style>
