<template lang="html">
  <div class="add-role">
    <b-button id="plus-btn" size="sm" variant="outline-primary" class="mb-2" @click="addNewRole">
      <b-icon font-scale="3" icon="plus" aria-label="Help"></b-icon>
    </b-button>
    <div class="sl-list" v-if='rolesList'>

      <div v-if='rolesList'>
        <b-table id="bootsrap-table" sticky-header="700px" striped hover :fields="fields" :items="rolesList" fixed
          responsive :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:head()="data">
            <span id="tooltip-bootsrap-template" v-b-tooltip.hover :title='data.field.tooltip'>
              {{ data.label }}
            </span>
          </template>
          <template v-slot:cell(edit)="data" class="edit-tab">
            <button id="edit_btn" @click='editRoleItem(data.item)'>
              <b-icon icon="pencil"></b-icon>
            </button>
          </template>
          <template v-slot:cell(Permissions)="data" class="badge-perm">
            <b-badge v-for="item in data.item.permission" :key="item" variant="info">{{ item.name }}</b-badge>

          </template>

        </b-table>
      </div>

      <Modal class="modal-edit" :modalShow.sync=modalShowEdit :operation="'edit'" v-if="modalShowEdit"
        :roleList="rolesList" :opCode.sync="opCode" :currentRole.sync=currentRole
        :selectedPermissionsObj=currentRole.Permissions :text.sync=modalText></Modal>
      <Modal class="modal-add" :modalShow.sync=modalShowAdd :operation="'add'" v-if="modalShowAdd" :opCode.sync="opCode"
        :roleList="rolesList" :currentRole.sync=currentRole :date="''" :text="''"></Modal>

    </div>
  </div>
</template>


<script>
  import Modal from '../../components/Roles Managment/modal.vue'

  export default {
    components: {
      Modal
    },
    data() {
      return {
        fields: [{
          key: 'Edit',
          thStyle: {
            width: "5%"
          },
        }, {
          key: 'id',
          sortable: true,
          label: 'ID',
          thStyle: {
            width: "5%"
          },
        }, {
          key: 'name',
          sortable: true,
          label: 'Role Name',
          thStyle: {
            width: "10%"
          },
        }, {
          key: 'Permissions',
          sortable: true,
          label: 'Role Permissions',
          thStyle: {
            width: "5%"
          }
        }],
        isBusy: false,
        currentRole: {},
        modalShowEdit: false,
        modalShowAdd: false,
        performSubmit: 0,
        modalText: "",
        modalName: "",
        newRole: {
          name: null
        },
        optionsPermissions: [],
        rolesList: {},
        permsList: [],
        selectedRole: null,
        loaded: false,
        opCode: 0
      }
    },
    watch: {
      currentRole(newVal, oldVal) {
        if (this.modalShowAdd == false && this.modalShowEdit == false) {
          this.isBusy = !this.isBusy
          setTimeout(this.getData, 3000)
        }

      },
    },
    created() {
      this.getDataOnCreate();
    },
    methods: {

      getDataOnCreate: async function () {
        try {
          const r = await this.$http.get(this.resources.Admin.newRole)
          const data = await r.json()
          this.rolesList = data

          const i = await this.$http.get(this.resources.Admin.newPerm)
          const data1 = await r.json()
          this.permsList = data1
          for (const [key, value] of Object.entries(this.permsList)) {
            this.optionsPermissions.push(({
              name: value,
              item: key
            }))
          }
          this.loaded = true
        } catch (err) {
          this.notifyError("Could fetch roles.")
        }
      },
      getData: async function () {
        try {
          const r = await this.$http.get(this.resources.Admin.newRole)
          const data = await r.json()
          this.rolesList = data
          this.isBusy = !this.isBusy
        } catch (err) {
          this.notifyError("Could fetch roles.")
        }
      },
      addNewRole() {
        this.modalShowAdd = !this.modalShowAdd;
        this.currentRole = {
          id: 0,
          name: '',
          Permissions: [],
        }
      },
      editRoleItem(role) {
        this.currentRole = role

        this.modalShowEdit = !this.modalShowEdit
        this.modalText = ` ${role.name}  ?`
      },
      addRole: async function (newRole) {
        try {
          const data = await this.$http.post(this.resources.Admin.newRole, newRole)

          this.notifySuccess(`New role '${newRole.name}' has been created`)
          this.newRole.name = null
          this.rolesList.push(newRole)
          this.selectedRole = newRole
        } catch (err) {
          this.notifyError("Could not insert role.")
        }
      },
      editRole: async function (role) {
        try {
          const data = await this.$http.put(this.resources.Admin.editRole(role.id), role)
          this.notifySuccess(`Role has been edited`)
        } catch (err) {
          this.notifyError("Could not edit role.")
        }
      },
      deleteRole: async function (role) {
        try {
          await this.$http.delete(this.resources.Admin.deleteRole(role.id))
          this.notifySuccess(`Role ${role.name} has been deleted`)
        } catch (err) {
          this.notifyError(`Could not delete role ${role.name}.`)
        }
      }
    }
  }
</script>

<style lang="css" scoped>
  #edit_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
</style>