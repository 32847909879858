<template lang="html">
  <div class="cont-1">
    <h3>The Admin is temporarily not available.</h3>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.cont-1 {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  padding: 7px;
  background: white;
  border: 1px solid gainsboro;
}
</style>
