<template lang="html">
  <div class="all">

    <h2>{{onlyDeleted ? "Deleted" : ""}} Banners</h2>

    <div class="filter-form-container-2513">
      <form class="form-section" @submit.prevent=''>
        <div class="form-inputs">
          <label >Filter by name:</label>
          <input class="def filter-pl-name-input" v-model='filterForm.name'>
        </div>
      </form>
    </div>

    <ul class="banners-list">
      <li v-for='banner in bannerListFiltered' :key='banner.id'>
        <span class="banner-name">
          {{banner.name}}<br />
          Request yesterday: {{ banner.requests }}<br />
          <div v-if="banner.stag_ids !== ''">
            <a v-if="!banner.show_assoc_stags" href="javascript:void(0);" v-on:click="toggle_assoc_stags(banner.id)">Show Assoc Stags</a>
            <a v-if="banner.show_assoc_stags" href="javascript:void(0);" v-on:click="toggle_assoc_stags(banner.id)">Hide Assoc Stags</a>
            <div v-if="banner.show_assoc_stags">
              Associated Stag IDs: {{ banner.stag_ids }}
            </div>
          </div>
        </span>
        <sapn class="banner-req"> </sapn>
        <a :href='banner.link' target="_blank">
          <img :src='imageBaseURL + banner.filename'>
        </a>
        <span v-if='!onlyDeleted'>
          <i v-if='userHasPermission("banners.edit")' class="fa fa-trash trash-btn" aria-hidden="true" @click='delBanner(banner)'></i>
          <i v-if='userHasPermission("banners.edit")' class="fa fa-pencil edit-btn edit-btn-1251" aria-hidden="true" @click='editBanner(banner)'></i>
        </span>
      </li>
    </ul>

    <ScreenBlocker v-if='updateForm' :onClose='e => updateForm = null'>
      <div class="update-banner-form">
        <form class="banner-upload-form" @submit.prevent='submitUpdate'>
          <h4>Update Banner</h4>
          <img :src='imageBaseURL + updateForm.filename' class="ban-img-12">
          <div class="form-fields-53">
            <input class="def link-input-6341" placeholder="Banner Name" v-model='updateForm.name'>
            <input class="def link-input-6341" type="url" placeholder="Link" v-model='updateForm.link'>

            <button class="def btn-primary u-button-31" type="submit" :class='{disabled: ongoingUpload}' :disabled='ongoingUpload'>Update Banner</button>
          </div>

        </form>
      </div>

    </ScreenBlocker>

    <form class="banner-upload-form" @submit.prevent='submit' v-show='!onlyDeleted'>
      <h4>Upload Banner</h4>
      <!-- <label for="banner-upload-input">Upload a new banner</label> -->
      <div class="form-fields-53">
        <input id="banner-upload-input" type="file" accept="image/*" class="def-file" ref='banner_file'>
        <input class="def link-input-6341" placeholder="Banner Name" v-model='form.name'>
        <input class="def link-input-6341" type="url" placeholder="Link" v-model='form.link'>

        <button class="def btn-primary u-button-31" type="submit" :class='{disabled: ongoingUpload}' :disabled='ongoingUpload'>Upload Banner</button>
      </div>

    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageBaseURL: "https://du4ii0ldd40i3.cloudfront.net/banners/",
      ongoingUpload: false,
      bannerList: [],
      form: {
        name: null,
        link: null
      },
      updateForm: null,
      filterForm: {
        name: null
      }
    }
  },
  created() {
    this.fetchStagsBanners()
      .then(banners => {
      })
  },
  watch: {
    filterType(val) {
      this.fetchStagsBanners()
    }
  },
  computed: {
    bannerListFiltered() {
      let name = this.filterForm.name
      return name ? this.bannerList.filter(v => v.name.toLowerCase().includes(name.toLowerCase())) : this.bannerList
    },
    filterType() {
      return this.$route.params.filterType
    },
    onlyDeleted() {
      return this.filterType === "deleted"
    }
  },
  methods: {
    delBanner(banner) {
      if(!window.confirm(`Are you sure that you want to delete banner ${banner.name}?`)) {
        return
      }
      this.bannerList.splice(this.bannerList.indexOf(banner), 1)
      this.$http.delete(this.resources.ScriptTags.deleteBanner(banner.id))
      .then(() => {
        this.notifySuccess("Banner has been deleted")
      })
      .catch(err => {
        this.notifyError("Could not delete banner")
      })
    },
    editBanner(banner) {
      this.updateForm = Object.assign({}, banner)
    },
    clearForm() {
      this.form.name = null
      this.form.link = null
      this.$refs.banner_file.value = null
    },
    fetchStagsBanners() {
      this.bannerList = null
      return this.$http.get(this.resources.ScriptTags.getBanners, {
        params: {
          filter_deleted: Number(this.onlyDeleted)
        }
      })
      .then(r => r.json())
      .then(banners => {
        if(!banners) { throw "Err 2311" }
        this.bannerList = banners
        return banners
      })
    },
    submit() {
      if(this.ongoingUpload) {
        return
      }
      let image = this.$refs.banner_file
      image = image && image.files[0]
      if(!image) {
        this.notifyError("Please choose an image")
        return
      }
      let formData = new FormData()
      formData.append('file', image)
      let params = this.form
      if(!params.link || !params.name) {
        this.notifyError("Please fill banner link & name")
        return
      }
      if (params.name.length > 50) {
        this.notifyError("Banner name can't be more than 50 characters")
        return
      }

      params.name = params.name.trim()
      let bannerName = params.name.toLowerCase()
      if (this.bannerList.some(v => v.name.toLowerCase() === bannerName)) {
        this.notifyError("Banner with name '" + params.name + "' already exists")
        return
      }

      this.ongoingUpload = true
      this.$http.post(this.resources.ScriptTags.uploadBanner, formData, {
        params: params
      })
        .then(r => r.json())
        .then(banner => {
          this.clearForm()
          this.bannerList.unshift(banner)
          this.notifySuccess("Successfully uploaded banner: " + banner.name)
        })
        .catch(err => {
          this.notifyError("Could not upload banner")
        })
        .then(() => {
          this.ongoingUpload = false
        })
    },
    submitUpdate() {
      if(this.ongoingUpload) {
        return
      }
      // let image = this.$refs.banner_file
      // image = image && image.files[0]
      // if(!image) {
      //   this.notifyError("Please choose an image")
      //   return
      // }
      // let formData = new FormData()
      // formData.append('file', image)
      let updateForm = this.updateForm
      let params = {
        link: updateForm.link,
        name: updateForm.name
      }

      if(!params.link || !params.name) {
        this.notifyError("Please fill banner link & name")
        return
      }
      if (params.name.length > 50) {
        this.notifyError("Banner name can't be more than 50 characters")
        return
      }

      params.name = params.name.trim()
      let bannerName = params.name.toLowerCase()
      if (this.bannerList.some(v => v.name.toLowerCase() === bannerName && v.id !== updateForm.id)) {
        this.notifyError("Banner with name '" + params.name + "' already exists")
        return
      }

      this.ongoingUpload = true
      this.$http.put(this.resources.ScriptTags.updateBanner(updateForm.id), undefined, {
        params: params
      })
        .then(() => {
          this.notifySuccess("Successfully updated banner")
          Object.assign(this.bannerList.find(v=>v.id === updateForm.id), this.updateForm)
          this.updateForm = null
        })
        .catch(err => {
          this.notifyError("Could not update banner")
        })
        .then(() => {
          this.ongoingUpload = false
        })
    },
    toggle_assoc_stags(banner_id) {
      for (let k in this.bannerList) {
        if (this.bannerList[k].id == banner_id) {
          this.bannerList[k].show_assoc_stags = !this.bannerList[k].show_assoc_stags
        }
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="css" scoped>
.banners-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 360px;
  overflow-y: auto;
}
.banners-list li {
  border: 1px solid gainsboro;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f7f7f7;
}
.banners-list li img {
  max-height: 200px;
  max-width: 500px;
  background: white;
  border: 1px solid gainsboro;
  border-left: 0;
  border-right: 0;
}
.banner-name {
  text-align: center;
  font-weight: bold;
}
.banner-upload-form {
  margin-top: 36px;
  max-width: 500px;
  border: 1px solid gainsboro;
  padding: 12px;
  background: #f3f3f3;
}
.form-fields-53 {
  display: flex;
  flex-direction: column;
}
.form-fields-53 > * {
  margin-bottom: 15px;
}
.def-file {
  border: 1px solid gainsboro;
  padding: 8px;
  background: #f4f4f4;
}
.link-input-6341 {
  font-size: 18px;
  padding: 5px 11px;
}
.u-button-31 {
  padding: 10px 5px;
}
.u-button-31.disabled {
  background: #b7b7b7;
  color: #595959;
  border: 1px solid #9d9d9d;
}
.ban-img-12 {
  margin-bottom: 25px;
}
.edit-btn-1251 {
  margin-left: 5px;
}
.filter-form-container-2513 {
  width: 500px;
  background: #fdfdfd;
  margin-top: 16px;
  margin-bottom: 11px;
}

.banner-req {
  padding: 5px;
  padding-left: 20px;
  font-weight: 600;

}
</style>
