<template lang="html">
<div class="all">
    <Offer :isVta='isVta' :onSubmit='submit' :placement='placement'/>
   
</div>
</template>

<script>
import Offer from './components/Offer'

export default {
    props:{
        isVta: {
            type: Boolean
        },
        onAddOffer: {
            type: Function
        }   
    },
    components: {
        Offer
    },
    methods: {
        submit: async function (form) {
            let {
                campId,
                placementId,
                waterfallId
            } = this.$route.params
            if (this.placement.is_offers_rotation) {
                if (this.placement.current_selected_offer != 0 && this.placement.offers.length > 0) {
                    form.weight = 0;
                    if (form.click_offer) {
                        form.click_offer.weight = 0
                    }
                }
            }

            try {
                const r = await this.$http.post(this.resources.SmartLinksCampaigns.postOffer(campId, placementId, waterfallId), form)
                const data = await r.json()
                let {
                    offer_id,
                    click_offer_id,
                    currently_selected_pid,
                    last_rotate
                } = data
                form.id = offer_id
                if (click_offer_id) {
                    form.click_offer.id = click_offer_id
                }
                if (currently_selected_pid !== '') {
                    form.currently_selected_pid = currently_selected_pid
                }
                if (last_rotate !== '') {
                    form.last_rotate = last_rotate
                }
                let offers = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId).offers
                this.handleAdd(form)
                this.notifySuccess("Offer successfully saved. If you don't see it please refresh the page")
            } catch (err) {
                console.log(err);
                this.notifyError("Error occured.")
            }
            return true
        },
        handleAdd(form){
            this.onAddOffer && this.onAddOffer(form)
        }
    },
    computed: {
        placement() {
            let {
                campId,
                placementId,
                waterfallId
            } = this.$route.params
            return this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
        }
    }
}
</script>

<style lang="css">
</style>
