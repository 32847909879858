<template>
    <div>
        <b-modal v-model="showDeleteModal" @ok="confirmDelete()">Are you sure you want to delete advrtiser- {{ deleteAdvName }}</b-modal>
        <h1> Advertisers </h1>

        <!-- <form class="report-form-1" @submit.prevent=''>
            <DateRange :disabled='!page_loaded' :onChange='onDatesChange' :from_='form.date_from' :to_='form.date_to' />
            <div class="div-1412">
                <button :disabled='!page_loaded' @click='updateData' class="btn btn-success">Update Revenue</button>
                <i class="spinner fa fa-circle-o-notch fa-spin" v-if='!page_loaded'></i>
            </div>
        </form> -->

        <!-- date range -->
        <form class="daterangepicker-form" @submit.prevent=''>
            <div>
                <div>
                    <date-range-picker 
                        style="z-index: 9999; padding-bottom: 5%;"
                        ref="picker"
                        v-model="dateRange"
                        opens="right"
                        :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                        :ranges="ranges"
                        @update="logEvent"
                        @toggle="logEvent('toggle', $event)"
                        @start-selection="logEvent('event: startSelection', $event)"
                        @finish-selection="logEvent('event: finishSelection', $event)"
                        :disabled='!page_loaded'
                        :maxDate="maxDate"
                    >
                    </date-range-picker>
                </div> 
            </div>
            <div class="div-1412">
                <i class="spinner fa fa-circle-o-notch fa-spin" v-if='!page_loaded'></i>
            </div>
        </form>

        <div v-if="page_loaded" class="filter-form-container">
            <form class="" @submit.prevent=''>
                <h5>Filter</h5>
                <div class="form-inputs">
                    <b-form-select class="def filter-select" v-model="selected_list" :options="list_types" @change="updateCurrentList"></b-form-select>
                    <b-form-select class="def filter-select" v-model="filter_value" v-if="current_list.length !== 0" :options="current_list" @change="filterTableRows"></b-form-select>
                    <button :class="'btn btn-danger'" v-if="filter_value !== null" @click="clearTableRowFilter">Clear</button>
                </div>
            </form>
        </div>
        <br> <br> <br>
        <a href="/apps/script-tags/add-advertisers?id=0"> Add New Advertiser </a>
       <ResizableDataTable v-if="page_loaded" :rows_data.sync="advertisers" :totalRow="total_row" :fields="fields" :show_c.sync="show_col" :delete="deleteRow" :nonIntFields="nonIntFields" :booleanFields="boolFields" :revenueFields="revenueFields" :show_columns_name="'advertisers'" ></ResizableDataTable>
    </div>
</template>
<script>
import { sum } from 'lodash'
import ResizableDataTable from '../../../../components/shared/ResizableDataTable.vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
export default {
    components:{ResizableDataTable, DateRangePicker},
    data() {
        let today = new Date()
        let yesterday = today.setDate(today.getDate() - 1)

        let yesterdayx = new Date();
        yesterdayx.setDate(yesterdayx.getDate() - 1);
        let todayx = new Date();

        return {
            advertisers: [],
            deleteConfirm: false,
            showDeleteModal: false,
            deleteAdvName: '',
            deleteAdvId: 0,
            dataOptions: {
                sortdatafield: "adv_company_name",
                sortorder: "asc"
            },
            form: {
                date_from: yesterday,
                date_to: yesterday
            },
            fields: [],
            page_loaded: false,
            show_col: {},
            boolFields: ['adv_var_adv'],
            nonIntFields: ['adv_company_name', 'adv_csm_name', 'affise_adv_name'],
            revenueFields: ['revenue'],
            selected_list: [],
            current_list: [],
            adv_list: [],
            csm_list: [],
            filter_value: null,
            origin_list: [],
            list_types: [
                {
                    value: 'adv_company_name',
                    text: 'Company Name'
                },
                {
                    value: 'adv_csm_name',
                    text: 'CSM Name'
                }
            ],
            dateRange: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            ranges: {
                'Today': [todayx, todayx],
                'Yesterday': [yesterdayx, yesterdayx],
                'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
                'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
                'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            },
            dateRangeDelta: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            maxDate: new Date()
        }
    },
    async created() {
        this.page_loaded = false;
        await this.getData();
        this.fields = [
            {
                name: 'id',
                label: 'ID',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'adv_company_name',
                label: 'Company',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'affise_adv_name',
                label: 'Affise Adv Name',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'adv_csm_name',
                label: 'CSM',
                sortable: true,
                toggle_column: 3,
                show_c: true
            },
            {
                name: 'adv_var_adv',
                label: 'Var Adv',
                sortable: true,
                toggle_column: 4,
                checkbox: true,
                show_c: true
            },
            {
                name: 'global_offers_count',
                label: 'Offers Count',
                sortable: true,
                toggle_column: 5,
                show_c: true
            },
            {
                name: 'revenue',
                label: 'Revenue',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            }
        ];

        for (const field of this.fields) {
           this.show_col[field.toggle_column] = true;
        }

        this.adv_list = this.advertisers.map(adv => {
            return {   
                value: adv.adv_company_name,
                text: adv.adv_company_name
            }
        });
        // Remove duplicates
        this.adv_list = this.adv_list.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
        
        this.origin_list = this.advertisers;
        
        this.page_loaded = true;
        
    },
    computed: {
        total_row() {
            let sum_row = {
                id: '',
                adv_company_name: '',
                affise_adv_name: '',
                adv_csm_name: '',
                adv_var_adv: '',
                global_offers_count: 0,
                revenue: 0
            }

            this.advertisers.forEach(row => {
                sum_row.global_offers_count += parseInt(row.global_offers_count);
                sum_row.revenue += parseFloat(row.revenue);
            });

            return  sum_row;
        }
    },
    methods: {
        async deleteRow(adv) {
            await this.deleteAdvertiser(adv.id, adv.adv_company_name);
        },
        async getData(date_from = null, date_to = null) {
            try {
                let data
                if (!date_from || !date_to) {
                    data = await this.$http.post(this.resources.Management.getAdvertisersList, null, {
                        params: {
                            add_csm: true
                        }
                    });
                } else {
                    data = await this.$http.post(this.resources.Management.getAdvertisersList, {
                        date_from: moment(date_from).format('YYYY-MM-DD'),
                        date_to: moment(date_to).format('YYYY-MM-DD')
                    }, {
                        params: {
                            add_csm: true
                        }
                    });
                }
               
                if (data.body.res.includes('OK')) {
                    const advs = data.body.data;
                    for (const k in advs) {
                        advs[k].revenue = advs[k].revenue.toFixed(2);
                        advs[k].edit = `/apps/script-tags/add-advertisers?id=${ advs[k].id}`;
                    }

                    this.advertisers = advs;
                    this.csm_list = data.body.extra_data.csms.map(csmObj => {
                        return {
                            value: csmObj.csm,
                            text: csmObj.csm
                        }
                    });
                }
            } catch (err) {
                console.log(err);
            }   
        },
        async updateData() {
            this.page_loaded = false;
            await this.clearTableRowFilter();
            await this.getData(this.dateRange.startDate, this.dateRange.endDate);
            this.page_loaded = true;            
        },
        onDatesChange(v) {
            let {
                from,
                to
            } = v
            this.form.date_from = from
            this.form.date_to = to
        },
        async deleteAdvertiser(id, Advname) {
            this.deleteAdvName = Advname;
            this.deleteAdvId = id;
            this.showDeleteModal = true;
        }, 
        async confirmDelete () {
            try {
                const data = await this.$http.post(this.resources.Management.deleteAdvertiser(this.deleteAdvId));
                console.log(data.body);
                if (data.body.res.includes('OK')) {
                    this.notifySuccess('Advertiser has successfully deleted');
                }
                this.showDeleteModal = false;

                // fetch data again
                this.page_loaded = false;
                await this.getData();
                this.page_loaded = true;
            } catch (err) {
                console.log(err);
            }  
        },
        async clearTableRowFilter() {
            this.selected_list = [];
            this.filter_value = null;
            this.current_list = [];
            this.advertisers = this.origin_list;
        },
        async filterTableRows() {
            this.page_loaded = false;
            this.advertisers = this.origin_list;
            let filtered_rows = []
            for (const k in this.advertisers) {
                if (this.advertisers[k][`${this.selected_list}`] === this.filter_value){
                    filtered_rows.push(this.advertisers[k])
                }

              
            }
            this.advertisers = filtered_rows;
            this.page_loaded = true;
        },
        async updateCurrentList() {
            if (this.selected_list === null) {
                return;
            }

            if (this.selected_list === 'adv_company_name') {
                this.current_list = this.adv_list;
            }

            if (this.selected_list === 'adv_csm_name') {
                this.current_list = this.csm_list;
            }

        },
        logEvent(x, y) {
            if (x === 'toggle' && !y) {
                setTimeout(async () => {
                    if (this.dateRangeDelta.startDate !== this.dateRange.startDate || this.dateRangeDelta.endDate !== this.dateRange.endDate) {
                        this.dateRangeDelta.startDate = this.dateRange.startDate
                        this.dateRangeDelta.endDate = this.dateRange.endDate
                        this.page_loaded = false;
                        await this.getData(this.dateRange.startDate, this.dateRange.endDate)
                        this.page_loaded = true;
                    }
                }, 100);
            }
        }
    },
}
</script>
<style scoped>

th,td{
    padding-right: 60px;
}

h1 {
    margin-bottom: 20px;
}
.sort-icon {
    position:absolute;
    display: inline-block;
    padding-left: 1%;
}

.clickable-th {
    cursor: pointer;
}

.daterangepicker-form {
    z-index: 1000 !important;
}

</style>