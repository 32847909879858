<template lang="html">
  <router-view v-if='loaded' />
</template>

<script>
export default {
  data() {
    return {
      loaded: false
    }
  },
  created() {
    let {campId, placementId} = this.$route.params
    let placements = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements
    let placement = placements.find(v => v.id == placementId)
    if (placement.waterfalls) {
      this.loaded = true
      return
    }
    this.$http.get(this.resources.SmartLinksCampaigns.getOffers(campId, placementId))
      .then(r => r.json())
      .then(offers => {
        offers = offers || [];
        offers.forEach(offer => {
          if (offer.is_new_pid === '1') {
            offer.list_id = "NEW_PID_" + offer.list_id
          }
          if(!offer.blFiles) {
            offer.blFiles = []
            return
          }
          offer.blFiles = offer.blFiles.map(v => this.$store.state.blFiles.find(v2 => v == v2.id))
        })
        placement.offers = offers || []
        this.loaded = true
      })
  }
}
</script>

<style lang="css">
</style>
