<template lang="html">
  <div class="all">
    <FrqModal :is-show.sync=showLegend></FrqModal>
    <form @submit.prevent='submit'>
      <div class="all">
        <div class="field-container">
          <label >Name</label>
          <input :disabled='!userHasPermission("smartlinks.edit")' class='form-control pub-id-input' v-model.trim='form.name'>
        </div>

        <div class="field-container">
          <label >Frequency</label>

          <section v-for='i in [0,24,12,6,3,1]'>
            <label :for='"ca-frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
            <input :disabled='!userHasPermission("smartlinks.edit")' :id='"ca-frq-hours-"+i' type="radio" v-model.number='form.frq' :value='i'>
          </section>
        </div>
        <div class="field-container">
          <label >IP Frequency</label>
          <section v-for='i in [0,24,12,6,3,1]'>
            <label :for='"ca-ip-frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
            <input :disabled='!userHasPermission("smartlinks.edit")' :id='"ca-ip-frq-hours-"+i' type="radio" v-model.number='form.ip_frq' :value='i'>
          </section>
        </div>
        <div class="field-container">
          <label>IP Frequency Level:</label>
          <select v-model='form.ip_frq_level'>
            <option value="l1">Level 1</option>
            <option value="l2">Level 2</option>
            <option value="l3">Level 3</option>
            <option value="l4">Level 4</option>
            <option value="l5">Level 5</option>
          </select>
          <div>
            <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
          </div>
        </div>
        <div v-if="isVta" class="field-container">
          <label for="i-vta-14">Is VTA</label>
          <input v-model='form.is_vta' id="i-vta-14" type="checkbox" :disabled='data'>
        </div>


      </div>
      <button v-if='userHasPermission("smartlinks.edit")' type="submit" class="btn btn-success">{{data ? 'Save Campaign Changes' : 'Create Smart Link'}}</button>
    </form>
  </div>
</template>

<script>
import FrqModal from '../../../../components/FrqModal.vue';

export default {
  components: {
    FrqModal
  },
  props: {
    data: {},
    onSubmit: {},
    isVta: Boolean
  },
  data() {
    return {
      form: null,
      ratesFileName: null,
      ratesFileOk: null,
      showLegend: false
    }
  },
  created() {
    let data = this.data || {}
    this.form = Object.assign({
      name: null,
      description: null,
      notes: null,
      frq: 0,
      ip_frq: 0,
      is_vta: this.isVta,
      ip_frq_level: 'l3'
    }, data)
  },
  methods: {
    downloadRates(rates) {
      let a = document.createElement('a')
      a.href = "data:application/octet-stream," + ["start_date", "rate", "geo"].join('%2C')
      Array.isArray(rates) && rates.forEach(rate => {
        a.href += "\n" + [rate.start_date, rate.rate, rate.geo].join('%2C')
      })
      a.download = "rates.csv"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    downloadCurrentRates() {
      let rates = this.form.rates
      this.downloadRates(rates)
    },
    downloadRatesTemplate() {
      this.downloadRates()
    },
    onFileZoneClick() {
      this.$refs.ratesFile.click()
    },
    ratesFileUpload(event) {
      let file = event.target.files[0]
      if (!file) {
        return
      }
      this.ratesFileName = file.name
      const reader = new FileReader()
      reader.onload = event => this.ratesFileOk = this.readCsvFile(event.target.result)
      reader.onerror = error => console.log(error)
      reader.readAsText(file)
    },
    readCsvFile(content) {
      let lines = content.split('\n')
      let l = lines[0].trim()
      let fields = l.split(',')
      let indexes = {}
      window.fields = fields
      fields.forEach((v, i) => {
          switch(v.toLowerCase()) {
            case "start_date":
              indexes.startDate = i
            case "rate":
              indexes.rate = i
            case "geo":
              indexes.geo = i
          }
      })
      if (Object.keys(indexes).length < 3) {
        return false
      }
      this.form.rates = []
      for (let i = 1; i < lines.length; i++) {
        let line = lines[i].split(',')
        if (line.length <= 1) {
          continue
        }
        this.form.rates.push({
          start_date: line[indexes.startDate],
          rate: Number(line[indexes.rate]),
          geo: line[indexes.geo]
        })
      }
      return true
    },
    newRate() {
      this.form.rates.push({
        start_date: null,
        rate: null,
        geo: null
      })
    },
    removeRate(rate) {
      let rates = this.form.rates
      if (rates.length > 1) {
        rates.splice(rates.indexOf(rate), 1)
      }
    },
    submit() {
      if(!this.userHasPermission("smartlinks.edit")) { return }

      let form = this.form
      if(false && Object.values(form).findIndex(v => v == undefined || v === '' || (Array.isArray(v) && !v.length)) > -1) {
        return
      }

      this.$validate.smartlinks.campaign(form)
      .then(() => {
        this.onSubmit(Object.assign({}, form)) && form.nullify()
      })
      .catch(err => {
        this.notifyError(err.msg)
      })

    }
  },
  computed: {
    isEdit() {
      return !!this.data
    }
  }
}
</script>

<style lang="css" scoped>
.rate {
  border: 1px solid #cacaca;
  margin: 16px 0;
  padding: 18px 20px;
  position: relative;
}
.rate-remove {
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
}
.file-select {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
  border: 1px solid gainsboro;
  border-radius: 2px;
  margin: 15px 0;
  cursor: pointer;
}
.file-select:hover {
  background: #eeeeee;
}
.file-select.ok {
  background-color: #d9ffd9;
  font-size: 18px;
}
.file-select.error {
  background: #ff8585;
}
</style>
