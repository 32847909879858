<template lang="html">
  <div class="all">
    <Placement :onSubmit='submit' />
  </div>
</template>

<script>
import Placement from './components/Placement'
import utils from './utils/utils.smartlinks.js'

export default {
  components: {Placement},
  methods: {
    submit(form) {
      const route = this.$route.fullPath
      let campId = this.$route.params.campId
      form.camp_id = parseInt(campId)
      this.$http.post(this.resources.SmartLinksCampaigns.postPlacement(campId), form)
        .then(r => r.json())
        .then(id => {
          form.id = id
          let placements = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements

          form = utils.formPlacement(form)
          placements.push(form)
          this.$router.push({name: route.includes("VTA") ? "SmartLinksVTAPlacementId" : "SmartLinksPlacementId", params: {placementId: id}})
        })
        .catch(console.log)
      return true
    }
  }
}
</script>

<style lang="css">
</style>
