<template lang="html">
  <div class="all">
    <h1>Script Tags - Whitelisting</h1>
    <br>
    <div class="di-112">
      <div class="all">
        <h3>SSPs (sub8) Whitelisting</h3>
        <Table
        :search='false'
        :data='ssps'
        :autoFetch='false'
        :apiUrl='this.resources.ScriptTags.ssps'
        :fields='{value: "Value"}'
        :permissions='{delete: userHasPermission("script-tags.delete")}'
        :askBeforeDelete='0'
         />
        <section class="sec-121">
          <label >New SSPs:</label>
          <textarea class="def tx tx-ssp" v-model='sspsInput'></textarea>
        </section>
        <button type="button" @click='submitSsp' class="btn btn-success" v-if='userHasPermission("script-tags.edit")'>Save</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      campIds: [],
      bundles: [],
      ssps: [],
      campIdInput: "",
      bundlesInput: "",
      sspsInput: ""
    }
  },
  created() {
    this.$http.get(this.resources.ScriptTags.wlData)
      .then(r => r.json())
      .then(data => {
        let {camp_ids, bundles, ssps} = data
        this.campIds = (camp_ids || [])
        this.bundles = (bundles || [])
        this.ssps = (ssps || [])
      })
  },
  methods: {
    submitCampId() {
      let rawInput = this.campIdInput
      let vals = rawInput.split("\n").filter(v => v).map(v => v.trim())

      if(vals.length === 0) {
        return
      }
      this.$http.post(this.resources.ScriptTags.campIds, vals)
        .then(() => {
          vals = vals.map(v => ({value: v}))
          this.campIds.push(...vals)
          this.campIdInput = ""
        })
    },
    submitBundles() {
      let rawInput = this.bundlesInput
      let vals = rawInput.split("\n").filter(v => v).map(v => v.trim())

      if(vals.length === 0) {
        return
      }
      this.$http.post(this.resources.ScriptTags.bundles, vals)
        .then(() => {
          vals = vals.map(v => ({value: v}))
          this.bundles.push(...vals)
          this.bundlesInput = ""
        })
    },
    submitSsp() {
      let rawInput = this.sspsInput
      let vals = rawInput.split("\n").filter(v => v).map(v => v.trim())

      if(vals.length === 0) {
        return
      }
      this.$http.post(this.resources.ScriptTags.ssps, vals)
        .then(() => {
          vals = vals.map(v => ({value: v}))
          this.ssps.push(...vals)
          this.sspsInput = ""
        })
    }
  }
}
</script>

<style lang="css" scoped>
.tx {
  resize: none;
  font-size: 12px;
}
.tx-cid {
  width: 400px;
  height: 230px;
}
.tx-bid {
  width: 400px;
  height: 230px;
}
.tx-ssp {
  width: 500px;
  height: 250px;
}
.di-112 {
  display: flex;
  flex-direction: column;
}
.di-112 > div {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.sec-121 {
  display: flex;
  margin-bottom: 9px;
}
.sec-121 > label {
  width: 150px;
  margin-right: 25px;
}
</style>
