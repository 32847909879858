<template>
    <div class="all">
        <EventInfoModal :is-show.sync="open_event_info"></EventInfoModal>
        <CallbackInfoModal :is-show.sync="open_callback_info"></CallbackInfoModal>
        <h1>Adjust Link Generator</h1><br><br>
        <div v-if="!page_loaded">Loading ...</div>

        <div v-if="page_loaded">
            <form @submit="buildUrl">
                <div class="form-field">
                    <label>Type:</label>
                    <div class="vals-options">
                        <b-form-radio-group v-model="form.type" stacked :options="type_options" required name="radio-inline-type"></b-form-radio-group>
                    </div>
                </div>

                <div class="form-field">
                    <label>Link ID:</label>
                    <div class="vals-options">
                        <input v-model="form.link_id" required type="text">
                    </div>
                </div>

                <div class="form-field">
                    <label>Ad Group:</label>
                    <div class="vals-options">
                        <input v-model="form.ad_group" type="text">
                    </div>
                </div>

                <div class="form-field">
                    <label>Campaign:</label>
                    <div class="vals-options">
                        <b-form-radio-group v-model="form.campaign" stacked :options="campaign_options" required name="radio-inline-type-camp"></b-form-radio-group>
                        <input v-if="form.campaign == 'manual'" v-model="campaign" type="text">
                    </div>
                </div>

                <div class="form-field">
                    <label>Creative:</label>
                    <div class="vals-options">
                        <b-form-radio-group v-model="form.creative" stacked :options="creative_options" required name="radio-inline-type-creative"></b-form-radio-group>
                        <input v-if="form.creative == 'manual'" required v-model="creative" type="text">
                    </div>
                </div>

                <div class="form-field">
                    <label>OS:</label>
                    <div class="vals-options">
                        <b-form-radio-group v-model="form.os" stacked :options="os_options" required name="radio-inline-type-os"></b-form-radio-group>
                    </div>
                </div>

                <div class="form-field">
                    <label>Coast Type:</label>
                    <div class="vals-options">
                        <b-form-radio-group v-model="form.cost_type" stacked :options="cost_type_options" required name="radio-inline-type-cost"></b-form-radio-group>
                    </div>
                </div>

                <div class="form-field">
                    <label>Tracker Limit:</label>
                    <div class="vals-options">
                        <b-form-checkbox v-model="form.tracker_limit"></b-form-checkbox>
                        <input v-if="form.tracker_limit" required v-model="tracker_limit" type="text">
                    </div>
                </div>

                <!-- (free text) (example: https://go.postback-offer.com/postback?clickid={clickid}&goal=FTD)& -->
                <div class="form-field">
                    <label>Install Callback:</label>
                    <div class="vals-options">
                        <select class="input-select" v-model="form.install_callback" required>
                            <option v-for="domain in domains_list" :value="domain">{{ domain }}</option>
                        </select>
                    </div>
                </div>

                <div v-for="event, index in event_callbackes">
                    <button v-if="index !== 0" class="edit-btn" @click.prevent="deleteEvent(index)">
                        <b-icon icon="trash"></b-icon>
                    </button>
                    <div class="form-field">
                        <label>Event Name:</label>
                        <div class="vals-options">
                            <input v-model="event.event_name" required type="text">
                            <span style="padding-left: 4px;">
                                <b-icon @click="open_event_info = !open_event_info" class="icon-info" icon="exclamation-circle-fill" variant="warning"></b-icon>
                            </span>
                        </div>
                    
                    </div>

                    <div class="form-field">
                        <label>Event Callback:</label>
                        <div class="vals-options">
                            <select class="input-select" v-model="event.event_callback" required>
                                <option v-for="domain in domains_list" :value="domain">{{ domain }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-field">
                        <label>Event Callback Token:</label>
                        <div class="vals-options">
                            <input v-model="event.event_callback_token" required type="text">
                            <span style="padding-left: 4px;">
                                <b-icon @click="open_callback_info = !open_callback_info" class="icon-info" icon="exclamation-circle-fill" variant="warning"></b-icon>
                            </span>
                        </div>
                    </div>
                    <br>
                </div>

                <div class="form-field" style="padding-bottom: 40px;">
                    <button @click.prevent="addEvents" :disabled="!enable_add_btn" class="btn btn-success btn-sm">Add Event Callback</button>
                </div>

                <button type="submit" class="btn btn-primary">Generate Link</button>
            </form>   
            
            <br>
            <br>

            <!-- Result Link Display -->
            <div class="result-div-41" v-if='resultLink && passed_validation'>
                <textarea readonly :value='resultLink' class="resault-link-textarea" @focus='onInputFocus'></textarea>
            </div>
        </div>
    </div>
</template>
<script>
import EventInfoModal from './EventInfoModal.vue'
import CallbackInfoModal from './CallbackInfoModal.vue'

export  default {
    components: {
        EventInfoModal,
        CallbackInfoModal
    },
    data() {
        return {
            page_loaded: true,
            type_options: [],
            campaign_options: []
,           creative_options: [],
            os_options: [],
            cost_type_options: [],
            form: {
                type: 'https://view.adjust.com/impression/',
                link_id: '',
                ad_group: '{sub6}',
                campaign: 'avp12345',
                creative: 'qwv12345',
                os: 'android',
                cost_type: 'cpa',
                tracker_limit: '',
                install_callback: '',
                
            },
            campaign: '',
            creative: '',
            tracker_limit: '',
            url_template: {
                ios: '<Type><Link_ID>?adgroup=<Ad_Group>&campaign=<Campaign>&creative=<Creative>&os_name=ios&cost_type=<Cost_Type>&idfa={sub5}&ip_address={ip}&install_callback=<Install_Callback>',
                android : '<Type><Link_ID>?adgroup=<Ad_Group>&campaign=<Campaign>&creative=<Creative>&os_name=android&cost_type=<Cost_Type>&install_callback=<Install_Callback>'
            },
            resultLink: null,
            passed_validation: false,
            domains_list: [
                '',
                's2x2q.com', 
                'xwiw2.com', 
                'offers-imali.affise.com', 
                'go.app-back-offer.com', 
                'offers-apptimus.affise.com', 
                'go.postback-offer-2.com', 
                'go.postback-offer.com'
            ],
            open_event_info: false,
            open_callback_info: false,
            // enable_add_btn: false,
            event_callbackes: [
                {
                    event_callback: '',
                    event_callback_token: '',
                    event_name: '',
                }
            ],
        }
    },
    async created() {
        this.page_loaded = false;
        await this.initDataOptions();
        this.page_loaded = true;

    },
    watch: {
        ['tracker_limit'](newValue) {
            if (newValue !== null) {
                const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                // this.$set(this.tracker_limit,'tracker_limit' ,result);
                this.tracker_limit = result;
            }
        },
        ['form.install_callback'] (newValue) {
            if (newValue !== '') {
                console.log(newValue);
                this.event_callbackes[0].event_callback = newValue;
            }
        }
    },
    computed: {
        enable_add_btn() {
            return this.event_callbackes[0].event_callback !== '' && this.event_callbackes[0].event_callback_token !== '' && this.event_callbackes[0].event_name !== '';
        }
    },
    methods: {
        async initDataOptions() {
            this.campaign_options = [{text:'Auto', value:'avp12345'}, {text:'Manual', value:'manual'}];
            this.creative_options = [{text:'Auto', value:'qwv12345'}, {text:'Manual', value:'manual'}];
            this.os_options = [{text:'Android', value:'android'}, {text:'iOS', value:'ios'}];
            this.cost_type_options = [{text:'CPA', value:'cpa'}, {text:'CPI', value:'cpi'}]
            this.type_options = [{text:'Impression', value:'https://view.adjust.com/impression/'}, {text:'Click', value:'https://app.adjust.com/'}];
        },
        async addEvents() {
            this.event_callbackes.push({
                event_callback: this.event_callbackes[0].event_callback,
                event_callback_token: '',
                event_name: '',
            });
        },
        async buildUrl(e) {
            e.preventDefault();
           
            // Validations
            // const failedValidation = await this.validateForm();
            // if (failedValidation) {
            //     this.passed_validation = false;
            //     return;
            // }
            this.passed_validation = true;

            // Renove spaces from everywhere
            this.campaign = this.campaign.replaceAll(' ', '');
            this.creative = this.creative.replaceAll(' ', '');
            this.form.link_id = this.form.link_id.replaceAll(' ', '');
            for (let k in this.event_callbackes) {
                this.event_callbackes[k].event_callback = this.event_callbackes[k].event_callback.replaceAll(' ', '');
                this.event_callbackes[k].event_callback_token = this.event_callbackes[k].event_callback_token.replaceAll(' ', '');
                this.event_callbackes[k].event_name = this.event_callbackes[k].event_name.replaceAll(' ', '');
            }

            // Combine
            let {
                type,
                os,
                link_id, 
                ad_group, 
                campaign,
                creative,
                cost_type, 
                tracker_limit,
                install_callback,
                // event_callback,
                // event_callback_token,
                // event_name
            } = {...this.form};

            let event_callbackes = this.event_callbackes;

          
            if (tracker_limit && this.tracker_limit !== '') {
                tracker_limit = this.tracker_limit.replaceAll(',', '');
            }

            if (creative === 'manual' && this.creative !== '') {
                creative = this.creative;
            }

            if (campaign === 'manual' && this.campaign !== '') {
                campaign = this.campaign;
            }


            let urlTemplate = this.url_template[os];
            // install_callback = encodeURIComponent(install_callback)
            install_callback = `https%3A%2F%2F${install_callback}%2Fpostback%3Fclickid%3D{clickid}%26goal%3DInstall`;

            // Replace placeholders in the URL template with actual values from the form
            let resultLink = urlTemplate
                .replace('<Type>', type)
                .replace('<Link_ID>', link_id)
                .replace('<Ad_Group>', ad_group)
                .replace('<Campaign>', campaign)
                .replace('<Creative>', creative)
                .replace('<Cost_Type>', cost_type)
                .replace('<Install_Callback>', install_callback)

            let callbackTemplate = '&event_callback_<Event_Callback_Token>=<Event_Callback>';
            let eventsString = '';
            for (let i = 0; i < event_callbackes.length; i++) {
                let event_callback = event_callbackes[i].event_callback;
                let event_callback_token = event_callbackes[i].event_callback_token;
                let event_name = event_callbackes[i].event_name;

                event_callback = `https%3A%2F%2F${event_callback}%2Fpostback%3Fclickid%3D{clickid}%26goal%3D${event_name}`;
                let paramStr = callbackTemplate
                    .replace('<Event_Callback>', event_callback)
                    .replace('<Event_Callback_Token>', event_callback_token);
                eventsString += paramStr;
            }
            resultLink += eventsString;
            
            if (tracker_limit) {
                resultLink += `&tracker_limit=${tracker_limit}`;
            }

            this.resultLink = resultLink;

            // Additional params to add later
            // let queryParams = [];
            // if (campaign) queryParams.push(`campaign=${campaign}`);
            // if (campaign) queryParams.push(`campaign=${campaign}`);
            // if (creative) queryParams.push(`creative=${creative}`);
            // if (cost_type) queryParams.push(`cost_type=${cost_type}`);
            // if (tracker_limit) queryParams.push(`tracker_limit=${tracker_limit}`);
            // if (install_callback) queryParams.push(`install_callback=${install_callback}`);
            // if (event_callback) queryParams.push(`event_callback=${event_callback}`);

            // // Joining all query parameters with '&' and prepending '?' if there are any parameters
            // resultLink += queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

        },
        async deleteEvent(index) {
            this.event_callbackes.splice(index, 1);
        },
        async validateForm() {
            const containsWhitespace = /\s+/;
            let failed = false;
            let failed_input = '';

            if (containsWhitespace.test(this.form.type)) {
                failed = true;
                failed_input = 'Type';
            } else if (containsWhitespace.test(this.form.link_id)) {
                failed = true;
                failed_input = 'Link Id';
            } else if (containsWhitespace.test(this.form.ad_group)) {
                failed = true;
                failed_input = 'Ad Group';
           
            } else if (this.form.campaign === 'manual' && containsWhitespace.test(this.form.campaign)) {
                failed = true;
                failed_input = 'Campaign';
            } else if (this.form.creative === 'manual' && containsWhitespace.test(this.form.creative)) {
                failed = true;
                failed_input = 'Creative';
            } else if (this.form.tracker_limit && containsWhitespace.test(this.form.tracker_limit)) {
                failed = true;
                failed_input = 'Tracker Limit';
            } else if (containsWhitespace.test(this.form.os)) {
                failed = true;
                failed_input = 'OS';
            } else if (containsWhitespace.test(this.form.cost_type)) {
                failed = true;
                failed_input = 'Cost Type';
            } else if (containsWhitespace.test(this.form.install_callback)) {
                failed = true;
                failed_input = 'Install Callback';
            } else if (containsWhitespace.test(this.form.event_callback)) {
                failed = true;
                failed_input = 'Event Callback';
            }

            if (failed) {
                this.notifyError(`Error - ${failed_input} contains spaces.`);
                return true;
            }
            return false;

        },
        onInputFocus(v) {
            v.target.select && v.target.select()
        },
    },

    
}

</script>
<style scoped>

.form-field > * {
    display: inline-block;
}

.form-field > label {
    vertical-align: middle;
}

.form-field {
    margin-bottom: 20px;
    position: relative;
}

.form-field > label {
    margin-right: 40px;
    font-weight: bolder;
    width: 200px;
}

.resault-link-textarea {
    width: 710px;
    margin-top: 14px;
    padding: 5px;
    font-size: 14px;
    height: 146px;
    background: #f7f7f7;
    font-style: italic;
    color: #1d1d1d;
}

.link-textarea {
    width: 710px;
    margin-top: 14px;
    padding: 5px;
    font-size: 14px;
    height: 146px;
    background: #f7f7f7;
    font-style: italic;
    color: #1d1d1d;
}

input {
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #3e515b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.vals-options {
    width: 50%;
}

.input-select {
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #3e515b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.edit-btn {
    border: none;
    color: red;
}

</style>