<template>

  <div class="di-112">
    <form @submit.prevent="submitForm">
      <h3>Manual Postback</h3>
      <section class="sec-121">
        <label>Click IDS </label>
        <textarea class="def tx tx-cid" v-model='form.clickIDS'></textarea>
      </section>
      <label>sum</label>
      <input type="number" v-model='form.sum' min="0.00000" value="0.00000" step="0.00001" @change="decimalPointHandler(form.sum)">
      <label>goal</label>
      <input v-model='form.goal'>
      <input type="submit" value="Submit" :disabled="form.isLoading" />
      <Spinner v-show='isLoading' />
    </form>
  </div>

</template>

<script>

  export default {
    data() {
      return {
        isLoading: false,
        baseURL: 'https://offers-imali.affise.com/postback',
        form: {
          clickIDS: '',
          goal: '',
          sum: ''
        }
      }
    },
    methods: {
      submitForm(e) {
        if(!this.validateForm()){
          return
        }
        let allUrls=[]
        const clickids = this.form.clickIDS.split('\n')
        this.isLoading = true
        for (const cId of clickids) {
          let url = new URL(this.baseURL)
          url.searchParams.set('goal', this.form.goal)
          url.searchParams.set('clickid', cId)
          url.searchParams.set('sum', this.form.sum)
          allUrls.push(url)
        }
        Promise.all(allUrls.map(u => fetch(u, { mode: 'no-cors'} )))
          .finally(() => {
            this.isLoading = false
            this.notifySuccess('all Postbacks are done')
          })
      },
      validateForm() {
        if (this.form.clickIDS === '') {
          this.notifyError('you must enter clickIDS')
          return false
        }
        if (this.form.goal === '') {
          this.notifyError('you must enter goal')
          return false
        }
        if (this.form.sum === '') {
          this.notifyError('you must enter sum')
          return false
        }
        return true
      },
      decimalPointHandler(val){
        console.log(val);
        this.form.sum = parseFloat(val).toFixed(5)
      }
    }
  }

</script>
<style lang="css" scoped>

  .di-112 {
    display: flex;
    flex-direction: column;
  }
  .di-112 > div {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 25px;
    padding-bottom: 25px;
  }
  .sec-121 {
    display: flex;
    margin-bottom: 9px;
  }
  .sec-121 > label {
    width: 75px;
    margin-right: 0px;
  }
  .tx {
    resize: none;
    font-size: 12px;
  }
  .tx-cid {
    width: 400px;
    height: 230px;
  }

</style>


<!-- for (const cId of clickids) {
  let url = new URL(this.baseURL)
  url.searchParams.set('goal', this.form.goal)
  url.searchParams.set('clickid', cId)
  url.searchParams.set('sum', this.form.sum)
  fetch(url, {
    mode: 'no-cors'
  }).then(res => {
    console.log("then")
    console.log(res)
    return res
    }).catch(err => {
      console.log('in err: ', err)
    })
} -->
