<template>
<div class="all">
    <div class="app-load-container" v-if='!initialLoad'>
        <div class="app-load-box">
            <h2>Loading Dashboard...</h2>
            <Spinner class='app-load' />
        </div>
    </div>
    <div class="msg-section" v-else>
        <div v-if='notify && notify.msg && !notify.error' class="alert success-alert">
            <div class="text-container">   
                <h3 class="h3-alerter">{{notify.msg}}</h3>
            </div>    
            <a class="close" @click="close()">&times;</a>
        </div>
        <div v-if='notify && notify.msg && notify.error' class="alert danger-alert">
            <div class="text-container">
                <h3 class="h3-alerter">{{notify.msg}}</h3>
            </div>    
            <a class="close" @click="close()">&times;</a>
        </div>
        <router-view></router-view>
    </div>
</div>
</template>

<script>
import {
    method
} from 'lodash'
export default {
    name: 'app',
    computed: {
        initialLoad() {
            return this.$store.state.initialLoad
        },
        notify() {
            return this.appState.notify
        },
    },
    methods: {
        close() {
            this.appState.notify = {}
        }
    },
}
</script>

<style>
/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/css/font-awesome.min.css';
/* Import Simple Line Icons Set */
$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/css/simple-line-icons.css';
/* Import Bootstrap Vue Styles */
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style><style lang="scss">
// Import Main styles for this application
@import './scss/style';
@import './scss/styles/report';

.app-load {
    font-size: 48px !important;
    color: #b3b3b3;
}

.app-load-container {
    margin-top: 20%;
    display: flex;
}

.app-load-box {
    margin: 0 auto;
    text-align: center;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f5f5f5;
}

.filter-form-container {
    border: 1px solid #d4d4d4;
    padding: 10px 12px;
    width: 500px;
    background: whitesmoke;
    margin-top: 16px;
}

.filter-pl-name-input {
    width: 300px;
    margin-left: 16px;
    padding: 6px 7px;
    border-radius: 3px;
    background: #fffbfb;
    border: 1px solid #e8e8e8;
}
</style><style scoped>

h3 {
    font-family: Arial, Helvetica, sans-serif;
}

.h3-alerter {
    margin-top: 5px;
    padding-right: 10px; 
}

.alert {
    padding: 20px;
    margin-bottom: 15px;
    position: fixed;
    top: 5%;
    left: 25%;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    z-index: 999999;
    width: 50%;
    box-shadow: 0 0 15px 5px #ccc;
    border-radius: 5px;
}

.close {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    border: 1px solid;
    border-radius: 50%;
    text-align: center;
    font-size: 1.6em;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%; 
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.success-alert {
    background-color: #a8f0c6;
    border-left: 5px solid #1eae5a;
}

.danger-alert {
    background-color: #f7a7a3;
    border-left: 5px solid #bd1910;
}

.success-alert .close {
    border-color: #1eae5a;
    color: #1eae5a;
}

.danger-alert .close {
    border-color: #bd1910;
    color: #bd1910;
}

.text-container {
    flex-grow: 1; 
    padding-right: 10px; 
}

@keyframes example {
    from {
        transform: translateY(-45px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes example2 {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-45px);
    }
}
</style>
