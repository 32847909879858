<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="padding-bottom: 20px;">
            <h3>Stags Versions</h3>
            <div v-for="stags_version in stags_versions" class="versions_div">
                <div>
                    # {{ stags_version.id }}
                </div>
                <div>
                    {{ stags_version.stags_versions_name }}
                </div>
                <div>
                    <a :href="'/apps/script-tags/data-manager-stags-versions-edit/' + stags_version.id">Edit</a> | <a href="javasceipt:void(0)" @click="deleteStagVersion(stags_version.id)">Delete</a>
                </div>
            </div>
        </div>
        <div>
            <a href="/apps/script-tags/data-manager-stags-versions-edit/0">Add Version</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stags_versions: [],
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        async getData() {
            const data = await this.$http.post(this.resources.Management.tgGetStagVersions, {})
            this.stags_versions = data.body.data
        },
        async deleteStagVersion(id) {
            const data = await this.$http.post(this.resources.Management.tgDeleteStagVersions, {
                id
            })
            await this.getData()
        }
    },
}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.subs_textarea {
    resize: none;
    width: 100%;
    height: 400px;
}

.versions_div {
    padding-bottom: 10px;
}
</style>