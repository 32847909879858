  <template>
  <div class="app">
    <AppHeader/>
    <div class="app-body">
      <Sidebar :navItems="nav"/>
      <main class="main">
        <breadcrumb :list="list"/>
        <div class="container-fluid">
          <Card :collapse="false" class="route-card">
            <span slot="card-title">{{cmName | seperateWords}}</span>
            <div class="width100">
              <router-view v-if='hasPermission'></router-view>
              <h3 v-else>
                You don't have the permission to view the current page.
              </h3>
            </div>
          </Card>
        </div>
      </main>
      <AppAside/>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import nav from '../_nav'
import { Header as AppHeader, Sidebar, Aside as AppAside, Footer as AppFooter, Breadcrumb } from '../components/'

export default {
  name: 'full',
  components: {
    AppHeader,
    Sidebar,
    AppAside,
    AppFooter,
    Breadcrumb
  },
  data () {
    return {
      nav: nav.items
    }
  },
  computed: {
    hasPermission() {
      let matchedRoutes = this.$route.matched
      let ok = true
      matchedRoutes.forEach(route => {
        let routeMeta = route.meta
        if(!routeMeta) {
          return
        }
        let routePermission = routeMeta.permission
        if(!routePermission) {
          return
        }
        if(!this.userHasPermission(routePermission)) {
          ok = false
        }
      })
      return ok
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
    },
    cmName() {
      return this.$route.name
    }
  },
  created() {
  }
}
</script>


<style>
  .route-card > div > .card-header {
    background: #f9f9f9;
    border: 0;
    color: #585858;
    border-bottom: 1px solid gainsboro;
    font-size: 17px;
  }
</style>
