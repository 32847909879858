<template>
    <div>
        <h1>Home Bundle Ids Page</h1>
        <!-- link to create -->
        <a href="/bundle-ids/edit/0">Create Bundle Id</a>
        <!-- resizable data table -->
         <ResizableDataTable v-if="!loading" :rows_data.sync="tableData" :fields="fields" :show_c.sync="show_col" :nonIntFields="nonIntFields" :delete="deleteBundle" :loading="loading"  :show_columns_name="'bundle-ids-page'" :goToStoreFunction="goToStore"></ResizableDataTable>

    </div>
</template>

<script>
import ResizableDataTable from '../../../components/shared/ResizableDataTable.vue';

export default {
    components: {                                                   
        ResizableDataTable
    },
    data() {
        return {
            tableData: [],
            loading: false,
            fields: [],
            show_col: {},
            nonIntFields: ['bundle_id', 'app_name', 'os', 'vertical_name', 'mmp'],
        };
    },
    async created() {
        this.fields = [
            {
                name: 'id',
                label: 'ID',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'bundle_id',
                label: 'Bundle ID',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'app_name',
                label: 'App Name',
                sortable: true,
                toggle_column: 3,
                show_c: true
            },
            {
                name: 'os',
                label: 'OS',
                sortable: true,
                toggle_column: 4,
                show_c: true
            },
            {
                name: 'vertical_name',
                label: 'Vertical Name',
                sortable: true,
                toggle_column: 5,
                show_c: true
            },
            {
                name: 'mmp',
                label: 'MMP',
                sortable: true,
                toggle_column: 6,
                show_c: true
            },
            {
                name: 'preview_link',
                label: 'Preview Link',
                toggle_column: 7,
                show_c: true,
                is_preview_link: true
            }
        ];

        for (const field of this.fields) {
            this.show_col[field.toggle_column] = true;
        }
        await this.fetchTableData();
    },
    methods: {
        async fetchTableData() {
            this.loading = true;
            try {
                // axios request
                let res = await this.$http.post(this.resources.BundleIDs.getBundleList)
                let data = await res.json();
                this.tableData = data.data;
                this.tableData.forEach(row => {
                    row.edit = `/bundle-ids/edit/${row.id}`;
                });
            } catch (error) {
                console.error('Error fetching table data:', error);
            } finally {
                this.loading = false;
            }
        },
        async deleteBundle (bundle_form) {
            this.loading = true;
            try {
                let res = await this.$http.post(this.resources.BundleIDs.deleteBundle, {
                    bundle: bundle_form
                });

                let data = await res.json();
                if (data.res != 'OK') {
                    this.notifyError(data.error_msg);
                } else {
                    this.notifySuccess('Bundle ID deleted successfully');
                    await this.fetchTableData();
                }
            } catch (err) {
                console.error('Error deleting bundle:', err);
                this.loading = true;
            }
        },
        goToStore (link) {
            window.open(link, '_blank').focus();
        }
    },
};
</script>

<style scoped>
</style>
