<template>
<div>
    <div>
        <h2> {{this.group.name}}</h2>
        <h3>{{this.time }}</h3>
    </div>
    <div class="scheduler">
        <scheduler :value="this.timetable" v-on:submit="submit"></scheduler>
        <div style="text-align: center;"">
            <b-button ref="btn-close" variant="success" size="sm" @click="submit" class="slgSaveBtn">
                Save
            </b-button>
        </div>
    </div>
</div>
</template>

<script>
import {
    json
} from 'body-parser'
import Scheduler from '../../../../components/Scheduler.vue'
import moment from 'moment'



export default {
    components: {
        Scheduler
    },
    data() {
        return {
            scheduale: {},
            group: {},
            time: 'Loading...',
            timetable: {
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: []
            },
        }
    },
    created() {
        this.updateGroup()
        setInterval(() => {
            this.getNow();
        }, 1000)
    },
    methods: {
        getNow() {
           let plainDate = moment().toDate().toUTCString();
            this.time = plainDate.substring(0,plainDate.length-4);

        },
        updateGroup() {
            this.$nextTick(() => {
                let {
                    groupId
                } = this.$route.params
                this.group = this.$store.state.smartlinks.groups.find(v => v.id == groupId)
                this.updateScheduler(this.group.scheduler)

            })
        },
        submit: async function () {
            let {
                groupId
            } = this.$route.params
            const bitmap = this.parseTableToBitMap(this.timetable)
            try {
                const r = await this.$http.post(this.resources.SmartLinksCampaigns.createGroupScheduler(groupId), JSON.stringify(bitmap))
                const data = await r.json() 
              
                this.notifySuccess(data.Res)
            } catch (err) {
                this.notifyError(err)
            }

        },
        parseTableToBitMap(obj) {
            const bitmap = new Array(7).fill(0).map(() => new Array(24).fill(0));
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    for (let i in obj[key]) {
                        bitmap[key][obj[key][i]] = 1
                    }
                }
            }

            return bitmap
        },
        updateScheduler(scheduler) {
            if (scheduler == {}) {
                return
            }
            for (let day in scheduler) {
                for (let time in scheduler[day]) {
                    if (scheduler[day][time] == 1) {
                        this.timetable[day].push(time)
                    }
                }
            }
        }

    }
}
</script>

<style>
.slgSaveBtn {
    margin-top: 20px;
    width: 250px;
}
</style>
