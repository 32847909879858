<template lang="html">
  <VueDatePicker :disabled-picker='disabled' format="dd/MM/yy" :value='value || Date.now()' @input='onInput' />
</template>

<script>
import VueDatePicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  props: ['disabled', 'onChange', 'value'],
  components: {VueDatePicker},
  methods: {
    onInput(v) {
      this.onChange(moment(v).format('YYYY-MM-DD'))
    }
  },
  mounted() {
    this.onInput(this.value || Date.now())
  }
}
</script>

<style lang="css">
.datepicker {
  display: inline-flex;
}
.vdp-datepicker input {
  border: 1px solid #bdbdbd;
  padding: 2px 8px;
  border-radius: 1px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #58666e;
}
</style>
