<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td>
                            VAR Advertisers ID
                        </td>
                        <td>
                            VAR Advertisers Name
                        </td>
                        <td class="align-checkbox">
                            VAR Advertisers Active
                        </td>
                        <td>
                            
                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.affise_adv_id }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.affise_adv_id" placeholder="VAR Advertiser" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.affise_adv_name }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.affise_adv_name" placeholder="VAR Advertiser" />
                        </td>
                        <td>
                            <div  :class="{'checkbox-td': row.edit, 'align-checkbox': !row.edit}" >
                                <input v-model="row.is_active" name="checkbox-1" type="checkbox" :disabled="!row.edit" :value=1>
                            </div>
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRow(row.id)">Edit</a> 
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.affise_adv_id" placeholder="VAR Advertiser" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.affise_adv_name" placeholder="VAR Advertiser" />
                        </td>
                        <td>
                            <div class="checkbox-td">
                                <input v-model="newRow.is_active" name="checkbox-1" type="checkbox" :value=1>
                            </div>
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newRow: {
                id: 0,
                affise_adv_id: '',
                is_active: false,
                affise_adv_name: ''
            },
            checkbox: 0,
            rows: [],
            showNewRow: true,
            isAdmin: true
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    methods: {
        async save(e) {
            e.preventDefault();
            const rowReq = this.newRow
            const data = await this.$http.post(this.resources.Management.saveVarAdv, {
                ...this.newRow
            });
            if (data.body.res !== 'OK') {
                this.notifyError(data.body.msg)
            }

            this.newRow.affise_adv_id = ''
            this.newRow.affise_adv_name = ''
            this.newRow.is_active = 0
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getVarAdvData, {});
            if (data.body.res !== 'OK') {
                this.notifyError(data.body.msg)
                return
            }
            for (let k in data.body.data) {
                data.body.data[k].edit = false
                data.body.data[k].is_active = data.body.data[k].is_active === 1 ? true : false
            }
            this.rows = data.body.data
        },
        async editRow(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    editRowData = this.rows[k];
                }
            }
            
            this.showNewRow = true;
            const data = await this.$http.post(this.resources.Management.saveVarAdv, {
                ...editRowData
            });

            if (data.body.res !== 'OK') {
                this.notifyError(data.body.msg)
            }
            this.getListData()
            
        },
    },
}
</script>

<style scoped>

.checkbox-td {

    text-align:center;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    color: #3e515b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    max-height: 35px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.align-checkbox {
    text-align: center;
}
</style>
