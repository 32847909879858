<template lang="html">
<div class="all">
    <Card :collapse='0' class="edit-record" v-if='formMode'>
        <template slot="card-title">{{formMode==='edit' ? 'Edit Record' : 'Create a New Record'}}</template>
        <form @submit.prevent='formSubmit'>
            <div class="edit-fields-pair" v-for='(pair, index) in formFieldsPairs'>
                <FormField v-for='value in pair' :inline='true' :key='value.key' class="form-group-table" :placeholder="value.title || fields[value.key].title || fields[value.key]" :def='(undefined === value.def && (formMode !== "edit" || !value.staticOnEdit))' :value='form[value.key]' :onUpdate='e => { form[value.key] = e }' :labelText='value.title || fields[value.key].title || fields[value.key]'>
                    <template v-if='undefined !== value.def || (formMode === "edit" && value.staticOnEdit)'>
                        <Select v-if='value.options' :options='(value.options)' :onChange='e => { form[value.key] = e }' :value='form[value.key]' />
                        <span v-else-if='value.staticOnEdit && formMode === "edit"'>
                            {{form[value.key]}}
                        </span>
                    </template>
                </FormField>
            </div>
            <div class="btns">
                <button v-if='formMode==="edit"' type="button" @click='resetEdit' class="btn btn-primary">Cancel Changes</button>
                <button v-else type="button" @click='emptyForm' class="btn btn-primary">Reset</button>
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </form>
    </Card>

    <Table-Show-Until-Date v-if='displayLatestDatePicker' :onChange='v => {dataOptions.latestRecordsDate = v; reloadData(v)}' class="flex" />
    <DateRange v-else-if='displayDateRangePicker' />

    <div :class="{'table-container': true, [tableClass]: true, editable}" ref="table">
        <div v-show='isLoading' class='loading'>
            <Spinner />
        </div>
        <div class="create-new-top" v-show='permissions.create'>
            <button type="button" class="btn btn-success" @click='enableCreate'>
                Add Record
            </button>
        </div>
        <ul class='fields'>
            <li class='action-column' v-show='permissions.delete'>
                &nbsp;
            </li>
            <li v-for='(value, key) in fields'>
                <div class='clickable field-title' @click='orderBy(key)'>
                    <span>
                        {{value.title || value}}
                    </span>
                
                    <div>
                        <i v-show='dataOptions.sortdatafield === key' :class='dataOptions.sortorder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </div>
                <div class="field-filter" v-show='apiUrl && search'>
                    <input class="def" v-model='fieldsFilters[key]' @change='updateFilters' type="checkbox" v-if='value.type === Boolean'>
                    <input class="def" v-model='fieldsFilters[key]' @change='updateFilters' v-else>
                </div>
            </li>
            <li v-show='enter'>
                <div class="field-title">&nbsp;</div>
            </li>
        </ul>
        <ul :class='{"table-values": true, pointer: onRowClick, "scrollbar-1": true, "on-drag": drag}' ref="tableRows">
            <li class='no-data' v-if='rows.length === 0'>
                This table is empty
            </li>
            <draggable v-else-if='draggableEnabled && rows.length > 1' v-model='rows' @start="drag=true" @end="drag=false">
                <li v-for='value in rows' @click='rowClickHandler(value)' :class='{"drag-and-drop-item": true, item: true, selected: selectedRow === value}'>
                    <ul class="table-row">
                        <li class='action-column' v-show='permissions.delete && value.id' @click.stop='deleteRecord(value)'>
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </li>
                        <li v-for='(_, field) in fields'>
                            <span title="Exclude" class="exclude" v-show='!includeFieldValues(field, value)'>Exc. </span>
                            <span>
                                {{value[field] | tdVal}}
                            </span>
                        </li>
                        <li v-if='enter'>
                            <router-link class='link' :to="enter(value)">Enter</router-link>
                        </li>
                    </ul>
                </li>
            </draggable>
            <li v-else v-for='value in rows' @click='rowClickHandler(value)' :class='{item: true, selected: isRowActive && isRowActive(value)}'>
                <ul class="table-row">
                    <li v-show='permissions.delete' :class='{"action-column": true, "invis121": !value.id}' @click.stop='deleteRecord(value)'>
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </li>
                    <li v-for='(_, field) in fields'>
                        <span title="Exclude" class="exclude" v-show='!includeFieldValues(field, value)'>Exc. </span>
                        <span v-if="field === 'is_vta'">
                            <input type="checkbox" disabled name="" id="" :checked="value[field] === 'true' ? true : false">
                        </span>
                        <span v-else>
                            {{ value[field] | tdVal}} 
                        </span>
                    </li>
                    <li v-if='enter'>
                        <router-link class='link' :to="enter(value)">Enter</router-link>
                    </li>
                </ul>
            </li>
        </ul>
        </ul>
        <div class="table-bottom-container" v-show='apiUrl'>
            <div>
                <CSV :disabled='!totalRecordsAmount' v-if='includeCsv' :onClick='downloadCSV' />
            </div>
            <div class="permissions">
                <template v-if='permissions.edit || permissions.create || permissions.delete'>
                    <span>You may: </span>
                    <div class="permissions-icons">
                        <div v-show='permissions.delete'>
                            <i title="Remove" class="fa fa-trash"></i>
                            Delete
                        </div>
                        <div v-show='permissions.edit'>
                            <i title="Edit" class="fa fa-edit"></i>
                            Edit
                        </div>
                        <div v-show='permissions.create'>
                            <i title="Create" class="fa fa-plus"></i>
                            Create
                        </div>
                    </div>
                </template>
                <span v-else data-table-min-width>
                    You can only read from this table.
                </span>
            </div>
            <div class="pagination">
                <span class="total-records-text">
                    Total records: {{totalRecordsAmount}}
                </span>
                <div class="rows-per-page">
                    <span>Rows per page: </span>
                    <select :disabled='totalRecordsAmount === 0' class="def" @change='fetchData' v-model='dataOptions.pagesize'>
                        <option v-for='val in [10,25,50,100]' :value='val'>{{val}}</option>
                    </select>
                </div>
                <span>Page {{dataOptions.pagenum + 1}}/{{totalPagesAmount + 1}}</span>
                <button type="button" @click='pagMove(1)' :class='dataOptions.pagenum < totalPagesAmount ? "" : "invisible"'>
                    <i class="fa fa-angle-right"></i>
                </button>
                <button type="button" @click='pagMove(-1)' :class='dataOptions.pagenum > 0 ? "" : "invisible"'>
                    <i class="fa fa-angle-left"></i>
                </button>
            </div>
        </div>
    </div>
    <button v-show='draggable && !orderEdit && rows.length > 1' type="button" @click='enableOrderEdit' class="btn btn-outline-primary smartlinks-toggle-form-display-button">Edit Order</button>

    <div class="" v-if='orderEdit'>
        <button type="button" @click='cancelOrderEdit' class="btn btn-outline-info smartlinks-toggle-form-display-button">Cancel Changes</button>
        <button type="button" @click='saveOrder' class="btn btn-outline-success smartlinks-toggle-form-display-button">Save Order</button>
    </div>
</div>
</template>

<script>
import buildUrl from 'build-url'
import moment from 'moment'
import draggable from 'vuedraggable'

let devFields = {
    adv_id: 'Advertiser ID',
    adv_name: 'Advertiser Name',
    geo: 'Country',
    adv_rate: 'Rate',
    created_on: 'Start Date'
}

export default {
    components: {
        draggable
    },
    props: {
        tableClass: {
            default: ""
        },
        draggable: {
            default: false
        },
        askBeforeDelete: {
            default: false
        },
        autoFetch: {
            default: false
        },
        onOrderUpdate: {
            type: Function
        },
        'api-url': {
            validator: val => val.length > 0,
            default: ''
        },
        'api-url2': {
            default: ''
        },
        fields: {
            default () {
                return devFields
            }
        },
        method: {
            default: 'get'
        },
        data: {
            type: Array
        },
        includeCsv: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object
        },
        permissions: {
            type: Object,
            default: e => ({
                delete: false,
                create: false,
                edit: false
            })
        },
        formFields: {
            type: Object
        },
        displayLatestDatePicker: {
            type: [Boolean, Number],
            default: false
        },
        displayDateRangePicker: {
            type: [Boolean, Number],
            default: false
        },
        enter: {},
        isRowActive: {
            default () {
                return e => {}
            }
        },
        onRowClick: {
            default: null
        },
        search: {
            default: true,
            type: Boolean
        },
        onDelete: {
            type: Function
        },
        deleteRow: {
            type: Function
        },
        tableInstance: {
            default: ''
        }
    },
    created() {

        if (this.formFields) {
            let th = {}
            Object.keys(this.formFields).forEach(f => {
                th[f] = null
            })
            this.form = th
        }
        if ((this.permissions.edit || this.permissions.create) && !this.formFields) {
            throw new Error("NO formFields prop to permissions create or edit")
        }
        if (this.draggable && typeof this.onOrderUpdate !== 'function') {
            throw new Error()
        }
        if (this.data) {
            this.rows = this.data
        }
        if (this.filters) {
            Object.assign(this.dataOptions, this.filters)
        }

        Object.keys(this.fields).forEach(v => {
            this.fieldsFilters[v] = null
        })
        if (this.apiUrl && this.autoFetch) {
            this.fetchData()
                .then(this.updateRecordsAmount)
        }
    },
    data() {
        return {
            selectedRow: null,
            isLoading: false,
            form: {},
            originalOrder: null,
            originalOrderStr:"",
            orderEdit: false,
            rows: [],
            formMode: null,
            linkMouseDown: false,
            fieldsFilters: {},
            totalRecordsAmount: null,
            drag: false,
            dataOptions: {
                filterscount: 0,
                groupscount: 0,
                pagenum: 0,
                pagesize: 25,
                recordstartindex: 0,
                recordendindex: 25,
                latestRecords: 'true',
                latestRecordsDate: undefined
            },
            sortOrder: 'asc',
            error: null
        }
    },
    watch: {
        filters(val) {
            Object.assign(this.dataOptions, val)
            this.reloadData()
        },
        data(val) {
            this.rows = val
        },
        rows(val) {
            if (!val) {
                this.rows = []
            }
        }
    },
    computed: {
        draggableEnabled() {
            return this.draggable && this.orderEdit
        },
        editable() {
            return this.permissions && this.permissions.edit
        },
        formFieldsPairs() {
            function addKeyToObject(object, value) {
                object.key = value
            }
            let fields = this.formFields
            let keys = Object.keys(fields)
            let l = keys.length
            let l2 = Math.floor(l / 2)
            let arr = [];
            arr.length = l2
            for (let i = 0, j = 0; i < l2; i++, j += 2) {
                let v1 = fields[keys[j]]
                let v2 = fields[keys[j + 1]]
                addKeyToObject(v1, keys[j])
                addKeyToObject(v2, keys[j + 1])
                arr[i] = [v1, v2]
            }
            if (l % 2 !== 0) {
                let v1 = fields[keys[keys.length - 1]]
                addKeyToObject(v1, keys[keys.length - 1])
                arr.push([v1])
            }
            return arr
        },
        updateUrl() {
            return this.apiUrl
        },
        addUrl() {
            return this.apiUrl
        },
        showUrl() {
            return this.apiUrl
        },
        deleteUrl() {
            return this.apiUrl
        },
        deleteUrl2() {
            return this.apiUrl2
        },
        csvUrl() {
            return this.apiUrl + '/show/csv'
        },
        totalPagesAmount() {
            return Math.floor(this.totalRecordsAmount / this.dataOptions.pagesize)
        },
        columnAverageWidth() {
            let sum = 0
            let i = 0
            let str = ""
            for (let field in this.columnsWidths) {
                let value = this.columnsWidths[field].width
                str += field + ": " + value + ', '
                sum += value
                i++
            }
            return sum / i
        }
    },
    filters: {
        tdExclude(str, b) {
            return "Exc. " + str
        },
        tdVal(str) {
            return Array.isArray(str) ? str.join(', ') : str
        }
    },
    methods: {
        rowClickHandler(v) {
            if (this.onRowClick) {
                this.onRowClick(v)
            }
        },
        includeFieldValues(field, row) {
            if (!row || typeof row.fieldsIn !== "object" || !row.fieldsIn.hasOwnProperty(field)) {
                return true
            }
            return row.fieldsIn[field]
        },
        enableOrderEdit() {
            this.orderEdit = true
            this.originalOrder = this.rows.slice()
            this.originalOrderStr = this.originalOrder.map(v => v.id).join(',')

        },
        saveOrder() {
            this.orderEdit = false
            let newOrder = this.rows.map(v => v.id).join(',')
            // Reset order bug when there is no change
            if (newOrder === this.originalOrderStr){
                return
            }
            else {
                this.onOrderUpdate(this.rows)

            }
        },
        cancelOrderEdit() {
            this.rows = this.originalOrder
            this.orderEdit = false
        },
        reloadData(v) {
            this.fetchData().then(this.updateRecordsAmount)
        },
        resetForm() {},
        deleteRecord(val) {
            if (this.askBeforeDelete) {
                let ok = window.confirm("Are you sure that you wish delete this record?")
                if (!ok) {
                    return
                }
            }
            if(val.weight >= 0){
                this.rows.splice(this.rows.indexOf(val), 1)
                this.onDelete && this.onDelete(val.id)
                return
            }
           
            if (this.deleteRow) {
                return this.deleteRow(val)
                    .then(() => {
                        this.rows.splice(this.rows.indexOf(val), 1)
                    })
            }
            this.rows.splice(this.rows.indexOf(val), 1)

            let apiUrl = this.deleteUrl
            apiUrl = apiUrl[apiUrl.length - 1] !== '/' ? apiUrl + '/' : apiUrl
            console.log(val.id);
            this.onDelete && this.onDelete(val.id)
            this.$http.delete(apiUrl + val.id)
                .then(() => {
                    this.onDelete && this.onDelete()
                   
                })
                .catch((err) => {
                    this.notifyError("Error occured. Could not delete record.")
                })

            if (this.apiUrl2 && this.apiUrl2 != '') {
                let apiUrl2 = this.apiUrl2
                apiUrl2 = apiUrl2[apiUrl2.length - 1] !== '/' ? apiUrl2 + '/' : apiUrl2
                this.$http.delete(apiUrl2 + val.id)
                    .then(() => {
                        this.onDelete && this.onDelete()
                    })
                    .catch((err) => {
                        this.notifyError("Error occured. Could not delete record.")
                    })
            }
        },
        formSubmit() {
            let form = this.form
            if (this.formMode === 'edit') {
                this.updateRecord(form)
            } else {
                this.createRecord(form)
            }
        },
        updateRecord(form) {
            Object.assign(this.selectedRow, form)
            let apiUrl = this.updateUrl
            this.$http.post(apiUrl, form)
        },
        createRecord(form) {
            this.rows.push(Object.assign({}, form))
            let apiUrl = this.addUrl
            this.$http.post(apiUrl, form)
            this.emptyForm()
        },
        scrollTop() {
            this.$refs.tableRows.scrollTop = 0;
        },
        pagMove(i) {
            this.updatePag(i)
            this.fetchData()
        },
        updatePag(i) {
            let dataOptions = this.dataOptions
            dataOptions.pagenum += i
            dataOptions.recordstartindex = dataOptions.pagesize * dataOptions.pagenum
            dataOptions.recordendindex = dataOptions.recordstartindex + dataOptions.pagesize
            this.scrollTop()
        },
        resize(e, field) {
            let cursorX = e.clientX,
                mostLeft = this.columnsWidths[field].mostLeft
            if (!cursorX) {
                return
            }
            let width = cursorX - mostLeft
            if (width < 65) {
                return
            }
            this.columnsWidths[field].width = width
            this.columnsWidths[field].style = this.getColumnStyle(field)
        },
        dragEnd(field) {
            let nextField = Object.keys(this.fields)[Object.keys(this.fields).indexOf(field) + 1]
            if (!nextField) {
                return
            }
            let currentField = this.columnsWidths[field],
                next = this.columnsWidths[nextField]
            next.mostLeft = currentField.mostLeft + currentField.width + 0
        },
        getColumnStyle(field) {
            let averageWidth = this.columnAverageWidth
            let columnWidth = (this.columnsWidths[field].width / averageWidth)
            if (columnWidth) {
                return {
                    flex: columnWidth
                }
            }
            return {}
        },
        updateRecordsAmount(data) {
            this.totalRecordsAmount = data.totalRecords
        },
        fetchData(options = {}) {
            return new Promise((res, rej) => {
                options = Object.assign(this.dataOptions, options)
                this.isLoading = true
                this.$http[this.method](this.showUrl, this.method === 'get' ? {
                        params: options
                    } : options)
                    .then(r => r.json())
                    .then(data => {
                        res(data)
                        this.rows = data
                    })
                    .catch(err => {
                        this.error = "Error fetching data"
                        rej(err)
                    })
                    .then(() => {
                        this.isLoading = false
                    })
            })
        },
        resetEdit() {
            let original = this.selectedRow
            let form = this.form
            for (let field in original) {
                form[field] = original[field]
            }
        },
        enableEdit(row) {
            if (!this.permissions.edit) {
                return
            }
            this.selectedRow = row
            this.form = Object.assign({}, row)
            this.formMode = 'edit'
        },
        enableCreate() {
            this.formMode = 'create'
            this.emptyForm()
        },
        emptyForm() {
            let form = this.form
            for (let field in form) {
                form[field] = null
            }
        },
        downloadCSV() {
            let root;
            (root = this.$http.options.root) && (root = root[root.length - 1] === '/' ? root = root.substr(0, root.length - 1) : root)
            window.location.replace(buildUrl(root, {
                path: this.csvUrl,
                queryParams: this.dataOptions
            }))
        },
        orderBy(field) {
            if (this.tableInstance === 'blFiles') {
                if (field !== 'str_date') {
                    this.slSort(field)
                }

                return 
            }
            if (this.tableInstance === 'sl_offers') {
                this.slSort(field)
                return 
            }

            if (!this.apiUrl) {
                return
            }
            let dataOptions = this.dataOptions
            this.updatePag(-dataOptions.pagenum)
            if (dataOptions.sortdatafield === field) {
                dataOptions.sortorder = dataOptions.sortorder === 'desc' ? 'asc' : 'desc'
            } else {
                Object.assign(dataOptions, {
                    sortdatafield: field,
                    sortorder: 'desc'
                })
            }
            this.fetchData(dataOptions)
            this.scrollTop()
        },
        updateFilters() {
            let fieldsFilters = this.fieldsFilters
            let filters = {
                geooperator: "or",
                filterGroups: []
            }
            let i = 0
            for (let field in fieldsFilters) {
                let value = fieldsFilters[field]
                if (!value) {
                    continue
                }
                filters['filtervalue' + i] = value
                filters['filtercondition' + i] = "CONTAINS"
                filters['filteroperator' + i] = 0
                filters['filterdatafield' + i] = field
                i++
                filters.filterGroups.push({
                    field: field,
                    filters: [{
                        value: value,
                        condition: "CONTAINS",
                        operator: "and",
                        field: field,
                        type: "stringfilter"
                    }]
                })
            }
            filters.filterscount = filters.filterslength = i
            Object.assign(this.dataOptions, filters)
            this.updatePag(-this.dataOptions.pagenum)
            this.fetchData().then(this.updateRecordsAmount)
        },
        slSort(field) {
            let dataOptions = this.dataOptions
            if (dataOptions.sortdatafield === field) {
                dataOptions.sortorder = dataOptions.sortorder === 'desc' ? 'asc' : 'desc'
            } else {
                Object.assign(dataOptions, {
                    sortdatafield: field,
                    sortorder: 'desc'
                })
            }
            
            // Client side sorting
            console.log(field)
            const nonIntFields = ['name', 'title', 'date_uploaded']
            if (! nonIntFields.includes(field)) {
                if (dataOptions.sortorder === 'desc') {
                this.rows.sort((a, b) => (parseInt(a[field]) < parseInt(b[field])) ? 1 : -1)
                } else {
                    this.rows.sort((a, b) => (parseInt(a[field]) > parseInt(b[field])) ? 1 : -1)
                }
            } else {
                if (dataOptions.sortorder === 'desc') {
                    this.rows.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
                } else {
                    this.rows.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
                }
            }
        }
    },
}
</script>

<style lang="css" scoped>
.edit-fields-pair {
    display: flex;
    justify-content: space-between;
}

.edit-record {
    max-width: 750px;
}

.table-container {
    margin: 5px 0 8px;
    position: relative;
    border: 1px solid #cccccc;
}

.loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #0000001f;
    z-index: 2;
}

.fields {
    background: #f1f3f7;
    border-bottom: 1px solid #dcdee2;
    display: flex;
    padding-right: 15px;
}

.fields>li {
    display: flex;
    flex-direction: column;
}

.fields>li>.field-title {
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #dedede;
    background: #f7f7f7;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 15px;
    color: #484141;
}

.field-filter {
    padding: 3px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.field-filter input {
    border: 1px solid #dadada;
    border-radius: 3px;
    background: #ffffff94;
}

.table-row {
    display: flex;
    height: 30px;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-bottom: 0;
    border-left: 0;
}

.table-values>li:hover {
    /* background: #ededed !important; */
}

.table-values.pointer>li:hover {
    cursor: pointer;
    background: #e3e3e3;
}

.table-values>li:nth-child(even) {
    background: #f9f9f9;
}

.table-values>li.selected {
    background: #005a83 !important;
    color: white;
}

.editable .table-values>li:hover {
    cursor: pointer;
}

.fields>li,
.table-row>li {
    flex: 1;
    overflow-x: hidden;
    white-space: nowrap;
    padding-left: 9px;
}

.table-row>li {
    overflow-y: auto;
    padding-right: 5px;
    max-height: 46px;
    display: flex;
    align-items: center;
}

.table-values {
    max-height: 400px;
    overflow-y: overlay;
    min-height: 24px;
}

.resize-column {
    height: 100%;
    position: absolute;
    z-index: 5;
    width: 15px;
    right: -8px;
    cursor: e-resize;
}

.table-bottom-container {
    background: #fffdfd;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    height: 43px;
    border-top: 1px solid gainsboro;
}

.pagination {
    display: flex;
    align-items: center;
}

.pagination button {
    border: 1px solid #e4e4e4;
    background: #ffffff;
    margin: 1px;
    border-radius: 4px;
    width: 27px;
    font-size: 16px;
    color: #383a3c;
}

.pagination span {
    color: #5a5a5a;
    margin-right: 6px;
    font-size: 12px;
    font-weight: bold;
}

.total-records-text {
    border-right: 1px solid #989898;
    padding-right: 6px;
}

.rows-per-page {
    display: flex;
    align-items: center;
}

.action-column {
    width: 35px;
    flex: none !important;
    /* justify-content: center; */
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    display: flex;
}

.action-column i {
    padding: 5px 5px;
    background: #fbfbfb;
    /* margin: 3px; */
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    color: #151515;
}

.action-column i:hover {
    background: #fafafafa;
}

.permissions {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.permissions-icons {
    display: flex;
    margin-left: 6px;
}

.permissions-icons>div {
    margin-right: 8px;
}

.btns {
    margin-top: 26px;
}

.create-new-top {}

.create-new-top button {
    margin: 4px 5px;
}

.form-group-table {
    width: 312px;
}

.link {
    margin-right: 6px;
}

.drag-and-drop-item {
    background: #f9f9f9;
    cursor: -webkit-grab;
}

.on-drag .drag-and-drop-item {
    background: #f9f9f9 !important;
}

.drag-and-drop-item:hover {
    background: #f1f1f1;
}

.drag-and-drop-item.sortable-chosen {
    background: #c7f7c7 !important;
}

.no-data {
    padding: 9px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #494949;
    font-style: italic;
    background: #fcfcfc;
    font-size: 15px;
}

.exclude {
    font-size: 11px;
    font-style: italic;
    font-weight: bold;
    color: #535353;
}

.invis121 {
    visibility: hidden;
}
</style>
