<template lang="html">
    <b-modal v-model="isShow" :id="infoModal.id" :title="title" @hide=" $emit('update:is-show', false)">
      <div >
            <b-form-group id="input-group-1" :label="inputTitle" label-for="input-3">
                <b-form-input id="input-3" v-model="infoModal.name" placeholder="Enter Name" ></b-form-input>
            </b-form-group>
      </div>

       <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="$emit('update:isShow', false)"
            >
            Close
          </b-button>
          <b-button
          ref="btn-close"
          variant="success"
            size="sm"
            class="float-right"
            @click="saveAndClose"
            >
            Add
          </b-button>
        </div>
      </template>
    </b-modal> 
</template>
<script>
import { emit } from 'process';

    
export default {
    
    props: ['isShow', 'title', 'urlToSubmit', 'inputTitle'],
    data() {
        return{
            infoModal: {
                id: 'info-modal',
                title: this.title,
                name: ''
            },
        }
    },
    created(){

    },
    methods: {
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
            this.infoModal.name =''
        },
        saveAndClose(){
            this.$emit('add', this.infoModal.name)
            this.$emit('update:is-show', false)
            this.resetInfoModal()
        }
    },
    
}
</script>
<style scoped>

</style>