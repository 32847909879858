<template lang="html">
  <div class="" v-if='camp'>
    <h2 class='camp-name'>Smartlink "{{camp.name}}"</h2>
    <CampaignEdit />
    <br>
    <hr>
    <h4>Ad Set</h4>
    <Table :draggable='true' :onOrderUpdate='saveOrder'
      :fields='{id: "ID", geo: "Geo", region: "Region", os: "OS", frq: "Frq", ip_frq: "IP Frq", comments: "Comments"}'
      :data='placements' v-if='placements'
      :links='{Edit: v => ({ name: "SmartLinksPlacementEdit", params: {placementId: v.id}})}'
      :onRowClick='onRowClick' />

    <button v-if='userHasPermission("smartlinks.edit")' type="button"
      @click='newPlacementFormDisplay = !newPlacementFormDisplay'
      class="btn btn-outline-primary smartlinks-toggle-form-display-button">New Ad Set</button>

    <NewPlacement v-if='newPlacementFormDisplay' :camp_id='camp.id' />


    <div class="link-23">
      <span class="c-12345">
        Campaign Link:
      </span>
      <input @focus='onSystemLinkFocus' class="c-231" :value='systemCampLink' readonly>
      </input>
    </div>
    <hr>
    </hr>

    <div v-if="subsValidationError.length > 0" class="alert">
        <a class="close" @click="subsValidationError = []">&times;</a>
        <li v-for="msg in subsValidationError">{{ msg }}</li>
    </div>

    <div class="subs-bl">
      <h4>Subs Blacklisting</h4>
      <div class="blacklist-container" v-show='userHasPermission("smartlinks.edit")'>
        <div class="inline-container">
          <label>Sub:</label>
          <Select class="select" :options="unselectedSubs" v-model="new_sub_bl.sub" @change="changeSelectedSub"/>
          <input id="new-sub-bl-wl" v-model='new_sub_bl.whitelist' type="checkbox">
          <label for="new-sub-bl-wl">Whitelist</label>
          <i class="fa fa-plus edit-btn" aria-hidden="true" @click='onSubsBlacklistAdd'></i>
        </div>
        <div>
          <span v-if="selectedSub === 'sub2' || selectedSub === 'sub2' || selectedSub === 'sub2'" style="color: red;">Case sensitive</span>
          <span v-if="selectedSub === 'sub4'" style="color: red;">Not case sensitive</span>
        </div>
        <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="new_sub_bl.list"></LimitedTextarea>
      </div>
      <br class="flat-breaks"><br>
      <div>
        <div v-for="(s,i) in form.blacklisting_subs">
          <div class="inline-container">
            <i class="fa fa-trash trash-btn" aria-hidden="true" @click='onSubsBlacklistRemove(i)'></i>
            <h5>{{s.sub}}</h5>
          </div>
          <div>
            <input :disabled='!userHasPermission("smartlinks.edit")' v-model='s.whitelist' type="checkbox">
            <label>Whitelist</label>
          </div>
          <div>
            <span v-if="s.sub === 'sub2' || s.sub === 'sub3' || s.sub === 'sub7'" style="color: red;">Case sensitive</span>
            <span v-if="s.sub === 'sub4'" style="color: red;">Not case sensitive</span>
          </div>
          <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="s.list"></LimitedTextarea>
          <br class="flat-breaks"><br>
        </div>
      </div>
      <b-button variant="success" @click='submitSubsBlacklisting'>save</b-button>
    </div>

  </div>
</template>

<script>
  import NewPlacement from '../NewPlacement.smartlinks.vue'
  import draggable from 'vuedraggable'
  import CampaignEdit from '../in-view/EditCampaign.smartlinks'

  export default {
    components: {
      NewPlacement,
      draggable,
      CampaignEdit
    },
    data() {
      return {
        form: {
          blacklisting_subs: [],
        },
        subsValidationError: [],
        camp: null,
        placements: null,
        newPlacementFormDisplay: false,
        orderEdit: false,
        originalOrder: null,
        systemLinkTemplate: process.env.VUE_APP_SMARTLINKS_SYSTEM_LINK,
        new_sub_bl: {},
        selectedSub: '',
        all_subs: [{
            val: "sub1",
            label: "Sub1"
          },
          {
            val: "sub2",
            label: "Sub2"
          },
          {
            val: "sub3",
            label: "Sub3"
          },
          {
            val: "sub4",
            label: "Sub4"
          },
          {
            val: "sub6",
            label: "Sub6"
          },
          {
            val: "sub7",
            label: "Sub7"
          },
          {
            val: "sub8",
            label: "Sub8"
          }
        ],
      }
    },
    computed: {
      systemCampLink() {
        return this.systemLinkTemplate.replace("{camp_id}", this.$route.params.campId)
      },
      unselectedSubs() {
        if (!this.all_subs || !this.all_subs.length) {
          return
        }
        return this.all_subs.filter(v => !this.form.blacklisting_subs.find(v2 => v2.sub == v.val))
      },
    },
    methods: {
      changeSelectedSub() {
            if (!this.new_sub_bl || !this.new_sub_bl.sub) {
                return
            } else {
                this.selectedSub = this.new_sub_bl.sub.val
            }
      },
      onSubsBlacklistAdd() {
        if (!this.new_sub_bl || !this.new_sub_bl.sub) {
          return
        }
        this.selectedSub = ''
        let newsub = this.new_sub_bl
        this.new_sub_bl = {}
        newsub.sub = newsub.sub.val
        this.form.blacklisting_subs.unshift(newsub)
      },
      onSubsBlacklistRemove(i) {
        this.form.blacklisting_subs.splice(i, 1);
      },
      onSystemLinkFocus(v) {
        v.target.select && v.target.select()
      },
      onRowClick(v) {
       const route = this.$route.fullPath
        this.$router.push({
          name: route.includes("VTA") ? "SmartLinksVTAPlacementId" : "SmartLinksPlacementId",
          params: {
            placementId: v.id
          }
        })
      },
      saveOrder: async function (order) {
        let newOrder = order.map(v => v.id).join(',')
        this.placements.length = 0
        this.placements.push.apply(this.placements, order)
        let campId = this.$route.params.campId
        try {
          await this.$http.put(this.resources.SmartLinksCampaigns.smartLinksUpdateCampaignOrder(campId), {
            order: newOrder
          })
          this.camp.placements_order = newOrder

        } catch (err) {
          this.notifyError("Failed to change placements order")
        }
      },
      async validateBlacklistingSubs() {
        let subsMsgArray = []
        let matchedArray = []
        for (let k in this.form.blacklisting_subs) {
          matchedArray = this.form.blacklisting_subs[k].list.match(/[\r\n\t\f\v\s]/g) || []
          let lastMatch = 0
          let msgArray = []
  
          for (const i in matchedArray) {
            lastMatch =  this.form.blacklisting_subs[k].list.indexOf(matchedArray[i],lastMatch)
            msgArray.push({
              str : this.form.blacklisting_subs[k].list.substring(lastMatch-10, lastMatch+10),
              char: matchedArray[i]
            })
            lastMatch ++
          }
          if (matchedArray.length > 0) {
            subsMsgArray[this.form.blacklisting_subs[k].sub] = msgArray
          } 
        }
          return subsMsgArray
      },
      ThrowSubsAlerst(subsMsgArray) {
        let msg = []
        for (const k in subsMsgArray) {
          for (const i in subsMsgArray[k]) {
            let txt = ''
            switch (subsMsgArray[k][i].char) {
              case ' ':
                  txt = `There is a space in ${k} between - ${subsMsgArray[k][i].str}`
                  break
              case '\n':
                  txt = `There is a line break in ${k} between - '${subsMsgArray[k][i].str}''`
                  break
              case '\t': 
                  txt = `There is a tab in ${k} between - ${subsMsgArray[k][i].str}`
                  break

            }
            msg.push(txt)
          }
        }
        this.subsValidationError = msg 
      },
      submitSubsBlacklisting: async function () {
        for (let k in this.form.blacklisting_subs) {
          if (this.form.blacklisting_subs[k].list.length > 65000) {
            this.notifyError (`Sub BL/WL Can't be more than 65000 characters`)
            return
          }
        }
        
        this.subsValidationError = []
        const subsMsgArray = await this.validateBlacklistingSubs() 
        if (Object.keys(subsMsgArray).length > 0) {
            this.ThrowSubsAlerst(subsMsgArray)
            return
        }
        let campId = this.$route.params.campId
        try{
          await this.$http.post(this.resources.SmartLinksCampaigns.postCampaignBl(campId), this.form)
          this.notifySuccess(`Campaign ${this.camp.name} succesfully updated blacklisting data`)
        }
        catch(err){
          this.notifyError (`Campaign ${this.camp.name} didn't update blacklisting data`)
        } 
      },
      getCampSubsBl: async function(){
        let campId = this.$route.params.campId
        try {
          const r = await this.$http.get(this.resources.SmartLinksCampaigns.smartLinksCampaignGetBL(campId))
          const data = await r.json()
          this.form.blacklisting_subs = data
        } catch (err) {
          this.notifyError("Failed to load camps blacklisting")
        }
      }
    },
    created() {
      this.getCampSubsBl()
      let campId = this.$route.params.campId
      let camp = this.$store.state.smartlinks.campaigns.find(v => v.id == campId)
      this.camp = camp
      this.placements = camp.placements
    }
  }
</script>

<style lang="css" scoped>
  .subs-bl {
    margin-top: 5%;
  }

  .camp-name {
    margin-bottom: 20px;
  }

  .link-23 {
    margin-top: 17px;
  }

  .c-231 {
    padding: 7px;
    background: #f9f9f9;
    border: 1px solid #c2c2c2;
    border-radius: 3px;
    width: 750px;
    
  }

  .c-12345 {
    margin-right: 5px;
  }

  .max-textarea {
    width: 100%;
  }


  .max-textarea {
    width: 100%;
  }

  .inline-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .inline-container>* {
    margin-right: 9px;
  }

  .flat-breaks br {
    content: "";
  }

  .flat-breaks br:after {
    content: " ";
  }

  .alert {
    padding: 20px;
    margin-bottom: 15px;
    position: fixed;
    top: 5%;
    left: 25%;
    right: 0;
    z-index: 999999;
    width: 50%;
    box-shadow: 0 0 15px 5px #ccc;
    border-radius: 5px;
    background-color: lightcoral;
  }
</style>