<template>
    <div>
        <div>
            <a href="/admin/dash">Back</a>
        </div>
       Under constructions
    </div>
</template>
<script>
import { sum } from 'lodash'
import ResizableDataTable from '@/components/shared/ResizableDataTable.vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
export default {
    components:{ResizableDataTable, DateRangePicker},
    data() {
        let today = new Date()
        let yesterday = today.setDate(today.getDate() - 1)

        let yesterdayx = new Date();
        yesterdayx.setDate(yesterdayx.getDate() - 1);
        let todayx = new Date();

        return {
            advertisers: [],
            deleteConfirm: false,
            showDeleteModal: false,
            deleteAdvName: '',
            deleteAdvId: 0,
            dataOptions: {
                sortdatafield: "adv_company_name",
                sortorder: "asc"
            },
            form: {
                date_from: yesterday,
                date_to: yesterday
            },
            fields: [],
            page_loaded: false,
            show_col: {},
            boolFields: ['adv_var_adv'],
            nonIntFields: ['adv_company_name', 'adv_csm_name', 'affise_adv_name'],
            revenueFields: ['revenue'],
            selected_list: [],
            current_list: [],
            adv_list: [],
            csm_list: [],
            filter_value: null,
            origin_list: [],
            list_types: [
                {
                    value: 'adv_company_name',
                    text: 'Company Name'
                },
                {
                    value: 'adv_csm_name',
                    text: 'CSM Name'
                }
            ],
            dateRange: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            ranges: {
                'Today': [todayx, todayx],
                'Yesterday': [yesterdayx, yesterdayx],
                'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
                'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
                'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            },
            dateRangeDelta: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            geoOs: [],
            geoOsSelected: 'All',
            ma_on_display: '',
            dd_from: 'ddd',
            dd_to: 'bbb',
        }
    },
    async created() {
        this.page_loaded = false;
        await this.getData();
        this.fields = [
            {
                name: 'global_offer_id',
                label: 'Global Offer ID',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'global_offer_name',
                label: 'Global Offer Name',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'app_name',
                label: 'AppName',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'bundle_id',
                label: 'Bundle ID',
                sortable: true,
                toggle_column: 5,
                show_c: true
            },
            {
                name: 'prime_served_clicks',
                label: 'Prime Served Clicks',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'affise_clicks',
                label: 'Affise Clicks',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'mmp_clicks',
                label: 'MMP Clicks',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            }
            ,
            {
                name: 'revenue',
                label: 'Revenue',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'cr',
                label: 'CR',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'rpm',
                label: 'RPM',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'scraper_or_manual',
                label: 'Scraper or Manual',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'is_sub_data_available',
                label: 'Is sub data available',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'mmp',
                label: 'MMP',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            },
            {
                name: 'conversions',
                label: 'Conversions',
                sortable: true,
                toggle_column: 6,
                show_c: true,
                revenue: true
            }
        ];
        
        this.page_loaded = true;
    },
    methods: {
        async deleteRow(adv) {
            await this.deleteAdvertiser(adv.id, adv.adv_company_name);
        },
        async getData(date_from = null, date_to = null) {
            const r = await this.$http.get(this.resources.Dash.getDataGlobal, {
                params: {
                    start_date: moment(this.dateRange.startDate).format('DD.MM.YYYY'),
                    end_date: moment(this.dateRange.endDate).format('DD.MM.YYYY'),
                    geo_os_selected: this.geoOsSelected
                }
            })
            const data = await r.json();
            this.isBusy = !this.isBusy;
            this.advertisers = data.table_data;
            this.geoOs = data.geo_os_data;
        },
        async updateData() {
            this.page_loaded = false;
            await this.clearTableRowFilter();
            await this.getData(this.dateRange.startDate, this.dateRange.endDate);
            this.page_loaded = true;            
        },
        onDatesChange(v) {
            let {
                from,
                to
            } = v
            this.form.date_from = from
            this.form.date_to = to
        },
        async deleteAdvertiser(id, Advname) {
            this.deleteAdvName = Advname;
            this.deleteAdvId = id;
            this.showDeleteModal = true;
        }, 
        async confirmDelete () {
            try {
                const data = await this.$http.post(this.resources.Management.deleteAdvertiser(this.deleteAdvId));
                console.log(data.body);
                if (data.body.res.includes('OK')) {
                    this.notifySuccess('Advertiser has successfully deleted');
                }
                this.showDeleteModal = false;

                // fetch data again
                this.page_loaded = false;
                await this.getData();
                this.page_loaded = true;
            } catch (err) {
                console.log(err);
            }  
        },
        async clearTableRowFilter() {
            this.selected_list = [];
            this.filter_value = null;
            this.current_list = [];
            this.advertisers = this.origin_list;
        },
        async filterTableRows() {
            this.page_loaded = false;
            this.advertisers = this.origin_list;
            let filtered_rows = []
            for (const k in this.advertisers) {
                if (this.advertisers[k][`${this.selected_list}`] === this.filter_value){
                    filtered_rows.push(this.advertisers[k])
                }

              
            }
            this.advertisers = filtered_rows;
            this.page_loaded = true;
        },
        async updateCurrentList() {
            if (this.selected_list === null) {
                return;
            }

            if (this.selected_list === 'adv_company_name') {
                this.current_list = this.adv_list;
            }

            if (this.selected_list === 'adv_csm_name') {
                this.current_list = this.csm_list;
            }

        },
        logEvent(x, y) {
            
        },
        geoOsChanged() {
            console.log('geoOsSelected', this.geoOsSelected);
            const selectRow = this.geoOs.find(x => x.os_geo === this.geoOsSelected);
            console.log('selectRow', selectRow);
            if (selectRow.ma !== '' && selectRow.os !== '') {
                this.ma_on_display = `MA: ${selectRow.ma}, OM: ${selectRow.om}`;
            } else {
                this.ma_on_display = ``;
            }
        },
        dd() {

        }
    },
}
</script>
<style scoped>

th,td{
    padding-right: 60px;
}

h1 {
    margin-bottom: 20px;
}
.sort-icon {
    position:absolute;
    display: inline-block;
    padding-left: 1%;
}

.clickable-th {
    cursor: pointer;
}

.daterangepicker-form {
    z-index: 1000 !important;
}

.dash_table {
    margin: 0 auto;
}

.dash_row {
    text-align: center;
}

.dash_cell {
    display: inline-block;
    padding: 15px;
    border: 1px solid lightgray;
    margin: 3px;
    border-radius: 4px;
}

.filter_div1 {
    display: inline-block; 
    vertical-align: top; 
    padding-top: 2px;
    padding-right: 5px;
}

.filter_div2 {
    display: inline-block; 
    vertical-align: top;
    padding-top: 2px;
}

.select_style {
    padding: 6px;
    border: 1px solid lightgray;
}

.filter_div3 {
    display: inline-block; 
    padding-left: 15px;
}

.blocks_div {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>