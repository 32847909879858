<template>
    <div>
        <DataToSendModal :is-show.sync=showLegend></DataToSendModal>
        <div>
            <a href="/man/bundle-pool">Back to bundle pool</a>
        </div>
        <div>Bundle List</div>
        <div>
            <div v-if="showEditListName" style="padding-bottom: 20px;">
                <table>
                    <tr>
                        <td>
                            <b-form-input type="text" v-model="list_data.list_name" placeholder="Name" />
                        </td>
                        <td>
                            <a class="btn btn-primary" href="javascript:void(0);" @click="updateListData()">Save</a>
                        </td>
                    </tr>
                </table>
            </div>
            <div v-if="! showEditListName" style="font-size: 30px;">
                List Name: {{ list_data.list_name }} <a href="javascript:void(0);" @click="editListName" style="font-size: 15px;" v-if="isAdmin">Edit</a>
            </div>
        </div>
        <div style="padding-top: 20px; padding-bottom: 20px;">
            <div v-for="link of placement_links">
                <a :href="link.link_val">{{ link.link_text }}</a>
            </div>
        </div>
        <div v-if="isAdmin">
            <a class="btn btn-primary" href="javascript:void(0);" @click="duplicateList()">Duplicate List</a> <a class="btn btn-primary" href="javascript:void(0);" @click="bulkUpload()">Bulk Upload</a>
        </div>
        <div style="padding-top: 30px; padding-bottom: 30px;">
            <fieldset>
                <legend>Data to send <span style="font-size: 14px;"><b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon></span></legend>
                <div>
                    <input name="data_to_send" type="radio" @click="changeDataToSend" value="app_name" :checked="list_data.data_to_send === 'app_name'" :disabled="!isAdmin">
                    <label for="huey">App Name</label>
                </div>

                <div>
                    <input name="data_to_send" type="radio" @click="changeDataToSend" value="bundle" :checked="list_data.data_to_send === 'bundle'" :disabled="!isAdmin">
                    <label for="dewey">Bundle</label>
                </div>

                <div>
                    <input name="data_to_send" type="radio" @click="changeDataToSend" value="bundle_app_name" :checked="list_data.data_to_send === 'bundle_app_name'" :disabled="!isAdmin">
                    <label for="louie">Bundle + App Name</label>
                </div>
            </fieldset>
        </div>
        <div>
            Total Bundle Count: {{ rows.length }}
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td>
                            Bundle ID
                        </td>
                        <td>
                            App Name
                        </td>
                        <td>
                            Weight
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.bundle_id }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.bundle_id" placeholder="Bundle ID" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.app_name }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.app_name" placeholder="App Name" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.weight }} <span style="font-weight: bold;" v-if="!row.edit">({{ row.percent }})</span></div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.weight" placeholder="Weight" />
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRow(row.id)">Edit</a> <a class="btn btn-primary" href="javascript:void(0);" @click="deleteBundle(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.bundle_id" placeholder="Bundle ID" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.app_name" placeholder="App Name" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.weight" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
import {
    defineComponent
} from '@vue/composition-api'
import DataToSendModal from '../../../components/DataToSendModal.vue'
export default {
    data() {
        return {
            list_id: this.$route.params.id,
            newRow: {
                id: 0,
                bundle_id: "",
                app_name: "",
                weight: "10"
            },
            rows: [],
            showNewRow: true,
            list_data: {
                list_name: "",
                data_to_send: "",
            },
            showEditListName: false,
            placement_links: [],
            isAdmin: false,
            showLegend: false
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    methods: {
        async save(e) {
            e.preventDefault();
            let validationMsg = this.validateRowData(this.newRow.bundle_id, this.newRow.app_name, this.newRow.weight);
            if (validationMsg != "") {
                this.notifyError(validationMsg);
                this.getListData();
                return;
            }
            const data = await this.$http.post(this.resources.Management.saveBundleRow, {
                list_id: this.list_id,
                row_data: this.newRow
            });
            this.newRow.bundle_id = "";
            this.newRow.app_name = "";
            this.newRow.weight = "10";
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getListData, {
                list_id: parseInt(this.list_id),
            });
            for (let k in data.body.data.bundles) {
                data.body.data.bundles[k].edit = false;
            }
            this.list_data = data.body.data.list_data;
            this.rows = this.calculatePercentage(data.body.data.bundles);
            this.placement_links = data.body.data.placement_links;
        },
        async deleteBundle(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteBundle, {
                id,
            });
            this.getListData();
        },
        async editRow(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            this.showNewRow = true;
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    editRowData = this.rows[k];
                }
                this.rows[k].edit = false;
            }
            let validationMsg = this.validateRowData(editRowData.bundle_id, editRowData.app_name, editRowData.weight);
            if (validationMsg != "") {
                this.notifyError(validationMsg);
                this.getListData();
                return;
            }
            const data = await this.$http.post(this.resources.Management.saveBundleRow, {
                list_id: this.list_id,
                row_data: editRowData
            });
            this.getListData();
        },
        async changeDataToSend(e) {
            this.list_data.data_to_send = e.target.value;
            await this.updateListData();
        },
        async updateListData() {
            const data = await this.$http.post(this.resources.Management.saveBundleList, {
                id: parseInt(this.list_id),
                list_name: this.list_data.list_name,
                data_to_send: this.list_data.data_to_send,
            });
            this.showEditListName = false;
        },
        editListName() {
            this.showEditListName = true;
        },
        async duplicateList() {
            const data = await this.$http.post(this.resources.Management.duplicateBundleList, {
                id: parseInt(this.list_id)
            });
            window.location = "/man/bundle-pool-edit/" + data.body.id;
        },
        bulkUpload() {
            console.log("bulkUpload");
            window.location = "/man/bundle-pool-bulk/" + this.list_id;
        },
        calculatePercentage(arr) {
            let totalWeight = 0;
            for (let k in arr) {
                totalWeight += parseInt(arr[k].weight);
            }
            for (let k in arr) {
                arr[k].weight = arr[k].weight;
                arr[k].percent = ((parseInt(arr[k].weight) / totalWeight) * 100).toFixed(2) + "%";
            }
            return arr;
        },
        validateRowData(bundleID, appName, weight) {
            if (!this.validateNumber(weight)) {
                return "Weight is not a number";
            }
            if (weight > 500) {
                return "Weight can't be more than 500";
            }
            if (bundleID == "") {
                return "Bundle ID can't be empty";
            }
            if (appName == "") {
                return "App name can't be empty";
            }
            if (!this.validateStr(bundleID)) {
                return "Please enter a valid bundle id";
            }
            if (!this.validateStr(appName)) {
                return "Please enter a valid app name";
            }
            return "";
        },
        validateStr(str) {
            const re = /([A-Za-z0-9-_.]+)/;
            if (re.test(str)) {
                return true;
            }
            return false;
        },
        validateNumber(str) {
            const re = /^\d+$/; 
            if (re.test(str)) {
                return true;
            }
            return false;
        }
    },
    components: { DataToSendModal }
}
</script>

<style scoped>

</style>
