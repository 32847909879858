<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td @click="sortByField('pid_text')">
                            Partner Name
                            <div class="th-icon" v-if="sortOptions.sortByField === 'pid_text'" style="display: inline-block;">
                                <i :class='sortOptions.sortOrder === "desc" && sortOptions.sortByField === "pid_text" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </td>
                        <td>
                            PID
                        </td>
                        <td>
                            User Name
                        </td>
                        <td>
                            Password
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.pid_text }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model.trim="row.pid_text" placeholder="Partner Name" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.pid_value }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model.trim="row.pid_value" placeholder="PID" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.user_name }}</div>
                            <b-form-input :disabled="!userHasPermission('admin')" v-if="row.edit" type="text" v-model.trim="row.user_name" placeholder="User Name" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.password }}</div>
                            <b-form-input :disabled="!userHasPermission('admin')" v-if="row.edit" type="text" v-model.trim="row.password" placeholder="Password" />
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRow(row.id)">Edit</a> <a class="btn btn-primary" href="javascript:void(0);" @click="deleteEntity(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.pid_text" placeholder="Partner Name" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.pid_value" placeholder="PID" />
                        </td>
                        <td>
                            <b-form-input :disabled="!userHasPermission('admin')" type="text" v-model="newRow.user_name" placeholder="User Name" />
                        </td>
                        <td>
                            <b-form-input :disabled="!userHasPermission('admin')" type="text" v-model="newRow.password" placeholder="Password" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newRow: {
                id: 0,
                pid_text: '',
                pid_value: '',
                user_name: '',
                password: '',
            },
            rows: [],
            showNewRow: true,
            isAdmin: true,
            sortOptions: { 
                sortOrder: 'desc',
                sortByField: 'pid_text'
            },
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
       
    },
    methods: {
        async save(e) {
            e.preventDefault();
            const rowReq = this.newRow
            let validateRes = await this.validateRowData(this.newRow.pid_text, this.newRow.pid_value)
            if (validateRes !== "") {
                this.notifyError(validateRes)
                return 
            }
            const data = await this.$http.post(this.resources.Management.savePid, {
                ...this.newRow
            });
            this.newRow.pid_text = "";
            this.newRow.pid_value = "";
            this.newRow.password = "";
            this.newRow.user_name = "";
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getPid, {});
            for (let k in data.body.data) {
                data.body.data[k].edit = false;
            }
            this.rows = data.body.data;
            this.sortByField('pid_text');
        },
        async deleteEntity(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.dmDeletePid, {
                id,
            });
            this.getListData();
        },
        async editRow(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    editRowData = this.rows[k];
                }
            }
            
            this.showNewRow = true;
            const data = await this.$http.post(this.resources.Management.savePid, {
                ...editRowData
            });
            this.getListData()
            
        },
        async validateRowData(dm_pid_text, dm_pid_value) {
            if (dm_pid_value == "") {
                return "PID value can't be empty";
            }
            if (!this.validateStr(dm_pid_value)) {
                return "Please enter a valid PID value";
            }
            if (dm_pid_text == "") {
                return "PID name can't be empty";
            }
            if (!this.validateStr(dm_pid_text)) {
                return "Please enter a valid PID name";
            }
            return "";
        },
        validateStr(str) {
            const re = /([A-Za-z0-9-_]+)/;
            if (re.test(str)) {
                return true;
            }
            return false;
        },
        sortByField(fieldName) {
            this.sortOptions.sortByField = fieldName
            if (this.sortOptions.sortOrder === 'desc') {
                this.sortOptions.sortOrder = 'asc'
            } else {
                this.sortOptions.sortOrder = 'desc'
            }

            const nonIntFields = ['pid_text']
            if (! nonIntFields.includes(fieldName)) {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows.sort((a, b) => (parseInt(a[fieldName]) < parseInt(b[fieldName])) ? 1 : -1)
                } else {
                    this.rows.sort((a, b) => (parseInt(a[fieldName]) > parseInt(b[fieldName])) ? 1 : -1)
                }
            } else {
                // Remove spaces from the field
                for (let k in this.rows) { 
                    this.rows[k][fieldName] = this.rows[k][fieldName].trim()
                }
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows.sort((a, b) => (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) ? 1 : -1)
                } else {
                    this.rows.sort((a, b) => (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) ? 1 : -1)
                }
            }
        },
    },
    components: {  }
}
</script>

<style scoped>
.link-div {
    padding-top: 40px;
    text-align: center;
}
</style>
