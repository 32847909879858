<template lang="html">
    <div>
        <div>
            <a v-if="!show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Show Columns Selector</a>
            <a v-if="show_columns_selector" href="javascript:void(0);" @click="toggle_columns_selector">Hide Columns Selector</a>
            <div v-if="show_columns_selector">
                <input type="checkbox" @click="toggle_column(1)" :checked="show_c[1]"> ID
                <input type="checkbox" @click="toggle_column(2)" :checked="show_c[2]"> Affise ID
                <input type="checkbox" @click="toggle_column(3)" :checked="show_c[3]"> Global Offer ID
                <input type="checkbox" @click="toggle_column(4)" :checked="show_c[4]"> VTA
                <input type="checkbox" @click="toggle_column(5)" :checked="show_c[5]"> Name
                <input type="checkbox" @click="toggle_column(6)" :checked="show_c[6]"> Comments
                <input type="checkbox" @click="toggle_column(7)" :checked="show_c[7]"> Weight
                <input type="checkbox" @click="toggle_column(8)" :checked="show_c[8]"> Cap
                <input type="checkbox" @click="toggle_column(9)" :checked="show_c[9]"> Frq
                <input type="checkbox" @click="toggle_column(10)" :checked="show_c[10]"> IP Frq
                <input type="checkbox" @click="toggle_column(11)" :checked="show_c[11]"> D Frq
            </div>
            <span style="display: none;">{{ r }}</span>
        </div>
        <table class="offers_tbl">
            <tr class="table_header_row bottom_border">
                <td style="width: 30px;">

                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[1]">
                    <div>
                        <div class="th-title" @click="sortByField('id')">
                            ID
                        </div>
                        <div class="th-icon" v-if="sortOptions.sortByField === 'id'">
                            <i :class='sortOptions.sortOrder === "desc" && sortOptions.sortByField === "id" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                        </div>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[2]">
                    <div class="th-title" @click="sortByField('affiseID')">
                        Affise ID
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'affiseID'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[3]">
                    <div class="th-title" @click="sortByField('global_offer_assoc_id')">
                        Global Offer ID
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'global_offer_assoc_id'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[4]">
                    <div class="th-title" @click="sortByField('is_vta')">
                        VTA
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'is_vta'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[5]">
                    <div class="th-title" @click="sortByField('name')">
                        Name
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'name'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[6]">
                    <div class="th-title" @click="sortByField('sl_comments')">
                        Comments
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'sl_comments'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[7]">
                    <div class="th-title" @click="sortByField('weight')">
                        Weight
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'weight'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[8]">
                    <div class="th-title" @click="sortByField('cap')">
                        Cap
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'cap'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[9]">
                    <div class="th-title" @click="sortByField('frq')">
                        Frq
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'frq'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[10]">
                    <div class="th-title" @click="sortByField('ip_frq')">
                        IP Frq
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'ip_frq'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
                <td class="resize_td selectable_columns left_border" v-if="show_c[11]">                    
                    <div class="th-title" @click="sortByField('delivery_frq')">
                        D Frq
                    </div>
                    <div class="th-icon" v-if="sortOptions.sortByField === 'delivery_frq'">
                        <i :class='sortOptions.sortOrder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                    </div>
                </td>
            </tr>
            <tr v-for="r in rows_data_c" v-on:click="onRowClickInternal(r)" :class="r.classes">
                <td class="noresize_td">
                    <i data-v-68b4e752="" @click="onRowDeleteClick(r)" aria-hidden="true" class="fa fa-trash"></i>
                </td>
                <td class="resize_td left_border" v-if="show_c[1]">
                    {{ r.id }}
                    <span class="excluded-offer-span" v-if="r.exclude_offer_rotation">
                        excluded
                    </span>
                </td>
                <td class="resize_td left_border" v-if="show_c[2]">
                    {{ r.affiseID }}
                </td>
                <td class="resize_td left_border" v-if="show_c[3]">
                    {{ r.global_offer_assoc_id }}
                </td>
                <td class="resize_td left_border" v-if="show_c[4]" style="text-align: center;">
                   <input type="checkbox" disabled :checked="r.is_vta == 'true'">
                </td>
                <td class="resize_td left_border" v-if="show_c[5]">
                    {{ r.name }}
                </td>
                <td class="resize_td left_border" v-if="show_c[6]">
                    {{ r.sl_comments }}
                </td>
                <td class="resize_td left_border" v-if="show_c[7]">
                    {{ r.weight }}
                </td>
                <td class="resize_td left_border" v-if="show_c[8]">
                    {{ r.cap }}
                </td>
                <td class="resize_td left_border" v-if="show_c[9]">
                    {{ r.frq }}
                </td>
                <td class="resize_td left_border" v-if="show_c[10]">
                    {{ r.ip_frq }}
                </td>
                <td class="resize_td left_border" v-if="show_c[11]">
                    {{ r.delivery_frq }}
                </td>
            </tr>
        </table>
    </div>
</template>
  
<script>
export default {
    props: {
        rows_data: [],
        onRowClick: null,
        handleDeleteOffer: null,
        deselect: ''
    },
    components: {

    },
    data() {
        return {
            show_columns_selector: false,
            show_c: {
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
                6: true,
                7: true,
                8: true,
                9: true,
                10: true,
                11: true,
            },
            selected_row: -1,
            selected_offer: 0,
            r: 0,
            sortOptions: { 
                sortOrder: 'desc',
                sortByField: 'id'
            }
        }
    },
    created() {
        const t_arr = window.location.href.split('/')
        if (typeof t_arr[10] !== 'undefined') {
            this.selected_offer = t_arr[10]
        }
        this.updateSelectedOffer()
        if (localStorage.getItem('show_c')) {
            this.show_c = JSON.parse(localStorage.getItem('show_c'));
        }
    },
    methods: {
        toggle_columns_selector() {
            this.show_columns_selector = !this.show_columns_selector
        },
        toggle_column(i) {
            this.show_c[i] = !this.show_c[i]
            console.log(this.show_c)
            localStorage.setItem('show_c', JSON.stringify(this.show_c));
        },
        onRowClickInternal(r) {
            this.onRowClick(r)
            this.selected_offer = r.id
            this.updateSelectedOffer()
        },
        onRowDeleteClick(r) {
            this.handleDeleteOffer(r.id)
        },
        updateSelectedOffer() {
            this.r = Math.random()
            for (let k in this.rows_data) {
                if (this.rows_data[k].id == this.selected_offer) {
                    this.rows_data[k].classes = 'clickable_row selected_row';
                } else {
                    this.rows_data[k].classes = 'clickable_row';
                }
            }
        },
        sortByField(fieldName) {
            this.sortOptions.sortByField = fieldName
            if (this.sortOptions.sortOrder === 'desc') {
                this.sortOptions.sortOrder = 'asc'
            } else {
                this.sortOptions.sortOrder = 'desc'
            }

            const nonIntFields = ['name, sl_comments']
            if (! nonIntFields.includes(fieldName)) {
                if (fieldName === 'weight') {
                    for (let k in this.rows_data) { 
                        this.rows_data[k].weight_int = this.rows_data[k].weight.split(' ')[0]
                    }
                    fieldName = 'weight_int'
                    console.log(this.rows_data)
                }
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (parseInt(a[fieldName]) < parseInt(b[fieldName])) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (parseInt(a[fieldName]) > parseInt(b[fieldName])) ? 1 : -1)
                }
            } else {
                if (this.sortOptions.sortOrder === 'desc') {
                    this.rows_data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : -1)
                } else {
                    this.rows_data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1)
                }
            }
            

            this.updateSelectedOffer()
        }
    },
    computed: {
        rows_data_c() {
            console.log('rows_data_c')
            return this.rows_data
        }
    },
    watch: {
        deselect: function (newVal, oldVal) {
            this.selected_offer = 0
            this.updateSelectedOffer()
        }
    }
}
</script>
  
<style lang="css" scoped>
.offers_tbl {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

.resize_td {
    resize: both;
    overflow: auto;
    padding: 10px;
}

.noresize_td {
    padding: 10px;
}

td {
    font-size: 14px;
}

.left_border {
    border-left: 1px dotted gray;
}

.bottom_border {
    border-bottom: 1px solid gray;
}
.table_header_row {
    font-weight: bold;
}

.clickable_row {
    cursor: pointer;
}

.selected_row {
    background-color: #f2f2f2;
}

.th-title {
    display: inline-block;
    cursor: pointer;
}

.th-icon {
    display: inline-block; 
    padding-left: 2px;
}

.excluded-offer-span {
    font-size: 7px;
    color: rgb(157, 0, 0);
}
</style>
