<template>
    <form @submit.prevent=''>
        <div v-if="loader">
            Loading ...
        </div>
        <div v-if="!loader">
            <h1>Advanced</h1><br><br>
            <a :href=mainLink>Go back</a>
            <template v-if='form.click_offer'>
                <h5>Click Offer</h5>
                <div class="field-container">
                    <label>Click Offer Frequency</label>

                    <section v-for='i in [0,24,12,6,3,1]'>
                        <label :for='"frq-c-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                        <input :disabled='!userHasPermission("smartlinks.edit")' :id='"frq-c-hours-"+i' type="radio" v-model.number='form.click_offer.frq' :value='i'>
                    </section>
                </div>

                <div class="field-container">
                    <label>Click Offer IP Frequency</label>

                    <section v-for='i in [0,24,12,6,3,1]'>
                        <label :for='"ip-frq-c-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                        <input :disabled='!userHasPermission("smartlinks.edit")' :id='"ip-frq-c-hours-"+i' type="radio" v-model.number='form.click_offer.ip_frq' :value='i'>
                    </section>

                </div>
                <div class="field-container">
                    <label>IP Frequency Level:</label>
                    <select v-model='form.click_offer.ip_frq_level'>
                        <option value="l1">Level 1</option>
                        <option value="l2">Level 2</option>
                        <option value="l3">Level 3</option>
                        <option value="l4">Level 4</option>
                        <option value="l5">Level 5</option>
                    </select>
                    <div>
                        <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
                    </div>
                </div>
                <h5>VTA Offer</h5>
            </template>

            <div class="field-container">
                <label>Weight</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' type="number" min="0" max="99999" class='form-control pub-id-input' v-model.number='form.weight' @keyup="validateWeightPrecent">
                <strong v-if="valid_weight">{{ this.form.weight }}% </strong>
            </div>

            <div class="field-container">
                <label>Frequency</label>
                <section v-for='i in [0,24,12,6,3,1]'>
                    <label :for='"frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' :id='"frq-hours-"+i' type="radio" v-model.number='form.frq' :value='i'>
                </section>
            </div>

            <div class="field-container">
                <label>IP Frequency</label>

                <section v-for='i in [0,24,12,6,3,1]'>
                    <label :for='"ip-frq-hours-"+i'>{{i > 0 ? i + " Hours" : "None"}}</label>
                    <input :disabled='!userHasPermission("smartlinks.edit")' :id='"ip-frq-hours-"+i' type="radio" v-model.number='form.ip_frq' :value='i'>
                </section>

            </div>

            <div class="field-container">
            <label>IP Frequency Level:</label>
            <select v-model='form.ip_frq_level'>
                <option value="l1">Level 1</option>
                <option value="l2">Level 2</option>
                <option value="l3">Level 3</option>
                <option value="l4">Level 4</option>
                <option value="l5">Level 5</option>
            </select>
            <div>
                <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
            </div>
            </div>

            <div class="field-container">
                <label>Delivery Frequency</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model.number='form.delivery_frq' class="pub-id-input delivery_frq form-control" @keyup="validateDeliveryPrecent">
                <strong v-if="valid_delivery_frq">{{ this.form.delivery_frq }}% </strong>
            </div>

            <div class="field-container" v-if="!form.is_global_offer_pid_rotating">
                <!-- <label>Pub ID</label>
                <input :disabled='!userHasPermission("smartlinks.edit") || form.is_rotating === 1' v-model='form.pid' class="pub-id-input form-control"> -->
                <div class="rotating" >
                    <b-form-checkbox id="checkbox-1" v-model="form.is_rotating" name="checkbox-1" :value=1 :unchecked-value=0>
                        Is rotating?
                    </b-form-checkbox>
                </div>

                <div class="rotating-div " v-if="form.is_rotating && !form.is_global_offer_pid_rotating">
                    
                    <b-dropdown id="dropdown-form" text="PID List" ref="dropdown" size="sm">
                        <b-dropdown-form>
                            <b-form-input id="dropdown-form-email" size="sm" placeholder="search" @keyup="pidFilterChange" v-model="pidFilter"></b-form-input>
                        </b-dropdown-form>
                        <div style="max-height: 300px; overflow: scroll;">
                            <b-dropdown-item-button v-for="row of pidListsNames" :value="row.value" @click="selectPid(row)" :key="row.id">{{ row.text }}</b-dropdown-item-button>
                        </div>
                    </b-dropdown>
                
                    <b-form-input size="sm" id="input-2" v-model.number="form.ttl" required></b-form-input>
                    <b-form-select size="sm" id="input-3" v-model="time_chosen" :options="time" required></b-form-select>
                    <b-button v-if="form.is_rotating == 1 && form.ttl >= 0" class="rotate-now-btn" size="sm" variant="secondary" @click="rotateNowHandler()">Rotate Now</b-button>
                </div>
            </div>

            <div v-if="form.is_rotating">
                <div class="field-container" v-if="form.is_new_pid === '1'" style="font-size: 10px;">
                    Please make sure to save the offer if you switched PIDs list bfore you can use rotate now
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <div style="font-weight: bold;">
                        List Name: {{ form.currently_selected_list_name }}
                    </div>
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <div>
                        Currently Selected PID: {{ form.currently_selected_pid }}
                    </div>
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <div>
                        Last Rotate: {{ form.last_rotate }}
                    </div>
                </div>
                <div class="field-container" v-if="form.is_new_pid === '1'">
                    <a :href="form.list_id_link">Go To List</a>
                </div>
            </div>


            <div v-if="form.is_global_offer_pid_rotating">
                <div class="field-container">
                    <b-form-checkbox v-model="form.is_global_offer_pid_rotating" :value="true" :unchecked-value="false" :disabled="true">
                       PID Rotator
                    </b-form-checkbox>
                </div>
                <div class="field-container">
                    <div>
                        Currently Selected PID: {{ form.currently_selected_pid }}
                    </div>
                </div>
                <div class="field-container">
                    <div>
                        Last Rotate: {{ form.last_rotate}}
                    </div>
                </div>
                <div class="field-container">
                    <div>
                        Rotating Every : <b>{{ form.global_offer_pid_rotating_period}}</b> Hours.
                    </div>
                </div>
            </div>
            
        </div>

            <div class="field-container">
                <label>Connection Type</label>
                <Select class='select' :options='connectionTypes' v-model="form.connection_type" />
            </div>

            <div class="field-container" v-if='form.connection_type === "3G" && form.geo && form.geo.length !== 0'>
                <label>Mobile Carrier</label>
                <cool-select v-if='isps' v-model="carrierSelected" :items="isps" />
                <ul class='selected-items'>
                    <template v-if='userHasPermission("smartlinks.edit")'>
                        <li @click='onCarrierDel(index)' v-for='(isps, index) in form.isps'>
                            <i class="fa fa-times del-x" aria-hidden="true"></i>
                            <span>
                                {{isps}}
                            </span>
                        </li>
                    </template>
                    <template v-else>
                        <li v-for='(isps, index) in form.isps'>
                            <span>
                                {{isps}}
                            </span>
                        </li>
                    </template>
                </ul>
                <div class="isp-checkbox">
                    <input type="checkbox" v-model="form.isp_bl" name="BL" >
                    <label for="scales">Blacklist</label>
                </div>
            </div>

            <div class="field-container">
                <label>Custom Subs Query</label>
                <input :disabled='customOffer || !userHasPermission("smartlinks.edit")' v-model='form.subs_query' class="pub-id-input form-control">
            </div>

            <div class="field-container">
                <label>Device ID Traffic Only</label>
                <b-form-checkbox v-model="form.is_device_id_traffic"></b-form-checkbox>
            </div>

            <div class="field-container">
                <label>Custom URL</label>
                <input :disabled='!userHasPermission("smartlinks.edit")' v-model='form.custom_url' class="pub-id-input form-control">
                <span class="span-415">Placholders: {date_q}, {hour_letter}</span>
            </div>

            <div class="result-url">
                <span>{{resultURL}}</span>
            </div>
            <hr>

            <div v-if="subsValidationError.length > 0" class="alert">
                <a class="close" @click="subsValidationError = []">&times;</a>
                <li v-for="msg in subsValidationError">{{ msg }}</li>
            </div>


            <div>
                <h4>Subs Blacklisting</h4>
                <div class="blacklist-container" v-show='userHasPermission("smartlinks.edit")'>
                    <div class="inline-container">
                        <label>Sub:</label>
                        <Select class="select" :options="unselectedSubs" v-model="new_sub_bl.sub" @change="changeSelectedSub"/>
                        <input id="new-sub-bl-wl" v-model='new_sub_bl.whitelist' type="checkbox">
                        <label for="new-sub-bl-wl">Whitelist</label>
                        <i class="fa fa-plus edit-btn" aria-hidden="true" @click='onSubsBlacklistAdd'></i>
                    </div>
                    <div>
                        <span v-if="selectedSub === 'sub2' || selectedSub === 'sub2' || selectedSub === 'sub2'" style="color: red;">Case sensitive</span>
                        <span v-if="selectedSub === 'sub4'" style="color: red;">Not case sensitive</span>
                    </div>
                    <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="new_sub_bl.list"></LimitedTextarea>
                </div>
                <br class="flat-breaks"><br>
                <div>
                    <div v-for="(s,i) in form.blacklisting_subs">
                        <div class="inline-container">
                            <i class="fa fa-trash trash-btn" aria-hidden="true" @click='onSubsBlacklistRemove(i)'></i>
                            <h5>{{s.sub}}</h5>
                        </div>
                        <div>
                            <input :disabled='!userHasPermission("smartlinks.edit")' v-model='s.whitelist' type="checkbox">
                            <label>Whitelist</label>
                        </div>
                        <div>
                            <span v-if="s.sub === 'sub2' || s.sub === 'sub3' || s.sub === 'sub7'" style="color: red;">Case sensitive</span>
                            <span v-if="s.sub === 'sub4'" style="color: red;">Not case sensitive</span>
                        </div>
                        <LimitedTextarea class="max-textarea" rows="4" :max="3000000" v-model="s.list"></LimitedTextarea>
                        <br class="flat-breaks"><br>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn-success btn" @click="submit">Save</button>

    </form>
</template>

<script>
import moment from "moment";
import { CoolSelect } from "vue-cool-select";
    export default{
        components: {
            CoolSelect
        },
        data() {
            return {
                form: {
                    affiseID: 0,
                    name: '',
                    sl_comments: '',
                    weight: 100,
                    cap: '0',
                    frq: 24,
                    ip_frq: 24,
                    ip_frq_level: 'l3',
                    pid: null,
                    subs_query: null,
                    custom_url: null,
                    os_version: null,
                    os_version_v: "  all",
                    os_version_operator: ">=",
                    connection_type: "all",
                    isps: [],
                    blFiles: [],
                    blacklisting_subs: [],
                    list_id: 'NEW_PID_102',
                    ttl: 6,
                    is_rotating: 1,
                    delivery_frq: 100,
                    geo: '',
                    os: '',
                    appsflyer_agency_id: 0,
                    gappsflyer_account_id: 0,
                    is_global_offer_pid_rotating: false,
                    click_offer: {
                        affiseID: null,
                        name: null,
                        weight: null,
                        cap: '0',
                        frq: 24,
                        ip_frq: 24,
                        ip_frq_level: 'l3',
                        pid: null,
                        subs_query: null,
                        custom_url: null,
                        os_version: null,
                        isps: [],
                        blFiles: [],
                        blacklisting_subs: [],
                        ttl: null,
                        list_id: null,
                        is_rotating: 1,
                        isp_bl: 0,
                        advertiser_id: 0,
                        is_global_offer: true,
                        is_click_offer: 1,
                    },
                    goffer_bundle_id: '',
                    is_global_offer: true,
                    list_id_link: '',
                    currently_selected_pid: '',
                    currently_selected_list_name: '',
                    last_rotate: '',
                    is_new_pid: '1',
                    af_cb: false,
                    appsflyer_account_id: 0,
                    isp_bl: 0,
                    advertiser_id: 0,
                    is_device_id_traffic: false
                },
                id: 0,
                isVtaSL: false,
                connectionTypes: ["all", "wifi", "3G"],
                loader: false,
                valid_weight: false,
                valid_delivery_frq: false,
                appsflyer_list: [],
                mainLink:'#',
                selectedSub: '',
                subsValidationError: [],
                new_sub_bl: {},
                all_subs: [{
                        val: "sub1",
                        label: "Sub1"
                    },
                    {
                        val: "sub2",
                        label: "Sub2"
                    },
                    {
                        val: "sub3",
                        label: "Sub3"
                    },
                    {
                        val: "sub4",
                        label: "Sub4"
                    },
                    {
                        val: "sub6",
                        label: "Sub6"
                    },
                    {
                        val: "sub7",
                        label: "Sub7"
                    },
                    {
                        val: "sub8",
                        label: "Sub8"
                    },
                ],
                pidFilter: '',
                list_name: "",
                pidListsNamesToId: null,
                pidListsNames: null,
                pidListsNamesOrig: null,
                time_number: 0,
                time: ["Hours", "Days"],
                time_chosen: "Hours",
                carrierSelected: null,
                all_isps: null,
            }
        },
        async created() {
            // always edit (with an id)
            this.loader = true;
            let { id, isVta }= this.$route.query;
            this.id = parseInt(id);

            this.isVtaSL = parseInt(isVta);
            if (! this.isVtaSL) {
                this.form.click_offer = null;
            }
            if (this.id !== 0) {
                this.edit = true
                await this.fetchOffer();
                this.mainLink =  '/apps/smartlinks/global-offers-edit?id=' + this.id + '&isVta=' + this.isVtaSL
            }

            this.getAppsflyerList()
            if (this.form.appsflyer_account_id != 0) {
                this.form.af_cb = true
            }
            await this.getPidToListName();
            await this.fetchIsps();
            this.loader = false;
        },
        computed: {
            customOffer() {
                let form = this.form
                return !!form.custom_url
            },
            resultURL() {
                if (this.customOffer) {
                    return this.form.custom_url
                }
                let {
                    subs_query,
                    affiseID,
                    pid
                } = this.form
                let defaultSubsQuery =
                    "pid={pid}&sub1={sub1}&sub2={sub2}&sub4={sub4}&sub5={sub5}&sub7={sub7}&sub8={sub8}&sub6={sub6}&sub3={sub3}"
                let basicURL = `https://go.g2app.net/click?offer_id=${affiseID}`
                if (!subs_query) {
                    subs_query = defaultSubsQuery
                }
                if (pid) {
                    subs_query = subs_query.replace("{pid}", pid)
                }

                return basicURL + "&" + subs_query
            },
            unselectedSubs() {
                if (!this.all_subs || !this.all_subs.length) {
                    return
                }
                return this.all_subs.filter(v => !this.form.blacklisting_subs.find(v2 => v2.sub == v.val))
            },
            ttl: {
                get() {
                    if (this.form.is_rotating) {
                        if (this.time_chosen === "Days") {
                            return this.form.ttl * 24
                        } else {
                            return this.form.ttl
                        }
                    }
                },
                set(newVal) {
                    return newVal
                } 
            },
            isps() {
                return this.all_isps
            },
        },
        watch: {
            carrierSelected(value) {
                if (value != null) {
                    if (this.form.isps == null) {
                        this.form.isps = []
                    }
                    this.form.isps.push(value)
                }
            },
        },
        methods: {
            async fetchOffer() {
                try {
                    this.loader = true;
                    const r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOfferById(this.id));
                    const data = await JSON.parse(r.bodyText);
                
                    if (data.res !== 'OK') {
                        this.notifyError("Can\'t fetch offer")
                    }
                    if (data.data.is_new_pid === '1') {
                        data.data.list_id = "NEW_PID_" + data.data.list_id
                    }

                    if (data.data.last_rotate) {
                        data.data.last_rotate =  moment(data.data.last_rotate).format('DD.MM.YYYY HH:mm:ss')
                    }

                    this.form = data.data;
                    this.loader = false;

                } catch (err) {
                    console.log(err);
                    this.notifyError("Can\'t fetch offer")
                }
            }, 
            async validateDeliveryPrecent() {
                if (this.form.delivery_frq > 0) {
                    if (this.form.delivery_frq > 0 && this.form.delivery_frq <= 100) {
                        this.valid_delivery_frq = true

                    } else {
                        this.valid_delivery_frq = false
                        this.form.delivery_frq = 0
                        this.notifyError('Delivery frequency expect number between 0 to 100')
                    }
                } else {
                    this.valid_delivery_frq = false
                }
            },
            async validateWeightPrecent() {
                if (this.form.weight > 0) {
                    if (this.form.weight > 0 && this.form.weight <= 100) {
                        this.valid_weight = true

                    } else {
                        this.valid_weight = false
                        this.form.weight = 0
                        this.notifyError('Weight frequency expect number between 0 to 100')
                    }
                } else {
                    this.valid_weight = false
                }
            },
            async submit() {
                for (let k in this.form.blacklisting_subs) {
                    if (this.form.blacklisting_subs[k].list.length > 65000) {
                        this.notifyError (`Sub BL/WL Can't be more than 65000 characters`)
                        return
                    }
                }

                this.subsValidationError = []
                if (!this.userHasPermission("smartlinks.edit")) {
                    return
                }
                if (this.resultURL.includes(" ")) {
                    this.notifyError("Space in result URL")
                    return false
                }

                const subsMsgArray = await this.validateBlacklistingSubs() 
                if (Object.keys(subsMsgArray).length > 0) {
                    console.log(subsMsgArray);
                    this.ThrowSubsAlerst(subsMsgArray)
                    return
                }
                if (this.form.connection_type !== '3G') {
                    this.form.isps = [];
                }

                this.loader = true;
                // save edited, need to fetch all newly data for it be be overwitten (from the main)
                const form = {
                    ...this.form
                }

                if (this.isVtaSL) {
                    form.click_offer = {
                        ..._.omit(form.click_offer, ['connection_type', 'blacklisting_subs', 'isps', 'subs_query', 'pid', 'blFiles', 'ttl', 'cap', 'advertiser_id', 'geo', 'os', 'appsflyer_agency_id', 'gappsflyer_account_id']),
                        connection_type: this.form.connection_type,
                        blacklisting_subs: this.form.blacklisting_subs,
                        isps: this.form.isps,
                        isp_bl: this.form.isp_bl,
                        subs_query: this.form.subs_query,
                        pid: this.form.pid,
                        blFiles: this.form.blFiles,
                        is_rotating: this.form.is_rotating,
                        ttl: this.ttl,
                        list_id: this.form.list_id,
                        os_version: form.os_version,
                        cap: this.form.cap,
                        advertiser_id: this.form.advertiser_id,
                        geo: form.geo,
                        os: this.form.os,
                        appsflyer_agency_id: this.form.appsflyer_agency_id,
                        gappsflyer_account_id: this.form.gappsflyer_account_id,
                        appsflyer_account_id: this.form.appsflyer_account_id,
                       
                    }
                }

                if (this.form.is_rotating) {
                    form.ttl = this.ttl
                    
                } else {
                    form.ttl = 0
                    form.list_id = 0
                    this.form.ttl = 0
                    this.ttl = 0
                    if (this.isVtaSL) {
                        form.click_offer.ttl = 0
                        form.click_offer.list_id = 0
                        this.form.click_offer.ttl = 0
                    }
                }

                try {

                        form.is_new_pid = "0"
                        var tListId = ''
                        if (String(form.list_id).includes("NEW_PID_")) {
                            tListId = form.list_id
                            form.list_id = parseInt(form.list_id.replace("NEW_PID_", ""))
                            form.is_new_pid = "1"
                        }
                
                        if (typeof form.connection_type !== 'string' || form.connection_type === '') {
                            form.connection_type = 'All'
                        }
        
                        // edit global offer
                        const r = await this.$http.put(this.resources.GlobalOffers.updateGlobalOffer(this.id), form)
                        const data = await JSON.parse(r.bodyText)
                        if (data.res === 'OK') {
                            this.notifySuccess("Offer has updated")
                        } else {
                            this.notifyError('Error occured. Offer did not save')
                        }
                        form.list_id = tListId;
                        this.loader = false;

                } catch (err) {
                    this.notifyError("An error Occured. Please refresh the page")
                }
            
            
            },
            changeSelectedSub() {
                if (!this.new_sub_bl || !this.new_sub_bl.sub) {
                    return
                } else {
                    this.selectedSub = this.new_sub_bl.sub.val
                }
            },
            onSubsBlacklistAdd() {
                if (!this.new_sub_bl || !this.new_sub_bl.sub) {
                    return
                }
                this.selectedSub = ''
                let newsub = this.new_sub_bl
                this.new_sub_bl = {}
                newsub.sub = newsub.sub.val
                this.form.blacklisting_subs.unshift(newsub)
            },
            onSubsBlacklistRemove(i) {
                this.form.blacklisting_subs.splice(i, 1);
            },
            async validateBlacklistingSubs() {
                let subsMsgArray = []
                let matchedArray = []
                for (let k in this.form.blacklisting_subs) {
                    matchedArray = this.form.blacklisting_subs[k].list.match(/[\r\n\t\f\v\s]/g) || []
                    let lastMatch = 0
                    let msgArray = []
            
                    for (const i in matchedArray) {
                        lastMatch =  this.form.blacklisting_subs[k].list.indexOf(matchedArray[i],lastMatch)
                        msgArray.push({
                            str : this.form.blacklisting_subs[k].list.substring(lastMatch-10, lastMatch+10),
                            char: matchedArray[i]
                        })
                        lastMatch ++
                    }
                    if (matchedArray.length > 0) {
                        subsMsgArray[this.form.blacklisting_subs[k].sub] = msgArray
                    } 
                }
                return subsMsgArray
            },
            ThrowSubsAlerst(subsMsgArray) {
                let msg = []

                for (const k in subsMsgArray) {
                    for (const i in subsMsgArray[k]) {
                        let txt = ''
                        switch (subsMsgArray[k][i].char) {
                            case ' ':
                                txt = `There is a space in ${k} between - ${subsMsgArray[k][i].str}`
                                break
                            case '\n':
                                txt = `There is a line break in ${k} between - '${subsMsgArray[k][i].str}''`
                                break
                            case '\t': 
                                txt = `There is a tab in ${k} between - ${subsMsgArray[k][i].str}`
                                break

                        }
                        msg.push(txt)
                    }
                }
                this.subsValidationError = msg 
            },
            async getAppsflyerList() {
                const r = await this.$http.post(this.resources.Management.getAfData, {})
                this.appsflyer_list = r.body.data
            },
            afCbClick() {
                this.form.appsflyer_account_id = 0
            },
            rotateNowHandler: async function () {
                try {
                    if (typeof this.form.id === 'undefined') {
                        this.notifyError("Please save the offer first")
                        return
                    }
                    const r = await this.$http.post(this.resources.SmartLinksCampaigns.rotateOfferNow, undefined, {
                        params: {
                            offer_id: this.form.id,
                            offer_ttl: this.form.ttl,
                            list_id: this.form.list_id
                        }
                    })
                    const data = await r.json()
                    this.notifySuccess("Offer pid is now succesfuly rotated!")
                    if (this.form.list_id.includes("NEW_PID_")) {
                        this.form.currently_selected_pid = data.pid
                        this.form.last_rotate = data.last_rotate
                        this.$forceUpdate();
                    }
                } catch (err) {
                    this.notifyError("Rotate now didnt actioned. Please try again")
                }
            },
            async fetchIsps() {
                if (this.form.geo && this.form.geo.length > 0) {
                    try {
                        const r = await this.$http.get(this.resources.SmartLinksCampaigns.getISPs, {
                            params: {
                                countries: [this.form.geo]
                            }
                        })
                        const isps = await r.json()
                        this.all_isps = isps
                    } catch (err) {
                        this.notifyError("can't fetch isps")
                    }
                }
            },
            onCarrierDel(index) {
                this.form.isps.splice(index, 1)
            },
            async getPidToListName() {
                try {
                    const r = await this.$http.get(this.resources.Management.pidListIdToName)
                    let data = await r.json()

                    this.pidListsNamesToId = data
                    this.pidListsNames = this.pidListsNamesToId.map(item => {
                        if (item.list_version === '2') {
                            if (typeof this.form.list_id === 'string') {
                                this.form.list_id_link = '/man/pid-pool-lists-details?id=' + this.form.list_id.replace('NEW_PID_', '')
                            }
                            return {
                                value: 'NEW_PID_' + item.list_id,
                                text: item.list_name
                            }    
                        }
                        return {
                            value: item.list_id,
                            text: item.list_name
                        }
                    })
                    for (let k in this.pidListsNames) {
                        if (this.form.is_new_pid === '1') {
                            this.form.list_id = this.form.list_id + ''
                            if (this.pidListsNames[k].value.trim() == this.form.list_id.trim()) {
                                this.form.currently_selected_list_name = this.pidListsNames[k].text
                            }
                        }
                    }
                    this.pidListsNamesOrig = this.pidListsNames
                } catch (err) {
                    this.notifyError("Could not fetch Pid's names")
                }
            },
            async listIdChange(v) {
                if (typeof v === 'string' && v.includes('NEW_PID_')) {
                    this.form.is_new_pid = '1'
                    this.form.list_id_link = '/man/pid-pool-lists-details?id=' + this.form.list_id.replace('NEW_PID_', '')
                    this.form.currently_selected_pid = 'Please save and rotate'
                    // this.$forceUpdate();
                } else {
                    this.form.is_new_pid = '0'
                }
                this.$forceUpdate();
            },
            pidFilterChange() {
                this.pidListsNames = []
                for (let k in this.pidListsNamesOrig) {
                    if (this.pidListsNamesOrig[k].text.toLowerCase().includes(this.pidFilter.toLowerCase())) {
                        this.pidListsNames.push(this.pidListsNamesOrig[k])
                    }
                }
            },
            selectPid(row) {
                this.form.currently_selected_list_name = row.text
                this.form.list_id = row.value
                this.listIdChange(row.value)
            },

        }
    }

</script>

<style scoped>
    .inline-container {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .inline-container>* {
        margin-right: 9px;
    }

    .max-textarea {
        width: 100%;
    }


    .flat-breaks br {
        content: "";
    }

    .flat-breaks br:after {
        content: " ";
    }

    .alert {
        padding: 20px;
        margin-bottom: 15px;
        position: fixed;
        top: 5%;
        left: 25%;
        right: 0;
        z-index: 999999;
        width: 50%;
        box-shadow: 0 0 15px 5px #ccc;
        border-radius: 5px;
        background-color: lightcoral;
    }

    .rotating-div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

    }


</style>