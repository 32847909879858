<template lang="html">
    <b-modal v-model="showSearchModal" title="Bundle Search"  @hide=" $emit('update:is-show', false)" hide-footer>
      <div>
        If you search a number - we will look only on bundle id, exact match<br />
        If it's a text - we will look on app name, not necessary exact match.<br />
      </div>
    </b-modal> 
</template>
<script>
export default {
    
    props: ['isShow'],
    data() {
        return {
            
        }
    },
    created(){

    },
    computed: {
        showSearchModal: {
            get: function() {
                return this.isShow
            },
            set: function() {

            }
        }
    },
    methods: {

    },    
}
</script>
<style scoped>

</style>