export default {

  // Trick to reset/clear native browser form validation state
    clearValidationState() {
        this.show = false
        this.$nextTick(() => {
            this.show = true

        })
  },
  resetValues(array){
      array.forEach(element => {
          element = ''
      });
      return array
  }
}
  