<template>
    <div>
        <div v-if="done_no_sync">
            Done.
            <a href="/man/pid-pool">Back</a>
        </div>
        <div v-if="success">
            <a v-if="finished_sync" href="/man/pid-pool">Back</a>
            
            <div v-if="started_sync">
                <b> Please leave browser open until all PID are syncd ! </b>
            </div>
            <div v-if="finished_sync">
                <b> Done syncing. <br/>
                    You can leave the page now. </b>
            </div>
            <div>
                <br /><br />
                Output:<br />
                <div v-for="r of output">
                    {{ r }}
                </div>
            </div>
        </div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-if="showForm">
            <div v-if="inputErr">
                Please check your input...
            </div>
            <form @submit="addBulk">
                <div>
                    <textarea v-model="pidsRawData" style="width: 100%; resize: none; height: 300px;"></textarea>
                </div>
                <div>
                    <input v-model="autoGenerateGroups" type="checkbox" /> Auto generate groups
                </div>
                <div>
                    <input v-model="syncPostback" type="checkbox" checked /> Sync postback
                </div>
                <div style="text-align: center;">
                    <input v-on:click="addBulk" class="btn" type="submit" value="Add Bulk" />
                </div>
            </form>
            <div>
                Please add PIDs separated by commas. If auto generated groups is cheked every 6 PIDs will have their own
                group.
            </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent
} from '@vue/composition-api'
export default {
    data() {
        return {
            pidsRawData: '',
            autoGenerateGroups: false,
            syncPostback: true,
            showForm: true,
            loading: false,
            inputErr: false,
            success: false,
            started_sync: false,
            finished_sync: false,
            output: [],
            done_no_sync: false
        }
    },
    methods: {
        async addBulk(e) {
            e.preventDefault()
            this.loading = true
            this.showForm = false
            const data = await this.$http.post(this.resources.Management.addBulk, {
                pids_raw_data: String(this.pidsRawData),
                autoGenerateGroups: String(this.autoGenerateGroups),
                syncPostback: this.syncPostback
            })
            if (data.body.res !== 'OK') {
                this.showForm = true
                this.loading = false
                this.inputErr = true
                this.success = false
                return
            }
            this.showForm = false
            this.loading = false
            this.inputErr = false
            this.success = true
            if (this.syncPostback) {
                await this.syncBulk(e)
            } else {
                this.success = false
                this.done_no_sync = true
            }
        },
        async syncBulk(e) {
            const splitStr = this.pidsRawData.split(',')
            this.started_sync = true

            try {
                for (const k in splitStr) {
                    let status = ''
                    const data = await this.$http.post(this.resources.Management.syncPostback, {
                        pids_raw_data: splitStr[k]
                    })

                    if (data.body.output[0].includes("error")) {
                        let msgErrArr = data.body.output[0].split('"')
                        status += 'Error - ' + msgErrArr[msgErrArr.length - 2] + ' for PID - '

                    } else {
                        status += 'Successfully syncing PID - '
                    }
                    status += splitStr[k]
                    this.output.push(status)
                }
                this.started_sync = false
                this.finished_sync = true
            
            } catch (err) {
                this.success = false
                this.inputErr = true
            }
        }
    },
}
</script>

<style scoped>

</style>
