<template>
    <div v-if="! loading">
        <h2>PIDs Pool - List Details</h2>
        <div>
            <div v-if="!editFormName">
                List Name: {{ listDetails.pid_list_name }}
                <a href="javascript:void(0);" v-on:click="showEditFormName">Edit</a><br />
            </div>
            <div v-if="editFormName">
                <form @submit="saveListName">
                    <input type="text" v-model="listDetails.pid_list_name" />
                    <input type="submit" value="Save" v-on:click="saveListName" />
                </form>
            </div>
            PIDs Count: {{ tableData.length }}<br />
            <div v-if="offers_assoc.length !== 0">
                Connected Offers:
                <span v-for="offer in offers_assoc">
                    <a :href="offer.offer_link">{{ offer.offer_id }}</a>&nbsp;
                </span>
            </div>
            <div v-if="offers_assoc.length === 0">
                This list is not connected to any offer
            </div>
        </div>
        <div>
            <div v-if="! addForm">
                <a href="javascript:void(0);" v-on:click="showAddForm">Add More PIDs</a> | <a href="javascript:void(0);" @click="replaceAllPids">Replace All PIDs</a>
            </div>
            <div v-if="addForm">
                <form @submit="addPidsAction">
                    <b-form class="pid-add-form">
                        <b-row>
                            <b-col>
                                <h1></h1>
                            </b-col>
                        </b-row>
                        <b-row class="pid-add">
                            <b-col>
                                <b-form-group id="input-group-1" label="Number of PID to assign from pool:"
                                    label-for="input-2">
                                    <b-form-input id="input-2" type="number"
                                        placeholder="Enter Number" v-model="form.number_of_pids" number required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col></b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button id="input-5" type="submit" v-on:click="addPidsAction" 
                                    variant="success">Add PIDs</b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                    <div>
                        {{ msg }}
                    </div>
                </form>
            </div>
        </div>
        <div v-if="tableData.length > 0" class="mt-5">
            <table class="table table-condense w-auto small">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">PID</th>
                        <th scope="col">PID Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in tableData">
                        <th>{{ row.id }}</th>
                        <td>{{ row.pid }}</td>
                        <td>{{ row.pid_status }}</td>
                        <td>
                            <a v-on:click="removeAndReplacePid(row.id)" href="javascript:void(0);">Replace</a>&nbsp;
                            <a v-on:click="removePidFromList(row.id)" href="javascript:void(0);">Remove</a>&nbsp;
                            <a v-on:click="coolDownPid(row.id)" href="javascript:void(0);">Cool Down</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                number_of_pids: 1
            },
            tableData: [],
            msg: '',
            listDetails: {},
            offers_assoc: [],
            addForm: false,
            loading: true,
            editFormName: false
        }
    },
    created() {
        this.getPidsListDetails()
    },
    methods: {
        async getPidsListDetails() {
            const data = await this.$http.post(this.resources.Management.getPidsListDetails, {
                id: this.$route.query.id
            })
            this.tableData = data.body.pids === null ? [] : data.body.pids
            this.listDetails = data.body.list_details
            this.offers_assoc = data.body.offers_assoc
            this.loading = false
            this.minimumPids = 2
        },
        async removePidFromList(id) {
            if (this.tableData.length <= this.minimumPids) {
                this.notifyError("Minimm of 2 PIDs")
                return
            }
            await this.$http.post(this.resources.Management.removePidFromList, {
                id: String(id)
            })
            this.getPidsListDetails()
        },
        async removeAndReplacePid(id) {
            const data = await this.$http.post(this.resources.Management.pidPoolReplace, {
                id: String(id)
            })
            if (data.body.res === 'OK_PIDS_0') {
                this.notifyError("There are no available PIDs")
                return
            }
            this.notifyError("Done!")
            this.getPidsListDetails()
        },
        async coolDownPid(id) {
            if (this.tableData.length <= this.minimumPids) {
                this.notifyError("Minimm of 2 PIDs")
                return
            }
            await this.$http.post(this.resources.Management.coolDownPid, {
                id: String(id)
            })
            this.getPidsListDetails()
        },
        showAddForm() {
            this.addForm = true
            console.log('showAddForm')
        },
        async addPidsAction(e) {
            e.preventDefault()
            if (this.form.number_of_pids === 0 || this.form.number_of_pids === '') {
                return
            }
            this.msg = 'Processing...'
            const data = await this.$http.post(this.resources.Management.addPidsToList, {
                list_id: this.$route.query.id,
                number_of_pids: String(this.form.number_of_pids)
            })

            if (data.body.res === 'OK') {
                this.addForm = false
                this.getPidsListDetails()
                this.msg = 'Done'
            } 
            if (data.body.msg) {
                this.msg = data.body.msg
            }
        },
        showEditFormName() {
            this.editFormName = true
        },
        async saveListName(e) {
            e.preventDefault()
            this.editFormName = false
            await this.$http.post(this.resources.Management.pidListNameUpdate, {
                pid_list_name: this.listDetails.pid_list_name,
                id: this.$route.query.id
            })
        },
        async replaceAllPids() {
            const c = confirm('Are you sure?')
            if (c) {
                for (let k in this.tableData) {
                    await this.removeAndReplacePid(this.tableData[k].id)
                    await new Promise((r) => setTimeout(r, 500))
                }
            }
        }
    },

}
</script>

<style scoped>
</style>
