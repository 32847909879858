let Validations = {}
Validations.install = function (Vue, options) {
  this._validations = []
  let self = this
  Vue.setValidations = function (val) {
    self._validations = val
  }

  Object.defineProperty(Vue.prototype, '$validate', {
    get() {
      return self._validations
    }
  })
}

export default Validations
