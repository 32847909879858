<template>
  <div>
    <div v-if="show_loader">
      <h1>Loading...</h1>
    </div>
    <div v-if="!show_loader">
      <div>
        <div>
          <button v-on:click="new_row" class="btn-success btn">New</button>
        </div>
        <div class="dash_main_table_div" style="padding-top: 60px;">
          <ResizableDataTable v-if="page_loaded" :rows_data.sync="dash_data" :fields="fields" :show_c.sync="show_col"
            :nonIntFields="nonIntFields" :booleanFields="boolFields" :revenueFields="revenueFields"
            :show_columns_name="'update_revenue'" :delete="dd" :dd_from="dd_from" :dd_to="dd_to"
            :no_actions="no_actions">
          </ResizableDataTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResizableDataTable from '@/components/shared/ResizableDataTable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';

export default {
  components: { ResizableDataTable, DateRangePicker },
  data() {
    let today = new Date()
    let yesterday = today.setDate(today.getDate() - 1)

    let yesterdayx = new Date();
    yesterdayx.setDate(yesterdayx.getDate() - 1);
    let todayx = new Date();

    return {
      show_loader: true,
      dash_data: [],
      deleteConfirm: false,
      showDeleteModal: false,
      deleteAdvName: '',
      deleteAdvId: 0,
      dataOptions: {
        sortdatafield: "adv_company_name",
        sortorder: "asc"
      },
      form: {
        date_from: yesterday,
        date_to: yesterday
      },
      fields: [],
      page_loaded: false,
      show_col: {},
      boolFields: ['adv_var_adv'],
      nonIntFields: ['adv_company_name', 'adv_csm_name', 'affise_adv_name'],
      revenueFields: ['revenue'],
      selected_list: [],
      current_list: [],
      adv_list: [],
      csm_list: [],
      filter_value: null,
      origin_list: [],
      list_types: [
        {
          value: 'adv_company_name',
          text: 'Company Name'
        },
        {
          value: 'adv_csm_name',
          text: 'CSM Name'
        }
      ],
      dateRange: {
        startDate: yesterdayx,
        endDate: yesterdayx
      },
      ranges: {
        'Today': [todayx, todayx],
        'Yesterday': [yesterdayx, yesterdayx],
        'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
        'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
        'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
        'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      },
      dateRangeDelta: {
        startDate: yesterdayx,
        endDate: yesterdayx
      },
      geoOs: [],
      geoOsSelected: 'All',
      ma_on_display: '',
      dd_from: 'ddd',
      dd_to: 'bbb',
      no_actions: true,
      selected_geo_os: ''
    }
  },
  async created() {
    this.page_loaded = false;
    await this.getData();
    this.fields = [
      {
        name: 'company',
        label: 'Company',
        sortable: true,
        toggle_column: 1,
        show_c: true,
        lock_column: false
      },
      {
        name: 'bundle_id',
        label: 'Bundle ID',
        sortable: true,
        toggle_column: 2,
        show_c: true,
        lock_column: false
      },
      {
        name: 'app_name',
        label: 'App Name',
        sortable: true,
        toggle_column: 3,
        show_c: true,
        lock_column: false
      },
      {
        name: 'os',
        label: 'OS',
        sortable: true,
        toggle_column: 4,
        show_c: true,
        lock_column: false
      },
      {
        name: 'last_update',
        label: 'Last Update',
        sortable: true,
        toggle_column: 5,
        show_c: true,
        lock_column: false
      }
    ];

    for (const field of this.fields) {
      this.show_col[field.toggle_column] = true;
    }

    this.page_loaded = true;
  },
  methods: {
    new_row() {
      console.log('new row');
      window.location = '/man/update-manual-revenue-edit?id=0';
    },
    async getData(date_from = null, date_to = null) {
      // Get id param from query string
      const r = await this.$http.post(this.resources.Dash.getPresets, {});
      const data = await r.json();
      console.log('data', data);
      this.dash_data = data.data;
      this.show_loader = false;
    },
    async updateData() {
      this.page_loaded = false;
      await this.getData(this.dateRange.startDate, this.dateRange.endDate);
      this.page_loaded = true;
    },
    onDatesChange(v) {
      let {
        from,
        to
      } = v
      this.form.date_from = from
      this.form.date_to = to
    },
    logEvent(x, y) {

    },
    geoOsChanged() {
      console.log('geoOsSelected', this.geoOsSelected);
      const selectRow = this.geoOs.find(x => x.os_geo === this.geoOsSelected);
      console.log('selectRow', selectRow);
      if (selectRow.ma !== '' && selectRow.os !== '') {
        this.ma_on_display = `MA: ${selectRow.ma}, OM: ${selectRow.om}`;
      } else {
        this.ma_on_display = ``;
      }
    },
    async dd(rr) {
      let cc = confirm('Are you sure you want to delete this row?');
      if (cc) {
        const r = await this.$http.post(this.resources.Dash.deletePreset, rr);
        await this.updateData();
      }
    }
  },
}
</script>
<style scoped>
th,
td {
  padding-right: 60px;
}

h1 {
  margin-bottom: 20px;
}

.sort-icon {
  position: absolute;
  display: inline-block;
  padding-left: 1%;
}

.clickable-th {
  cursor: pointer;
}

.daterangepicker-form {
  z-index: 1000 !important;
}

.dash_table {
  margin: 0 auto;
}

.dash_row {
  text-align: center;
}

.dash_cell {
  display: inline-block;
  padding: 15px;
  border: 1px solid lightgray;
  margin: 3px;
  border-radius: 4px;
}

.filter_div1 {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px;
  padding-right: 5px;
}

.filter_div2 {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px;
}

.select_style {
  padding: 6px;
  border: 1px solid lightgray;
}

.filter_div3 {
  display: inline-block;
  padding-left: 15px;
}

.blocks_div {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>