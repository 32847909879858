<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="padding-bottom: 20px;">
            <h1>OS Versions</h1>
            <div>
                <div v-for="os of os_data" style="padding-bottom: 10px;">
                    <h2>{{ os.dm_os_name }}</h2>
                    <div v-for="version of os.os_versions">
                        {{ version.dm_os_version }} <a href="javascript:void(0);" @click="deleteVersion(os.dm_os_name, version.dm_os_version)">Delete</a>
                    </div>
                    <div>
                        <a :href="'/apps/script-tags/data-manager-edit-os-version/os/' + os.dm_os_name + '?version=0'">Add Version</a>
                    </div>        
                </div>
            </div>
            <div style="display: none;">
                <a href="/apps/script-tags/data-manager-edit-os/0">Add OS</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            os_data: [],
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        async getData() {
            const data = await this.$http.post(this.resources.Management.getTgOsVersionsData, {})
            this.os_data = data.body.os_data
        },
        async deleteVersion(dm_os_name, dm_os_version) {
            const data = await this.$http.post(this.resources.Management.tgOsVersionDelete, {
                dm_os_name,
                dm_os_version
            })
            await this.getData()
        }
    },
}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.subs_textarea {
    resize: none;
    width: 100%;
    height: 400px;
}
</style>