<template lang="html">
  <div class="all">
    <table>
      <tr class='table-row'>
        <td></td>
        <th v-for='col in cols'>
          {{col.title}}
        </th>
      </tr>
      <tr v-for='row in rows' class='table-row'>
        <th>{{row.title}}</th>
        <td v-for='v in data[row.index]'>{{v}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    apiUrl: {
    },
    method: {}
  },
  data() {
    return {
      cols: {},
      rows: [],
      data: {}
    }
  },
  created() {
    this.$http[this.method](this.apiUrl)
      .then(r => r.json())
      .then(r => r.data)
      .then(r => Object.values(r)[0])
      .then(r => {
        this.cols = r.col_names
        let data = r.data
        this.rows = r.row_names
        this.data = {}
        let rowsList = this.rows.map(v => v.index)
        rowsList.forEach(v => {this.data[v] = []})
        rowsList.forEach(row => {
          Object.values(data).forEach(vs => {
            let v = vs[row]
            this.data[row].push(v)
          })
        })
      })
  }
}
</script> 

<style lang="css" scoped>
table {
  width: 100%;
  border: 1px solid gainsboro;
  border-bottom: 0;
}
.table-row {
  display: flex;
  border-bottom: 1px solid gainsboro;
  padding: 1px 0;
}
.table-row td, .table-row th {
  flex: 1;
  border-right: 1px solid gainsboro;
  padding-left: 9px;
}
.table-row td:last-child, .table-row th:last-child {
  border-right: 0;
}
</style>
