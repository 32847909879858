<template>
<div class="container mt-5">

    <div class="modal_container">
        <b-modal v-model="modalShow" class="domain_modal" :id="modalId" size="lg" @hide="onHide">
            <template id="bootstrap-overrides" #modal-header clas><span id="add-title" v-if="operation === 'add' ">Add new User:</span>
                <div class="edit-title" v-if="operation === 'edit' "><span id="space-header">
                        Make changes to:</span> <span id="modal-header-red" class="text-red"><b>{{text}}</b></span>
                </div>
            </template>

            <div class="modal-input-container">
                <label class="modal_label-md">Name</label>
                <input type="text" v-model="nameListener" class="def input-c-141 modal_input" :placeholder="operation === 'add' ? 'Enter name ' : currentUser.name " required>
                <span class="validation-sapn" v-if="msg.name && operation === 'add'">{{msg.name}}</span>
            </div>

            <div class="modal-input-container">
                <label class="modal_label-md">Email</label>
                <input type="text" v-model="emailListener" class="def input-c-141 modal_input" :class='{verified: msg.email === "" , unverified: msg.email !==""}' :placeholder="operation === 'add' ? 'Enter email ' : currentUser.email " required>
                <span class="validation-sapn" v-if="msg.email ">{{msg.email}}</span>
            </div>

            <div v-if="operation === 'add'" class="modal-input-container">
                <label class="modal_label-md">Password</label>
                <input type="text" v-model="passwordListener" class="def input-c-141 modal_input" :class='{verified: msg.password === "", unverified: msg.password !==""}' :placeholder="'Enter new password '" required>
                <span class="validation-sapn" v-if="msg.password && operation === 'add'">{{msg.password}}</span>
            </div>
            <div v-if="operation === 'edit'" class="modal-input-container">
                <label class="modal_label-md"> </label>
                <b-button id="change-pass-btn" variant="danger" @click="handleChangePassword">Change Password
                </b-button>
            </div>
            <div v-if="changePass" class="modal-input-container">
                <label class="modal_label-md">Password</label>
                <input type="text" v-model="passwordListener" class=" modal_input" :class='{verified: msg.password === "", unverified: msg.password !==""}' :placeholder="'Enter new password '" required>
                <span class="validation-sapn" v-if="msg.password && operation === 'edit'">{{msg.password}}</span>
            </div>
            <div class="modal-input-container">
                <label class="modal_label-md">Status</label>
                <button id="status_btn_modal" type="button" class="status-btn modal_input" :class='{active: status==="ACTIVE", paused: status==="PAUSED"}' @click="toggleEventListenersStatus(currentUser)">
                    {{status}}
                </button>
            </div>

            <div class="modal-input-container2">
                <label class="modal_label-md">Roles</label>
                <b-input-group>
                    <b-form-checkbox-group id="boxes" v-model="selectedRoles" :options="optionsRoles" value-field="item" text-field="name" name="boxes" stacked />
                </b-input-group>
            </div>

            <template class="footer-container" busy="true" v-slot:modal-footer>
                <b-button v-if="operation === 'edit'" id="modal-del-btn" variant="danger" @click="deleteUser(currentUser)">
                    Delete
                </b-button>
                <div v-if="operation === 'edit'" class="unseen-element"></div>
                <b-button v-if="operation === 'edit'" variant="success" @click="submitEditChanges(currentUser) ">
                    Save
                </b-button>
                <b-button v-if="operation === 'edit'" @click="cancel()">
                    Cancel
                </b-button>

                <div v-if="operation === 'add'">
                    <b-button type="submit" variant="primary" @click="submitAddChanges(currentUser)">Submit
                    </b-button>
                    <b-button @click="cancel()">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</div>
</template>

<script>
const DOMAIN_STATUS_ACTIVE = "ACTIVE"
const DOMAIN_STATUS_PAUSED = "PAUSED"

export default {
    props: {
        operation: String,
        currentUser: {},
        modalId: String,
        modalShow: Boolean,
        date: String,
        text: String,
        status: String,
        placeholderDate: String,
        placeholderComment: String,
        selectedRolesObj: {},
        usersList: [],
        forceUpdate: Number,
    },
    data() {
        return {
            show: true,
            loaded: false,
            index: 0,
            approvedStatusToggle: 0,
            permsList: [],
            selectedPermissions: [],
            optionsPermissions: [],
            selectedRoles: [],
            optionsRoles: [],
            nameListener: '',
            emailListener: '',
            passwordListener: '',
            givenRoles: [],
            sentRoleNames: [],
            users: null,
            update: this.forceUpdate,
            msg: [],
            changePass: false,
            saveStatus: this.status,
        }
    },
    beforeUpdate() {},
    created: async function () {
        if (this.operation === 'edit') {
            this.msg['email'] = '';
        }

        const rolesListObj = {};
        const rolesList = await this.$store.dispatch('getRolesList')
        rolesList.forEach(item => {
            this.optionsRoles.push(({
                name: item.name,
                item: item
            }));
            rolesListObj[item.id] = item;
        });
        this.selectedRoles = this.selectedRolesObj.map(sRole => rolesListObj[sRole.id]);
        this.loaded = true
    },
    watch: {
        emailListener(value) {
            this.emailListener = value;
            this.validateEmail(value);
        },
        passwordListener(value) {
            this.passwordListener = value;
            this.validatePassword(value);
        },
        nameListener(value) {
            this.nameListener = value;
            this.validateName(value);
        }
    },
    methods: {
        handleChangePassword() {
            this.changePass = !this.changePass
        },
        validatePassword(value) {
            let difference = 6 - value.length;
            if (value.length < 6) {
                this.msg['password'] = `Must be 6 characters! ${difference} characters left`;
            } else {
                this.msg['password'] = '';
            }
        },
        cancel() {
            this.$emit('update:status', this.saveStatus)
            this.$emit('update:modalShow', false)
        },
        toggleEventListenersStatus(user) {
            this.toggleStatus(user);
        },
        onHide(event) {
            if (event.trigger === "backdrop") {
                this.$emit('update:modalShow', false)
                event.preventDefault();
                this.handleBackdrop();
            }
        },
        onSubmit(event) {
            event.preventDefault()
        },
        onReset(event) {
            event.preventDefault()
            this.myComment = ''
            this.modalName = ''

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
        validateName(value) {
            if (this.nameListener.length > 0) {
                this.msg['name'] = ''
            } else {
                this.msg['name'] = 'Invalid User Name';
            }
        },
        validateEmail(value) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = 'Invalid Email Address';
            }
        },
        toggleStatus: async function (user) {
            let toStatus = this.status
            this.currentUser = {
                ...this.currentUser,
                status: toStatus
            }

            if (!this.userHasPermission("users")) {
                return
            }

            if (user.status === DOMAIN_STATUS_ACTIVE) {
                toStatus = DOMAIN_STATUS_PAUSED
            } else {
                toStatus = DOMAIN_STATUS_ACTIVE
            }

            user.status = toStatus
            if (this.operation == 'edit') {
                if (!window.confirm(
                        `Are you sure that you want to change user "${user.name}" status to: ${toStatus}?`
                    )) {
                    return
                }
            }
            this.$emit('update:status', toStatus)
        },

        fillValues(user) {
            if (this.nameListener != "")
                user.name = this.nameListener
            if (this.emailListener != "")
                user.email = this.emailListener
            if (this.passwordListener != "")
                user.password = this.passwordListener
        },
        resetValues() {
            this.nameListener = ""
            this.emailListener = ""
            this.passwordListener = ""
        },
        submitEditChanges: async function (user) {
            this.fillValues(user)

            let newUser = {
                ...user,
                Roles: this.selectedRoles
            }

            this.$emit('update:currentUser', newUser)
            this.$emit('update:modalShow', false)
            if (this.changePass && this.msg['password'] == '') {
                try {
                    await this.$http.put(this.resources.Admin.editPassword(user.id), {
                        password: this.passwordListener
                    })

                } catch (err) {
                    this.notifyError(`User ${user.name} password change failed!`)
                }
            } {
                try {
                    await this.$http.put(this.resources.Admin.editUser(user.id), newUser)
                    this.sentRoleNames = []
                    this.resetValues()
                } catch (err) {
                    this.notifyError(`User ${user.name} edit failed!`)
                }
            }

        },
        submitAddChanges: async function (user) {
            if (this.msg['email'] == '' && this.msg['password'] == '') {
                this.fillValues(user)

                let newUser = {
                    ...user,
                    Roles: this.selectedRoles
                }

                this.$emit('update:currentUser', newUser)
                this.$emit('update:modalShow', false)
                if (user.name != '' && user.email != '' && user.password != '') {
                    try {
                        await this.$http.post(this.resources.Admin.users, newUser)
                        this.sentRoleNames = []
                        this.resetValues()
                    } catch (err) {
                        this.notifyError(`User ${user.name} registration failed!`)
                    }
                } else {
                    this.notifyError("All form fields must be filled!")
                }
            } else {
                this.notifyError("Fields validation must pass!")
            }
        },
        deleteUser: async function (user) {
            let newUser = {
                ...user,
            }
            this.$emit('update:currentUser', newUser)
            this.$emit('update:modalShow', false)

            try {
                await this.$http.delete(this.resources.Admin.deleteUser(user.id))
                this.notifySuccess(`User ${user.name} was succesfully deleted`)

            } catch (err) {
                this.notifyError(`User ${user.name} deletion failed!`)
            }
        },
    }
}
</script>

<style scoped>
.modal_label-md {
    font-weight: bold;
    width: 30%;

}

#change-pass-btn {
    height: 22px;
    width: 200px;
    padding: 0rem 0rem;
    margin-bottom: 15px;
}

.validation-sapn {
    padding-top: 0px;
    margin-top: 0px;
    font-size: 10px;
    color: red;
    padding-left: 10px;
}

.text-red {
    color: red;
}

.modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 10px;
}

.modal-input-container {
    display: flex;
    flex-wrap: initial;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 15px;
}

.modal-input-container2 {
    display: flex;
    align-items: right;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

#space-header {
    padding-right: 10px;
}

.unverified {
    border-color: red;
    border-width: 2px;
    border-style: dashed;
}

.footer-container {
    width: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unseen-element {
    width: 75%;
}

.modal_input {
    width: 12.5rem;
}
</style>
