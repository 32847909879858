<template>
  <footer class="app-footer">
    <span><a href="http://apptimus.media">Apptimus Media</a> &copy; {{ new Date().getFullYear() }}.</span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer'
}
</script>
