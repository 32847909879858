<template lang="html">
  <div class="all">
  </div>
</template>

<script>
export default {
  props: {
    url: {}
  },
  created() {
    setTimeout(() => {
      window.location.href = this.url
    }, 20)
    this.$router.push('/')
  }
}
</script>

<style lang="css" scoped>
</style>
