import Vue from 'vue'
import { BootstrapVue,BSpinner , BIcon, BIconArrowUp, BIconArrowDown,IconsPlugin, BIconTrash , BTable,TablePlugin, BModal, VBModal, BForm, BAlert  } from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store'
import VueResource from 'vue-resource'
import Dropzone from 'dropzone'
import _buildUrl from 'build-url'
import { CoolSelectPlugin } from 'vue-cool-select'
import 'vue-cool-select/dist/themes/bootstrap.css'
window.setCookie = function(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
window.eraseCookie = function(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

window.deepClone = v => JSON.parse(JSON.stringify(v))

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

window.randStr = function(length, lengthMax) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   if(lengthMax) {
     length = getRandomInt(length, lengthMax)
   }
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

Array.prototype.toggle = function (value) {
  let i = this.indexOf(value)
  if (i > -1) {
    return this.splice(i, 1)
  }
  return this.push(value)
}


Object.prototype.nullify = function fn(obj = this) {
  for(let field in obj) {
    let val = obj[field]
    if(Array.isArray(val)) {
      obj[field] = []
    } else if(val && typeof val === 'object') {
      fn(val)
    } else {
      obj[field] = null
    }
  }
}
Object.defineProperty(Array.prototype, 'toggle', {
  enumerable: false
})
Object.defineProperty(Object.prototype, 'nullify', {
  enumerable: false
})
Vue.use(BootstrapVue)
Vue.use(TablePlugin)
Vue.use(IconsPlugin)

Vue.component('b-spinner', BSpinner)
Vue.component('BAlert', BAlert)
Vue.component('BModal', BModal)
Vue.component('BForm', BForm)
Vue.component('BIcon', BIcon)
Vue.component('BTable', BTable)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)
Vue.component('BIconTrash', BIconTrash)

Vue.use(VueResource)
Vue.use(require('vue-moment'));
Vue.use(CoolSelectPlugin)


Vue.http.options.root = process.env.VUE_APP_API
Vue.http.options.credentials = true
import resources from './resources'
Vue.http.resources = resources

import DatePicker from '@/components/shared/DatePicker'
import Card from '@/components/shared/Card'
import Table from '@/components/shared/Table'
// import Select from '@/components/shared/Select'
import DateRange from '@/components/shared/DateRange'
import Tabs from '@/components/shared/Tabs'
import FormField from '@/components/shared/FormField'
import CSV from '@/components/shared/CSV'
import Generate from '@/components/shared/Generate'
import CSVnGenerate from '@/components/shared/CSV&Generate'
import CheckList from '@/components/shared/CheckList'
import InputBox from '@/components/shared/InputBox'
import Checkbox from '@/components/shared/Checkbox'
import Spinner from '@/components/shared/Spinner'
import TableShowUntilDate from '@/components/shared/TableShowUntilDate'
import MoveLists from '@/components/shared/movelists/MoveLists'
import LineChart from '@/components/shared/LineChart'
import CrossTable from '@/components/shared/Cross-Table'
import Modal from '@/components/shared/Modal'
import ScreenBlocker from '@/components/shared/ScreenBlocker'
import LimitedTextarea from '@/components/shared/LimitedTextarea'

import vSelect from 'vue-select'
import vueValidations from './vue-validations'
import validations from './validations'

Vue.use(vueValidations)
Vue.setValidations(validations)

Vue.component('DatePicker', DatePicker)
Vue.component('FormField', FormField)
Vue.component('DateRange', DateRange)
Vue.component('Checkbox', Checkbox)
Vue.component('Tabs', Tabs)
Vue.component('Table', Table)
Vue.component('TableShowUntilDate', TableShowUntilDate)
Vue.component('Card', Card)
Vue.component('Spinner', Spinner)
Vue.component('CheckList', CheckList)
Vue.component('CSV', CSV)
Vue.component('Generate', Generate)
Vue.component('MoveLists', MoveLists)
Vue.component('CSVnGenerate', CSVnGenerate)
Vue.component('CrossTable', CrossTable)
Vue.component('InputBox', InputBox)
Vue.component('LimitedTextarea', LimitedTextarea)
Vue.component('Select', vSelect);
Vue.component('ScreenBlocker', ScreenBlocker);

Vue.component('Modal', Modal);

import { Line } from 'vue-chartjs'

Vue.component('line-chart-original', {
  extends: Line,
  props: ['data', 'options'],
  methods: {
    performRender() {
      this.renderChart(this.data, this.options)
    }
  },
  mounted () {
    this.performRender()
  },
  watch: {
    data() {
      this.performRender()
    }
  }
})
Vue.component('LineChart', LineChart)

Vue.filter('usersInCheckList', val => {
  if(!val) { return }
  let ob = {}
  val.forEach(e => {
    ob[e.id] = `${e.name} (${e.email})`
  })
  return ob
})

Vue.filter('seperateWords', str => {
  return str.replace(/([A-Z])/g, ' $1')
})
Vue.filter('cap', str => {
  return str[0].toUpperCase() + str.substr(1)
})

Vue.filter('numFormat', (v) => {
  console.log(v);
    let i = isNaN(v) ? 0 : v
    return(Number(i).toLocaleString())

})


let lastNotifyTimeout = 0

Vue.mixin({
  filters: {
    percentage(n) {
      if(isNaN(n)) {
        return 0
      }
      return n.toFixed(2) + '%'
    }
  },
  computed: {
    validations() {
      return this.$validations[this.$route.name]
    },
    user() {
      return store.state.user
    }
  },
  data() {
    return {
      resources: this.$http.resources,
      appState: store.state
    }
  },
  methods: {
    removeOverlapping(arr,r) {
      return arr.filter(v => !r.includes(v))
    },
    nameIDArrayToObject(arr) {
      let obj = {}
      arr.forEach(v => {
        obj[v.id] = v.name
      })
      return obj
    },
    userHasPermission(perm) {
      let userPerms = this.user.perms
      if(!userPerms) {
        return false
      }
      perm = perm.toLowerCase()

      return userPerms.includes(perm) || userPerms.includes("admin")
    },
    downloadFromUrl(url) {
      window.location.replace(url)
    },
    buildUrl() {
      if(!arguments[0]) {
        arguments[0] = process.env.VUE_APP_API
      }
      return _buildUrl.apply(null, arguments)
    },
    log: console.log,
    redirect(a) {
      this.$router.push(a)
    },
    getHtmlId() {
      return 'el-' + ++this.$store.state.elementId
    },
    organizeByOrder(placements, order) {
      if (!order || !placements) {
        return placements
      }
      order = order.split(',').map(v => Number(v.trim()))
      let orderedList = []
      order.forEach(nextID => {
        let p = placements.find(v => v.id === nextID)
        if (!p) {
          return
        }
        orderedList.push(p)
        placements.splice(placements.indexOf(p), 1)
      })
      orderedList.push.apply(orderedList, placements)
      return orderedList
    },
    calculateWeight(arr) {
      let totalWeight = 0

      arr.forEach(v => {
        totalWeight += v.weight
      })
      let dd = 100 / totalWeight
      return arr.map(v => {
        let d = Object.assign({}, v)
        let n = dd * v.weight
        n = n.toFixed(1)
        d.weight += ` (${n}%)`
        return d
      })
    },
    notifyError(msg, ms) {
      if(this.appState.notify.msg) {
        this.appState.notify.msg = null
        setTimeout(() => {
          this.notifyError(msg, ms)
        }, 200)
        return
      }

      let n = this.appState.notify.n + 1
      this.appState.notify = {
        error: true,
        msg: msg,
        n: n
      }
      if(!ms) {
        if(msg.length > 20) {
          ms = 3000
        } else {
          ms = 1500
        }
      }
      this.resetNotifyWithin(ms , n)
    },
    notifySuccess(msg, ms = 2000) {
      if(this.appState.notify.msg) {
        this.appState.notify.msg = null
        setTimeout(() => {
          this.notifySuccess(msg, ms)
        }, 200)
        return
      }

      let n = this.appState.notify.n + 1
      this.appState.notify = {
        error: false,
        msg: msg,
        n: n
      }
      this.resetNotifyWithin(ms, n)
    },
    resetNotifyWithin(ms, n) {
      window.clearTimeout(lastNotifyTimeout)
      let d = setTimeout(() => {
        if(this.appState.notify.n > n) {
          return
        }
        this.appState.notify = Object.assign({}, this.appState.notify, {slideAway: true})
        window.setTimeout(() => { this.appState.notify.msg = null }, 2000)
      }, ms)
      lastNotifyTimeout = d
    }
  }
})
Vue.http.interceptors.push((request, next) => {
  let token = localStorage.getItem('token')
  let userString = store.state.user && (store.state.user.name + " (" + store.state.user.id + ")")
  request.headers.set('Authorization', 'Bearer ' + token)
  if(userString) {
    request.headers.set('userid', userString)
  }
  next()
})

new Vue({
  el: '#app',
  router,
  template: '<App/>',
  render: e => e(App),
  store,
  components: {
    App
  }
})
