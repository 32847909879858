<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="padding-bottom: 20px;">
            <h3>DSPs</h3>
            <div>
                <div v-for="dsp of dsps" style="padding-bottom: 10px;">   
                    <div>{{ dsp.text }}</div>
                    <div><a :href="'/apps/script-tags/data-manager-edit-dsp/' + dsp.id">Edit</a> | <a href="javascript:void(0);" @click="deleteDsp(dsp.id)">Delete</a></div>
                </div>
            </div>
            <div>
                <a href="/apps/script-tags/data-manager-edit-dsp/0">Add DSP</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dsps: [],
        }
    },
    async created() {
        await this.getData()
    },
    methods: {
        async getData() {
            const data = await this.$http.post(this.resources.Management.getTgBaseData, {})
            this.dsps = data.body.dsps
        },
        async deleteDsp(id) {
            const r = confirm("Are you sure?")
            if (r) {
                const data = await this.$http.post(this.resources.Management.tgDeleteDsp, {
                  id
                })
                await this.getData()
            }
        }
    },
}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.subs_textarea {
    resize: none;
    width: 100%;
    height: 400px;
}
</style>