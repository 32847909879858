<template lang="html">
  <div class="all">
    <Placement :data='placement' :onSubmit='submit' :onDelete='onPlacementDelete' />
    <br>
    <hr>
    <section v-show='placement.offers.length'>
      <h5 >Offers</h5>
      <DataTable 
        :rows_data="offersWithWeightsTbl" 
        :onRowClick="onRowClick"
        :handleDeleteOffer="handleDeleteOffer"
        :deselect="deselect"
      />
    </section>

    <button :disabled="newVTAOfferDisplay" v-if='userHasPermission("smartlinks.edit")' type="button" class="btn btn-outline-primary" @click='newOfferDisplayClick(false)'>New Offer</button>
    <button :disabled="newOfferDisplay" v-if='userHasPermission("smartlinks.edit") && !vtaRoute' type="button" class="btn btn-outline-primary" @click='newOfferDisplayClick(true)'>New VTA Offer</button>
    <button v-if='userHasPermission("smartlinks.edit")' type="button" class="btn btn-outline-primary" @click='assocGlobalOfferDisplayClick = true'>Assoc Global Offer</button>
    <hr>
    <NewOffer v-if='newOfferDisplay' :onAddOffer="handleNewOffer" :isVta="!vtaRoute ? false : true"/>
    <NewOffer v-if='newVTAOfferDisplay' :onAddOffer="handleNewOffer" :isVta="true"/>
    <GlobalOfferAssocModal :isShow.sync='assocGlobalOfferDisplayClick' @shouldUpdate="fetchOffers()" :placement="placement" :isVtaRoute="vtaRoute"></GlobalOfferAssocModal>
    
    <router-view></router-view>
  </div>
</template>

<script>
import Placement from '../components/Placement'
import NewOffer from '../NewOffer.smartlinks'
import OfferView from './Offer.smartlinks'
import utils from '../utils/utils.smartlinks.js'
import GlobalOfferAssocModal from '../../../../components/AssocGlobalOfferToAdSetModal.vue' 
import DataTable from '../components/DataTable.vue'

export default {
  components: {Placement, NewOffer, OfferView, GlobalOfferAssocModal, DataTable},
  data() {
    return {
      shouldUpdate: false, 
      assocGlobalOfferDisplayClick: false,
      placement: null,
      newOfferDisplay: false,
      newVTAOfferDisplay: false,
      campID: null,
      placementID: null,
      displayOffer: null,
      offers: null,
      vtaRoute: false,
      deselect: ''
    }
  },
  created() {
    let {campId, placementId} = this.$route.params
    this.campID = campId
    this.placementID = placementId
    let placements = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements
    this.placement = placements.find(v => v.id == placementId)
    this.offers = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId).offers
    const route = this.$route.fullPath
    if(route.includes("VTA")) {
      this.vtaRoute = true
    }
  },
  computed: {
    offerId() {
      return this.$route.params.offerId
    },
    offersWithWeights() {
      let offers = this.addCommas(this.placement.offers)
      return this.calculateWeight(offers)
    },
    offersWithWeightsTbl() {
      let tArr = [];
      for (let k in this.placement.offers) {
        let t = {...this.placement.offers[k]}
        if (t.click_offer !== null) {
          t.name = t.click_offer.name
          t.is_vta = "true"
        } else {
          t.is_vta = "false"
        }

        tArr.push(t);
      }
      tArr = this.addCommas(tArr)
      tArr = this.addPrecentage(tArr)
      return this.calculateWeight(tArr)
    }
  },
  methods: {
    async fetchOffers() {
      const data = await this.$http.get(this.resources.SmartLinksCampaigns.getOffers(this.campID, this.placementID))
      if (data.body) {
          if (data.body.length > 1) {
            data.body.forEach(offer => {
              if (offer.is_new_pid === '1') {
                offer.list_id = "NEW_PID_" + offer.list_id
              }
            })
            this.placement.offers = data.body

          } else {
            this.handleNewOffer(data.body[0])
          }
      }
    },
    addPrecentage(offers) {
      for (let i in offers) {
        let df = offers[i].delivery_frq
        if (df !== 0) {
           offers[i].delivery_frq = `${offers[i].delivery_frq}%`
        }
      }
      return offers
    },
    addCommas(offers){
      for (let i in offers) {
        let cap = offers[i].cap
        if (cap !== 0) {
           offers[i].cap = cap.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
      return offers

    },
    handleNewOffer(offer) {
      let {campId, placementId} = this.$route.params
      let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
      if (placement.offers.length === 0){
          if (offer.is_new_pid === '1') {
            offer.list_id = "NEW_PID_" + offer.list_id
          }
          placement.offers.push(offer)
      } else {
        this.offers.push(offer)
      }
    },
    async handleDeleteOffer(offerId){
      let {campId, placementId} = this.$route.params

      try {
        if (typeof offerId !== 'undefined') {
          await this.$http.delete(this.resources.SmartLinksCampaigns.deleteOfferByOfferID(campId,placementId,offerId))
          this.notifySuccess(`Offer ${offerId} successfully deleted`)
          // clean state from deleted offer after delete 
          this.offers.splice(this.offers.findIndex((i) => {
            return i.id === offerId;
          }), 1);

        }
      }
      catch (err) {
        this.notifyError(err)
      }
    },
    onPlacementDelete() {
      const route = this.$route.fullPath
      let {campId} = this.$route.params
      this.$http.delete(this.resources.SmartLinksCampaigns.deletePlacement(campId, this.placement.id))
      .then(() => {
        let placements = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements
        placements.splice(placements.indexOf(this.placement), 1)
        this.$router.push({
          name: route.includes("VTA") ? "SmartLinksVTAId" :"SmartLinksId",
          params: {
            campId: campId
          }
        })
      })
      .catch(err => {
        this.notifyError("Error occured. Could not delete ad set")
      })
    },
    newOfferDisplayClick(isVta) {
      this.deselect = Math.random()

      if (isVta) {
        this.newVTAOfferDisplay = !this.newVTAOfferDisplay
      } else {
        this.newOfferDisplay = !this.newOfferDisplay
        
      }
     
      if(this.offerId) {
        const route = this.$route.fullPath
        this.$router.push({ name:  route.includes("VTA") ? "SmartLinksVTAOfferId" : "SmartLinksPlacementEdit"})
      }
    },
    isRowActive(v) {
      return v.id == this.offerId
    },
    onRowClick(v) {
      this.newOfferDisplay = false
      this.newVTAOfferDisplay = false
      const route = this.$route.fullPath
      this.$router.push({ name: route.includes("VTA") ? "SmartLinksVTAOfferId": "SmartLinksOfferId", params: {offerId: v.id}})
      this.newOfferDisplay = false
    },
    submit(form) {
      if (form.isps !== null && form.isps.length !== 0) {
        for (const k in this.offers) {
          if (this.offers[k].isps !== null && this.offers[k].isps.length !== 0) {
            window.confirm('Pay attention that ISP Targeted in offer level');
            break;
          }
        }
      }

      let {campId, placementId} = this.$route.params
      form.camp_id = parseInt(campId)
      this.$http.put(this.resources.SmartLinksCampaigns.updatePlacement(campId, placementId), form)
        .then(() => {
          this.notifySuccess("Placement has been updated")
          form = utils.formPlacement(form)
          const offers = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId).offers
         // Take the offers from the state rather than the old version in state form
          form.offers = this.placement.offers
          Object.assign(this.placement, form)
        })
        .catch(err => {
          this.notifyError("Error has occured")
        })
    },
    calculateWeight(arr) {
      let totalWeight = 0

      arr.forEach(v => {
        totalWeight += v.weight
      })
      let dd = 100 / totalWeight
      return arr.map(v => {
        let d = Object.assign({}, v)
        let n = dd * v.weight
        n = n.toFixed(1)
        d.weight += ` (${n}%)`
        return d
      })
    },
  }
}
</script>

<style>
.table-12141 .fields > li:nth-child(1), .table-12141 .table-row > li:nth-child(1) {
  max-width: 10ch;
}
.table-12141 .fields > li:nth-child(2), .table-12141 .table-row > li:nth-child(2) {
  max-width: 8ch;
}
.table-12141 .fields > li:nth-child(3), .table-12141 .table-row > li:nth-child(3) {
  max-width: 15ch;
}
.table-12141 .fields > li:nth-child(4), .table-12141 .table-row > li:nth-child(4) {
  max-width: 15ch;
}
.table-12141 .fields > li:nth-child(5), .table-12141 .table-row > li:nth-child(5) {
  max-width: 7ch;
}
.table-12141 .fields > li:nth-child(6), .table-12141 .table-row > li:nth-child(6) {
  max-width: 45ch;
}
.table-12141 .fields > li:nth-child(7), .table-12141 .table-row > li:nth-child(7) {
  max-width: 45ch;
}
.table-12141 .fields > li:nth-child(8), .table-12141 .table-row > li:nth-child(8) {
  max-width: 15ch;
}
.table-12141 .fields > li:nth-child(9), .table-12141 .table-row > li:nth-child(9) {
  max-width: 7ch;
}
.table-12141 .fields > li:nth-child(10), .table-12141 .table-row > li:nth-child(10) {
  max-width: 7ch;
}
.table-12141 .fields > li:nth-child(11), .table-12141 .table-row > li:nth-child(11) {
  max-width: 7ch;
}

@media (max-width: 1600px) {
  .table-12141 .fields > li:nth-child(2), .table-12141 .table-row > li:nth-child(2) {
    max-width: 10ch;
  }
  .table-12141 .fields > li:nth-child(3), .table-12141 .table-row > li:nth-child(3) {
    max-width: 15ch;
  }
  .table-12141 .fields > li:nth-child(4), .table-12141 .table-row > li:nth-child(4) {
    max-width: 15ch;
  }
  .table-12141 .fields > li:nth-child(5), .table-12141 .table-row > li:nth-child(5) {
    max-width: 8ch;
  }
  .table-12141 .fields > li:nth-child(6), .table-12141 .table-row > li:nth-child(6) {
    max-width: 20ch;
  }
  .table-12141 .fields > li:nth-child(7), .table-12141 .table-row > li:nth-child(7) {
    max-width: 20ch;
  }
  .table-12141 .fields > li:nth-child(8), .table-12141 .table-row > li:nth-child(8) {
    max-width: 15ch;
  }
  .table-12141 .fields > li:nth-child(9), .table-12141 .table-row > li:nth-child(9) {
    max-width: 7ch;
  }
  .table-12141 .fields > li:nth-child(10), .table-12141 .table-row > li:nth-child(10) {
    max-width: 7ch;
  }
  .table-12141 .fields > li:nth-child(11), .table-12141 .table-row > li:nth-child(11) {
    max-width: 7ch;
  }
}

@media (min-width: 1850px) {
  .table-12141 .fields > li:nth-child(2), .table-12141 .table-row > li:nth-child(2) {
    max-width: 10ch;
  }
  .table-12141 .fields > li:nth-child(3), .table-12141 .table-row > li:nth-child(3) {
    max-width: 15ch;
  }
  .table-12141 .fields > li:nth-child(4), .table-12141 .table-row > li:nth-child(4) {
    max-width: 12ch;
  }
  .table-12141 .fields > li:nth-child(5), .table-12141 .table-row > li:nth-child(5) {
    max-width: 8ch;
  }
  .table-12141 .fields > li:nth-child(6), .table-12141 .table-row > li:nth-child(6) {
    max-width: 55ch;
  }
  .table-12141 .fields > li:nth-child(7), .table-12141 .table-row > li:nth-child(7) {
    max-width: 55ch;
  }
  .table-12141 .fields > li:nth-child(8), .table-12141 .table-row > li:nth-child(8) {
    max-width: 15ch;
  }
  .table-12141 .fields > li:nth-child(9), .table-12141 .table-row > li:nth-child(9) {
    max-width: 7ch;
  }
  .table-12141 .fields > li:nth-child(10), .table-12141 .table-row > li:nth-child(10) {
    max-width: 7ch;
  }
  .table-12141 .fields > li:nth-child(11), .table-12141 .table-row > li:nth-child(11) {
    max-width: 7ch;
  }
}
</style>
<style scoped>
  .edit-actions-link {
    margin-top: 8px;
    display: block;
  }

  .offers_tbl {
    width: 100%;
  }

table, th, td {
  border: 1px solid black;
  resize: both; 
  overflow: auto;
}
</style>
