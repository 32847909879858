<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td>
                            AppsFlyer Acount Name
                        </td>
                        <td>
                            24H Click Cap (0 = infinite)
                        </td>
                        <td>
                            24H Notification Click Cap (0 = no notification)
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.dm_appsflyer_name }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="editRow.dm_appsflyer_name" placeholder="AppsFlyer Acount Name" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.dm_appsflyer_click_cap | numFormat }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="editRow.dm_appsflyer_click_cap" placeholder="Click Cap" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.dm_appsflyer_notification_click_cap | numFormat}}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="editRow.dm_appsflyer_notification_click_cap" placeholder="Notification Click Cap" />
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRowf(row.id)">Edit</a> <a class="btn btn-primary" href="javascript:void(0);" @click="deleteEntity(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.dm_appsflyer_name" placeholder="AppsFlyer Acount Name" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.dm_appsflyer_click_cap" placeholder="Click Cap" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.dm_appsflyer_notification_click_cap" placeholder="Notification Click Cap" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
        <div class="link-div">
            <a href="/man/af-data">AF Traffic Monitor</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list_id: this.$route.params.id,
            newRow: {
                id: 0,
                dm_appsflyer_name: "",
                dm_appsflyer_click_cap: 0,
                dm_appsflyer_notification_click_cap: 0
            },
            editRow: {
                id: 0,
                dm_appsflyer_name: "",
                dm_appsflyer_click_cap: 0,
                dm_appsflyer_notification_click_cap: 0
            },
            rows: [],
            showNewRow: true,
            list_data: {
                list_name: "",
                data_to_send: "",
            },
            showEditListName: false,
            placement_links: [],
            isAdmin: false,
            showLegend: false
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    filters: {
        numFormat(v) {
            let i = isNaN(v) || !isFinite(v) ? 0 : v
            return (Number(i).toLocaleString())
        }
    },
    watch: {
        ['newRow.dm_appsflyer_click_cap'](newValue) {
          const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.$set(this.newRow,'dm_appsflyer_click_cap' ,result);
        }, 
        ['newRow.dm_appsflyer_notification_click_cap'](newValue) {
          const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.$set(this.newRow,'dm_appsflyer_notification_click_cap' ,result);
        }, 
        ['editRow.dm_appsflyer_click_cap'](newValue) {
            const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.$set(this.editRow,'dm_appsflyer_click_cap' ,result);
        },
        ['editRow.dm_appsflyer_notification_click_cap'](newValue) {
            const result = newValue.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.$set(this.editRow,'dm_appsflyer_notification_click_cap' ,result);
        }
    },
    methods: {
        async save(e) {
            e.preventDefault();
            let dm_appsflyer_click_capInt = this.newRow.dm_appsflyer_click_cap.toString().replace(/[,]*/g, '')
            let dm_appsflyer_notification_click_capInt = this.newRow.dm_appsflyer_notification_click_cap.toString().replace(/[,]*/g, '')
            let validationMsg = await this.validateRowData(this.newRow.dm_appsflyer_name, parseInt(dm_appsflyer_click_capInt), parseInt(dm_appsflyer_notification_click_capInt));
            if (validationMsg != "") {
                this.notifyError(validationMsg);
                this.getListData();
                return;
            }

            const rowReq = this.newRow
            rowReq.dm_appsflyer_click_cap = parseInt(dm_appsflyer_click_capInt)
            rowReq.dm_appsflyer_notification_click_cap = parseInt(dm_appsflyer_notification_click_capInt)

            const data = await this.$http.post(this.resources.Management.savAfRow, {
                ...this.newRow
            });
            this.newRow.dm_appsflyer_name = "";
            this.newRow.dm_appsflyer_click_cap = "";
            this.newRow.dm_appsflyer_notification_click_cap = "";
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getAfData, {});
            for (let k in data.body.data) {
                data.body.data[k].edit = false
            }
            this.rows = data.body.data
        },
        async deleteEntity(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteAfRow, {
                id,
            });
            this.getListData();
        },
        async editRowf(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.editRow.dm_appsflyer_name = this.rows[k].dm_appsflyer_name
                    this.editRow.dm_appsflyer_click_cap = this.rows[k].dm_appsflyer_click_cap
                    this.editRow.dm_appsflyer_notification_click_cap = this.rows[k].dm_appsflyer_notification_click_cap
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    editRowData = this.rows[k];
                }
            }
            //parse to int
            editRowData.dm_appsflyer_name = this.editRow.dm_appsflyer_name
            let dm_appsflyer_click_capInt = this.editRow.dm_appsflyer_click_cap.toString().replace(/[,]*/g, '')
            let dm_appsflyer_notification_click_capInt = this.editRow.dm_appsflyer_notification_click_cap.toString().replace(/[,]*/g, '')
            let validationMsg = await this.validateRowData(this.editRow.dm_appsflyer_name, parseInt(dm_appsflyer_click_capInt), parseInt(dm_appsflyer_notification_click_capInt));
            if (validationMsg != "") {
                this.notifyError(validationMsg);
                return;
            } 
            this.editRow = {
                id: 0,
                dm_appsflyer_name: "",
                dm_appsflyer_click_cap: 0,
                dm_appsflyer_notification_click_cap: 0
            }
            this.showNewRow = true;
            editRowData.dm_appsflyer_click_cap = parseInt(dm_appsflyer_click_capInt)
            editRowData.dm_appsflyer_notification_click_cap = parseInt(dm_appsflyer_notification_click_capInt)
            const data = await this.$http.post(this.resources.Management.savAfRow, {
                ...editRowData
            });
            this.getListData()
            
        },
        async changeDataToSend(e) {
            this.list_data.data_to_send = e.target.value;
            await this.updateListData();
        },
        async updateListData() {
            const data = await this.$http.post(this.resources.Management.saveBundleList, {
                id: parseInt(this.list_id),
                list_name: this.list_data.list_name,
                data_to_send: this.list_data.data_to_send,
            });
            this.showEditListName = false;
        },
        editListName() {
            this.showEditListName = true;
        },
        async duplicateList() {
            const data = await this.$http.post(this.resources.Management.duplicateBundleList, {
                id: parseInt(this.list_id)
            });
            window.location = "/man/bundle-pool-edit/" + data.body.id;
        },
        bulkUpload() {
            console.log("bulkUpload");
            window.location = "/man/bundle-pool-bulk/" + this.list_id;
        },
        calculatePercentage(arr) {
            let totalWeight = 0;
            for (let k in arr) {
                totalWeight += parseInt(arr[k].weight);
            }
            for (let k in arr) {
                arr[k].weight = arr[k].weight;
                arr[k].percent = ((parseInt(arr[k].weight) / totalWeight) * 100).toFixed(2) + "%";
            }
            return arr;
        },
        async validateRowData(dm_appsflyer_name, dm_appsflyer_click_cap, dm_appsflyer_notification_click_cap) {
            if (!this.validateNumber(dm_appsflyer_click_cap)) {
                return "Click cap is not a number";
            }
            if (!this.validateNumber(dm_appsflyer_notification_click_cap)) {
                return "Click cap is not a number";
            }
            if (dm_appsflyer_name == "") {
                return "Account name can't be empty";
            }
            if (!this.validateStr(dm_appsflyer_name)) {
                return "Please enter a valid bundle id";
            }
            return "";
        },
        validateStr(str) {
            const re = /([A-Za-z0-9-_]+)/;
            if (re.test(str)) {
                return true;
            }
            return false;
        },
        validateNumber(str) {
            const re = /^\d+$/; 
            if (re.test(str)) {
                return true;
            }
            return false;
        }
    },
    components: {  }
}
</script>

<style scoped>
.link-div {
    padding-top: 40px;
    text-align: center;
}
</style>
