<template lang="html">
  <div class="" v-if='loaded'>
    <h1>Camp "{{campName}}"</h1>
    <br>
    <h5>Links</h5>

    <Table
    tableClass="table-12341"
    :search='false'
    :apiUrl='this.resources.ScriptTags.campLinks(campId)'
    :data='links'
    :draggable='true'
    :onOrderUpdate='onOrderUpdate'
    :autoFetch='false'
    :fields='{name: "Name", url: "URL"}'
    :permissions='{}'
    :askBeforeDelete='1'
     />

    <br>

    <br>
    <form class="" @submit.prevent='' style="display: none;">
      <div class="fields-241">

        <div class="field-5121">
          <label >DSP</label>
          <div class="vals-options-5121">
            <select v-model='form.dsp'>
              <option v-for='val in relevantDspOptions' :value='val'>{{val}}</option>
            </select>
            </div>
        </div>

        <div class="field-5121" v-show='form.dsp === "Bucksense"'>
          <label >OS</label>
          <div class="vals-options-5121">
            <select v-model='form.os'>
              <option v-for='val in osOptions' :value='val'>{{val}}</option>
            </select>
            </div>
        </div>

        <div class="field-5121">
          <label >SSP</label>
          <div class="vals-options-5121">
            <select v-model='form.ssp'>
              <option v-for='val in relevantSspOptions' :value='val'>{{val}}</option>
            </select>
            </div>
        </div>

        <div class="field-5121">
          <label >Traffic Type</label>
          <div class="vals-options-5121">
            <select v-model='form.trafficType'>
              <option v-for='val in relevantTrafficTypeOptions' :value='val'>{{val}}</option>
            </select>
            </div>
        </div>

        <div class="get-tag-btn">
          <button type="button" @click='generateTag' class="btn btn-primary">Generate Tag</button>
        </div>

        <hr>
        <div class="link-23" v-show='displayResultTag'>
          <span class="c-12345">
            Script Tag:
          </span>
          <input @focus='onSystemLinkFocus' class="c-231 c-6" :value='resultTag' readonly>
          </input>
        </div>
      </div>
    </form>

    <hr>
    <button style="display: none;" v-if='userHasPermission("stags.all.view")' class="btn btn-default" type="button" @click='e=>displayTagsDebugging=!displayTagsDebugging'>View Additional Info</button>
    <div v-if='displayTagsDebugging' class="debug-stags-container">
      <div class="link-23">
        <span class="c-12345">
          Script Tag:
        </span>
        <input @focus='onSystemLinkFocus' class="c-231" :value='systemCampTag' readonly>
        </input>
      </div>

      <div class="link-23">
        <span class="c-12345">
          Script Link:
        </span>
        <input @focus='onSystemLinkFocus' class="c-231" :value='systemCampLink' readonly>
        </input>
      </div>


      <div class="form-section">
        <FormField :inline='1' :def='0' placeholder="Class" labelText='Class'>
          <select v-model='selectedVersionIndex' class="def select-234">
            <option v-for='x, i in stagVersions' :value='i'>{{x.title}}</option>
          </select>
        </FormField>

        <FormField :inline='1' :def='0' placeholder="Domain" labelText='Domain'>
          <select v-model='tagGenerateForm.domain' class="def select-234">
            <option v-for='option in domainOptions' :value='option'>{{option}}</option>
          </select>
        </FormField>

        <FormField :inline='1' :def='0' placeholder="DSP" labelText='DSP'>
          <select v-model='tagGenerateForm.dsp' class="def select-234">
            <option v-for='option, t in dspOptions' :value='option'>{{t}}</option>
          </select>
        </FormField>


        <div class="tag-unique-id-container">
          <label  class="t-label-2512">Tag Unique ID: </label>
          <input class="def" readonly :value='stag.random_id'>
          <button v-if='userHasPermission("stags.regenrate_id")' type="button"
          class="btn btn-warning regenerate-tagid-btn" @click='regenerateTagID'>Regenrate Tag ID</button>
        </div>

        <hr>

        <button type="button" class="btn" @click='e=>tagTest=true' v-if='userHasPermission("stags.test-tag") && !tagTest'>Test Tag</button>

        <div class="" v-show='tagTest'>
          <label for="c-251254">Skip Blocking</label>
          <input id="c-251254" type="checkbox" v-model='tagTestForm.skipBlocking'>
          <button type="button" class="btn" @click='runTag'>Run tag</button>
        </div>
        <div class="" id="e29a2">
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import fn from '../fn.stags.js'

export default {
  data() {
    let stagVersions = this.$store.state.stags.versions
    let store = this.$store.state
    return {
      resultTag: null,
      displayTagsDebugging: false,
      displayResultTag: false,
      domainFlow: store.stags.domainFlow,
      form: {
        dsp: "",
        ssp: "",
        os: "",
        trafficType: ""
      },
      dspOptions2: ["Apptimus", "Bucksense", "Kayzen", "MSM"],
      sspOptions: ["Fyber", "Mopub", "New SSP TEST", "Non Mopub", "Appodeal", "Opera", "Loopme", "Other", "Pubnative"],
      osOptions: ["iOS", "Android"],
      trafficTypeOptions: ["RON", "Sport", "WL"],
      tagTestForm: {
        skipBlocking: false
      },
      tagTest: false,
      selectedVersionIndex: 0,
      stag: null,
      links: [],
      loaded: false,
      systemLinkTemplate: process.env.VUE_APP_SCRIPT_TAGS_SYSTEM_LINK,
      tag_id: "",
      dspOptions: {
        Apptimus:	"{PREFIX}1={clickid}&{PREFIX}2=66_{app_id}&{PREFIX}4={bundle_id}&{PREFIX}5={idfa}&{PREFIX}6={camp_id}&{PREFIX}7={campaign}&{PREFIX}8={exchange_name}&{PREFIX}3={app}&{PREFIX}9={gps_lat}&{PREFIX}10={gps_lon}",
        Spotad:	"{PREFIX}1=**clickId**&{PREFIX}2=55_**app_id**&{PREFIX}4=**bundle_id**&{PREFIX}5=**ifa**&{PREFIX}6={camp_id}&{PREFIX}7=**campaign_id**&{PREFIX}8=**exchange**&{PREFIX}3=**app_name_enc**",
        MediaSmart:	"{PREFIX}1=%impressionid%&{PREFIX}2=44_%placement_id%&{PREFIX}4=%bundle%&{PREFIX}5=%udid%&{PREFIX}6={camp_id}&{PREFIX}7=%campaign_name%&{PREFIX}8=%exchange%&{PREFIX}3=%appname%",
        "Bucksense-iOS":	"{PREFIX}1=%%SESSION_BID_ID%%&{PREFIX}2=33_%%PLACE_ID%%&{PREFIX}4=%%APP_BUNDLE_ENC%%&{PREFIX}5=%%DEVICE_IOS_IFA%%&{PREFIX}6={camp_id}&{PREFIX}7=%%CAMPAIGN_ID%%&{PREFIX}8=%%EXCHANGE_ID%%&{PREFIX}3=%%APP_NAME_ENC%%&{PREFIX}9=%%DEVICE_LAT%%&{PREFIX}10=%%DEVICE_LON%%",
        "Bucksense-Android": "{PREFIX}1=%%SESSION_BID_ID%%&{PREFIX}2=33_%%PLACE_ID%%&{PREFIX}4=%%APP_BUNDLE_ENC%%&{PREFIX}5=%%DEVICE_ANDROID_IFA%%&{PREFIX}6={camp_id}&{PREFIX}7=%%CAMPAIGN_ID%%&{PREFIX}8=%%EXCHANGE_ID%%&{PREFIX}3=%%APP_NAME_ENC%%&{PREFIX}9=%%DEVICE_LAT%%&{PREFIX}10=%%DEVICE_LON%%",
        Kayzen: "{PREFIX}1={CONVERSION_ID}&{PREFIX}2=11_{APP_ID}&{PREFIX}4={APP_BUNDLE}&{PREFIX}5={DEVICE_PLATFORM_ID}&{PREFIX}6={camp_id}&{PREFIX}7={CAMPAIGN_ID}&{PREFIX}8={EXCHANGE_NAME}&{PREFIX}3={APP_NAME_ALPHANUM}&{PREFIX}9={GPS_LAT_BID}&{PREFIX}10={GPS_LON_BID}",
        MSM: "{PREFIX}1=%impressionid%&{PREFIX}2=44_%placement_id%&{PREFIX}4=%bundle%&{PREFIX}5=%udid%&{PREFIX}6={camp_id}&{PREFIX}7=%campaign_name%&{PREFIX}8=%exchange%&{PREFIX}3=%appname%"
      },
      domainlist: null,

      stagVersions,
      tagGenerateForm: {
        domain: null,
        dsp: null
      }
    }
  },
  mixins: [fn],
  watch: {
    ['tagGenerateForm.domain']() {
      this.updateTagID()
    },
    ['tagGenerateForm.dsp']() {
      this.updateTagID()
    },
    ['selectedVersionIndex']() {
      let version = this.stagVersions[this.selectedVersionIndex]
      this.tagGenerateForm.domain = version.domains[0]
    }
  },
  computed: {
    relevantDspOptions() {
      return this.dspOptions2
    },
    relevantSspOptions() {
      let {dsp} = this.form
      let trafficType  = ""
      let v = this.sspOptions.filter(ssp =>
        this.domainFlow.filter(v => (!dsp || v.dsp.includes(dsp) && (!trafficType || v.trafficType === trafficType))).map(v => v.ssp).find(v => v === ssp)
      )
      return v
    },
    relevantTrafficTypeOptions() {
      let {dsp, ssp} = this.form
      let v = this.trafficTypeOptions.filter(t =>
        this.domainFlow.filter(v => (!dsp || v.dsp.includes(dsp) && (!ssp || v.ssp === ssp))).map(v => v.trafficType).find(v => v === t)
      )
      return v
    },
    domainOptions() {
      let version = this.stagVersions[this.selectedVersionIndex]
      let a = version.domains.slice(0)
      if(process.env.NODE_ENV === "development") {
        a.unshift("development")
      }

      return a
    },
    campId() {
      return this.$route.params.campId
    },
    campName() {
      return this.stag.externalID
    },
    systemCampLink() {
      // let l = this.systemLinkTemplate

      let domain = this.tagGenerateForm.domain
      let domainAndScheme
      if(domain == "development") {
        domainAndScheme = "http://localhost:9898"
      } else {
        domainAndScheme = "https://" + domain
      }

      let version = this.stagVersions[this.selectedVersionIndex]
      let dspQueryPart = this.tagGenerateForm.dsp
      dspQueryPart = dspQueryPart.replace("&{PREFIX}6={camp_id}", "")
      dspQueryPart = dspQueryPart.replaceAll("{PREFIX}", version.prefix)
      let l = domainAndScheme + version.path + "&" + dspQueryPart
      l = l.replaceAll("{camp_id}", this.stag.id)
      l = l.replaceAll("{tid}", this.tag_id)

      return this.shuffleQueryStringKeys(l)
    },

    systemCampTag() {
      let src = this.systemCampLink
      let version = this.stagVersions[this.selectedVersionIndex]
      if(version.title === "Testing" || version.title === "V7") {
          return `<div id="${this.tag_id}"></div>
          <script id="${this.tag_id}-S">
              var d = document;
              var id = "${this.tag_id}-S";
          	  var t = new Date().getTime();
              var url = '${src}&rt=' + t;
              var s = d.createElement('script');
              s.src = url;
              var cd = d.getElementById(id);
              cd.parentElement.insertBefore(s, cd);
          <\/script>
          `.split("\n").map(v=>v.trim()).join("")
      }
      return `\<div id="${this.tag_id}"></div><script src="${src}"><\/script>`
    }
  },
  created() {
    this.updateTagID()
    this.tagGenerateForm.domain = this.stagVersions[0].domains[0]
    this.tagGenerateForm.dsp = this.dspOptions[Object.keys(this.dspOptions)[0]]

    let campId = Number(this.campId)

    this.fetchStags()
      .then(stags => {
        let stag = stags.find(v => v.id === campId)
        if(!stag) {
          return
        }
        this.stag = stag
        let links = stag.pixels
        if(!links) { links = [] }
        let orderedLinks = this.orderItems(links, stag.linksOrder)
        stag.links = orderedLinks
        this.links = orderedLinks
        this.loaded = true
      })
      this.getDomainList();


  },
  methods: {
    getDomainList: async function () {
      var flag = false
      try {
        const r = await this.$http.get(this.resources.Domains.getDomains, {
            params: {
                start_date: null,
                end_date: null
            }
        })
      } catch (err) {
          if (flag)
              return
          else
              this.notifyError("Could not fetch domains")
      }

    },
    shuffleQueryStringKeys(url) {
      if(typeof url !== "string") { return url }
      let queryStringIndex = url.indexOf("?")
      if(queryStringIndex === -1) {
        return url
      }
      let queryString = url.substr(queryStringIndex +  1)
      let keyValues = queryString.split("&")
      keyValues.sort((a,b) => Math.random() > Math.random() ? 1 : -1)
      return url.substr(0, queryStringIndex + 1) + keyValues.join("&")
    },
    regenerateTagID() {
      if(!window.confirm("Are you sure that you want to regenerate this tag id?")) {
        return
      }
      this.$http.post(this.resources.ScriptTags.regenerateTagID(this.campId))
        .then(r => r.json())
        .then(id => {
          this.notifySuccess("Successfully regenerated id")
          this.stag.random_id = id
        })
        .catch(err => {
          this.notifyError(err)
        })
    },
    getSystemCampLink() {
      let domain = this.tagGenerateForm.domain
      let domainAndScheme
      if(domain == "development") {
        domainAndScheme = "http://localhost:9898"
      } else {
        domainAndScheme = "https://" + domain
      }

      let version = this.stagVersions[this.selectedVersionIndex]
      let dspQueryPart = this.tagGenerateForm.dsp.replaceAll("{PREFIX}", version.prefix)
      let l = domainAndScheme + version.path + "&" + dspQueryPart
      l = l.replaceAll("{camp_id}", this.$route.params.campId)
      l = l.replaceAll("{tid}", this.tag_id)
      return l
    },
    getSystemCampTag() {
      let src = this.getSystemCampLink()
      return `\<div id="${this.tag_id}"></div><script src="${src}"><\/script>`
    },
    getDomainVersionIndex(domain) {
      let versions = this.stagVersions
      return versions.findIndex(v => v.domains.includes(domain))
    },
    generateTag() {
      this.displayResultTag = false
      let form = this.form
      let {dsp, ssp, os, trafficType} = form
      if(dsp !== "Bucksense") {
        os = ""
      }
      let s = `${dsp}-${os}-${ssp}-${trafficType}`
      let resultDomain = this.domainFlow.find(v => v.dsp.includes(form.dsp) && !["ssp", "trafficType"].some(v2 => form[v2] != v[v2]))
      resultDomain = resultDomain && resultDomain.domain
      if(!resultDomain) {
        this.notifyError("Invalid settings")
        return
      }
      let domainVersionIndex = this.getDomainVersionIndex(resultDomain)
      if(domainVersionIndex < 0) {
        this.notifyError("Error ocurred")
        return
      }
      this.selectedVersionIndex = domainVersionIndex
      this.tagGenerateForm.domain = resultDomain
      if(dsp === "Bucksense") {
        this.tagGenerateForm.dsp = this.dspOptions[dsp + "-" + os]
      } else {
        this.tagGenerateForm.dsp = this.dspOptions[dsp]
      }

      this.resultTag = this.getSystemCampTag()
      window.setTimeout(() => {
        this.displayResultTag = true
      }, 100)
    },
    runTag() {
      let div = document.getElementById("e29a2")
      div.innerHTML = ''
      var iframe = document.createElement('iframe')
      iframe.setAttribute("sandbox", "allow-same-origin allow-scripts")
      div.appendChild(iframe)
      let cLink = this.systemCampLink
      let version = this.stagVersions[this.selectedVersionIndex]
      let prefix = version.prefix
      var reg = new RegExp(`(${prefix}\\d)=([^&]+)(&|$)`, 'g')
      cLink = cLink.replace(reg, function(a,b,c,d) {
          let val = b + "_val"

					var rep = b + "=" + val + d;
					return rep;
				});
      if(this.tagTestForm.skipBlocking) {
        cLink += "&"+["debug","_","sk","ip","=!0","00!"].join("")
      }
      let tagSrc = `\<div id="${this.tag_id}"></div><script src="${cLink}"><\/script>`
      var src = `<html><head></head><body>
      <div>${tagSrc}</div></body></html>`;
      iframe.contentDocument.write(src)
      iframe.contentDocument.close()
      // var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
      // win.document.body.innerHTML = this.systemCampTag
    },
    updateTagID() {
      this.tag_id = this.generateTagID()
    },
    generateTagID() {
      let val = window.randStr(7, 11)
      if(isNaN(val[0])) {
        return val
      }
      return this.generateTagID()
    },
    onSystemLinkFocus(v) {
      v.target.select && v.target.select()
    },
    onOrderUpdate(val) {
      let orderString = val.map(v => v.id).join(",")
      this.$http.put(this.resources.ScriptTags.updateCampOrder(this.campId), {order: orderString})
        .then(() => {})
        .catch(err => {})
    },
    onRowClick() {

    }
  }
}
</script>

<style>

.table-12341 .fields > li:nth-child(2), .table-12341 .table-row > li:nth-child(2) {
  max-width: 300px;
}
</style>

<style lang="css" scoped>
.form-12 {
  margin-top: 15px;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
  padding: 9px;
  background: #f9f9f9;
}
.form-fields-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.form-fields-1 > div {
  display: flex;
  justify-content: space-between;
  width: 353px;
  margin-bottom: 7px;
}
.c-231 {
  padding: 7px;
  background: #f9f9f9;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  width: 750px;
}
.c-12345 {
  margin-right: 5px;
}
.link-23 {
  margin-bottom: 12px;
}

.field-5121 {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}
.field-5121 > *:first-child {
  width: 200px;
}
.fields-241 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.vals-options-5121 {
  display: flex;
}
.vals-options-5121 > *:first-child {
  margin-right: 20px;
}
.label-val-12 {
  text-transform: capitalize;
  margin-left: 25px;
}
.vals-options-5121 select {
  border: 1px solid #d2d2d2;
  padding: 4px;
  border-radius: 4px;
}
.c-6 {
  background: #cfe8cf;
  border: 1px solid #73bb73;
  color: #163816;
  margin-top: 6px;
}
.debug-stags-container {
  border-top: 1px solid gainsboro;
  padding-top: 20px;
}
.tag-unique-id-container {
  display: flex;
}
.t-label-2512 {
  width: 125px;
}
.regenerate-tagid-btn {
  margin-left: 7px;
}
</style>
