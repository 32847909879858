<template lang="html">
  <div class="items column list-move-container">
    <h4>{{title}}</h4>
    <input type="text" placeholder="filter" v-show='!noFilter'>
    <div class="b1" :class='{reverse: this.isLast}'>
      <button title="Move all" type="button" @click='onMove(Object.keys(items))'>
        <i :class='doubleArrowClass'></i>
      </button>
      <button title="Move selected" type="button" @click='onMove(selected)'>
        <i :class='arrowClass'></i>
      </button>
    </div>
    <select v-model='selected' multiple class='scrollbar-1 list list-2345'>
      <option @dblclick='onMove([index])' v-for='(item, index) in items' :value='index'>{{item}}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
    },
    isLast: {
      default: false
    },
    noFilter: {
      default: false
    },
    title: {
    },
    onMove: {}
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    arrowDirection() {
      return this.isLast ? 'left' : 'right'
    },
    arrowClass() {
      return "fa fa-angle-" + this.arrowDirection
    },
    doubleArrowClass() {
      return "fa fa-angle-double-" + this.arrowDirection
    }
  }
}
</script>

<style lang="css" scoped>
.b1 {
  display: flex;
}
.b1 button {
  flex: 1;
  background: #fbfbfb;
  font-size: 20px;
  color: grey;
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.items {
  background: white;
}
.reverse {
  display: flex;
  flex-direction: row-reverse;
}
.list-move-container button:hover {
  background-color: #f7f7f7;
}
.column {
  flex: 1;
  border-top: 1px solid gainsboro;
}
.list-move-container input {
  padding-left: 4px;
}
.list-move-container input, .list-move-container button, .list-move-container select {
  border: 1px solid gainsboro;
}
.list-2345 {
  height: 256px;
}
</style>
