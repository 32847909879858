import Validator from 'validator-v2'

const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const domRegex = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/


let password = {
  required: true,
  length: [4, 60]
}

let tmPixelValidator = new Validator({
  name: {
    required: true,
    minLen: 4,
    onError: {
      any: "Please fill in pixel name (min 4 chars)"
    }
  },
  url: {
    required: true,
    regexMatch: urlRegex,
    regexFail: /undefined/,
    onError: {
      regexMatch: "Pixel URL is invalid",
      regexFail: "Error occured. Please refresh the page and try again",
      any: "Please fill in pixel URL"
    }
  }
})

let fns = {
  tmPixels: new Validator({
    pixels: {
      each: tmPixelValidator
    }
  }),
  signup: new Validator({
    name: {
      required: true,
      length: [4, 60]},
    email: {
      required: true,
      email: true
    },
    password: password,
    password_confirmation: password,
    // recaptcha: {length: [6]}
  }, {
  }),
  login: new Validator({
    email: {
      required: true,
      email: true
    },
    password: password
  }),
  tmPlacement: new Validator({
    externalID: {
      required: true,
      length: [4],
      onError: {
          any: "Please fill in Placement ID"
      }
    },
    domain: {
      required: true,
      regexMatch: domRegex,
      onError: {
        any: "Domain is not a URL"
      }
    },
    country: {
      required: true,
      onError: {
        any: "Please choose country"
      }
    },
    os: {
      required: true,
      onError: {
        any: "Please choose OS"
      }
    },
    pixels: {
      each: tmPixelValidator
    }
  }),
  tmTemplate: new Validator({
    name: {
      required: true,
      length: [5,30],
      onError: {
        any: "Name must be 5-30 chars"
      }
    },
    url: {
      required: true,
      regexMatch: urlRegex,
      onError: {
        any: "URL field is not a valid URL"
      }
    }
  }),
  tmDomainPrefix:  new Validator({
    prefix: {
      required: true,
      length: [2,5],
      onError: {
        any: "Prefix must be 2-5 chars"
      }
    },
    domain: {
      required: true,
      regexMatch: domRegex,
      onError: {
        any: "Domain is not a valid domain"
      }
    }
  }),
  tmInternalPlacement: new Validator({
    externalID: {
      required: true,
      length: [3],
      onError: {
          any: "Please fill in Placement ID"
      }
    },
    pixels: {
      each: tmPixelValidator
    }
  }),
  stags: {
    link: new Validator({
      name: {
        required: true
      },
      url: {
        required: true,
        url: true
      }
    })
  },
  smartlinks: {
    campaign: new Validator({
      name: {
        required: true
      }
    }),
    placement: {

    },
    customOffer: new Validator({
      name: {
        required: true,
        onError: "Please fill in offer name"
      },
      custom_url: {
        required: true,
        url: true,
        onError: "Please fill in a valid custom URL"
      }
    }),
    affiseOffer: new Validator({
      affiseID: {
        required: true,
        onError: "Please fill in affise ID"
      },
      name: {
        required: true,
        onError: "Please fill in offer name"
      },
      subs_query: {
        validate(v) {
          let keys = []
          let t1 = v.split("&")
          t1.forEach(t => {
            let t2 = t.split("=")
            let key = t2[0]
            let val = t2[1]
            keys.push(key)
          })

          for (let i = 1; i<=8; i++) {
            let t3 = "sub" + i
            if(!keys.includes(t3)) {
              return false
            }
          }

          if(!keys.includes("pid")) {
            return false
          }

          return true
        },
        onError: {
          validate: "Invalid Custom Subs Query"
        }
      }
    })
  }
}

function bindCheck(what) {
  return what.check.bind(what)
}
export default {
  signup: bindCheck(fns.signup),
  login: bindCheck(fns.login),
  tmTemplate: bindCheck(fns.tmTemplate),
  tmDomainPrefix: bindCheck(fns.tmDomainPrefix),
  tmPixels: bindCheck(fns.tmPixels),
  tmPlacement: bindCheck(fns.tmPlacement),
  tmInternalPlacement: bindCheck(fns.tmInternalPlacement),
  smartlinks: {
    affiseOffer: bindCheck(fns.smartlinks.affiseOffer),
    customOffer: bindCheck(fns.smartlinks.customOffer),
    campaign: bindCheck(fns.smartlinks.campaign)
  },
  stags: {
    link: bindCheck(fns.stags.link),
  }
}
