<template lang="html">
  <div :class="{'form-group-233': true, inline: inline}" :style='{marginBottom: addMargin ? "12px" : "0"}'>
    <label v-show='labelText' :for='htmlId'>{{labelText}}:</label>
    <input :autocomplete='autoComplete' spellcheck="false" :disabled='disabled' :readonly='readonly'
    :style='inputStyle' v-if='def' :type='type ? type : "text"'
    v-model.trim='value_' :id='htmlId' class="def input-245" :class='{long: inline}' :placeholder='placeholder'>
    <slot>
    </slot>
  </div>
</template>

<script>
import * as act from '@/act-types'
export default {
  props: {
    inputStyle: {
      default() {
        return {}
      }
    },
    autoComplete: {
      default: true
    },
    addMargin: {
      default: true
    },
    placeholder: {},
    bindTo: {},
    disabled: {
      default: false
    },
    readonly: {
      default: false
    },
    labelText: {},
    type: {
      default: ""
    },
    onUpdate: {
      type: Function
    },
    def: {
      default: true
    },
    value: {},
    inline: {default: false}
  },
  data() {
    return {
      htmlId: null,
      value_: null
    }
  },
  watch: {
    value_(val) {
      this.onUpdate && this.onUpdate(val)
    },
    value(val) {
      this.value_ = this.value
    }
  },
  created() {
    this.value_ = this.value
    this.$store.dispatch(act.GET_NEXT_ID)
      .then(id => {
        this.htmlId = id
      })
  }
}
</script>

<style lang="css" scoped>
.form-group-233 {
  display: flex;
  flex-direction: column;
}
.form-group-233.inline {
  margin-right: 20px;
  align-items: center;
  flex-direction: row;
}
.form-group-233.inline label {
  width: 125px;
}
.input-245 {
}
.form-group-233 label {
  font-size: 15px;
}
</style>
