<template lang="html">
  <div class="all">

    <section class="data-collecting">

      <h3 class="h3-12">Data Collecting Info</h3>

      <div class="data-col-cont" v-if='notFetchedTimes'>

        <div class="d-123">
          <span>Date range limit:</span>
          <span class="d2312">
            <span>{{sinceDate}}</span> - <span>{{lastAvailableDt}}</span>
          </span>
        </div>
        <ul class="missing-data-table" v-if='notFetchedTimes.length > 0'>
          <li class="th">
            <span>Source</span>
            <span>Date</span>
            <span>Status</span>
          </li>
          <li v-for='t in notFetchedTimes'>
            <span>{{t[2]}}</span>
            <span>{{t[0]}}</span>
            <span class="missing">MISSING</span>
          </li>
        </ul>
        <span class="last-dt" v-else>All data has been fetched</span></span>
      </div>

    </section>
    <hr>
    <h3>Profit Report</h3>

    <form class="profit-form" @submit.prevent=''>
      <h5 class="h121">Filters</h5>

      <div class="filters-container">
        <div class="field-container">
          <label >From (UTC):</label>
          <div class="d1234">
            <DatePicker :onChange='v=> { form.fromDate = v } ' />
          </div>
        </div>

        <div class="field-container">
          <label >Until (UTC):</label>
          <div class="d1234">
            <DatePicker :onChange='v=> { form.untilDate = v } '  />
          </div>
        </div>

        <div class="field-container">
          <label >Countries</label>
          <Select class='select' :options='countryOptions' :onChange='onGeoAdd' />
          <ul class='selected-items' v-show='form.countries.length'>
            <li @click='onGeoDel(index)' v-for='(geo, index) in form.countries'>
              <i class="fa fa-times del-x" aria-hidden="true"></i>
              <span>
                {{countryOptionsObject[geo]}}
              </span>
            </li>
          </ul>
        </div>

        <div class="field-container">
          <label >Os</label>
          <select v-model='form.os' class="def select-234">
            <option value=''>All Os</option>
            <option v-for='(title, option) in osOptions' :value='option'>{{title}}</option>
          </select>
        </div>

        <div class="field-container">
          <label >App ID</label>
          <input v-model='form.app_id' class="def" placeholder="App ID">
        </div>

        <div class="field-container">
          <label >Sub3</label>
          <input v-model='form.sub3' class="def" placeholder="sub3">
        </div>

        <div class="field-container">
          <label >Sub7</label>
          <input v-model='form.sub7' class="def" placeholder="sub7">
        </div>

        <div class="field-container">
          <label >Sub8</label>
          <input v-model='form.sub8' class="def" placeholder="sub8">
        </div>
      </div>

    <h5 class="h121">Dimensions</h5>

    <div class="groupby-container">

      <div class="form-inputs">
        <input id="el-date" value="date" type="checkbox" v-model='form.groupBy'>
        <label for="el-date">Date</label>
      </div>

      <div class="" v-if='0'>
        <input id="el-hour" value="hour" type="checkbox" v-model='form.groupBy'>
        <label for="el-hour">Hour</label>
      </div>

      <div class="form-inputs">
        <input id="el-country" value="country" type="checkbox" v-model='form.groupBy'>
        <label for="el-country">Country</label>
      </div>

      <div class="form-inputs">
        <input id="el-os" value="os" type="checkbox" v-model='form.groupBy'>
        <label for="el-os">Os</label>
      </div>

      <div class="form-inputs">
        <input id="el-app_id" value="app_id" type="checkbox" v-model='form.groupBy'>
        <label for="el-app_id">App ID</label>
      </div>
      <div class="form-inputs">
        <input id="el-sub3" value="sub3" type="checkbox" v-model='form.groupBy'>
        <label for="el-sub3">Sub3</label>
      </div>
      <div class="form-inputs">
        <input id="el-sub4" value="sub4" type="checkbox" v-model='form.groupBy'>
        <label for="el-sub4">Sub4 <span class="s-241">(only Spotad)</span></label>
      </div>
      <div class="form-inputs">
        <input id="el-sub7" value="sub7" type="checkbox" v-model='form.groupBy'>
        <label for="el-sub7">Sub7</label>
      </div>

      <div class="form-inputs">
        <input id="el-sub8" value="sub8" type="checkbox" v-model='form.groupBy'>
        <label for="el-sub8">Sub8</label>
      </div>

      <div class="form-inputs">
        <input id="el-dsp" value="dsp" type="checkbox" v-model='form.groupByDsp'>
        <label for="el-dsp">Dsp</label>
      </div>

    </div>

    <h5 class="h121">Dsps</h5>
    <ul class="dsps-list">
      <li class="" v-for='dsp in dsps'>
        <label :for='"el12-" + dsp' class="dsp-title">{{dsp}}</label>
        <input :id='"el12-" + dsp' type="checkbox" :value='dsp' v-model='form.dsps'>
      </li>
    </ul>

  <div class="btns">
    <button type="button" class="btn btn-primary" @click='onSubmit' :disabled='isProcessing'>
      Submit
    </button>
  </div>
  </form>

  <hr v-show='isProcessing || reportResponse'>
  <Spinner v-show='isProcessing' />
  <div class="response-container" v-if='reportResponse && !isProcessing'>

    <template v-if='reportResponse.rows_count > 0'>
      <ul class="placements-table" v-if='!reportResponse.csv_only'>
        <li class="headers-4">
          <div class="row-2">
            <span @click='sortBy(f)' class="field-123" v-for='f in reportResponse.report.fields'>
              {{f | formatUnderscore}}
              <template v-if='f === sort.field'>
                <i class="fa fa-sort-asc" aria-hidden="true" v-if='!sort.asc'></i>
                <i class="fa fa-sort-desc" aria-hidden="true" v-else></i>
              </template>
            </span>
          </div>
        </li>
        <li v-for='p in sortedRows' class="placement-row-data">
          <div class="row-2">
            <span v-for='f in reportResponse.report.fields'>{{p[f] | ifFloatDecimal4 | formatNumber(f) }}</span>
          </div>
        </li>
      </ul>

      <span class="report-contains-3">Report contains {{reportResponse.rows_count}} rows. Click below to download it.</span>
      <button type="button" class="btn btn-dark" @click='downloadCsv'>Download CSV</button>
    </template>

    <strong v-else class="no-results-s">No results.</strong>
  </div>
  </div>
</template>

<script>
function getHourOptions() {
  let n = 24
  let arr = []
  arr.length = n
  for(let i = 0; i < n; i++) {
    let str = i < 10 ? '0' + i : i
    str += ":00"
    arr[i] = str
  }
  return arr
}

import moment from 'moment'
export default {
  data() {
    return {
      notFetchedTimes: null,
      lastAvailableDt: null,
      sinceDate: null,
      yourTimeValue: null,
      hourOptions: getHourOptions(),
      sort: {
        field: null,
        asc: false
      },
      form: {
        fromDate: null,
        untilDate: null,
        fromHour: 0,
        untilHour: 0,
        countries: [],
        os: null,
        app_id: null,
        sub3: null,
        sub7: null,
        sub8: null,
        groupBy: ["date"],
        dsps: [],
        groupByDsp: false
      },
      countryOptions: [],
      countryOptionsObject: {},
      osOptions: {
        ios: "iOS",
        android: "Android"
      },
      reportResponse: null,
      isProcessing: false,
      dsps: ["mediasmart", "bucksense", "spotad", "smadex", "pocketmath"]
    }
  },
  filters: {
    ifFloatDecimal4(val) {
      if(isNaN(val) || val % 1 === 0) {
        return val
      }
      return val.toFixed(4)
    },
    formatUnderscore(val) {
      return val.replace("_", " ")
    },
    formatNumber(val, field) {
      if(["impressions", "bids", "wins", "clicks", "cost", "revenue", "profit", "conversions"].includes(field) === false) {
        return val
      }
      return isNaN(val) ? val : new Intl.NumberFormat('en-US').format(val)
      val = val.toString()
      let indOfPoint = val.indexOf('.')
      let beforePoint, afterPoint
      if(indOfPoint > -1) {
        beforePoint = val.substr(0, indOfPoint)
        afterPoint = val.substr(indOfPoint)
      } else {
        beforePoint = val
      }

      let s = ""
      for(let i = 0; i < beforePoint.length; i++) {
        if(i % 3 === 0) {
          s += ','
        }
        s += beforePoint[i]
      }
      return beforePoint + "." + afterPoint

    }
  },
  computed: {
    sortedRows() {
      let rows = this.reportResponse.report.rows
      let sortField = this.sort.field
      if(!sortField) {
        return rows
      }
      let asc = this.sort.asc ? 1 : -1
      return rows.sort((a,b) => {
          a = a[sortField]
          b = b[sortField]
          if(a > b) {
            return 1 * asc
          }
          if(a < b) {
            return -1 * asc
          }
          return 0
      })

    }
  },
  methods: {
    onGeoAdd(v) {
      this.form.countries.push(v.value)
    },
    onGeoDel(index) {
      this.form.countries.splice(index, 1)
    },
    sortBy(field) {
      let currentField = this.sort.field
      if (field === currentField) {
        this.sort.asc = !this.sort.asc
      } else {
        this.sort = {
          field: field,
          asc: false
        }
      }
    },
    sortReportFields(fields) {
      let arr = []
      if(fields.date) {
        arr.push("date")
      }
    	if(fields.hour) {
        arr.push("hour")
      }
    	if(fields.country) {
        arr.push("country")
      }
    	if(fields.os) {
        arr.push("os")
      }
    	if(fields.app_id) {
        arr.push("app_id")
      }
      if(fields.sub3) {
        arr.push("sub3")
      }
      if(fields.sub4) {
        arr.push("sub4")
      }
    	if(fields.sub7) {
        arr.push("sub7")
      }
    	if(fields.sub8) {
        arr.push("sub8")
      }
      if(fields.dsp) {
        arr.push("dsp")
      }
      arr.push("revenue", "cost", "profit", "bids", "wins", "clicks", "impressions", "conversions")
      return arr
    },
    hourToNumber(s) {
      if(s[0] === '0') {
        return +s[1]
      }
      return +s.substr(0,2)
    },
    overlap(a1, b1) {
      return function(a2,b2) {
        let da = a1 - a2
        if(a1 >= a2 && (b2-a2) >= da) {
          return true
        }
        if(a2 >= a1 && a2 <= b1) {
          return true
        }
        return false
      }
    },
    onSubmit() {

      this.reportResponse = null
      let form = this.form
      let from = moment.utc(form.fromDate), until = moment.utc(form.untilDate)

      let daysDiff = moment.duration(until.diff(from)).asDays()
      if(daysDiff < 0) {
        this.notifyError("Invalid Date Range")
        return
      }
      if(form.groupBy.length === 0) {
        this.notifyError("Please choose at least one dimension")
        return
      }

      if(form.dsps.length === 0) {
        this.notifyError("Please select at least one dsp")
        return
      }

      // from.set({hour: form.fromHour})
      // until.set({hour: form.untilHour})

      if(from.isSame(until)) {
        this.notifyError("Date range contains two exact datetimes")
        return
      }
      let f_date = moment.utc(this.sinceDate)
      let u_date = moment.utc(this.lastAvailableDt)

      if(from.isBefore(f_date) || until.isAfter(u_date)) {{
        this.notifyError("Date range is beyond the date range limit")
        return
      }}
      let oval = this.overlap(from.unix(), until.unix() - (60*60))

      let errorWithDsp
      this.notFetchedTimes.forEach(d => {
        let t = moment.utc(d[0])
        let t1 = t.clone().add(0, "hours")
        let t2 = t.clone().add(23, "hours")

        let dsp = d[2].toLowerCase()
        if(
          (dsp === "affise" || form.dsps.includes(dsp))
          &&
          oval(t1.unix(), t2.unix()  - (60*60))
        ) {
          errorWithDsp = dsp
          }
      })

      if(errorWithDsp) {
        this.notifyError("Date range includes date that is not available for: " + errorWithDsp)
        return
      }
      let groupBy = this.form.groupBy, dsps = this.form.dsps
      let groupsBySub3 = groupBy.includes("sub3")
      let groupsBySub4 = groupBy.includes("sub4")
      let groupsByAppID = groupBy.includes("app_id")
      let dspsIncludeBucksense = dsps.includes("bucksense")
      let dspsIncludeSpotad = dsps.includes("spotad")
      if(dspsIncludeBucksense && (groupsByAppID || groupsBySub3)) {
        this.notifyError("Bucksense DSP doesn't support App ID or Sub3 in Dimensions")
        return
      }
      if(dspsIncludeSpotad && groupsByAppID) {
        this.notifyError("Spotad DSP doesn't support App ID in Dimensions")
        return
      }

      if(groupsBySub4 && (dsps.length > 1 || dsps[0] !== "spotad")) {
        this.notifyError("Sub4 is supported only with Spotad")
        return
      }
      this.isProcessing = true
      this.$http.get(this.resources.Reports.Profit.profitReport, {
        params: form
      })
      .then(r => r.json())
      .then(res => {
        if(!res.csv_only) {
          let fieldsMap = res.report.fields_map
          res.report.fields = this.sortReportFields(fieldsMap)
        }
        this.reportResponse = res
        this.notifySuccess("Finished generating report")
      })
      .catch(err => {
        this.notifyError("Error")

      })
      .then(() => {
        this.isProcessing = false
      })
    },
    downloadCsv() {
      let downloadFileName = this.reportResponse.csv_file

      if(!downloadFileName) { return }
      let endPoint = 'reports/profit/csv/' + downloadFileName

      this.downloadFromUrl(this.buildUrl(undefined, {
        path: endPoint
      }))
    },
    formatHour(h) {
      return (h < 10 ? "0" + h.toString() : h.toString()) + ":00"
    },
    getTimesArray(times, title) {
      if(!Array.isArray(times)) {
        return []
      }
      let lastT = null
      let currentDay = Array(3)
      currentDay[2] = title
      let currentAccHours = currentDay[1] = Array(2)
      let allDays = []
      times.forEach((t, i) => {
        t = moment(t).utc()
        if(!lastT) {
          currentDay[0] = t
          currentAccHours[0] = this.formatHour(t.hour())
          currentAccHours[1] = this.formatHour(t.hour() + 1)
        } else {
          let diff = moment.duration(t.diff(lastT))
          if(diff.asHours() === 1 && t.day() === lastT.day()) {
            currentAccHours[1] = this.formatHour(t.hour() + 1)
          } else {
            allDays.push(currentDay)
            currentDay = Array(3)
            currentDay[2] = title
            currentAccHours = currentDay[1] = Array(2)
            currentDay[0] = t
            currentAccHours[0] = this.formatHour(t.hour())
            currentAccHours[1] = this.formatHour(t.hour() + 1)
          }
        }
        if(i+1 === times.length) {
          allDays.push(currentDay)
        }
        lastT = t
      })
      return allDays
    }
  },
  created() {
    this.$store.dispatch("GET_COUNTRIES_LIST")
      .then(countries => {
        let t = []
        for(let key in countries) {
          let val = countries[key]
          t.push({
            value: key, label: val
          })
        }
        this.countryOptions = t
        this.countryOptionsObject = countries
      })

    this.$http.get(this.resources.Reports.Profit.notFetchedTimes)
    .then(r => r.json())
    .then(data => {
      let dsps = data.dsps
      let allTimes = []
      for(let dspName in dsps) {
        let times = dsps[dspName]
        let dspTimes = this.getTimesArray(times, dspName)
        dspTimes = dspTimes.filter((v, i) => dspTimes.findIndex(v2 => v2[0].format("YYYY-MM-DD") === v[0].format("YYYY-MM-DD")) === i)
        dspTimes = dspTimes.filter(v => moment(v[0]).isBefore(moment(data.lastAvailableDt)))
        allTimes = allTimes.concat(dspTimes)
      }
      allTimes.sort((a, b) => a[0].isAfter(b[0]) ? 1 : -1)
      allTimes.forEach(v => v[0] = v[0].format("YYYY-MM-DD"))
      this.notFetchedTimes = allTimes
      this.lastAvailableDt = data.lastAvailableDt
      this.sinceDate = data.sinceDate
    })
  }
}
</script>

<style lang="css" scoped>
.field-container {
  border: 1px solid gainsboro;
  padding: 9px;
  margin: 0 10px;
  border-radius: 3px;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
}
.field-container > label {
  margin-bottom: 3px;
}
.d1234 {
  display: flex;
}
.profit-form {
  display: flex;
  flex-direction: column;
}
.filters-container {
  display: flex;
  flex-wrap: wrap;
}
.groupby-container {
  display: flex;
}
.groupby-container > div {
  margin-right: 43px;
}
.groupby-container > div > label {
  display: inline-flex;
  align-items: center;
}
.h121 {
  margin-top: 20px;
  margin-bottom: 19px;
}
.headers-4 {
  font-weight: bold;
  border-top: 1px solid #c7c7c7;
}
.placements-table > li > .row-2 {
  display: flex;
  border-bottom: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
}
.placements-table > li > .row-2 > span {
  flex: 1;
  border-right: 1px solid #c7c7c7;
  height: 27px;
  overflow: hidden;
  text-align: center;
  padding: 0 7px;
}
.placements-table {
  margin-bottom: 16px;
}
.placement-row-data {

}
.placement-row-data .row-2:hover {
  background: #f1f1f1;
}
.placements-table > li {
  display: flex;
  flex-direction: column;
}
.response-container {
  padding-top: 7px;
}
.no-results-s {

}
.field-123 {
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}
.field-123:hover {
  background: gainsboro;
}
.report-contains-3 {
  display: block;
  margin-bottom: 8px;
}

.missing-data-table {
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
  width: 446px;
  max-height: 270px;
  overflow-y: auto;
}
.th span {
  font-weight: bold;
}
.missing-data-table li:not(.th) {
}
.missing-data-table li {
  display: flex;
  border-bottom: 1px solid #e6e6e6;
}
.missing-data-table li:last-child {
  border-bottom: 0;
}
.missing-data-table li span {
  flex: 1;
  border-right: 1px solid #e6e6e6;
  padding: 6px 0;
  text-align: center;
}
.missing-data-table li span:last-child {
  border-right: 0;
}
.data-collecting {
  margin-bottom: 20px;
}
.missing {
  background: #da2f2f;
  color: white;
  font-weight: bold;
}
.h3-12 {
  margin-bottom: 20px;
}
.last-dt {
  font-size: 18px;
  padding: 7px 10px;
  border: 1px solid #b7e0b4;
  border-radius: 2px;
  background: #f8fff9;
  color: #176f1b;
}
.last-dt-1 {
  font-weight: bold;
}
.data-col-cont {
  display: flex;
  flex-direction: column;
}
.d-123 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid gainsboro;
  padding: 4px 6px;
}
.d2312 {
  font-weight: bold;
}
.dsp-title {
  text-transform: capitalize;
}
.dsps-list {
  display: flex;
}
.dsps-list > li {
  display: flex;
  align-items: center;
  margin-right: 41px;
}
.dsps-list > li > label {
  margin-right: 10px;
}
.s-241 {
  font-size: 11px;
  margin-left: 4px;
}
.selected-items {
  background: #f1f0f0;
  padding: 2px 6px;
  border: 1px solid gainsboro;
  border-radius: 4px;
  max-width: 196px;
  display: flex;
  flex-wrap: wrap;
}
.selected-items > li {
  margin-right: 3px;
  padding: 2px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.selected-items > li:hover {
  background: #dfdfdf;
  cursor: pointer;
}
</style>
