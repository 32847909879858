<template lang="html">
  <div class="checkbox-container">
    <input type="checkbox" v-show='false' class="form-check-input" @change='isChecked = !isChecked; onChange(isChecked)' :id='htmlId'>
    <label class="checkbox" :for='htmlId'>
      <i v-show='isChecked' class="fa fa-times"></i>
    </label>
  </div>
</template>

<script>
import * as act from '@/act-types'
export default {
  props: [
    'id', 'onChange', 'checked'
  ],
  data() {
    return {
      htmlId: null,
      isChecked: false
    }
  },
  watch: {
    id() {
      this.updateHtmlId()
    },
    checked() {
      this.updateChecked()
    }
  },
  methods: {
    updateChecked() {
      if(typeof this.checked !== 'undefined') {
        return this.isChecked = this.checked
      }
      this.isChecked = false
    },
    updateHtmlId() {
      if(this.id) {
        return this.htmlId = this.id
      }
      this.$store.dispatch(act.GET_NEXT_ID)
        .then(id => {
          this.htmlId = id
        })
    }
  },
  created() {
    this.updateHtmlId()
    this.updateChecked()
  }
}
</script>

<style lang="css" scoped>
.checkbox {
  height: 14px;
  width: 14px;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #404040;
  background: #e6e6e6;
}
.checkbox i {
  margin-top: -1px;
}
.checkbox-container {
  display: inline-flex;
}
.checkbox:hover {
  cursor: pointer;
  background: #c8c8c8;
}
</style>
