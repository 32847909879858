export default {

  // what this function does is parsing fields string values to array
  // and identifying include or exclude
  // example: geo: "!US,CA" would turn into Exclude ["US", "CA"]

  formPlacement(v) {
    v = Object.assign({}, v)
    v.fieldsIn = {}
    for (let field in v) {
      if (!["geo", "region"].includes(field)) {
        continue
      }
      let val = v[field]
      let f = val[0] === '!'
      if(f) {
        val = val.substr(1)
      }
      v.fieldsIn[field] = !f
      if(val === "") {
        v[field] = []
      } else {
        v[field] = val.split(',')
      }
    }
    return v
  }
}
