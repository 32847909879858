<template>

    <div class="container mt-5">
        <div class="modal_container">
            <b-modal v-model="modalShow" class="domain_modal" :id="modalId" size="md" @hide="onHide">
                <b-alert v-if="showDismissibleAlert" show variant="success">
                    <h4 class="alert-heading">Be Aware!</h4>
                    <p>
                        {{alertMsg}}
                    </p>
                    <hr>
                    <p> Once you click the <b>Save</b> button the changes are saved!</p>
                </b-alert>
                <template id="bootstrap-overrides" #modal-header clas><span id="add-title"
                        v-if="operation === 'add' ">Add new domain:</span>
                    <div class="edit-title" v-if="operation === 'edit' "><span id="space-header">
                            Make changes to:</span> <span id="modal-header-red"
                            class="text-red"><b>{{modalText}}</b></span></div>
                </template>
                <div class="modal-input-container">
                    <label class="modal_label">Name</label>
                    <input v-if="operation === 'add'" type="text" v-model.trim="modalName" class="def input-c-141 modal_input"
                        placeholder="Enter name">
                    <input v-if="operation !== 'add'" type="text" v-model.trim="currentDom.name" class="def input-c-141 modal_input"
                        placeholder="Enter name">
                </div>

                <div class="modal-input-container">
                    <label class="modal_label">Status</label>
                    <button id="status_btn_modal" type="button" class="status-btn modal_input"
                        :class='{active: currentDom.status==="ACTIVE", paused: currentDom.status==="PAUSED"}'
                        @click="toggleEventListenersStatus(currentDom)">
                        {{currentDom.status}}
                    </button>
                </div>

                <div v-if="operation === 'edit' " class="modal-input-container">
                    <label class="modal_label">Date</label>
                    <DateRangePicker
                        v-model="dateRange"
                        :singleDatePicker="true"
                        :showDropdowns="true"
                        :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                        @apply="applyDate"
                    >
                    </DateRangePicker>
                </div>

                <div v-else class="modal-input-container">
                    <label class="modal_label">Date</label>
                    <DateRangePicker
                        v-model="dateRange"
                        :singleDatePicker="true"
                        :showDropdowns="true"
                        :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                        @apply="applyDate"
                    >
                    </DateRangePicker>
                </div>

                <div class="modal-input-container">
                    <label class="modal_label">Traffic Type</label>
                    <b-form-select class="date-picker" v-model="traffic_type" :options="traffic_types"
                        :placeholder="currentDom.traffic_type"></b-form-select>
                </div>

                <div class="modal-input-container">
                    <label class="modal_label">DSP</label>
                    <b-form-select class="date-picker" v-model="dsp" :options="dsps"
                        :placeholder="currentDom.dsp"></b-form-select>
                </div>
                <div class="modal-input-container">
                    <label class="modal_label">Stags Version</label>
                    <b-form-select class="date-picker" v-model="stags_version" :options="stags_versions"></b-form-select>
                </div>
                <div class="modal-input-container">
                    <label class="modal_label">CDN</label>
                    <b-form-select class="date-picker" v-model="cdn" :options="cdns"></b-form-select>
                </div>

                <div class="modal-input-container" style="position: relative;">
                    <label class="modal_label">SSP</label>
                    <input type="text" v-model.trim="ssp" @keyup="autoc" @focus="autoc" class="def input-c-141 modal_input">
                    <div class="autoc" v-if="show_autoc">
                        <div v-for="row in autoc_res">
                            <div @click="autoc_select" class="autoc_item">
                                {{ row }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="operation === 'add'" class="modal-input-container">
                    <label class="modal_label" for="">Comment</label>

                    <b-form-textarea class="modal_input" id="textarea-small" v-model="enteredComment" size="sm"
                        placeholder="Optinally add a comment"></b-form-textarea>
                </div>
                <div v-if="operation === 'edit'" class="modal-input-container">
                    <label class="modal_label">Comment</label>
                    <b-form-textarea class="modal_input" id="textarea-small" size="sm" v-model="currentDom.comments"
                        :placeholder="''"></b-form-textarea>
                </div>

                <template busy="true" v-slot:modal-footer>
                    <div class="footer-container" v-if="operation === 'edit'">
                        <b-button id="modal-del-btn" variant="danger" @click="deleteDomain(currentDom)">
                            Delete
                        </b-button>
                        <div class="unseen-element"></div>
                        <b-button variant="success" @click="submitEditChanges(currentDom)">
                            Save
                        </b-button>
                        <b-button id="modal-del-btn" variant="danger" @click="resetDomain(currentDom)">
                            Reset
                        </b-button>
                        <b-button @click="cancel()">
                            Cancel
                        </b-button>
                    </div>
                    <div v-if="operation === 'add'">
                        <b-button type="submit" variant="primary" @click="submitAll">Submit</b-button>
                        <b-button @click="cancel()">
                            Cancel
                        </b-button>
                    </div>

                </template>

            </b-modal>
        </div>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker'
    import moment from 'moment'
    const DOMAIN_STATUS_ACTIVE = "ACTIVE"
    const DOMAIN_STATUS_PAUSED = "PAUSED"

    export default {
        props: {
            operation: String,
            currentDom: {},
            modalId: String,
            modalShow: Boolean,
            text: String,
            status: String,
            domainsOptions: {},
        },
        components:{
            DateRangePicker
        },
        data() {
            return {
                show: true,
                modalText: this.text,
                modalName: "",
                enteredDate: '',
                enteredComment: '',
                approvedStatusToggle: 0,
                showDismissibleAlert: false,
                alertMsg: '',
                saveStatus: this.status,
                versions: [{
                        value: 'V2',
                        text: 'V2'
                    },
                    {
                        value: 'V3.1',
                        text: 'V3.1'
                    },
                    {
                        value: 'Test',
                        text: 'Test'
                    },
                ],
                selected: this.currentDom.version,
                traffic_types: this.domainsOptions.traffic_types,
                traffic_type: this.currentDom.traffic_type,
                dsps: this.domainsOptions.dsps,
                dsp: this.currentDom.dsp,
                stags_versions: this.domainsOptions.stags_versions,
                stags_version: this.currentDom.stags_version,
                ssps: this.domainsOptions.ssps,
                ssp: this.currentDom.ssp,
                cdn: this.currentDom.cdn,
                cdns: this.domainsOptions.cdns,
                autoc_res: [],
                show_autoc: false,
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date()
                }   
            }
        },
        beforeUpdate() {},
        created() {
            if (this.operation === 'edit') {
                this.dateRange.startDate = new Date(this.currentDom.ssl_start_date)
                this.dateRange.endDate = new Date(this.currentDom.ssl_start_date)
            }
        },
        methods: {
            cancel() {
                this.$emit('update:status', this.saveStatus)
                this.$emit('update:modalShow', false)
            },
            toggleEventListenersStatus(domain) {
                this.toggleStatus(domain);
            },
            onHide(event) {
                if (event.trigger === "backdrop") {
                    this.$emit('update:modalShow', false)
                    this.showDismissibleAlert = false
                    event.preventDefault();
                    this.handleBackdrop();
                }
            },
            handleBackdrop() {
                this.showDismissibleAlert = false
            },
            onSubmit(event) {
                event.preventDefault()
            },
            resetFormValues() {
                this.enteredDate = ''
                this.enteredComment = ''
                this.modalName = ''
                this.selected = 'V2'
                this.dateRange.startDate = new Date()
                this.dateRange.endDate = new Date()
            },
            onReset(event) {
                event.preventDefault()
                this.resetFormValues()
                utils.clearValidationState()
            },
            toggleStatus(domain) {
                if (!this.userHasPermission("domains.status-change")) {
                    return
                }

                if (domain.status === DOMAIN_STATUS_ACTIVE) {
                    domain.status = DOMAIN_STATUS_PAUSED
                } else {
                    domain.status = DOMAIN_STATUS_ACTIVE
                }
                if (this.operation == 'edit') {
                    this.showDismissibleAlert = true;
                    this.alertMsg =
                        `Are you sure that you want to change domain "${domain.name}" status to: ${domain.status}?`
                }

                this.$emit('update:currentDom', this.currentDom)
            },
            submitAll: async function (domain) {
                if (this.modalName === '') {
                    this.notifyError(`Please fill all the details`)
                    return
                }
                if (!this.userHasPermission("domains.edit")) {
                    return
                }
                let newDomain = {
                    ...domain,
                    status: this.currentDom.status,
                    date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    name: this.modalName,
                    ssl_start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    comments: this.enteredComment,
                    requests: 0,
                    version: this.selected,
                    traffic_type: this.traffic_type,
                    ssp: this.ssp,
                    dsp: this.dsp,
                    cdn: parseInt(this.cdn)
                }
                this.$emit('update:currentDom', newDomain)
                this.$emit('update:modalShow', false)
                try {
                    await this.$http.post(this.resources.Domains.postDomains, newDomain)
                } catch (err) {
                    this.notifyError(`Domain ${domain.name} failed registration`)
                }

                this.resetFormValues();
            },
            fillValues(domain) {
                if (this.modalName != "")
                    domain.name = this.modalName
                if (this.dateRange.startDate != null)
                    domain.date = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                if (this.enteredComment != "")
                    domain.comments = this.enteredComment
                if (this.selected != "V2")
                    domain.version = this.selected
            },
            submitEditChanges: async function (domain) {
                if (domain.modalName === '') {
                    console.log(this.dsp)
                    this.notifyError(`Please fill all the details`)
                    return
                }
                this.showDismissibleAlert = false
                this.fillValues(domain)
                
                let newDomain = {
                    ...domain,
                    name: domain.name,
                    date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    status: domain.status,
                    ssl_start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    comments: domain.comments,
                    version: this.selected,
                    traffic_type: this.traffic_type,
                    dsp: this.dsp,
                    ssp: this.ssp,
                    stags_version: this.stags_version,
                    cdn: parseInt(this.cdn)
                }

                this.$emit('update:currentDom', newDomain)
                this.$emit('update:modalShow', false)
                try {
                    await this.$http.put(this.resources.Domains.setDomainEdit(domain.id), newDomain)
                } catch (err) {
                    this.notifyError(`Domain ${domain.name} failed editing`)
                }

                this.resetFormValues()
            },
            deleteDomain: async function (domain) {
                if (confirm("Are you sure?") !== true) {
                    return
                }
                let newDomain ={
                    ...domain
                }
                this.$emit('update:currentDom', newDomain)
                this.$emit('update:modalShow', false)
                try {
                    await this.$http.delete(this.resources.Domains.deleteDomains(domain.id))
                    this.notifySuccess(`Domain ${domain.name} was succesfully deleted`)
                } 
                catch (err) {
                    this.notifyError(`Domain ${domain.name} was not deleted`)
                }
            },
            autoc: async function(e) {
                this.autoc_res = []
                for (let k in this.ssps) {
                    if (this.ssps[k].text.startsWith(e.target.value)) {
                        this.autoc_res.push(this.ssps[k].text)
                    }
                }
                if (this.autoc_res.length > 0) {
                    this.show_autoc = true
                } else {
                    this.show_autoc = false
                }
            },
            autoc_select: function(e) {
                this.show_autoc = false
                this.ssp = e.target.innerHTML.trim()
            },
            resetDomain() {
                this.traffic_type = ''
                this.dsp = ''
                this.ssp = ''
                this.stags_version = ''
                this.comments = ''
            },
            applyDate(event) {
                this.dateRange.startDate = event.startDate.toDate();
                this.dateRange.endDate = event.endDate.toDate();
            }
        }
    }
</script>
<style scoped>
    .text-red {
        color: red;
    }

    .modal_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        column-gap: 10px;
    }

    .modal_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        column-gap: 10px;
    }

    .date-picker {
        width: 200px;
    }

    .modal-input-container {
        display: flex;
        flex-wrap: initial;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        border-bottom: 1px solid gainsboro;
        padding-bottom: 15px;
    }

    #space-header {
        padding-right: 10px;
    }

    .footer-container {
        width: 150%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .unseen-element {
        width: 75%;
    }

    #status_btn_modal{
        width: 12.5rem;
    }

    .autoc {
        position: absolute; 
        top: 30px; 
        left: 270px; 
        background-color: white; 
        cursor: pointer; 
        padding: 5px; 
        border: 1px solid gray;
        max-height: 200px;
        overflow-y: auto;
    }

    .autoc_item {
        padding: 2px;
    }

    .autoc_item:hover {
        background-color: blue;
        color: white;
    }

    /deep/ .ranges {
        display: none !important;
    }

    /deep/ .daterangepicker {
        min-width: 100% !important;
    }

    /deep/ .reportrange-text {
        min-width: 200px !important;
    }
</style>