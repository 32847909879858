<template lang="html">
  <div class="all">
    <Campaign :data='campaign' :onSubmit='submit' />
  </div>
</template>

<script>
import Campaign from '../components/Campaign'
export default {
  components: {Campaign},
  data() {
    return {
      campaign: null
    }
  },
  created() {
    let {campId} = this.$route.params
    let campaign = this.$store.state.smartlinks.campaigns.find(v => v.id == campId)
    this.campaign = campaign
  },
  methods: {
    submit(form) {
      let campId = this.$route.params.campId
      this.$http.put(this.resources.SmartLinksCampaigns.updateCampaign(campId), form)
        .then(() => {
          Object.assign(this.campaign, form)
          this.notifySuccess("Campaign has been updated")
        })
        .catch(err => {
          this.notifyError("Error has occured")
        })
    }
  }
}
</script>

<style scoped>
</style>
