<template lang="html">
  <div class="all">
    <h1>Discrepancy Report (Tag -> SL)</h1>
    <h4>Yesterday</h4>
    <div class="sl-list" v-if='reportRows'>
      <ul class="sl-ul">
        <li class="sl-th">
          <span class="sortable-column id-col" :class='{active: sort.field === "id"}'
            @click='onColumnClick("stag_id")'>Tag ID</span>
          <span class="sortable-column" :class='{active: sort.field === "stag_name"}'
            @click='onColumnClick("stag_name")'>Tag Name</span>
          <span class="sortable-column" :class='{active: sort.field === "stag_served_total"}'
            @click='onColumnClick("stag_served_total")'>Total Tag Requests Served</span>
          <span class="sortable-column" :class='{active: sort.field === "stag_served_no_sl"}'
            @click='onColumnClick("stag_served_no_sl")'>Tag Requests Served No SL</span>
          <span class="sortable-column" :class='{active: sort.field === "discrepancy"}'
            @click='onColumnClick("discrepancy")'>Discrepancy %</span>
        </li>
        <li class="sl-t" v-for='row in reportRows' :key='row.stag_id'>
          <span class="id-col">{{row.stag_id}}</span>
          <span class="">{{row.stag_name}}</span>
          <span class="">{{row.stag_served_total | numFormat}}</span>
          <span class="">{{row.stag_served_no_sl | numFormat}}</span>
          <span :class='{"alert_bad": row.discrepancy > 20}'>{{row.discrepancy | percentageFormat}}</span>
          <!-- <span>{{sl.stats.other_errors}}</span> -->
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        reportRows: null,
        sort: {
          field: "stag_d",
          asc: false
        },
      }
    },
    created() {
      this.$http.get(this.resources.ScriptTags.discrepancyReport)
        .then(r => r.json())
        .then(report => {
          this.reportRows = Object.values(report)
        })
        .catch(err => {
          this.notifyError("Could not load report")
        })
    },
    filters: {
      numFormat(v) {
         let i = isNaN(v) ? 0 : v
        return(Number(i).toLocaleString())
      },
      percentageFormat(v) {
        if (!v) {
          v = 0
        }
        return v.toFixed(0) + "%"
      }
    },
    methods: {
      onColumnClick(field) {
        if (this.sort.field === field) {
          this.sort.asc = !this.sort.asc
        } else {
          this.sort.field = field
          this.sort.asc = false
        }
        this.sortReport()
      },
      sortReport() {
        let rows = this.reportRows

        let sort = this.sort
        if (!sort.field) {
          return
        }

        let sortField = sort.field

        rows.sort((a, b) => {
          a = a[sortField] || 0
          b = b[sortField] || 0

          let f
          if (a > b) {
            f = 1
          } else if (a < b) {
            f = -1
          } else {
            f = 0
          }

          if (!sort.asc) {
            f = f * -1
          }

          return f
        })
      }
    }
  }
</script>

<style lang="css" scoped>
  .sl-list {
    display: flex;
    flex-direction: column;
    border: 1px solid gainsboro;
    border-bottom: 0;
    margin-top: 25px;
  }

  .sl-list .sl-th {
    background: #ededed;
    position: sticky;
    top: 0;
    font-weight: bold;
    color: #4d4d4d;
    font-size: 14px;
  }

  .sl-list li {
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    font-size: 15px;
    font-weight: bold;
    color: #5a5a5a;
  }

  .sl-list li.sl-t:hover {
    background: #f5f5f5;
  }

  .sl-list li span {
    padding-left: 11px;
    border-right: 1px solid gainsboro;
    padding: 4px 4px 4px 9px;
    flex: 1;
    min-width: 90px;
  }

  .sl-ul {
    max-height: 70vh;
    overflow-y: auto;
    position: relative;
  }

  .sl-list li span.po {
    flex: 2;
  }

  .s-512 {
    display: block;
    margin-left: 4px;
    margin-top: 5px;
    font-size: 12px;
    color: gray;
    font-style: italic;
  }

  .sortable-column {
    cursor: pointer;
  }

  .sortable-column.active {
    background: #dedede !important;
  }

  .sortable-column:hover {
    background-color: #e5e5e5;
  }

  .alert_bad {
    color: #e44400;
  }

  .id-col {
    width: 85px;
    max-width: 85px;
  }
</style>