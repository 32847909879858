<template lang="html">
  <div class="all">
    <DateRange :onChange='onDatesChange' />
    <line-chart-original v-if='loaded' :data="{
      labels: labels.map(e => isNaN(e) ? e : formatTimestamp(e)),
        datasets: lines.map(v => Object.assign({}, defaultDataset, v))
      }" :options="{responsive: true, maintainAspectRatio: false}">
    </line-chart-original>
  </div>
</template>

<script>
export default {
  props: {
    // lines: {},
    // labels: {},
    apiUrl: {}
  },
  components: {},
  data() {
    return {
      defaultDataset: {
        label: 'Data One',
        borderColor: '#f87979',
        fill: false
      },
      dates: {
        from: new Date(),
        to: new Date()
      },
      lines: [],
      labels: [],
      loaded: false,
      colors: [
        "#294049",
        "#BDEA72",
        "#A71139",
        "#2AB61C" // TODO: add more colors (unless not needed)
      ]
    }
  },
  methods: {
    getRandomColor() {
      let letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    onDatesChange(v) {
      this.dates = v
      this.fetchData()
    },
    formatTimestamp(v) {
      let [date, time] = new Date(v).toLocaleString('en-US', {hour12: false}).split(', ')
      return date
    },
    fetchData() {
      this.$http.post(this.apiUrl, this.dates)
        .then(r => r.json())
        .then(r => r.data)
        .then(data => {
          let lines = []
          let i = 0
          for(let label in data) {
            let value = data[label]
            lines.push({
              data: value.map(e => e[1]),
              label,
              borderColor: this.colors[i++]
            })
          }
          this.lines = lines
          if(data.length === 0) { this.labels = [] }
          else {
            this.labels = Object.values(data)[0].map(e => (e[0]))
          }
          this.loaded = true
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="css">
</style>
