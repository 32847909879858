<template>
  <div class="app flex-row align-items-center">
    <div class="container container-23">
      <div class="logo-container">
        <img src="/img/apptimus.png" alt="" class="logo-m">
      </div>
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group class="square-1">
            <b-card no-body class="p-4 login-square">
              <b-card-body>
                <form @submit.prevent='submit'>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <input type="email" class="form-control" placeholder="Email" v-model='form.email'>
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <input type="password" class="form-control" placeholder="Password" v-model='form.password'>
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type='submit' variant="primary" class="px-4" :disabled='awaitingResponse'>Login</b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      If you have login issues please contact IT
                      <!-- 
                      <router-link :to="{ name: 'ForgotPassword', params: {} }">
                        <b-button variant="link" class="px-0">Forgot password?</b-button>
                      </router-link>
                       -->
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
            <b-card no-body class="text-white bg-primary py-5 d-md-down-none sign-up-square" style="width:44%" v-show='0'>
              <b-card-body class="text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>Create a new user.</p>
                  <router-link :to="{ name: 'SignUp'}">
                    <b-button variant="primary" class="active mt-3">Register Now!</b-button>
                  </router-link>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: null,
        password: null,
        remember: true
      },
      awaitingResponse: false
    }
  },
  methods: {
    submit() {
      if(this.awaitingResponse) {
        return
      }
      let form = this.form
      this.$validate.login(form)
        .then(() => {
          this.awaitingResponse = true
          this.$http.post('auth/login', this.form)
          .then(r => r.json())
          .then(data => {
            this.$store.state.user = data.user
            localStorage.setItem('token', data.token)
            window.setCookie('token', data.token, 365*10)
            this.$router.push('/')
          })
          .catch(err => {
            this.notifyError("Incorrect email or password")
          })
          .then(() => {
            this.awaitingResponse = false
          })
        })
        .catch(err => {
          this.notifyError("Please fill all fields")
        })
    }
  }
}
</script>
<style scoped>
  .sign-up-square {
    background: #1c3e4a !important;
    border: 1px solid #1c3e4a;
  }
  p {
    margin-bottom: 1rem;
  }
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
  .logo-m {
    height: 51px;
  }
  .container-23 {
    width: 700px;
  }
</style>
