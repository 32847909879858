<script>
import { Bar } from 'vue-chartjs'
export default {
    extends: Bar,
    props: {
        datasetsData: {},
        chartLabels: []
    },
    computed: {
        chartData() {
            return {
                labels: this.chartLabels,
                datasets: [{
                    label: 'Impressions',
                    backgroundColor: 'rgb(124,181,236)',
                    data: this.datasetsData['Impressions'],
                    order: 4,
                    yAxisID: 'B'
                },
                {
                    label: 'Clicks',
                    backgroundColor: 'rgb(67,67,72)',
                    data: this.datasetsData['Clicks'],
                    order: 5,
                    yAxisID: 'B'
                },
                {
                    label: 'Installs',
                    backgroundColor: 'rgb(144,237,125)',
                    borderColor: 'rgb(144,237,125)',
                    data: this.datasetsData['Installs'],
                     type: 'line',
                    order: 3,
                    fill: false,
                    yAxisID: 'A'
                },
                {
                    label: 'Events',
                    borderColor: 'rgb(247, 163, 92)',
                    backgroundColor: 'rgb(247, 163, 92)',
                    data: this.datasetsData['Events'],
                    type: 'line',
                    order: 2,
                    fill: false,
                    pointStyle: 'circle',
                    yAxisID: 'A'
                }
                ]
            }
        },
    },
    data() {
        return {
            options: {
                scales: {
                    yAxes: [{
                        id: 'A',
                        type: 'linear',
                        position: 'right',
                        scaleLabel: {
                            display: true,
                            labelString: "Events / Installs"
                        },
                    }, {
                        id: 'B',
                        type: 'linear',
                        position: 'left',
                        scaleLabel: {
                            display: true,
                            labelString: "Impressions / Clicks"
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                return value / 1000 > 5 ? (value / 1000).toLocaleString() + 'k' : value.toLocaleString();
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom'
                },
                plugins: {
                    tooltip: {
                        usePointStyle: true,
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    watch: {
        datasetsData: function () {
            this.renderChart(this.chartData, this.options);
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    }
}
</script>