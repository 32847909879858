let moduleStore = {
  tags: null
}
export default {
  methods: {
    fetchStags(reportForm) {
      return new Promise((res, rej) => {
        let url = this.resources.ScriptTags.getCampaigns
        // if(dateStr) {
        //   url += "?date=" + dateStr
        // }
        reportForm = reportForm || {}
        this.$http.get(url, {
          params: {
            start_date: reportForm.startDate,
            end_date: reportForm.endDate
          }
        })
        .then(r => r.json())
        .then(data => {
          let tags = data.placements
          moduleStore.tags = tags
          res(tags)
        })
      })
    },
    fetchStagLinks(campId) {
      return new Promise((res, rej) => {
        this.$http.get(this.resources.ScriptTags.getCampLinks(campId))
        .then(r => r.json())
        .then(links => {
          res(links)
        })
      })
    },
    orderItems(links, linksOrder) {
      let orderItems = linksOrder.split(",")
      let orderedLinks = []
      links = links.slice(0)
      orderItems.forEach(v => {
        v = Number(v)
        let v1 = links.find(v2 => v2.id === v)
        if(!v1) {
          return
        }
        orderedLinks.push(v1)
        links.splice(links.findIndex(v2 => v2.id === v), 1)
      })
      links.forEach(v => {
        orderedLinks.push(v)
      })
      return orderedLinks
    }
  }
}
