<template>
    <div>
        <div style="width: 100%;">
            <div class="back">
                <a :href="'/apps/smartlinks/global-offers-v2'">
                    <i class="fa fa-chevron-circle-left back-btn-2" aria-hidden="true"></i>
                </a>
            </div>
            
            <h2>Global Offers</h2>
            <GlobalOfferMain></GlobalOfferMain>
        </div>
    </div>
</template>

<script>
import Offer from '../components/Offer'
import GlobalOfferMain from '../views/GlobalOfferMain.smartlinks.vue'
export default {
    components: {
        Offer, GlobalOfferMain
    },
    data() {
        return {
            isVtaSL: 0,
            id: '0',
            offer: null,
            state: ''
        }
    },
    async created() {
        let { id, isVta }= this.$route.query
        this.id = id
        this.isVtaSL = parseInt(isVta) 
    },
    methods: {
        async submit(offer) { 
            if (this.id === '0') {
                await this.createOffer(offer)
            } else {
                await this.editOffer(offer)
            }
        },
        checkVtaCta(offer) {
            if (offer.click_offer) {
                if (offer.affiseID == offer.click_offer.affiseID) {
                    return true
                }
            }
            return false
        },
        async createOffer(offer) {
            if (this.checkVtaCta(offer)) {
                this.notifySuccess(`CTA and VTA can't have the same Affise ID`)
                return     
            }
            try {
                const r = await this.$http.post(this.resources.GlobalOffers.createGlobalOffer, offer)
                const data = await JSON.parse(r.bodyText)
                return
                if (data.res === 'OK') {
                    this.notifySuccess("Offer has created")
                    if (data.id && data.id !== 0) {
                        //window.location.href = '/apps/smartlinks/global-offers-edit?id=' + data.id + '&isVta=' + this.isVtaSL
                    }
                } else {
                    this.notifySuccess(data.data)
                }
            } catch (err) {
                console.log(err);
                this.notifyError("Can\'t create offer")
            }
        },
        async editOffer(offer) {
            if (this.checkVtaCta(offer)) {
                this.notifySuccess(`CTA and VTA can't have the same Affise ID`)
                return     
            }
            try {
                const r = await this.$http.put(this.resources.GlobalOffers.updateGlobalOffer(offer.id), offer)
                const data = await JSON.parse(r.bodyText)
               
                if (data.res !== 'OK') {
                    this.notifyError(data.data)
                } else {
                    this.notifySuccess('Offer has been updated!')
                }
             
            } catch (err) {
                console.log(err);
                this.notifyError("Can\'t update offer")
            }
        },
        async fetchOffer() {
            try {
                const r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOfferById(this.id))
                const data = await JSON.parse(r.bodyText)
               
                if (data.res !== 'OK') {
                    this.notifyError("Can\'t fetch offer")
                }
                if (data.data.is_new_pid === '1') {
                    data.data.list_id = "NEW_PID_" + data.data.list_id
                }
                
                this.offer = data.data
                this.state = 'edit'
            } catch (err) {
                console.log(err);
                this.notifyError("Can\'t fetch offer")
            }
        },
    }
}
</script>

<style scoped>
.back-btn-2 {
  font-size: 41px;
  color: #3b67a0;
  cursor: pointer;
}

.back {
    margin-bottom: 50px;
}
</style>
