<template lang="html">
  <div class="all">
    <Card :collapse='1' :show='0' v-if='userHasPermission("placements.edit")'>
      <template slot="show-title">Create placement</template>
      <PlacementForm :onSubmit='onCreatePlacement' />
    </Card>
    <Card :collapse='1' :show='1' >
      <template slot="show-title">View placements</template>

      <FilterPlacementsForm :onSubmit='setFilter' />
      <hr>
      <template v-if='placements.length > 0'>
        <PlacementsTableHorizontal :data='placements' :filterForm='filterForm' :selectedPixels='selectedPixels' :selectedPlacements='selectedPlacements' />
        <div class="pagination-23" v-show='pagination.total > 0'>
          <span>Page: </span>
          <ul class="pagination">
            <li class="page-item" v-for='p in pagers' @click='paginate(p)' :class='{active: pagination.currentPage === p}'>
              <a class='page-link'>
                {{p}}
              </a>
            </li>
          </ul>
        </div>
        <hr>
        <div class="btns-cont-2">
          <div class="btns btn-1-pixels">
            <div class="bt123">
              <span class="span12">{{selectedPixelsAmount}} pixels selected</span>
              <button type="button" class="btn _btn-white btn123" @click='e => selectedPixels = {}'>Unselect all pixels</button>
              <button :disabled='selectedPixelsAmount===0' type="button" class="btn btn-danger" @click='showDeletePixelsModal = true'>Delete selected pixels</button>
            </div>
          </div>
          <div class="btns">
            <div class="bt123">
              <span class="span12">{{selectedPlacementAmount}} placements selected</span>
              <button type="button" class="btn _btn-white btn123" @click='e => selectedPlacements = {}'>Unselect all placements</button>
              <button :disabled='selectedPlacementAmount===0' type="button" class="btn btn-primary" @click='e => showInsertOffersModal = true'>Insert Offers to selected placements</button>
            </div>
          </div>
        </div>
      </template>
      <span v-else class="no-results-2">
        No placements found.
      </span>
    </Card>

    <p class="alert-2" v-if='alert.msg' :class='{alert: 1, "alert-danger": !alert.ok, "alert-primary": alert.ok}'>{{alert.msg}}</p>

    <Modal v-if='showDeletePixelsModal'>
      <span>
        Are you sure that you want to delete {{selectedPixelsAmount}} pixels?
      </span>
      <br/>
      <div class="modal-btns">
        <div class="control-btn">
          <button class='btn _btn-danger delbtn-2345' @click='deleteSelectedPixels'>Delete</button>
          <button class='btn _btn-white' @click='showDeletePixelsModal = false'>Cancel</button>
        </div>
      </div>
    </Modal>
    <Modal v-if='showInsertOffersModal'>
      <InsertOfferForm :onSubmit='onInsertOffersSubmit' :placements='Object.values(selectedPlacements)' />
    </Modal>


  </div>
</template>

<script>
import PlacementForm from './tmplacements/PlacementForm.tmplacements'
import PlacementsTableHorizontal from './tmplacements/PlacementsTableHorizontal.tmplacements'
import FilterPlacementsForm from './tmplacements/FilterPlacementsForm.tmplacements'
import InsertOfferForm from './tmplacements/InsertOfferForm.placements'
import placementUtils from './tmplacements/utils.placements'
import fn from '@/views/apps/script-tags/fn.stags.js'

export default {
  components: {PlacementForm, FilterPlacementsForm, PlacementsTableHorizontal, InsertOfferForm},
  data() {
    return {
      filterForm: {
      },
      pagination: {
        currentPage: 1,
        total: null,
        limit: null
      },
      placements: [],
      alert: {
        ok: null,
        msg: null
      },
      isProcessing: false,
      selectedPixels: {},
      selectedPlacements: {},
      showDeletePixelsModal: false,
      showInsertOffersModal: false
    }
  },
  created() {
    this.fetchStagsBanners()
    let query = this.$route.query
    let editPlacementID = query.placement_id
    if(!editPlacementID) {
      this.fetchPlacements(this.pagination.currentPage)
    }
  },
  mixins: [fn],
  computed: {
    pagers() {
      let pag = this.pagination
      let m = Math.ceil(pag.total / pag.limit)
      return this.getNumbersAround(this.pagination.currentPage, m)
    },
    selectedPixelsAmount() {
      return Object.keys(this.selectedPixels).length
    },
    selectedPlacementAmount() {
      return Object.keys(this.selectedPlacements).length
    }
  },
  methods: {
    fetchStagsBanners() {
      this.$http.get(this.resources.ScriptTags.getBanners)
        .then(r => r.json())
        .then(banners => {
          if(!banners) { return }
          let obj = {}
          banners.forEach(v => {
            obj[v.id] = v.name
          })
          this.$store.state.stags.banners = obj
        })
    },
    onInsertOffersSubmit(success) {
      if(success) {
        this.selectedPlacements = {}
      }
      this.showInsertOffersModal = false
    },
    deleteSelectedPixels() {
      let selectedPixels = this.selectedPixels
      let changedPlacements = this.removePixelsFromView(selectedPixels)
      this.removePixelsFromServer(changedPlacements)

      this.selectedPixels = {}
      this.showDeletePixelsModal = false
    },
    removePixelsFromServer(placements) {
      placements.forEach(p => {
        p.pixels.forEach(px => px.template_vars = JSON.stringify(px.template_vars))
        this.$http.put(this.resources.Management.tmEditPlacement(p.id),
            p)
            .then(r => r.json())
            .then(px => {
              p.pixels = placementUtils.parsePixels(px.pixels)
            })
      })
    },
    removePixelsFromView(pixelsMap) {
      let alteredPlacements = new Set()
      for(let pixelID in pixelsMap) {
        let placement = pixelsMap[pixelID]
        let pixels = placement.pixels
        let index = pixels.findIndex(v => v.id.toString() === pixelID)
        if(index > -1) {
          pixels.splice(index, 1)
        }
        alteredPlacements.add(placement)
      }
      return alteredPlacements
    },
    setFilter(f) {
      this.selectedPixels = {}
      this.filterForm = Object.assign({}, f)
      return this.fetchPlacements(1)
    },
    getNumbersAround(p, max = 0) {
      let t = 4
      let arr = []
      if(p < t) {
        p += t - (p - 1)
      }
      let m = max !== 0 ? Math.min(p + t, max) : p + t
      for (let i = p - t; i <= m; i++) {
        if(i === 0) {
          continue
        }
        arr.push(i)
      }
      return arr
    },
    fetchPlacements(page = 1) {
      this.pagination.currentPage = page
      let params = {
        page,
        limit: 25
      }

      Object.assign(params, this.filterForm)

      return this.$http.get(this.resources.Management.tmPlacements, {
          params
        })
        .then(r => r.json())
        .then(d => {
          let {limit, total, placements} = d
          if(!placements) {
            placements = []
          }
          this.pagination.limit = limit
          this.pagination.total = total

          placements.forEach(p => {
            if(!p.pixels) {
              p.pixels = []
            } else {
              p.pixels = placementUtils.parsePixels(p.pixels)
              p.pixels = this.orderItems(p.pixels, p.linksOrder)
            }
          })
          this.placements = placements
        })
    },
    paginate(n) {
      if(n === this.pagination.currentPage) {
        return
      }
      this.fetchPlacements(n)
    },
    onCreatePlacement(p) {
      this.placements.unshift(p)
      this.notifySuccess("Placement has been created")
    },
    submit() {
      this.isProcessing = true
      this.alert.msg = null
      this.$http.put(this.resources.Management.tmPlacements)
        .then(() => {
          this.alert.msg = "Ok"
          this.alert.ok = true
        })
        .catch(err => {
          let msg = err.body
          this.alert.msg = msg
          this.alert.ok = false
        })
        .then(() => {
          this.isProcessing = false
        })
    }
  }
}
</script>

<style lang="css" scoped>
.alert-2 {
  margin-top: 7px;
}
.sheets-link {
  display: block;
  margin-bottom: 20px;
  color: #404040;
  font-weight: bold;
  max-width: 152px;
}
.pagination {
  margin-top: 15px;
}
.pagination-23 {
  margin-top: 20px;
}
.no-results-2 {
  margin-top: 15px;
  display: block;
  margin-left: 3px;
  color: #505050;
  font-weight: bold;
}
.bt123 {
  display: flex;
  align-items: center;
}
.span12 {
  margin-right: 15px;
}
.delbtn-2345 {
  margin-right: 6px;
}
.btn123 {
  margin-right: 8px;
}
.btns-cont-2 {
  display: flex;
  flex-direction: column;
}
.btn-1-pixels {
  margin-bottom: 9px;
}
</style>
