<template lang="html">
  <div class="home-container">
    Loading...
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      data: []
    }
  },
  created: async function () {
    // await this.refreshInshgts()
    window.location = '/admin/dash';
  },
  methods: {
    async refreshInshgts() {
      let id=26
      try {
        this.loading = true
        const r = await this.$http.post(this.resources.Management.getServiceUrl("insights","getInsights" ),{
        id: String(id)
      })
        const data = await r.json()
        this.data = data.data
        this.loading = false
      } catch (e) {
        this.loading = false
        this.notifyError(`Can't connect to service`)
      }
    },
    async deleteAllInsights() {
      const r = await this.$http.post(this.resources.Management.getServiceUrl("insights","deleteAll"))
      this.refreshInshgts()
    },
    async deleteInsight(id) {
      console.log(id)
      const r = await this.$http.post(this.resources.Management.getServiceUrl("insights","delete"), {
        id: String(id)
      })
      this.refreshInshgts()
    }
  }
}
</script>
