<template>
<div>
    <div class="vws-table-rule">
        <table>
            <tr>
                <td class="ts_td nb">

                </td>
                <td v-for="t in timeArray" class="ts_td nb" @click="selectHour(t)">
                    {{ t }}
                </td>
            </tr>
            <tr v-for="d in dayTable">
                <td class="ts_td nb" @click="selectDay(d)">{{ d }}</td>
                <td v-for="t in timeArray" v-bind:class="tsArr[d + '_' + t]['classes']" @click="tsclick(d, t)">
                </td>
            </tr>
        </table>
        <div style="text-align: right;">
            <a href="javascript:void(0);" @click="clear_all">Clear All</a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            timeArray: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
            dayTable: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            timeArrayLength: 24,
            steps: 60,
            tclass: ['ts_td', 'ts_active'],
            tsArr: {},
            ttt: 0
        }
    },
    mounted() {
        if (this.value) {
            this.tsArr = this.value;
        }
    },
    created() {
        if (this.value) {
            this.tsArr = this.value;
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.tsArr = val;
            }
        }
    },
    methods: {
        tsclick(d, t) {
            console.log('clicked', d, t);
            this.tsArr[d + '_' + t]['checked'] = !this.tsArr[d + '_' + t]['checked'];
            if (this.tsArr[d + '_' + t]['checked']) {
                this.tsArr[d + '_' + t]['classes'] = ['ts_td', 'ts_active']; 
            } else {
                this.tsArr[d + '_' + t]['classes'] = ['ts_td']; 
            }

            this.$forceUpdate();
            this.$emit('update-tsArr', this.tsArr);
        },
        clear_all() {
            for (let k1 of this.dayTable) {
                for (let k2 of this.timeArray) {
                    this.tsArr[k1 + '_' + k2]['classes'] = ['ts_td'];
                    this.tsArr[k1 + '_' + k2]['checked'] = false;
                }
            }
            this.$forceUpdate();
            this.$emit('update-tsArr', this.tsArr);
        },
        selectHour(t) {
            let marked = false;
            let counter = 0;
            for (let k1 of this.dayTable) {
                for (let k2 of this.timeArray) {
                    if (k2 == t) {
                        if (this.tsArr[k1 + '_' + k2]['checked']) {
                            counter ++ ;
                        }
                   
                    }
                }
            }

            if (counter == 7) {
                marked = true;
            }

            // unselect
            if (marked) {
                for (let k1 of this.dayTable) {
                    for (let k2 of this.timeArray) {
                        if (k2 == t) {
                            this.tsArr[k1 + '_' + k2]['classes'] = ['ts_td'];
                            this.tsArr[k1 + '_' + k2]['checked'] = false;
                        }
                    }
                }
            } else {
                for (let k1 of this.dayTable) {
                    for (let k2 of this.timeArray) {
                        if (k2 == t) {
                            this.tsArr[k1 + '_' + k2]['classes'] = ['ts_td', 'ts_active'];
                            this.tsArr[k1 + '_' + k2]['checked'] = true;
                        }
                    }
                }
            }
          
            this.$forceUpdate();
            this.$emit('update-tsArr', this.tsArr);
        },
        selectDay(d) {
            console.log('clicked day', d);
            // check if full dat is already set 
            let marked = false;
            let counter = 0;
            for (let k1 of this.dayTable) {
                for (let k2 of this.timeArray) {
                    if (k1 == d) {
                        if (this.tsArr[k1 + '_' + k2]['checked']) {
                            counter ++ ;
                        }
                   
                    }
                }

            }
            if (counter == 24) {
                marked = true;
            }

            // unselect 
            if (marked) {
                for (let k1 of this.dayTable) {
                    for (let k2 of this.timeArray) {
                        if (k1 == d) {
                            this.tsArr[k1 + '_' + k2]['classes'] = ['ts_td'];
                            this.tsArr[k1 + '_' + k2]['checked'] = false;
                        }
                    }
                }
            } else {
                // select 
                for (let k1 of this.dayTable) {
                    for (let k2 of this.timeArray) {
                        if (k1 == d) {
                            this.tsArr[k1 + '_' + k2]['classes'] = ['ts_td', 'ts_active'];
                            this.tsArr[k1 + '_' + k2]['checked'] = true;
                        }
                    }
                }
            }
         
            this.$forceUpdate();
            this.$emit('update-tsArr', this.tsArr);
        }
    }
}
</script>

<style scoped>
.ts_td {
    border: 1px solid #000;
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
}
.nb {
    border: none;
}
.ts_active {
    background-color: gray;
}
</style>
