<template lang="html">

    <b-modal v-model="showAddAdvertiser" :title="title" @hide="$emit('update:is-show', false)">
        <div>
            <form @submit.prevent='saveAdvertiser'>
                <div>
                    Search By Offer ID:<br />
                    <input @keyup="getOfferDetailsByID" type="text" v-model.trim="offerId" class="def input-c-141 modal_input input-offerId">
                    <div v-if="offerName !== ''">
                        Offer Name: {{ offerName }}
                    </div>
                    <hr />
                </div>
                <div>
                    Advertiser:<br />
                    <b-form-select @change="getGlobalOfferByAdvId(advertiserForm.adv_id)" v-model="advertiserForm.adv_id" :options="advRows"></b-form-select>
                    <hr />
                </div>
                <div v-if="advertiserForm.adv_id !== 0 ">
                    Global Offer:<br />
                   <input type="text" v-model.trim="selectedOffer" @keyup="autoc" @focus="autoc" class="def input-c-141 modal_input input-select">
                    <div class="autoc" v-if="show_autoc">
                        <div v-for="row in autoc_res">
                            <div @click="autoc_select" class="autoc_item">
                                {{ row }}
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
              

            </form>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button :disabled="!((advertiserForm.adv_id !== 0 && selectedOffer.length !== 0) || offerName.length !== 0)" size="sm" variant="success" @click.prevent="submit">
                OK
            </b-button>
        </template>
    </b-modal> 
</template>
<script>
export default {
    
    props: ['isShow', 'shouldUpdate', 'isVtaRoute', 'placement'],
    data() {
        return {
            advertiserForm: {
                adv_id: 0,
                global_offer_id: 0,
            },
            offerId: 0,
            offerName: '',
            selectedOffer: '',
            submmited: false,
            globalOfferRows: [],
            advRows: [],
            title: 'Assoc Global Offer to Ad Set',
            autoc_res: [],
            show_autoc: false,
        }
    },
    async created() {
        await this.getData()
    },
    computed: {
        showAddAdvertiser: {
            get: function() {
                return this.isShow
            },
            set: function() {

            }
        }
    },
    methods: {
        async getData() {
            const data = await this.$http.post(this.resources.Management.getAdvertisersList)

            if (data.body.res === 'OK') {
               
                let res = await data.body.data
                this.advRows = res.map(advObj => {
                    return {
                        value: advObj.id,
                        text: advObj.adv_company_name
                    }
                })
            }
        },
        async getGlobalOfferByAdvId(advId) {
            const data = await this.$http.post(this.resources.Management.getGlobalOffersByAdvertise(advId, this.isVtaRoute))
            if (data.body.res === 'OK') {
                if (data.body.data !== null) {
                    let res = await data.body.data
                    this.globalOfferRows = res.map(offerObj => {
                        return {
                            value: offerObj.id,
                            text: offerObj.name
                        }
                    })
                }
            }
        },
        async getOfferDetailsByID(e) {
            e.preventDefault()
            // gets offer data by id - then show the adv data , offer name and and select the offer.
            if (this.offerId === 0) {
                this.notifyError(`Please enter offer ID`)
                return
            }
           try {
                if(this.offerId.length === 5 || this.offerId.length === 4) {
                    let res = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOfferById(this.offerId))
                    let data = await res.json()
                    if (data.res !== 'OK' || data.data.affiseID === 0) {
                        this.notifyError(`Offer ${this.offerId} does not exist.`)
                        this.offerId = 0;
                        return
                    }


                    let offerData = data.data
                    if (!offerData.is_global_offer) {
                        this.notifyError(`Offer ${offerData.name} is not global.`)
                        this.offerId = 0;
                        return
                    }

                    if (offerData.advertiser_id === 0) {
                        this.notifyError(`Offer ${offerData.name} has no related advertiser.`)
                        this.offerId = 0;
                        return
                    }
                    this.offerName = offerData.name
                    this.advertiserForm.adv_id = offerData.advertiser_id
                    this.advertiserForm.global_offer_id = this.offerId
                    await this.getGlobalOfferByAdvId(this.advertiserForm.adv_id)
                    this.selectedOffer = offerData.name
                }
               
           } catch(err) {
                this.notifyError(err)
           }
        },
        async submit() {
            if (this.advertiserForm.adv_id === 0 || this.selectedOffer.length === 0) {
                this.notifyError('Please select advertiser and global offer . ')
                return
            }

            let validateRes = await this.validateOffer()
            if (!validateRes) {
                this.notifyError('Please select global offer from the list. ')
                return
            }

            let selectedObj = this.globalOfferRows.filter(obj => {
                if (obj.text === this.selectedOffer) {
                    return obj
                }
            })
            this.advertiserForm.global_offer_id = selectedObj[0].value

            if (this.advertiserForm.global_offer_id === undefined || this.advertiserForm.global_offer_id === 0) {
                this.notifyError('An error occured ! ')
            }
            try {
                // Get global offer data to compare placement geo to offer geo
                const res = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOfferById(this.advertiserForm.global_offer_id))
                const oData = await res.json();
                const offer = oData.data;
                let offerGeo;
                if (offer.geo.length === 0) {
                    offerGeo = []
                } else {
                    offerGeo = offer.geo.split(',');
                }

                const isGeoMatch = await this.compare(this.placement.geo, offerGeo)
                if (!isGeoMatch) {
                    this.notifyError('Please make sure location targeting is the same for the global offer and the adset and try again.');
                    return;
                }

                const data = await this.$http.post(this.resources.Management.assocGlobalOfferToAdSet(this.advertiserForm.global_offer_id, this.$route.params.placementId))
                if (data.body.res === 'OK') {
                    if (data.body.data !== 0 ) {
                        this.$emit('shouldUpdate');
                        this.notifySuccess('Global offer has successfully created on ad set .')
                    }
                    await this.setDefault();
                    this.$emit('update:is-show', false)
                } else {
                    this.notifyError('An error occured ! ')
                }
            } catch (err) {
                console.log(err);
                this.notifyError('An error occured ! ')

            }
        },
        autoc: async function(e) {
            if (this.globalOfferRows.length === 0) {
                return
            }
            this.autoc_res = []
            for (let k in this.globalOfferRows) {
                if (this.globalOfferRows[k].text.startsWith(e.target.value)) {
                    this.autoc_res.push(this.globalOfferRows[k].text)
                }
            }
            if (this.autoc_res.length > 0) {
                this.show_autoc = true
            } else {
                this.show_autoc = false
            }
        },
        autoc_select: function(e) {
            this.show_autoc = false
            this.selectedOffer = e.target.innerHTML.trim()
          
        },
        async validateOffer() {
            let found = false 
            for (const k in this.globalOfferRows) {
                if (this.globalOfferRows[k].text === this.selectedOffer) {
                    found = true
                }
            }

            return found
        },
        async compare(array1, array2) {
            if (array1.length != array2.length) {
                return false;
            }

            array1 = array1.slice();
            array1.sort();
            array2 = array2.slice();
            array2.sort();
            for (var i = 0; i < array1.length; i++) {
                if (array1[i] != array2[i]) {
                    return false;
                }
            }

            return true;
        },
        async setDefault() {
            this.advertiserForm.adv_id = 0;
            this.advertiserForm.global_offer_id = 0;
            this.offerId = 0;
            this.offerName = '';
            this.selectedOffer = '';
        }
    },    
}
</script>
<style scoped>
.autoc {
    top: 30px; 
    left: 270px; 
    background-color: white; 
    cursor: pointer; 
    padding: 5px; 
    border: 1px solid gray;
    max-height: 200px;
    overflow-y: auto;
    }

.autoc_item {
    padding: 2px;
}

.autoc_item:hover {
    background-color: blue;
    color: white;
}

.input-select {
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3e515b;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%2329363d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input-offerId {
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3e515b;
    vertical-align: middle;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
</style>