<template>
    <div>
        <h2>Tag Generator</h2>
        <div>
            <a href="/apps/script-tags/data-manager">Data Manager</a>
        </div>
        <div>
            <div style="width: 300px;">
                <form @submit="generate">
                    <div style="">
                        <div class="input-cont">
                            Enter Stag ID:<br />
                            <b-form-input id="input-2" placeholder="" @blur="getStagName" v-model="stagId"></b-form-input>
                            <div v-if="showStagName">
                                Stag Name: {{ stageName }}
                            </div>
                        </div>

                        <div class="input-cont">
                            Traffic Type:<br />
                            <select v-model="selectedTrafficType" @change="trafficTypeChange">
                                <option v-for="v of traffic_types" :value="v.value">{{ v.text }}</option>
                            </select>
                        </div>

                        <div class="input-cont">
                            DSP:<br />
                            <select v-model="selectedDsp" @change="dspChange">
                                <option v-for="v of dsps" :value="v.value">{{ v.text }}</option>
                            </select>
                        </div>

                        <div class="input-cont" v-if="showSsp">
                            SSP:<br />
                            <select v-model="selectedSsp" @change="sspChange">
                                <option v-for="v of ssps" :value="v.value">{{ v.text }}</option>
                            </select>
                        </div>
                        <div class="input-cont" v-if="showDomains">
                            Domains:<br />
                            <select v-model="selectedDomain">
                                <option v-for="v of domains" :value="v.value">{{ v.text }}</option>
                            </select>
                        </div>

                        <div class="input-cont">
                            Sub2 Label (optional):<br />
                            <b-form-input v-model="sub2Label" id="input-2" placeholder=""></b-form-input>
                        </div>

                        <div>
                            <b-button id="input-5" type="submit" variant="success">Generate</b-button>
                        </div>
                        
                    </div>
                </form>
            </div>
            <div style="padding-top: 20px;" v-if="script_data !== ''">
                <div>{{ script_data }}</div>
                <div>
                    <a href="javascript:void(0)" @click="copySt">Copy</a> | <a href="javascript:void(0)" @click="testSt()">Test</a>
                </div>
                <iframe class="prev_iframe" ref="prev"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            stagId: '',
            stageName: '',
            showStagName: false,
            traffic_types: [],
            dsps: [],
            ssps: [],
            domains: [],
            selectedTrafficType: '',
            selectedDsp: '',
            selectedSsp: '',
            selectedDomain: '',
            sub2Label: '',
            script_data: '',
            showSsp: false,
            showDomains: false,
        }
    },
    async created() {
        await this.getTgBaseData()
    },
    methods: {
        async getTgBaseData() {
            const data = await this.$http.post(this.resources.Management.getTgBaseData, {})
            this.traffic_types = data.body.traffic_types
            this.dsps = data.body.dsps
            this.ssps = data.body.ssps

            const t = []
            for (let k in this.ssps) {
                if (this.ssps[k].value !== '') {
                    t.push(this.ssps[k])
                }
            }
            this.ssps = t
        },
        async getStagName() {
            if (this.stagId !== '') {
                const data = await this.$http.post(this.resources.Management.getTgStagData, {
                    id: this.stagId
                })
                this.stageName = data.body.stag_name
                if (data.body.stag_name !== '') {
                    this.showStagName = true
                } else {
                    this.showStagName = false
                }
            } else {
                this.showStagName = false
            }
        },
        async generate(e) {
            e.preventDefault()
            if (this.stagId === '' || this.stageName === '') {
                this.notifyError('Please make sure to enter a valid stag id')
                return
            }
            if (this.selectedTrafficType === '') {
                this.notifyError('Please make sure to select traffic type')
                return
            }
            if (this.selectedDsp === '') {
                this.notifyError('Please make sure to select DSP')
                return
            }
            if (this.selectedSsp === '') {
                this.notifyError('Please make sure to select SSP')
                return
            }
            if (this.selectedDomain === '') {
                this.notifyError('Please make sure to selected a domain')
                return
            }
            const data = await this.$http.post(this.resources.Management.tgGenerate, {
                stag_id: this.stagId,
                selectedTrafficType: this.selectedTrafficType,
                selectedDsp: this.selectedDsp,
                selectedSsp: this.selectedSsp,
                sub2Label: this.sub2Label,
                selectedDomain: this.selectedDomain,
            })
            this.script_data = data.body.script_data
            this.copySt()
        },
        async trafficTypeChange() {
            this.selectedDsp = ''
            this.selectedSsp = ''
            this.selectedDomain = ''
            this.showSsp = false
            this.showDomains = false
        },
        async dspChange() {
            this.showDomains = false
            this.selectedSsp = ''
            this.selectedDomain = ''
            const data = await this.$http.post(this.resources.Management.getTgSsps, {
                dsp: this.selectedDsp,
                traffic_type: this.selectedTrafficType
            })
            this.ssps = data.body.ssps
            this.showSsp = true
        },
        async sspChange() {
            this.selectedDomain = ''
            const data = await this.$http.post(this.resources.Management.getTgDomains, {
                ssp: this.selectedSsp,
                dsp: this.selectedDsp,
                traffic_type: this.selectedTrafficType
            })
            this.domains = data.body.domains
            this.showDomains = true
        },
        copySt() {
            navigator.clipboard.writeText(this.script_data);
            this.notifySuccess('Copied to clipboard')
        },
        testSt() {
            this.$refs['prev'].src = "data:text/html;charset=utf-8," + this.script_data
        }
    },

}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.prev_iframe {
    border: 0;
    width: 400px;
}
</style>