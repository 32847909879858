<template lang="html">
  <div class="all">
    <ul class="placements-table">
      <li v-for='d in data' class="placement-row">
        <span :class='{selected: isPlSelected(d)}' @click='togglePlSelect(d)' class="b r-id">{{d.externalID}}<template v-if='d.stag'>({{d.id}})</template>
          <i @click.stop='editPlacement(d)' class="edit-p-2 fa fa-pencil-square" aria-hidden="true"></i>
        </span>
        <span class="r-text">{{d.notes}}</span>
        <!-- <span class="r-text">{{d.targeting}}</span> -->
        <span class="r-style-2">{{d.country}}</span>
        <span class="r-style-2">{{d.os}}</span>
        <span @click='togglePxSelect(p, d)' class="r-px" v-for='p, i in d.pixels' :class='{highlight: highlightPixel(p), selected: isPxSelected(p)}'>
          <span class="sp-14124">
            {{i+1}}
          </span>
          <span>
            {{p.name}}
          </span>
        </span>
      </li>
    </ul>
    <section class='modal12' v-if='editedPlacement'>
      <div class="box123">
        <i class="x-btn x-btn12" @click='closeEditedPlacement'>X</i>
        <h3 class="h233">Edit Placement</h3>
        <PlacementForm :placement='editedPlacement' :isFloating='1'
        :onSubmit='closeEditedPlacement' :onCancel='closeEditedPlacement'
        :onDelete='e => delPlacementFromMemory(editedPlacement)'
        :onDuplicatePlacement='onDuplicatePlacement'
         />
      </div>
    </section>
  </div>
</template>

<script>
import PlacementForm from './PlacementForm.tmplacements'

export default {
  components: {PlacementForm},
  props: {
    data: {
    },
    filterForm: {},
    selectedPixels: {
      type: Object,
      default() { return {} }
    },
    selectedPlacements: {
      type: Object,
      default() { return {} }
    },
  },
  data() {
    return {
      editedPlacement: null
    }
  },
  created() {
    let query = this.$route.query
    let editPlacementID = query.placement_id
    if(editPlacementID) {
      let p = this.data.find(v => v.id == editPlacementID)
      if(!p) {
        return
      }
      this.editPlacement(p)
    }
  },
  destroyed() {
    document.body.classList.remove("freeze")
  },
  methods: {
    onDuplicatePlacement(pl) {
      pl.pixels.forEach(px => {
        try {
          px.template_vars = JSON.parse(px.template_vars)
        } catch(e) {}
      })
      this.data.unshift(pl)
      this.closeEditedPlacement()
    },
    delPlacementFromMemory(pl) {
      let data = this.data
      let i = data.indexOf(pl)
      data.splice(i, 1)
      this.closeEditedPlacement()
    },
    getHash(input) {
      var hash = 0, len = input.length;
      for (var i = 0; i < len; i++) {
        hash  = ((hash << 5) - hash) + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
      }
      return hash;
    },
    genColor(str) {
      if(!str) {
        return "black"
      }
      let colors = ["blue", "rgb(172, 5, 122)", "#bb0000", "orange", "#006868"]
      let n = this.getHash(str)
      if (n < 0) {
        n = n * -1
      }
      return colors[n % colors.length]
    },
    onPlacementDel(p) {
      this.closeEditedPlacement()
    },
    closeEditedPlacement() {
      this.editedPlacement = null
      document.body.classList.remove("freeze")
    },
    editPlacement(placement) {
      this.editedPlacement = placement
      document.body.classList.add("freeze")
    },
    togglePxSelect(px, placement) {
      let pid = px.id.toString()
      if(this.selectedPixels[pid]){
        this.$delete(this.selectedPixels, pid)
      } else {
        this.$set(this.selectedPixels, pid, placement)
      }
    },
    togglePlSelect(pl) {
      let pid = pl.id.toString()
      if(this.selectedPlacements[pid]){
        this.$delete(this.selectedPlacements, pid)
      } else {
        this.$set(this.selectedPlacements, pid, pl)
      }
    },
    isPxSelected(p) {
      let pid = p.id.toString()
      if(this.selectedPixels && this.selectedPixels[pid]) {
        return true
      }
      return false
    },
    isPlSelected(p) {
      let pid = p.id.toString()
      if(this.selectedPlacements && this.selectedPlacements[pid]) {
        return true
      }
      return false
    },
    highlightPixel(p) {
      let {inPixel, inOfferName} = this.filterForm
      if(inOfferName && p.name.toLowerCase().includes(inOfferName.toLowerCase())) {
        return true
      }
      if(inPixel && p.url.includes(inPixel)) {
        return true
      }
      return false
    },
    minimizeRow() {
      this.expandedRow = null
    },
    onPlacementDel(p) {
      this.data.splice(this.data.indexOf(p), 1)
      this.minimizeRow()
    },
    onRowClick(p) {
      if(this.expandedRow === p) {
        this.expandedRow = null
        return
      }
      this.expandedRow = p
    }
  }
}
</script>

<style lang="css" scoped>
.placements-table {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid gainsboro;
}
.placement-row {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dcdcdc;
  margin-bottom: 0;
  border-bottom: 9px solid #000;
  padding-bottom: 4px;
  background: #fff;
  max-width: 400px;
  min-width: 200px;
}
.placement-row > span {
  padding: 5px 8px;
  max-height: 100px;
  border: 1px solid #00000000; /* transparent border */
  border-bottom: 1px solid gainsboro;
  min-height: 34px;
  padding-right: 37px;
}
.placement-row > span.r-text {
  min-height: 35px;
  color: black;
  text-align: center;
  font-weight: bold;
}
.r-id {
  text-align: center;
  background: #20f320;
  color: black;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.r-px {
  background: whitesmoke;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 6px 5px 0px !important;
}
.r-style-2 {
  font-weight: bold;
}
.highlight {
  background: yellow;
}
.selected {
  background: #9fe1ff;
  border: 1px solid #55a4c7 !important;
}
.modal12 {
  position: fixed;
  min-width: 300px;
  min-height: 500px;
  background: #4a4a4a40;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}
.box123 {
  min-width: 300px;
  background: white;
  max-height: 91vh;
  overflow-y: auto;
  border: 1px solid #7db6ff;
  border-radius: 3px;
  position: relative;
}
.h233 {
  padding-left: 11px;
  padding-top: 8px;
  color: #235698;
}
.x-btn12 {
  position: absolute;
  right: 0;
  font-style: normal;
  background: white;
  border: 1px solid gainsboro;
  padding: 2px 8px;
  cursor: pointer;
}
.x-btn12:hover {
  background: #f5f5f5;
}
.edit-p-2 {
  position: absolute;
  right: 4px;
  color: #004800;
  font-size: 23px;
}
.edit-p-2:hover {
  color: #006f00;
}
.sp-14124 {
  width: 22px;
  display: inline-block;
  text-align: center;
  font-style: italic;
  color: #565656;
  font-size: 13px;
}
</style>
