<template>
    <div>
        <b-modal v-model="show_modal" :header-bg-variant="'info'" :header-text-variant="'dark'" title="Info">
            <p>{{ info_data }}</p>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="primary" size="sm" class="float-right" @click="show_modal=false"> Close </b-button>
                </div>
            </template>
        </b-modal>
        <h1>Data Manager</h1>
        <div class="dm-links-div">
            <h1>
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('DSP list that we working with. In use under tag generator')"  icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-dsps">DSP's</a> |
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('SSP whitelist. In use of our protection system')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/wl">SSP's</a>  | 
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Global Bundle ID BL. In use of our protection system')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/bl">Bundle ID Blacklisting</a> | 
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('iOS and Android version list')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-os">OS version</a>  | 
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Script tag version management. In use under domain management')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-stags-versions">Stags Versions</a> |
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Add \ Edit CDN url’s. In use under domain management')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-cdn">Assets CDN</a> | 
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Appsflyer Partner and Agency list')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-af-prt-pid">AppsFlyer Accounts</a>| 
                </div> 
                <!-- <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Appslyer account to be shown in AF monitor')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-appsflyer">AF Monitor</a> | 
                </div>  -->
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Bulk sync for Affise PID postback to our DSP')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/man/sync_postback">Affise Postback Sync</a> | 
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Control global bundle WL. In use in placement setup')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-bundle-wl-details">Bundle's WL</a> |
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Bundle and App name list for block email we get from Appsflyer')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-af-app-name">AF App Name</a> |
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Employee list by team')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-operation-csm">Employee Role</a> |
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Country\ OS\ Media Analysts')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-geo-list">GEO List</a> |
                </div> 
                <div>
                    <b-icon style="cursor: pointer; width: 20px; margin-right: 5px;" @click="setModalInfoData('Vertical list in use in global offer')" icon="question-circle-fill" aria-label="Help"></b-icon> 
                    <a href="/apps/script-tags/data-manager-vertical">Vertical List</a> <br/> 
                </div> 
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            traffic_types: [],
            dsps: [],
            ssps: [],
            info_data: '',
            show_modal: false,
        }
    },
    async created() {
        
    },
    methods: {
        setModalInfoData(text) {
            this.info_data = text;
            this.show_modal = !this.show_modal
        }
    },

}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.dm-links-div {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}
.dm-links-div h1 {
  display: flex;
  flex-wrap: wrap;         
  align-items: center;      
  justify-content: center;  
  gap: 10px;                
}

.dm-links-div div {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.dm-links-div div a {
    padding-right: 10px;
}
</style>