<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        
                        <td @click="sortByCol('country')">
                            GEO
                            <div  v-show='dataOptions.sortdatafield === "country"' class="sort-icon" >
                                <i :class='dataOptions.sortorder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </td>
                        
                        <td @click="sortByCol('os')">
                            OS
                            <div  v-show='dataOptions.sortdatafield === "os"' class="sort-icon" >
                                <i :class='dataOptions.sortorder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </td>
                        
                        <td @click="sortByCol('media_analyst_manager')">
                            Media Analyst
                            <div  v-show='dataOptions.sortdatafield === "media_analyst_manager"' class="sort-icon" >
                                <i :class='dataOptions.sortorder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </td>

                        <td @click="sortByCol('om')">
                            Operation Manager
                            <div  v-show='dataOptions.sortdatafield === "om_manager"' class="sort-icon" >
                                <i :class='dataOptions.sortorder === "desc" ? "fa fa-sort-up" : "fa fa-sort-down"'></i>
                            </div>
                        </td>

                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>

                        <td>
                            <div v-if="!row.edit">{{ row.country }}</div>
                            <div v-if="row.edit">
                                <b-form-input @change="selectGeo('edit')" v-model="selected_geo" list="geo" type="text" placeholder="GEO" ></b-form-input>
                                <datalist id="geo" >
                                    <option>Manual Option</option>
                                    <option v-for="country in countries">{{ country }}</option>
                                </datalist>
                            </div>
                        </td>

                        <td>
                            <div v-if="!row.edit">{{ row.os === 'ios' ? 'iOS' : 'Android' }}</div>
                            <b-form-select  v-if="row.edit" type="text" v-model="editRow.os" placeholder="OS" :options="os_options"></b-form-select>
                        </td>

                        <td>
                            <div v-if="!row.edit">{{ row.media_analyst_manager }}</div>
                            <b-form-select v-if="row.edit" type="text" v-model="editRow.media_analyst_manager_id" placeholder="Media Analyst" :options="media_analyst_options"></b-form-select>
                        </td>

                        <td>
                            <div v-if="!row.edit">{{ row.om }}</div>
                            <b-form-select v-if="row.edit" type="text" v-model="editRow.om_manager_id" placeholder="Operation Manager" :options="om_rows"></b-form-select>
                        </td>

                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRowf(row.id)">Edit</a> <a class="btn btn-primary" href="javascript:void(0);" @click="deleteEntity(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        
                        <td>
                            <b-form-input @change="selectGeo('add')" v-model="selected_geo" list="geo" type="text" placeholder="GEO" ></b-form-input>
                            <datalist id="geo" >
                                <option>Manual Option</option>
                                <option v-for="country in countries">{{ country }}</option>
                            </datalist>
                        </td>
                        
                        <td>
                            <b-form-select type="text" v-model="newRow.os" placeholder="OS" :options="os_options"></b-form-select>
                        </td>
                        
                        <td>
                            <b-form-select type="text" v-model="newRow.media_analyst_manager_id" placeholder="Media Analyst" :options="media_analyst_options"></b-form-select>
                        </td>
                        
                        <td>
                            <b-form-select type="text" v-model="newRow.om_manager_id" placeholder="Media Analyst" :options="om_rows"></b-form-select>
                        </td>

                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
import countries from '@/assets/countries.js'
import {CoolSelect} from "vue-cool-select";

export default {
    components: {
        CoolSelect
    },
    data() {
        return {
            list_id: this.$route.params.id,
            newRow: {
                id: 0,
                geo: '',
                os: 'ios',
                media_analyst_manager_id: 1,
                om_manager_id: 1,
            },
            editRow: {
                id: 0,
                geo: '',
                os: '',
                media_analyst_manager_id: 0,
                om_manager_id: 0,
            },
            dataOptions: {},
            countries: countries,
            selected_geo: '',
            all_geos: [],
            os_options: [],
            media_analyst_options: [],
            rows: [],
            showNewRow: true,
            isAdmin: false,
            showLegend: false,
            om_rows: [],
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    methods: {
        async save(e) {
            e.preventDefault();
            if (await this.validateUnique(this.newRow)) {
                this.notifyError('Geo-OS already exists.')
                return
            }

            const data = await this.$http.post(this.resources.Management.saveGeoList, {
                ...this.newRow
            });
            if (data.body.res !== 'OK') {
                this.notifyError('GEO list did not saved')
            }

            this.newRow.geo = '';
            this.selected_geo = '';
            this.newRow.os = '';
            this.newRow.media_analyst_manager_id = 0;
            this.getListData();
        },
        async getListData() {
            // get media analyst
            this.os_options = [
                { value: 'ios', text: 'iOS' },
                { value: 'android', text: 'Android' },
            ]
            
            const data = await this.$http.post(this.resources.Management.getMediaAnalystManagers, {});
            for (let k in data.body.data) {
                data.body.data[k].edit = false;
            }

            let ma_rows = data.body.data;
            this.media_analyst_options = ma_rows.map((row) => {
                return {
                    value: row.id,
                    text: row.media_analyst_manager
                };
            })

            let res = await this.$http.post(this.resources.Management.getDmOms, {});
            for (let k in res.body.data) {
                res.body.data[k].edit = false
            }
            this.om_rows = res.body.data.map((row) => {
                return {
                    value: row.id,
                    text: row.om
                }
            })

            for (const key in this.countries) {
                this.all_geos.push(this.countries[key]);
            }

            // get all rows
            const listData = await this.$http.post(this.resources.Management.getGeoLists, {});
            for (let k in listData.body.data) {
                listData.body.data[k].edit = false
                listData.body.data[k].country = this.countries[listData.body.data[k].geo] === undefined ? '' : this.countries[listData.body.data[k].geo];
                let ma_obj = ma_rows.filter(row => {
                    if (row.id === listData.body.data[k].media_analyst_manager_id) {
                        return row;
                    }
                });
                let om_obj = this.om_rows.filter((row) => {
                    if (row.value === listData.body.data[k].om_manager_id) {
                        return row;
                    }
                })
                listData.body.data[k].media_analyst_manager = ma_obj[0].media_analyst_manager;
                listData.body.data[k].om = typeof om_obj[0]?.text !== 'undefined' ? om_obj[0]?.text : '';
            }
            this.rows = listData.body.data;
        },
        async deleteEntity(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteGeoList, {
                id,
            });
            this.getListData();
        },
        async editRowf(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.editRow.id = id;
                    this.editRow.geo = this.rows[k].geo;
                    this.editRow.os = this.rows[k].os;
                    this.editRow.om_manager_id = this.rows[k].om_manager_id;
                    this.editRow.media_analyst_manager_id = this.rows[k].media_analyst_manager_id;
                    this.selected_geo = this.countries[this.rows[k].geo];
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow() {
            //validate
            if (await this.validateUnique(this.editRow)) {
                this.notifyError('Row can not be inserted twice.');
                return;
            }
            
            this.showNewRow = true;
            const data = await this.$http.post(this.resources.Management.saveGeoList, {
                ...this.editRow
            });

            if (data.body.res !== 'OK') {
                this.notifyError('GEO list did not saved')
            }

            this.editRow = {
                id: 0,
                geo: '',
                os: '',
                media_analyst_manager_id: 0
            };
            this.selected_geo = '';
            this.getListData();
            
        },
        async selectGeo(type) {
            for (const key in countries) {
               if (countries[key] === this.selected_geo) {
                    if (type === 'add') {
                        this.newRow.geo = key;
                    } else {
                        this.editRow.geo = key;
                    }
                    
               }
            }
        },
        async validateUnique(row) {
            // check new row/edit row unique
            for (const k in this.rows) {
                if (this.rows[k].geo === row.geo && this.rows[k].os === row.os && this.rows[k].id !== row.id) {
                    return true;
                }
            }
            return false;
        },
        sortByCol(field) {
            let dataOptions = this.dataOptions
            if (dataOptions.sortdatafield === field) {
                dataOptions.sortorder = dataOptions.sortorder === 'desc' ? 'asc' : 'desc'
            } else {
                Object.assign(dataOptions, {
                    sortdatafield: field,
                    sortorder: 'desc'
                })
            }
            
            // Client side sorting
            if (dataOptions.sortorder === 'desc') {
                this.rows.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
            } else {
                this.rows.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
            }

        }
    },
}
</script>

<style scoped>
.link-div {
    padding-top: 40px;
    text-align: center;
}

.sort-icon {
    position:absolute;
    display: inline-block;
    padding-left: 1%;
}
</style>
