<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager-os">Back to OS</a>
        </div>
        <h2>Data Manager</h2>
        <div style="width: 700px;">
            <form @submit="saveDsp">
                OS Name:<br />
                <b-form-input id="input-2" placeholder="" v-model="edit_os.os_name"></b-form-input><br/>
                <b-button id="input-5" type="submit" variant="success">Save</b-button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            edit_os: {
                id: 0,
                os_name: '',
            }
        }
    },
    async created() {
        await this.tgGetOs()
    },
    methods: {
        async saveDsp(e) {
            e.preventDefault()
            const data = await this.$http.post(this.resources.Management.tgSaveOs, {
                id: this.edit_os.id,
                os_name: this.edit_os.os_name
            })
            if (data.body.id != 0) {
                // window.location = '/apps/script-tags/data-manager-edit-os/' + data.body.id
            } else {
                this.notifySuccess('Saved')
            }
        },
        async tgGetOs() {
            if (this.$route.params.id !== "0") {
                const data = await this.$http.post(this.resources.Management.tgGetOs, {
                    id: parseInt(this.$route.params.id)
                })
                this.edit_os.id = data.body.dsp_entity.id
                this.edit_os.os_name = data.body.dsp_entity.dsp_name
            }
        }
    },
}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.subs_textarea {
    resize: none;
    width: 100%;
    height: 400px;
}
</style>