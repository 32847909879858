<template lang="html">
  <div class="all">
    <form class="form-42" @submit.prevent='onSubmit'>
      <div class="form-fields-1">
        <div class="form-inputs">
          <label >Advertiser ID:</label>
          <input class="def" v-model='form.id'>
        </div>

        <div class="form-inputs">
          <label >Name:</label>
          <input class="def" v-model='form.name'>
        </div>

        <div class="form-inputs">
          <label >Description:</label>
          <input class="def" v-model='form.description'>
        </div>
      </div>
      <button type="submit" class="btn btn-success">{{editMode ? "Submit Changes": "Create"}}</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    onCreate: {},
    onEdit: {},
    data: {}
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        description: null
      },
    }
  },
  created() {
    Object.assign(this.form, this.data)
  },
  computed: {
    editMode() {
      return !!this.data
    }
  },
  methods: {
    createRow(form) {
      this.$http.post(this.resources.sourcesControl.advs, form)
        .then(r => r.json())
        .then(id => {
          form.id = id
          this.onCreate && this.onCreate(form)
          this.form = {
            id: null,
            name: null,
            description: null
          }
          this.notifySuccess("Inserted new advertiser")
        })
        .catch(err => {
          console.log(err)
          this.notifyError("Error occured")
        })
    },
    editRow(form) {
      this.$http.put(this.resources.sourcesControl.varById(form.id), form)
        .then(r => r.json())
        .then(affiseVar => {
          affiseVar.timings = affiseVar.timings.map(t => t.substr(0,5)).join("\n")

          this.onEdit && this.onEdit(affiseVar)
          // this.form = { title: null, id: null, payout: null}
        })
        .catch(err => {
          console.log(err)
          this.notifyError("Error occured")
        })
    },
    onSubmit() {
      let form = Object.assign({}, this.form)

      if(!form.id || !form.name) {
        this.notifyError("Please fill Adv ID and Adv Name")
        return
      }
      Promise.resolve() // this.$validate.stags.link(this.form)
      .then(() => {
        if(this.editMode) {
          this.editRow(form)
        } else {
          this.createRow(form)
        }
      })
      .catch(err => {
        this.notifyError(err.msg)
      })
    }
  }
}
</script>

<style lang="css" scoped>
.form-fields-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.form-fields-1 > div {
  display: flex;
  justify-content: space-between;
  width: 550px;
  margin-bottom: 7px;
}
.form-42 {
  margin: 22px 0;
}
.t-p-2 {
  display: flex;
  flex-direction: column;
}
.p-242 {
  margin-top: 7px;
}
.text-12 {
  border: 1px solid gainsboro;
  resize: none;
  height: 160px;
  width: 53px;
  padding: 6px 13px 0 28px;
  box-sizing: content-box;
}
.text-13 {
  border: 1px solid gainsboro;
  resize: none;
  height: 160px;
  width: 400px;
  padding: 6px 0px 0 9px;
  box-sizing: content-box;
  margin-top: 7px;
  font-size: 14px;
}
.div-2514 {
  display: flex;
  justify-content: space-between;
}
.t-p-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 170px;
}
.r-512 {
  margin-bottom: 3px;
}
.input-52 {
  margin-top: 11px;
}
.lab-2 {
  background: #e8e8e8;
  padding: 2px;
  border-radius: 2px;
}
</style>
