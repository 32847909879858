<template lang="html">
  <div class="card">
    <button v-show='collapse' class="card-header" :data-toggle="collapse ? 'collapse' : ''" :data-target="'#' + htmlId" aria-expanded="false" :aria-controls="htmlId">
      <span><slot name="show-title"></slot></span>
    </button>
    <div :class="{collapse: true, show: show || !collapse, 'card-content': true}" :id="htmlId">
      <!-- <h4 :class="{'card-header': !collapse, 'card-header-no-collapse': collapse}">
        <slot class="card-title" v-show="card-title" name="card-title"></slot>
      </h4> -->
      <div class="card-block">
        <slot></slot>
      </div>
    </div>
</div>
</template>

<script>
import * as act from '@/act-types'
export default {
  props: {
    show: {
      default: !1
    },
    collapse: {
      default: !0
    }
  },
  data() {
    return {
      htmlId: null
    }
  },
  created() {
    this.$store.dispatch(act.GET_NEXT_ID)
      .then(id => {
        this.htmlId = id
      })
  }
}
</script>

<style lang="css" scoped>
.card-header-no-collapse {
padding: 0.75rem 1.25rem;
  font-weight: normal;
  color: #424242;
}
.card-content {
  border: 1px solid #dadada;
  border-top: 0;
}
.card-header {
  font-size: 15px;
  padding: 8px 13px;
  border: 1px solid gainsboro;  
}
</style>
<style>
.card {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
</style>
