<template>
  <div id="all">
    <div id="selection-view">

      <div id="logo">
        <h2> Campaign Performance Overview </h2>
        <img v-if="selected" class="logo-img" src="../../../assets/Picture1.png" alt="">
      </div>
      <div>
        <div class="form-select">
          <label class="modal_label-md">ID/Title: </label>
          <b-form-select v-model="selected" :options="options" size="sm" class="mt-3"></b-form-select>
        </div>
        <div class="form-select">
          <label class="modal_label-md">Countries: </label>
          <b-form-select v-model="selected1" :options="options1" size="sm" class="mt-3"></b-form-select>
        </div>
        <div class="form-select">
          <label class="modal_label-md">OS: </label>
          <b-form-select v-model="selected2" :options="options2" size="sm" class="mt-3"></b-form-select>
        </div>
        <div class="form-select">
          <label class="modal_label-md">Time Frame: </label>
          <b-form-select v-model="selected3" :options="options3" size="sm" class="mt-3"></b-form-select>
        </div>
      </div>
    </div>
    <div id="performence-view" v-if="selected">

      <div id="info-cubes">
        <div class="cube">
          <div class="cube-text">
            <span class="number-info">{{ chartDataByDatasets["Impressions"].slice(-1)[0].toLocaleString() }}</span>
            <p class="title-info">IMPRESSIONS</p>
          </div>
          <b-icon-people-fill class="icon" variant="primary" font-scale="2"></b-icon-people-fill>
        </div>

        <div class="cube">
          <div class="cube-text">
            <span class="number-info">{{ chartDataByDatasets["Clicks"].slice(-1)[0].toLocaleString() }}</span>
            <p class="title-info">CLICKS</p>
          </div>
          <b-icon-cursor-fill class="icon" variant="primary" font-scale="2"></b-icon-cursor-fill>
        </div>

        <div class="cube">
          <div class="cube-text">
            <span class="number-info">{{ chartDataByDatasets["Installs"].slice(-1)[0].toLocaleString() }}</span>
            <p class="title-info">INSTALLS</p>
          </div>
          <b-icon-cloud-download-fill class="icon" variant="primary" font-scale="2"></b-icon-cloud-download-fill>
        </div>

        <div class="cube">
          <div class="cube-text">
            <span class="number-info">{{ chartDataByDatasets["Events"].slice(-1)[0].toLocaleString() }}</span>
            <p class="title-info">EVENTS</p>
          </div>
          <b-icon-calendar-event-fill class="icon" variant="primary" font-scale="2"></b-icon-calendar-event-fill>
        </div>

      </div>
      <div id="chart">

        <campaigns-chart-vue :datasetsData="chartDataByDatasets" :chartLabels="chartLabels" />
      </div>
      <div>
        <div class="form-select">
          <label class="modal_label-md">Creative: </label>
          <b-form-select v-model="selected4" :options="options4" size="sm" class="mt-3"></b-form-select>
        </div>
            <b-table class="data-table" striped hover :items="tableRecords"></b-table>
      </div>




    </div>

  </div>
</template>

<script>
import countries from '@/assets/countries.js'
import CampaignsChartVue from './CampaignsChart.vue'
import moment from 'moment'
export default {
  components: {
    CampaignsChartVue
  },
  props: {
  },
  computed: {
    timeFrame() {
      return this.selected3.split(',');
    },
    chartDataByDatasets() {
      const dataDoordash = {
        Doordash_US_Android_300x250_$0_Delivery_Fee_Evergreen_1: {
        Impressions: [1888926,1856504,1862644,1887396,1863334,1887593,1829520],
        Clicks: [43929,43175,44954,44938,42349,41947,40656],
        CPA: [44,46,48,49,58,55,61],
        Installs: [312,333,358,374,391,418,442]
        },
        Doordash_US_Android_320x50_$0_Delivery_Fee_Evergreen_2: {
        Impressions: [2152497,2072376,2112838,2201962,2075077,2097325,1974161],
        Clicks: [43929,43175,44954,44938,42349,41947,40289],
        CPA: [38,42,44,46,48,49,48],
        Installs: [265,281,306,321,358,375,394]
        },
      }
      dataDoordash.All = {
        Impressions: dataDoordash.Doordash_US_Android_300x250_$0_Delivery_Fee_Evergreen_1.Impressions.map((val,index) => val + dataDoordash.Doordash_US_Android_320x50_$0_Delivery_Fee_Evergreen_2.Impressions[index]),
        Clicks: dataDoordash.Doordash_US_Android_300x250_$0_Delivery_Fee_Evergreen_1.Clicks.map((val,index) => val + dataDoordash.Doordash_US_Android_320x50_$0_Delivery_Fee_Evergreen_2.Clicks[index]),
        CPA: dataDoordash.Doordash_US_Android_300x250_$0_Delivery_Fee_Evergreen_1.CPA.map((val,index) => val + dataDoordash.Doordash_US_Android_320x50_$0_Delivery_Fee_Evergreen_2.CPA[index]),
        Installs: dataDoordash.Doordash_US_Android_300x250_$0_Delivery_Fee_Evergreen_1.Installs.map((val,index) => val + dataDoordash.Doordash_US_Android_320x50_$0_Delivery_Fee_Evergreen_2.Installs[index])
      };
      const timeFrameValue = Number(this.timeFrame[1]);
      return {
        Impressions: timeFrameValue >= 7 ? [...[...Array(timeFrameValue - 7)].map((_) => {
          return Math.floor(Math.random() * (Math.max(...dataDoordash[this.selected4].Impressions) - Math.min(...dataDoordash[this.selected4].Impressions))) + Math.min(...dataDoordash[this.selected4].Impressions)
        }), ...dataDoordash[this.selected4].Impressions,] : dataDoordash[this.selected4].Impressions.slice(timeFrameValue * -1),
        Clicks: timeFrameValue >= 7 ? [...[...Array(timeFrameValue - 7)].map((_) => {
          return Math.floor(Math.random() * (Math.max(...dataDoordash[this.selected4].Clicks) - Math.min(...dataDoordash[this.selected4].Clicks))) + Math.min(...dataDoordash[this.selected4].Clicks)
        }), ...dataDoordash[this.selected4].Clicks,] : dataDoordash[this.selected4].Clicks.slice(timeFrameValue * -1),
        Installs: timeFrameValue >= 7 ? [...[...Array(timeFrameValue - 7)].map((_) => {
          return Math.floor(Math.random() * (Math.max(...dataDoordash[this.selected4].Installs) - Math.min(...dataDoordash[this.selected4].Installs))) + Math.min(...dataDoordash[this.selected4].Installs)
        }), ...dataDoordash[this.selected4].Installs,] : dataDoordash[this.selected4].Installs.slice(timeFrameValue * -1),
        Events: timeFrameValue >= 7 ? [...[...Array(timeFrameValue - 7)].map((_) => {
          return Math.floor(Math.random() * (Math.max(...dataDoordash[this.selected4].CPA) - Math.min(...dataDoordash[this.selected4].CPA))) + Math.min(...dataDoordash[this.selected4].CPA)
        }), ...dataDoordash[this.selected4].CPA,] : dataDoordash[this.selected4].CPA.slice(timeFrameValue * -1),
      };
    },
    chartLabels() {
      const timeFrameScale = this.timeFrame[0];
      const timeFrameValue = Number(this.timeFrame[1]);
      return [...Array(timeFrameValue)].map((_, i) => {
        return moment().subtract(i, timeFrameScale).format("MMM, DD")
      }).reverse()
    },
    tableRecords() {
      const impressions = this.chartDataByDatasets["Impressions"].reduce((a,b) => a + b , 0);
      const clicks = this.chartDataByDatasets["Clicks"].reduce((a,b) => a + b , 0);
      const ctr = ((clicks / impressions) * 100).toFixed(2);
      const installs = this.chartDataByDatasets["Installs"].reduce((a,b) => a + b , 0);
      const cr = ((installs / clicks) * 100).toFixed(2);
      const cpa = this.chartDataByDatasets["Events"].reduce((a,b) => a + b , 0);
      const cpacr = ((cpa / installs) * 100).toFixed(2);
        return [
            {
              metric: "Impressions",
              value: impressions.toLocaleString()
            },
            {
              metric: "Clicks",
              value: clicks.toLocaleString()
            },
                        {
              metric: "CTR",
              value: `${ctr}%`
            },
                        {
              metric: "Installs",
              value: installs
            },
                        {
              metric: "CR",
              value: `${cr}%`
            },
                        {
              metric: "Registrations",
              value: "n/a"
            },
            {
              metric: "Install to Reg CR",
              value: "n/a"
            },
                        {
              metric: "CPA",
              value: cpa
            },
                        {
              metric: "Install to CPA CR",
              value: `${cpacr}%`
            },
                        {
              metric: "Cost",
              value: "*********"
            },
                        {
              metric: "Revenue",
              value: "***********"
            },
        ];
    },
  },
  data() {
    return {
      selected: null,
      options: [
        {
          value: null,
          text: 'please select a option'
        },
        {
          value: 'a',
          text: 'Doordash'
        },
        {
          value: 'b',
          text: 'Bet365'
        },
        {
          value: 'C',
          text: 'BetMGM'
        },
        {
          value: 'd',
          text: 'Credit Karma'
        },
        {
          value: 'e',
          text: 'Epoch TImes'
        },
        {
          value: 'f',
          text: 'Etoro'
        },
        {
          value: 'g',
          text: 'Shopee PH'
        },
        {
          value: 'h',
          text: 'Snoop'
        },
        {
          value: 'i',
          text: 'Survey Junkie'
        },
        {
          value: 'j',
          text: 'Vivid'
        },
        {
          value: 'k',
          text: 'Wish'
        }

      ],
      selected1: null,
      options1: [{
        value: null,
        text: 'Please select an option'
      }, ...Object.keys(countries).map(countryKey => ({
          value : countryKey,
          text : countries[countryKey]
      }))],
      selected2: null,
      options2: [{
        value: null,
        text: 'Please select an option'
      },
{
        value: 'Android',
        text: 'Android'
      },
      ],
      selected3: 'days,7',
      options3: [{
        value: 'days,2',
        text: 'Yesterday'
      },
      {
        value: 'days,7',
        text: 'Last 7 Days '
      },
      {
        value: 'days,30',
        text: 'Last Month '
      },
      {
        value: 'days,60',
        text: 'Last 2 Months'
      },
      {
        value: 'days,90',
        text: 'Last 3 Months'
      },

      ],
      selected4: 'All',
      options4: [{
        value: 'All',
        text: 'All'
      },
      {
        value: 'Doordash_US_Android_320x50_$0_Delivery_Fee_Evergreen_2',
        text: 'Doordash US Android 320x50 $0 Delivery Fee Evergreen 2'
      },
      {
        value: 'Doordash_US_Android_300x250_$0_Delivery_Fee_Evergreen_1',
        text: 'Doordash US Android 300x250 $0 Delivery Fee Evergreen 1'
      },
      ],
    }
  }
}
</script>

<style scoped>

.number-info {
  font-family: Arial, sans-serif;
  font-size: larger;
}

.title-info {
  color: cornflowerblue;
  font-weight: bolder;
  font-size: smaller;
}

.cube-text {
  display: flex;
  flex-direction: column;
  row-gap: 1%;
  flex: 2;
  margin-top: 2%;
  margin-left: 2%;
}

#info-cubes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.cube {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.5%;
  background-color: aliceblue;
}

#performence-view {
  display: flex;
  flex-direction: column;
}

.logo-img {
  position: absolute;
  margin-left: 70%;
  z-index: 2
}

.form-select {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  row-gap: 20px;
  column-gap: 10px;
}

.data-table {
margin-top: 2%;
}

.modal_label-md {
  width: 30%;
}
</style>
