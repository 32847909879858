<template>
    <div>
        <SearchModal :is-show.sync=showLegend></SearchModal>
        <h2>Bundle Pool</h2>
        <div v-if="isAdmin">
            <form @submit="createList">
                Create New List:
                <table>
                    <tr>
                        <td>
                            <b-form-input type="text" style="width: 400px;" placeholder="New List Name" v-model="list_name" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-primary" value="Create"/>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
        <div style="padding-top: 20px; padding-bottom: 20px;">
            Filter by name:<br />
            <b-form-input type="text" style="width: 400px;" placeholder="List Name" v-model="filterVal" @keyup="filterChange" />
        </div>
        <div v-if="!page_loaded">Loading ...</div>
        <div v-if="page_loaded">
            
            <div style="width: 100%;">
                <ResizableDataTable :rows_data.sync="bundles_lists" :show_c.sync="show_col" :fields="fields" :nonIntFields= "nonIntFields" :delete="deleteList" :download="downloadList"  :show_columns_name="'bundle-pool'"></ResizableDataTable>
            </div>
            <div class="search_div">
                <form @submit="searchBundle">
                    Search Bundle by App Name or Bundle ID:
                    <table>
                        <tr>
                            <td>
                                <b-form-input type="text" style="width: 400px;" placeholder="" v-model="bundle_search" />
                            </td>
                            <td>
                                <input type="submit" class="btn btn-primary" value="Search"/>
                                <div class="search_i">
                                    <b-icon style="cursor: pointer;" @click="showLegend = !showLegend" icon="question-circle-fill" aria-label="Help"></b-icon>
                                </div>
                            </td>
                        </tr>
                    </table>
                </form>
                <div>
                    <div v-if="search_processing">
                        Processing...
                    </div>
                    <div class="search_h" v-if="no_results">
                            No results
                        </div>
                    <div v-if="! search_processing">
                        <div class="search_h" v-if="bundle_search_res.length !== 0">
                            Total: {{ bundle_search_res.length  }} <a v-if="isAdmin" href="javascript:void(0)" @click="deleteAll">Delete All</a>
                        </div>
                        <div v-for="r in bundle_search_res" class="search_res_div">
                            # {{ r.id }}<br />
                            Bundle ID: {{ r.bundle_id }}<br />
                            App Name: {{ r.app_name }}<br />
                            List Name: {{ r.list_name }}<br />
                            <a v-if="isAdmin" href="javascript:void(0)" @click="deleteBundle(r.id)">Delete</a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </div>
</template>

<script>
import SearchModal from '../../../components/SearchModal.vue';
import ResizableDataTable from '../../../components/shared/ResizableDataTable.vue';
import {
    defineComponent
} from '@vue/composition-api'
export default {
    components: {
        SearchModal, ResizableDataTable
    },
    data() {
        return {
            list_name: '',
            bundles_lists: [],
            bundle_lists_bk: [],
            filterVal: '',
            isAdmin: false,
            bundle_search: '',
            bundle_search_res: [],
            search_processing: false,
            no_results: false,
            showLegend: false,
            show_col: {},
            nonIntFields: [],
            page_loaded: true,
            fields: []
        }
    },
    async created() {
        this.page_loaded = false
        const permList = await this.$store.state.user.perms
        if (Object.values(permList).indexOf('bundles.admin') > -1) {
            this.isAdmin = true
        }

        this.fields = [
            {
                name: 'id',
                label: 'ID',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'list_name',
                label: 'List Name',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'bundles_count',
                label: 'Bundle Count',
                sortable: true,
                toggle_column: 3,
                show_c: true
            }
        ]
          
        this.nonIntFields = ['list_name'];
        for (const field of this.fields) {
           this.show_col[field.toggle_column] = true;
        }
        await this.getBundlesLists()
        
        this.page_loaded = true
    },
    methods: {
        async createList(e) {
            e.preventDefault()
            const data = await this.$http.post(this.resources.Management.saveBundleList, {
                id: '0',
                list_name: this.list_name
            })

            this.$router.push('/man/bundle-pool-edit/' + data.body.id) 
        },
        async getBundlesLists() {
            const data = await this.$http.post(this.resources.Management.getBundlesLists, {})
            let res = data.body.data
            for (const k in res) {
                res[k].edit = '/man/bundle-pool-edit/' + res[k].id
                if (this.isAdmin) {
                    res[k].delete = true
                    res[k].download = true
                }
            }
            this.bundles_lists = res
            this.bundle_lists_bk = res
        },
        // edit(id) {
        //     this.$router.push('/man/bundle-pool-edit/' + id) 
        // },
        async deleteList(row) {
            let id = row.id
            id = parseInt(id)
            const data = await this.$http.post(this.resources.Management.deleteBundleList, {
                id
            })
            if (data.body.res === "NOK-SL") {
                this.notifyError("This list is associated with SL - can't be deleted")
                return
            }
            await this.getBundlesLists()
        },
        async downloadList(row) {
            let id = row.id
            id = parseInt(id)
            try {
                let link = document.createElement('a');
                link.href = process.env.VUE_APP_API + this.resources.Management.downloadBundleList(id);
                link.click();
            } catch (err) {
                this.notifyError(err);
            }
          
           
        },
        filterChange() {
            if (this.filterVal === '') {
                this.bundles_lists = this.bundle_lists_bk
            } else {
                this.bundles_lists = []
                for (let k in this.bundle_lists_bk) {
                    if (this.bundle_lists_bk[k].list_name.toLowerCase().includes(this.filterVal.toLowerCase())) {
                        this.bundles_lists.push(this.bundle_lists_bk[k])
                    }
                }
            }
        },
        async searchBundle(e) {
            try {
                e.preventDefault()
            } catch (e) {

            }
            console.log(this.bundle_search)
            const data = await this.$http.post(this.resources.Management.bundlesSearch, {
                bundle_search: this.bundle_search
            })
            this.bundle_search_res = data.body.data
            if (data.body.data.length === 0) {
                this.no_results = true
            } else {
                this.no_results = false
            }
        },
        async deleteBundle(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteBundle, {
                id,
            });
            this.searchBundle()
            this.getBundlesLists()
        },
        async deleteAll() {
            this.search_processing = true
            for (let k in this.bundle_search_res) {
                const data = await this.$http.post(this.resources.Management.deleteBundle, {
                    id: this.bundle_search_res[k].id,
                });
            }
            this.search_processing = false
            this.searchBundle()
            this.getBundlesLists()
        }
    },

}
</script>

<style scoped>
.search_div {
    padding-top: 20px;
}
.search_res_div {
    padding-bottom: 20px;
}
.search_h {
    padding-bottom: 20px;
}
.search_i {
    padding-left: 10px; 
    display: inline-block;
}
</style>
