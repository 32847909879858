<template>
    <div>
        <b-modal v-model="showDeleteModal" @ok="deleteGlobalOffer(globalOfferSelected)">Are you sure that you want to delete global offer - {{ globalOfferSelected.name}}</b-modal>
        <div style="width: 100%;">
            <h2>Global Offers</h2>
            <div>
                <a href="/apps/smartlinks/global-offers-edit?id=0&isVta=0">Create New Global Offer</a>
            </div>
            <div>
                <a href="/apps/smartlinks/global-offers-edit?id=0&isVta=1">Create New VTA Global Offer</a>
            </div>
        </div>
        <br>
        <GlobalOfferDataTable :tableHeight="'600px'" v-if="!loading" :rows_data="globalOffers"  :fetchGlobalOffers="fetchGlobalOffers"></GlobalOfferDataTable>
        <div v-if="loading">Loading ...</div>
<!--         <div>
            <table class="global-offers-view">
                <tr> 
                    <th>Offer ID</th>  
                    <th>Offer Name</th>
                    <th>VTA</th>
                    <th>Advertiser</th>
                    <th>Actions</th>
                </tr>
                <tr v-for="offer in globalOffers" class="tr">
                    <td> {{ offer.id }} </td>  
                    <td> {{ offer.name }} </td>
                    <td> {{ offer.ctr_id > 0 ? "true" : "false" }} </td>
                    <td> {{ offer.adv_name }} </td>
                    <td> 
                        <a :href=offer.href>Edit</a> | <a href="javascript:void(0);" @click="deleteValidator(offer)">Delete</a> | <a  href="javascript:void(0);" @click="duplicateGlobalOffer(offer)">Duplicate</a>
                        | <a  :href=offer.updateHref>Update related offers</a>
                    </td>
                </tr>
            </table>
        </div> -->
    </div>
</template>

<script>
import GlobalOfferDataTable from '../components/GlobalOfferDataTable.vue'
export default {
    components:{GlobalOfferDataTable},
    data() {
        return {
            globalOffers: [],
            globalOfferSelected:{},
            showDeleteModal: false,
            advList: [],
            loading: false,
        }
    },
    async created() {
        this.loading = true;
        await this.getAdvertisersList()
        await this.fetchGlobalOffers()
        this.loading = false;
    },
    methods: {
        async getAdvertisersList() {
            try {
                const res = await this.$http.post(this.resources.Management.getAdvertisersList)
                if (res.body.data) {
                    this.advList = res.body.data
                }
            } catch (err) {
                console.log(err);
            }
        },
        async fetchGlobalOffers() {
            try {
                this.loading = true;
                const r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOffers)
                const data = await JSON.parse(r.bodyText)
                
                if (data.res !== 'OK') {
                    this.notifyError("Can\'t fetch offers")
                    return
                }
                this.globalOffers = data.data 
                for (const k in this.globalOffers) {
                    let isVta = this.globalOffers[k].ctr_id !== 0 ? 1 : 0
                    this.globalOffers[k].href = `/apps/smartlinks/global-offers-edit?id=${this.globalOffers[k].id}&isVta=${isVta}`
                    this.globalOffers[k].updateHref = `/apps/smartlinks/global-offers-update?id=${this.globalOffers[k].id}`
                    this.globalOffers[k].show = true;
                    this.globalOffers[k].actions = false;
                    if (this.globalOffers[k].advertiser_id !== 0) {
                        let adv_obj = this.advList.filter(item => item.id === this.globalOffers[k].advertiser_id)[0]
                        let adv_name = adv_obj.adv_company_name
                        let csm_name = adv_obj.adv_csm_name
                        this.globalOffers[k].adv_name = adv_name
                        this.globalOffers[k].csm_name = csm_name
                       
                    } else {
                        this.globalOffers[k].adv_name = 'none'
                    } 
                }
                this.loading = false;

            } catch (err) {
                console.log(err);
                this.notifyError("Can\'t fetch offers")
            }
        },
        // async deleteGlobalOffer(offer) {
        //     try {
        //         const r = await this.$http.post(this.resources.GlobalOffers.deleteGlobalOfferById(offer.id), offer)
        //         const res = await JSON.parse(r.bodyText)

        //         if (res.res !== 'OK') {
        //             this.notifyError('Offer can\'t be deleted')
        //         } else {
        //             this.notifySuccess('Offer has been deleted')
        //         }
        //         let globalOffersFilterd = this.globalOffers.filter(item => item.id !== offer.id);
        //         this.globalOffers = globalOffersFilterd
        //     } catch (err) {
        //         console.log(err);
        //         this.notifyError('Offer can\'t be deleted')
        //     }
        // },
        // async duplicateGlobalOffer(offer) {
        //     try {
        //         const data = await this.$http.post(this.resources.GlobalOffers.duplicateGlobalOffer(offer.id))
        //         const res = await JSON.parse(data.bodyText)

        //         if (res.data) {
        //             this.notifySuccess('Global offer duplicated successfully.')
        //             this.fetchGlobalOffers()
        //         }

        //     } catch (err) {
        //         console.log(err);
        //         this.notifySuccess('Global offer did not duplicated due to an error.')
        //     }
        // },
        // deleteValidator(offer) {
        //     this.globalOfferSelected = offer
        //     this.showDeleteModal = true
        // }
    }
}
</script>

<style scoped>

.global-offers-view { 
    padding-top: 40px;
    width: 100%;
}

td,th {
    width: 150px;
}

.tr {
    padding-top: 10px;
}

button {
background-color: tomato;

}

</style>
