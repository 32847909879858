

<template>
    <div>
        <div v-if="loader">
            <div class="loader">Loading ...</div>
        </div>
        <div v-if="!loader">
            <div class="back">
                <a :href="mainLink">
                    <i class="fa fa-chevron-circle-left back-btn-2" aria-hidden="true"></i>
                </a>
            </div>
            <h1>Global Offer Scraper</h1>
            <div>
                <span>Global Offer ID: </span> 
                <span><b>{{ form.id }}</b></span>
            </div>
            <br>
            <br>
            <form @submit.prevent="submit">
                <!-- <div class="field-container">
                    <b-form-checkbox id="checkbox-1" v-model="form.active" name="checkbox-1" :value='true' :unchecked-value='false'>
                        Active?
                    </b-form-checkbox>
                </div> -->


                <div class="field-container">
                    <label>Event 1 (Non-Payable)</label>
                    <input v-model='form.event_1' class="pub-id-input form-control">
                </div>

                <div class="field-container">
                    <label>Event 2 (Payable)</label>
                    <input v-model='form.event_2' class="pub-id-input form-control">
                </div>

                <!-- <div class="field-container">
                    <label>Payout</label>
                    <input type="number"  step="0.01"  v-model='form.payout' class="pub-id-input form-control">
                </div> -->

                <button v-if='userHasPermission("smartlinks.edit")'  :disabled="!validateBeforSubmit" type="submit" class="btn btn-success btn-31214">Save</button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
  name: 'GlobalOfferScraper',
  data () {
    return {
        form: {
            id: 0,
            active: false,
            event_1: '',
            event_2: '',
            payout: 0.0,
        },
        loader: false,
        offer_id: 0,
        offer: {},
        mainLink: '',
        isVta: 0,
    }
  },
  async created () {
    //
    this.loader = true;
    this.offer_id = this.$route.query.offer_id;
    this.isVta = this.$route.query.isVta;

    this.mainLink =  '/apps/smartlinks/global-offers-edit?id=' + this.offer_id + '&isVta=' + this.isVta
    if (typeof this.offer_id === 'undefined') {
        this.notifyError('Unauthorized');
        
    } else {
        let r = await this.fetchData();
        if (r) {
            this.loader = false;
        }
       
    }
  },
  computed: {
    validateBeforSubmit() {
        if (this.form.event_1 === '' || this.form.event_2 === '') {
            return false;
        } 
        return true;
    }
  },
  methods: {
    async fetchData() {
        // get offer data from api by offer id
        try {
            const r = await this.$http.get(this.resources.GlobalOffers.fetchGlobalOfferById(this.offer_id));
            const data = await JSON.parse(r.bodyText)
            if (data.res !== 'OK') {
                this.notifyError("Can\'t fetch offer")
                return false;
            }

            if (!data.data.is_global_offer) {
                this.notifyError("Offer is not Global Offer")
                return false;
            }

            this.form.active = data.data.active;
            this.form.event_1 = data.data.event_1;
            this.form.event_2 = data.data.event_2;
            this.form.payout = data.data.payout;
            this.form.id = data.data.id;

            return true;
        } catch (error) {
            return false;
        }
    },
    async submit() {
        this.loader = true;
        try {
            // update only the fields that we need
            this.form.payout = parseFloat(this.form.payout)
            const r = await this.$http.post(this.resources.GlobalOffers.updateOffersScraperData, this.form);
            const data = await JSON.parse(r.bodyText)
            if (data.res !== 'OK') {
                this.notifyError("Can\'t save offer data")
                return;
            }
            this.loader = false;
            this.notifySuccess('Saved');
            setTimeout(() => {
                this.$router.push(this.mainLink);
            }, 1000);
        } catch (error) {
            this.loader = false;
          
        }
    }
   
  }
}
</script>
<style scoped>
.back-btn-2 {
  font-size: 41px;
  color: #3b67a0;
  cursor: pointer;
}
</style>
