<template lang="html">
  <div class="all">
    <Offer v-if='offer' :isVta='isVtaSL' :data='offer' :onSubmit='submit' :placement='placement'/> 
  </div>
</template>

<script>
import Offer from '../components/Offer'

export default {
  components: {Offer},
  data() {
    return {
      offer: null,
      placement: null,
    }
  },
  created() {
    this.updateOffer()
  },
  watch: {
    offerId(val) {
      this.updateOffer()
    }
  },
  computed: {
    offerId() {
      return this.$route.params.offerId
    },
    isVtaSL() {
      let {campId, placementId, offerId} = this.$route.params
      let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
      this.offer = placement.offers.find(v => v.id == offerId)
      return this.offer && this.offer.click_offer_id !== 0
    
    }
  },
  methods: {
    updateOffer() {
      this.offer = null
      this.$nextTick(() => {
        let {campId, placementId, offerId} = this.$route.params
        let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
        this.offer = placement.offers.find(v => v.id == offerId)
        this.placement = placement
      })
    },
    submit(form) {
      let {campId, placementId, offerId} = this.$route.params
      this.$http.put(this.resources.SmartLinksCampaigns.updateOffer(campId, placementId, offerId), form)
        .then(() => {
          let {campId, placementId, offerId} = this.$route.params
          let placement = this.$store.state.smartlinks.campaigns.find(v => v.id == campId).placements.find(v => v.id == placementId)
          let offer = placement.offers.find(v => v.id == offerId)

          Object.assign(offer, form)
          this.notifySuccess("Offer has been updated")
        })
        .catch(err => {
          this.notifyError("Error has occured, offer did not save")
        })
    }
  }
}
</script>

<style lang="css">
</style>
