<template>
    <div>
        <PidManager class="pid-manager"></PidManager>
        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>
        <PrtManager></PrtManager>
        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>
        <DataManagerAlternateSeat></DataManagerAlternateSeat>
    </div>
</template>
<script>
import PidManager from '../data-manager-pid/Home.data-manager-pid.vue'
import PrtManager from '../data-manager-af/Home.data-manager-af.vue'
import DataManagerAlternateSeat from '../data-manager-alternate-seat/Home.data-manager-alternate-seat.vue'
export default {
    components:{
        PidManager, PrtManager, DataManagerAlternateSeat
    },
    data() {
        return {
        }
    },
}
</script>
<style scoped>

</style>