<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>

        <DataManagerOm></DataManagerOm> <hr>
        <DataManagerCsm></DataManagerCsm> <hr>
        <DataManagerMediaAnalyst></DataManagerMediaAnalyst> <hr>
        
    </div>
   
</template>

<script>
import DataManagerMediaAnalyst from '../data-manager-media-analyst/Home.data-manager-media-analyst.vue'
import DataManagerCsm from '../data-manager-csm/Home.data-manager-csm.vue';
import DataManagerOm from '../data-manager-om/Home.data-manager-om.vue';
export default {
    components:{
        DataManagerMediaAnalyst,
        DataManagerCsm,
        DataManagerOm
    },
    data() {
        return {

        }
    },
    async created() {

    },
    methods: {

    }
}
</script>
<style scoped>

</style>