<template>
    <div>
        <h2>PIDs Pool</h2>
        <div>
            <a href="/man/pid-pool-lists">List Management</a> | <a href="/man/pid-pool-bulk">Add Bulk</a> | <a href="/man/sync_postback">Sync postback with PIDs</a> | <a href="javascript:void(0);" v-on:click="fixLiveNoAssoc">Fix LIVE not assoc</a>
        </div>
        <div v-if="page_loaded">
            <div class="loader">Loading ...</div>
        </div>
        <div class="add-form" v-if="!page_loaded">
            <form @submit="addPid" class="hide_mobile_only">
                <b-form class="pid-add-form">
                    <b-row>
                        <b-col>
                            <h1></h1>
                        </b-col>
                    </b-row>
                    <b-row class="pid-add">
                        <b-col>
                            <b-form-group id="input-group-1" label="Pid:" label-for="input-2">
                                <b-form-input v-model="form.pid" id="input-2" type="number" placeholder="Enter Pid"
                                    number required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button v-on:click="addPid" id="input-5" type="submit" variant="success">Add New
                                Pid</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </form>

            <b-form class="pid-add-form mt-5">
                <b-row class="pid-add">
                    <b-col>
                        <b-form-group label="Filter by Pid:">
                            <b-form-input @keyup="pidFilterChange" v-model="pid_filter" type="text"
                                placeholder="Enter Pid"></b-form-input>
                                <div style="font-size: 9px;">
                                    Can add multiple, separated by commas. Example: 144, 145, 165
                                </div>
                        </b-form-group>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-form>

            <b-form class="pid-add-form">
                <b-row>
                    <b-col>
                        <b-form-group label="Filter by Status:">
                            <div>
                                <b-form-select @change="statusFilterChange" v-model="status_filter" class="mb-3">
                                    <b-form-select-option value="" selected>All</b-form-select-option>
                                    <b-form-select-option value="Not in use">Not in use</b-form-select-option>
                                    <b-form-select-option value="COOLDOWN">COOLDOWN</b-form-select-option>
                                    <b-form-select-option value="LIVE">LIVE</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col>

                    </b-col>
                </b-row>
            </b-form>

            <b-form style="padding-left: 15px;">
                <b-row>
                    <b-form-group>
                        Total: {{ this.tableData.length }}
                    </b-form-group>
                </b-row>
            </b-form>
        </div>
        <div v-if="showGlobalOps">
            <button class="btn btn-success" @click="replaceAll">Replace All</button>
        </div>

        <ResizableDataTable v-if="tableData.length > 0" :fields="fields" :rows_data.sync="tableData" :show_c.sync="show_col" :delete="deletePid" :replace="removeAndReplacePid" :nonIntFields="nonIntFields" :edit_label="'Go To List'" :show_columns_name="'pid-bank'"></ResizableDataTable>
    </div>
</template>

<script>
import ResizableDataTable from '../../../components/shared/ResizableDataTable.vue'
export default {
    components:{ ResizableDataTable },
    data() {
        return {
            form: {
                pid: ''
            },
            tableData: [],
            tableDataBase: [],
            pid_filter: '',
            status_filter: '',
            showGlobalOps: false,
            page_loaded: false,
            fields: [],
            show_col: {},
            nonIntFields: [],
        }
    },
    created() {
        this.getPidsPool()
        this.page_loaded = true;
        this.fields = [
            {
                name: 'id',
                label: 'ID',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'pid',
                label: 'Pid',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'pid_status',
                label: 'Status',
                sortable: true,
                toggle_column: 3,
                show_c: true
            },
            {
                name: 'pid_list_name',
                label: 'In List',
                sortable: true,
                toggle_column: 4,
                show_c: true,
                class: "hide_mobile_only",
            }
        ];
        
        this.nonIntFields = ['pid_status', 'pid_list_name'];
        for (const field of this.fields) {
           this.show_col[field.toggle_column] = true;
        }
        
        this.page_loaded = false;
    },
    methods: {
        async addPid(event) {
            event.preventDefault()
            try {
                if (this.form.pid === '') {
                    this.notifyError("Please enter a valid PID")
                    return
                }
                const data = await this.$http.post(this.resources.Management.addPidToBank, {
                    pid: String(this.form.pid)
                })
                if (data.body.res === 'NOK_DUPLICATE') {
                    this.notifyError("PID already exist")
                    return
                }
                this.notifySuccess("PID created!")
                this.getPidsPool()
                this.form.pid = ''
            } catch (err) {
                console.log(err)
            }
        },
        async getPidsPool() {
            this.tableData = []
            const r = await this.$http.post(this.resources.Management.getPidsBank)
            let data = await r.json()
            this.tableData = data.data

            for (const k in this.tableData) {
                this.tableData[k].edit = this.tableData[k].pid_list_assoc !== 0 ? '/man/pid-pool-lists-details?id=' + this.tableData[k].pid_list_assoc : 'javascript:void(0)';
                this.tableData[k].replace = this.tableData[k].pid_status === 'LIVE' ? true : false;
            }

            this.tableDataBase = this.tableData;
        },
        async deletePid(row) {
            let id = row.id;
            const data = await this.$http.post(this.resources.Management.deletePid, {
                id: String(id)
            })
            if (data.body.res === 'NOK_MINIMUM') {
                this.notifyError("PID can't be deleted - a list must have at least 2 PIDs")
                return
            }
            await this.getPidsPool()
            await this.pidFilterChange()
        },
        async removeAndReplacePid(id, showNotification = true) {
            const data = await this.$http.post(this.resources.Management.pidPoolReplace, {
                id: String(id)
            })
            if (data.body.res === 'OK_PIDS_0') {
                this.notifySuccess("There are no available PIDs")
                return
            }
            if (showNotification) {
                this.notifySuccess("Done!")
            }
            await this.getPidsPool()
            await this.pidFilterChange()
        },
        pidFilterChange() {
            this.status_filter = ''
            if (this.pid_filter === '') {
                this.tableData = this.tableDataBase
                return
            }

            let splits = this.pid_filter.split(',')
            for (let k in splits) {
                splits[k] = splits[k].trim()
            }

            this.tableData = []
            for (let k in this.tableDataBase) {
                if (splits.includes(this.tableDataBase[k].pid)) {
                    this.tableData.push(this.tableDataBase[k])
                }
            }
            if (this.tableData.length > 1) {
                this.showGlobalOps = true    
            } else {
                this.showGlobalOps = false
            }
        },
        statusFilterChange() {
            this.showGlobalOps = false
            this.pid_filter = ''
            if (this.status_filter === '') {
                this.tableData = this.tableDataBase
                return
            }

            this.tableData = []
            for (let k in this.tableDataBase) {
                if (this.tableDataBase[k].pid_status.includes(this.status_filter)) {
                    this.tableData.push(this.tableDataBase[k])
                }
            }
        },
        async replaceAll() {
            for (let k in this.tableData) {
                if (this.tableData[k].pid_status === 'LIVE') {
                    await this.removeAndReplacePid(this.tableData[k].id, false)
                }
            }
            await this.getPidsPool()
            await this.pidFilterChange()
        },
        async fixLiveNoAssoc() {
            await this.$http.post(this.resources.Management.fixLiveNoAssoc, {})
            this.notifySuccess('Done.')
            await this.getPidsPool()
            await this.pidFilterChange()
        }
    },
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
    .hide_mobile_only {
        display: none;
    }
}
</style>
