<template>
<div>
    <h2>PIDs Pool - Lists</h2>
    <div class="add-form">
        <form @submit="createPidsBankList">
            <b-form class="pid-add-form">
                <b-row>
                    <b-col>
                        <h1></h1>
                    </b-col>
                </b-row>
                <b-row class="pid-add">
                    <b-col>
                        <b-form-group id="input-group-1" label="List Name:" label-for="input-2">
                            <b-form-input v-model="form.pids_list_name" id="input-2" type="text" placeholder="Enter List Name" required></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-1" label="Number of PID to assign from pool:" label-for="input-2">
                            <b-form-input v-model="form.number_of_pids" id="input-2" type="number" placeholder="Enter Number" number required min="2"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col></b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button v-on:click="createPidsBankList" id="input-5" type="submit" variant="success">Create List</b-button>
                    </b-col>
                </b-row>
            </b-form>
            <div>
                {{ msg }}
            </div>
        </form>
    </div>
    <div class="filter-form">
        <b-row>
            <b-col>
                <b-form-group id="input-group-1" label="Filter List By Name:" label-for="input-2">
                    <b-form-input type="text" placeholder="Filter by name" v-model="filter"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col></b-col>
        </b-row>
    </div>
    <ResizableDataTable v-if="!page_loading && tableData.length > 0" :rows_data.sync="filteredRows" :fields="fields" :show_c.sync="show_col" :delete="deletePidsList" :nonIntFields="nonIntFields" :replace_label="'Replace All'" :edit_label="'Show Details'" :replace="replaceAllByListId" :show_columns_name="'pids-pool-lists'"></ResizableDataTable>
    <div v-else>Loading ...</div>
</div>
</template>

<script>
import ResizableDataTable from '../../../components/shared/ResizableDataTable.vue'
export default {
    components: {ResizableDataTable},
    data() {
        return {
            form: {
                pids_list_name: '',
                number_of_pids: 4
            },
            tableData: [],
            msg: '',
            filter: '',
            fields: [],
            page_loading: false,
            show_col: {},
            nonIntFields: []
        }
    },
    created() {
        this.page_loading = true
        this.getPidsBankList()
        this.fields = [
            {
                name: 'id',
                label: 'ID',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'pid_list_name',
                label: 'List Name',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'pids_count',
                label: 'PIDs Count',
                sortable: true,
                toggle_column: 3,
                show_c: true
            },
        ]
        this.nonIntFields = ['pid_list_name']
        for (const field of this.fields) {
           this.show_col[field.toggle_column] = true;
        }

        this.page_loading = false

    },
    computed: {
        filteredRows() {
            return this.tableData.filter(row => {
                const listName = row.pid_list_name.toString().toLowerCase();
                const searchTerm = this.filter.toLowerCase();
                return listName.includes(searchTerm)
            })
        }
    },
    methods: {
        async createPidsBankList(event) {
            this.page_loading = true
            if (this.form.number_of_pids < 2) {
                this.notifyError("The minimum number is 2")
                return
            }
            if (this.form.pids_list_name === '') {
                this.notifyError("Please enter a valid list name")
                return
            }
            this.msg = 'Processing...'
            event.preventDefault()
            try {
                const res = await this.$http.post(this.resources.Management.createPidsBankList, {
                    pids_list_name: this.form.pids_list_name,
                    number_of_pids: String(this.form.number_of_pids)
                })
                this.msg = res.body.msg
                if (res.body.res === 'OK') {
                    this.form.pids_list_name = ''
                    this.getPidsBankList()
                } else {
                    this.page_loading = false
                }

            } catch (err) {
                console.log(err)
            } 
        },
        async getPidsBankList() {
            const r = await this.$http.post(this.resources.Management.getPidsBankList)
            let data = await r.json()
            let d = data.data
            // Add action links
            for (const k in d) {
                d[k].edit = '/man/pid-pool-lists-details?id=' + d[k].id
                d[k].replace = true
            }
            this.tableData = d
            this.page_loading = false;
        },
        async verifyRelatedOffer(row) {
            try {
                console.log(this.resources.Management.getOffersRelatedCount);
                const data = await this.$http.post(this.resources.Management.getOffersRelatedCount, {
                    id: String(row.id)
                });
                
                if (data.body.res === 'OK') {
                    if (data.body.msg !== '') {
                        let res = confirm(data.body.msg);
                        if (res) {
                            return [true, 'related']
                        } else {
                            return [false, 'related'];
                        }
                    } else {
                        return [true, ''];
                    }
                } else {
                    this.notifyError("Can't delete this list - It's associated with an offer")
                }
            } catch (err) {
                console.log(err);
            }
        },
        async deletePidsList(row) {
            let [confirmed, related] = await this.verifyRelatedOffer(row);
            if (!confirmed) {
                return;
            }
            this.page_loading = true;
            let id = row.id
                try {
                    const data = await this.$http.post(this.resources.Management.deletePidsBankList, {
                    id: String(id),
                    is_related_offers: related !== '' ? true : false
                })
                if (data.body.res === 'OK') {
                    await this.getPidsBankList()
                    console.log('done');
                } else {
                    this.notifyError("Can't delete this list - It's associated with an offer")
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.page_loading = false
            }
         
        },
        highlightMatches(text) {
            const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
            if (!matchExists) return text;

            const re = new RegExp(this.filter, 'ig');
            return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
        },
        async replaceAllByListId(listId) {
            const r = await this.$http.post(this.resources.Management.replaceAllByListId, {list_id: listId})
            if (r.body.res === 'NOK') {
                this.notifyError(r.body.msg)
            } else {
                this.notifySuccess("Done")
            }
        }
    },

}
</script>

<style scoped>
.filter-form {
    margin-top: 5%;
}
</style>
