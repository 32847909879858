<template lang="html">
  <div class="all">
    <div class="di-112">
      <div class="all">
        <h3>Admonster Offers Whitelisting</h3>
        <br>
        <Table
        :search='false'
        :data='admonsterVals'
        :apiUrl='this.resources.Management.affiseAdvertiserWhitelisting'
        :fields='{val: "Value"}'
        :permissions='{delete: userHasPermission("affise-whitelisting")}'
        :askBeforeDelete='0'
        />
        <section class="sec-121">
          <label >New Offer IDs:</label>
          <textarea class="def tx tx-cid" v-model='form.admonsterInput'></textarea>
        </section>
        <button type="button" @click='submitAdmonster' class="btn btn-success">Save</button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      admonsterVals: [],
      form: {
        admonsterInput: ""
      }
    }
  },
  created() {
    this.$http.get(this.resources.Management.affiseAdvertiserWhitelisting)
      .then(r => r.json())
      .then(data => {
        this.admonsterVals = (data || [])
        // let {camp_ids, bundles, ssps} = data
        // this.campIds = (camp_ids || [])
        // this.bundles = (bundles || [])
        // this.ssps = (ssps || [])
      })
  },
  methods: {
    submitAdmonster() {
      let rawInput = this.form.admonsterInput
      let vals = rawInput.split("\n").filter(v => v).map(v => v.trim())

      if(vals.length === 0) {
        return
      }
      let body = {
        advertiser: "admonster",
        vals: vals
      }

      this.$http.post(this.resources.Management.affiseAdvertiserWhitelisting, body)
        .then(() => {
          vals = vals.map(v => ({val: v}))
          this.admonsterVals.push(...vals)
          this.form.admonsterInput = ""
        })
    }
  }
}
</script>

<style lang="css" scoped>
.di-112 {
  display: flex;
  flex-direction: column;
}
.di-112 > div {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.sec-121 {
  display: flex;
  margin-bottom: 9px;
}
.sec-121 > label {
  width: 150px;
  margin-right: 25px;
}
.tx {
  resize: none;
  font-size: 12px;
}
.tx-cid {
  width: 400px;
  height: 230px;
}
</style>
