<template lang="html">
  <div class="all">
    <h1>Tags</h1>

    <!-- <form class="report-form-1" @submit.prevent=''>
      <DateRange :disabled='isFetching' :onChange='onDatesChange' :from_='reportForm.startDate'
        :to_='reportForm.endDate' />
      <div class="div-1412">
        <button :disabled='isFetching' @click='onReportSubmit' class="btn btn-success">Update Stats</button>
        <i class="spinner fa fa-circle-o-notch fa-spin" v-if='isFetching'></i>
      </div>
    </form> -->
    <!-- date range -->
    <form @submit.prevent=''>
        <div>
            <div>
                <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    opens="right"
                    :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                    :ranges="ranges"
                    @update="logEvent"
                    @toggle="logEvent('toggle', $event)"
                    @start-selection="logEvent('event: startSelection', $event)"
                    @finish-selection="logEvent('event: finishSelection', $event)"
                    :disabled='isFetching'
                    :maxDate="maxDate"
                >
                </date-range-picker>
            </div> 
        </div>
        <div class="div-1412">
            <i class="spinner fa fa-circle-o-notch fa-spin" v-if='isFetching'></i>
        </div>
    </form>


    <div class="filter-form-container">
      <form class="form-section" @submit.prevent=''>
        <div class="form-inputs">
          <label>Placment name / ID:</label>
          <input class="def filter-pl-name-input" ref="filter-stag-id" v-model='filterForm.id'>
        </div>
      </form>
    </div>
    <div class="sl-list" v-if='camps'>
      <ul class="sl-ul">
        <li class="sl-th sl-list-li">
          <span class="small-width w-span"></span>
          <span class="sortable-column w-span" :class='{active: sort.field === "id"}' @click='onColumnClick("id")'>ID</span>
          <span class="po sortable-column w-span-6" :class='{active: sort.field === "externalID"}'
            @click='onColumnClick("externalID")'>Name</span>
          <span class="sortable-column" :class='{active: sort.field === "total_requests"}'
            @click='onColumnClick("total_requests")'>Requests</span>
          <span class="sortable-column" :class='{active: sort.field === "OK"}'
            @click='onColumnClick("OK")'>Served</span>
          <span class="sortable-column" :class='{active: sort.field === "total_blocked"}'
            @click='onColumnClick("total_blocked")'>Blocked</span>
          <span class="sortable-column w-span-1" :class='{active: sort.field === "total_blocked_percentage"}'
            @click='onColumnClick("total_blocked_percentage")'>Blocked %</span>
          <span class="sortable-column" :class='{active: sort.field === "COUNTRY_BLOCKED"}'
            @click='onColumnClick("COUNTRY_BLOCKED")'>Country Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "OS_BLOCKED"}'
            @click='onColumnClick("OS_BLOCKED")'>Os Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "OS_VERSION_BLOCKED"}'
            @click='onColumnClick("OS_VERSION_BLOCKED")'>Os Version Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "HOUR_BLOCKED"}'
            @click='onColumnClick("HOUR_BLOCKED")'>Hour Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "SUBS_INVALID_BLOCKED"}'
            @click='onColumnClick("SUBS_INVALID_BLOCKED")'>Empty/Macro Subs Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "SSP_WHITELIST_BLOCKED"}'
            @click='onColumnClick("SSP_WHITELIST_BLOCKED")'>Ssp Whitelist Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "BUNDLE_WHITELIST_BLOCKED"}'
            @click='onColumnClick("BUNDLE_WHITELIST_BLOCKED")'>Bundle Whitelist Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "CLICK_ID_DUPLICATED_BLOCKED"}'
            @click='onColumnClick("CLICK_ID_DUPLICATED_BLOCKED")'>Click ID Blocked</span>
          <span class="sortable-column" :class='{active: sort.field === "ALL_TRAFFIC_BLOCKED"}'
            @click='onColumnClick("ALL_TRAFFIC_BLOCKED")'>New Tag Blocked</span>
        </li>
        <li v-if='sumRow' class="sl-t sum-row sl-list-li">
          <span class="small-width w-span"></span>
          <span class="w-span"></span>
          <span class="po w-span-6"></span>
          <span>{{sumRow.total_requests | numFormat}}</span>
          <span>{{sumRow.OK | numFormat}}</span>
          <span>{{sumRow.total_blocked | numFormat}}</span>
          <span class="w-span-1">{{sumRow.total_blocked_percentage | percentageFormat}}</span>
          <span>{{sumRow.COUNTRY_BLOCKED | numFormat}}</span>
          <span>{{sumRow.OS_BLOCKED | numFormat}}</span>
          <span>{{sumRow.OS_VERSION_BLOCKED | numFormat}}</span>
          <span>{{sumRow.HOUR_BLOCKED | numFormat}}</span>
          <span>{{sumRow.SUBS_INVALID_BLOCKED | numFormat}}</span>
          <span>{{sumRow.SSP_WHITELIST_BLOCKED | numFormat}}</span>
          <span>{{sumRow.BUNDLE_WHITELIST_BLOCKED | numFormat}}</span>
          <span>{{sumRow.CLICK_ID_DUPLICATED_BLOCKED | numFormat}}</span>
          <span>{{sumRow.ALL_TRAFFIC_BLOCKED | numFormat}}</span>
        </li>
        <li class="sl-t sl-list-li" v-for='camp in camps.filter(v => v.show)' :key='camp.id'>

          <span class="edit-pl-c small-width w-span">
            <i class="fa fa-pencil-square-o edit-icon" aria-hidden="true" @click='onEditPlLinkClick(camp)'></i>
          </span>
          <span class="clickable w-span" @click='onRowClick(camp)'>
            {{camp.id}} 
            <span class="global-bundle-wl-span" v-if="camp.is_global_bundle_wl">
              [BWL]
            </span>
          </span>
          <span class="po clickable w-span-6" @click='onRowClick(camp)'>{{camp.externalID}}</span>

          <span class="f-s-btns-2021">
            <span>
              {{camp.stats.total_requests | numFormat}}
            </span>
            <span>
              <button v-show='userHasPermission("all-requests-report") && camp.stats.total_requests > -1'
                @click='generateReport(camp.id, "requests")' type="button" class="btn">
                <i class="fa fa-download" aria-hidden="true"></i>
              </button>
            </span>
          </span>
          <span>{{camp.stats.OK | numFormat}}</span>
          <span class="f-s-btns-2021">
            <span>
              {{camp.stats.total_blocked | numFormat}}
            </span>
            <span>
              <button v-show='camp.stats.total_blocked > 0' @click='generateReport(camp.id, "blocked")' type="button"
                class="btn">
                <i class="fa fa-download" aria-hidden="true"></i>
              </button>
            </span>
          </span>
          <span
            class="w-span-1" :class='{"alert_bad": camp.stats.total_blocked_percentage > 20}'>{{camp.stats.total_blocked_percentage | percentageFormat}}</span>
          <span>{{camp.stats.COUNTRY_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.OS_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.OS_VERSION_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.HOUR_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.SUBS_INVALID_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.SSP_WHITELIST_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.BUNDLE_WHITELIST_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.CLICK_ID_DUPLICATED_BLOCKED | numFormat}}</span>
          <span>{{camp.stats.ALL_TRAFFIC_BLOCKED | numFormat}}</span>
          <!-- <span>{{sl.stats.other_errors}}</span> -->
        </li>
      </ul>
    </div>

    <br>

  </div>
</template>

<script>
  import fn from '../fn.stags.js'
  import DateRangePicker from 'vue2-daterange-picker'
  import moment from 'moment'
  export default {
    components: {
      DateRangePicker
    },
    data() {
      let today = new Date()
      let yes = new Date()
      let yesterday = yes.setDate(yes.getDate() - 1)

      let yesterdayx = new Date();
      yesterdayx.setDate(yesterdayx.getDate() - 1);
      let todayx = new Date();

      return {
        reportLoading: false,
        reportForm: {
          startDate: yesterday,
          endDate: yes
        },
        isFetching: false,
        camps: null,
        form: {
          name: null
        },
        sort: {
          field: "id",
          asc: false
        },
        filterForm: {
          id: null,
        },
        sumRow: null,
        statsDate: "YESTERDAY",
        fields: {
          id: "ID",
          externalID: "Placement ID",
          total_requests: "Requests",
          OK: "Served",
          total_blocked: "Blocked",
          COUNTRY_BLOCKED: "COUNTRY_BLOCKED",
          OS_BLOCKED: "OS_BLOCKED",
          OS_VERSION_BLOCKED: "OS_VERSION_BLOCKED",
          HOUR_BLOCKED: "HOUR_BLOCKED",
          SUBS_INVALID_BLOCKED: "SUBS_INVALID_BLOCKED",
          SSP_WHITELIST_BLOCKED: "SSP_WHITELIST_BLOCKED",
          BUNDLE_WHITELIST_BLOCKED: "BUNDLE_WHITELIST_BLOCKED",
          SSP_WHITELIST_BLOCKED: "SSP_WHITELIST_BLOCKED",
          BUNDLE_WHITELIST_BLOCKED: "BUNDLE_WHITELIST_BLOCKED",
          CLICK_ID_DUPLICATED_BLOCKED: "CLICK_ID_DUPLICATED_BLOCKED",
          ALL_TRAFFIC_BLOCKED: "ALL_TRAFFIC_BLOCKED"
        },
        loaded: false,
        dateRange: {
            startDate: yesterdayx,
            endDate: yesterdayx
        },
        autoApply: false,
        ranges: {
            'Today': [todayx, todayx],
            'Yesterday': [yesterdayx, yesterdayx],
            'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
            'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
            'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
            'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
            'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        },
        dateRangeDelta: {
            startDate: yesterdayx,
            endDate: yesterdayx
        },
        maxDate: new Date()
      }
    },
    mounted() {
      this.$refs['filter-stag-id'].focus()
    },
    watch: {
      ["filterForm.id"](val) {
        this.filterData()
      }
    },
    mixins: [fn],
    methods: {
      generateReport(id, type) {
        let fn = type === "requests" ? "generateRequestsReport" : "generateBlockedReport"
        this.$http.post(this.resources.ScriptTags[fn](id), undefined, {
            params: {
              date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
              end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            },
            timeout: 1000
          })
          .catch(e => {
            let status = e.status
            if (status !== 0) {
              throw e
            }
          })
          .then(e => {
            this.notifySuccess("The report will be sent to your email when ready", 10000);
          })
          .catch(e => {
            this.notifyError("Could not generate report");
          })
      },
      onReportSubmit() {
        this._fetchStags()
      },
      onDatesChange(v) {
        let {
          from,
          to
        } = v
        this.reportForm.startDate = from
        this.reportForm.endDate = to
      },
      _fetchStags() {
        this.isFetching = true
        let reportForm = {
          startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD") 
        }
        return this.fetchStags(reportForm)
          .then(camps => {
            camps = camps || []
            this.handleCamps(camps)
            this.camps = camps
            this.filterData()
            this.sumRow = this.getRowsTotal(camps)
            return camps
          })
          .finally(() => {
            this.isFetching = false
          })
      },
      filterData() {
        let filterID = this.filterForm.id
        this.camps.forEach(v => v.show = true)
        if (filterID) {
          filterID = filterID.toLowerCase()
          this.camps.forEach(v => v.show = v.id == filterID || v.externalID.toLowerCase().includes(filterID))
        }
      },
      onEditPlLinkClick(pl) {
        let u = this.$router.resolve({
          name: "Placements",
          query: {
            placement_id: pl.id
          }
        })

        window.open(u.href, "_blank")
      },
      getRowsTotal(data) {
        let r = {
          total_requests: 0,
          OK: 0,
          total_blocked: 0,
          total_blocked_percentage: 0,
          COUNTRY_BLOCKED: 0,
          OS_BLOCKED: 0,
          OS_VERSION_BLOCKED: 0,
          HOUR_BLOCKED: 0,
          SUBS_INVALID_BLOCKED: 0,
          SSP_WHITELIST_BLOCKED: 0,
          BUNDLE_WHITELIST_BLOCKED: 0,
          CLICK_ID_DUPLICATED_BLOCKED: 0,
          ALL_TRAFFIC_BLOCKED: 0
        }

        data.forEach(row => {
          let stats = row.stats
          r.total_requests += stats.total_requests || 0
          r.OK += stats.OK || 0
          r.total_blocked += stats.total_blocked || 0
          r.COUNTRY_BLOCKED += stats.COUNTRY_BLOCKED || 0
          r.OS_BLOCKED += stats.OS_BLOCKED || 0
          r.OS_VERSION_BLOCKED += stats.OS_VERSION_BLOCKED || 0
          r.HOUR_BLOCKED += stats.HOUR_BLOCKED || 0
          r.SUBS_INVALID_BLOCKED += stats.SUBS_INVALID_BLOCKED || 0
          r.SSP_WHITELIST_BLOCKED += stats.SSP_WHITELIST_BLOCKED || 0
          r.BUNDLE_WHITELIST_BLOCKED += stats.BUNDLE_WHITELIST_BLOCKED || 0
          r.CLICK_ID_DUPLICATED_BLOCKED += stats.CLICK_ID_DUPLICATED_BLOCKED || 0
          r.ALL_TRAFFIC_BLOCKED += stats.ALL_TRAFFIC_BLOCKED || 0
        })

        if (r.total_requests > 0) {
          r.total_blocked_percentage = (r.total_blocked / r.total_requests) * 100
        }
        return r
      },
      onColumnClick(field) {
        if (this.sort.field === field) {
          this.sort.asc = !this.sort.asc
        } else {
          this.sort.field = field
          this.sort.asc = false
        }
        this.sortReport()
      },
      sortReport() {
        let camps = this.camps

        let sort = this.sort
        if (!sort.field) {
          return
        }

        let sortField = sort.field

        camps.sort((a, b) => {
          if (!["id", "externalID"].includes(sortField)) {
            a = a.stats
            b = b.stats
          }
          a = a[sortField] || 0
          b = b[sortField] || 0

          let f
          if (a > b) {
            f = 1
          } else if (a < b) {
            f = -1
          } else {
            f = 0
          }

          if (!sort.asc) {
            f = f * -1
          }

          return f
        })
      },
      onRowClick(v) {
        this.$router.push({
          name: "ScriptTagsCamp",
          params: {
            campId: v.id
          }
        })
      },
      createStag() {
        let campName = this.form.name
        if (!campName || campName.length < 3) {
          this.notifyError("Please fill name")
          return
        }
        this.$http.post(this.resources.ScriptTags.createCampaign, this.form)
          .then(r => r.json())
          .then(id => {
            this.form.id = id
            this.form.linksOrder = ""
            if (!this.camps) {
              this.camps = [this.form]
            } else {
              this.camps.push(this.form)
            }
            this.form = {
              name: null
            }
          })
          .catch(err => {
            this.notifyError("Error occured")
          })
      },
      handleCamps(camps) {
        camps.forEach(camp => {
          if (!camp.stats) {
            camp.stats = {}
          }
          let stats = camp.stats
          let allRequests = 0
          for (let k in stats) {
            let v = stats[k]
            camp[k] = v
            allRequests += v
          }
          camp.stats.total_requests = allRequests
          camp.stats.total_blocked = allRequests - (stats["OK"] || 0)
          if (allRequests > 0) {
            camp.stats.total_blocked_percentage = (camp.stats.total_blocked / allRequests) * 100
          } else {
            camp.stats.total_blocked_percentage = 0
          }
        })

      },
      logEvent(x, y) {
        if (x === 'toggle' && !y) {
          setTimeout(async () => {
            if (this.dateRangeDelta.startDate !== this.dateRange.startDate || this.dateRangeDelta.endDate !== this.dateRange.endDate) {
              this.dateRangeDelta.startDate = this.dateRange.startDate
              this.dateRangeDelta.endDate = this.dateRange.endDate
              await this._fetchStags()
            }
          }, 100);
        }
      }
    },
    filters: {
     numFormat(v) {
        let i = isNaN(v) ? 0 : v
        return(Number(i).toLocaleString())
      },
      percentageFormat(v) {
        if (!v) {
          v = 0
        }
        return v.toFixed(0) + "%"
      }
    },
    created() {
      this._fetchStags()
        .then(camps => {
          this.loaded = true
        })
    }
  }
</script>

<style lang="css" scoped>
  .sl-list {
    margin-top: 25px;
  }

  .sl-list li>span {
    padding: 4px 4px 4px 9px;
  }

  .sl-list li span.po {
    flex: 0;
  }

  .edit-pl-c {
    text-align: center;
  }

  .edit-icon {
    font-size: 21px;
    background: #f1f1f1;
    padding: 8px;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    color: grey;
  }

  .edit-icon:hover {
    border: 1px solid gainsboro;
    background: #e8e8e8;
  }
  
  .w-span-6 {
    width: 30ch; 
    min-width: 17ch; 
    flex: none;
    padding: 4px;
  }

  .sl-list li > span {
    min-width: 170px ;
  }

  .w-span {
    width: 70px !important; 
    min-width: 50px !important; 
    flex: none !important;
    padding: 4px !important;
  }

  .w-span-1{
    width: 80px !important; 
    min-width: 10px !important; 
    flex: none !important;
    padding: 4px ;
  }

  .global-bundle-wl-span{
    color: gray;
  }

</style>