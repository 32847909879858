import Vue from 'vue'
import Vuex from 'vuex'
import * as act from '../act-types'
import resources from '../resources'
import countries from '@/assets/countries'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    elementId: 0,
    countriesList: countries,
    languagesList: null,
    pubIds: null,
    initialLoad: false,
    version: null,
    tagMahal: {
      templates: null,
      media: null
    },
    browsers: {
      Chrome: 'Chrome', Firefox: 'Firefox', Safari: 'Safari', IE7: 'Internet Explorer 7',
      IE8: 'Internet Explorer 8', IE9: 'Internet Explorer 9', IE10: 'Internet Explorer 10', IE11: 'Internet Explorer 11', Other: 'Other'
    },
    dimensions: {
      created_on: 'Date', geo: 'Country', pub_id: 'Publisher ID', Browser: 'Browser', src_id: 'Source'
    },
    user: null,
    smartlinks: {},
    notify: {
      error: null,
      msg: null,
      n: 1
    },
    stags: {
      banners: null,
      domainFlow: [
      { trafficType: "RON", dsp: ["Apptimus", "Bucksense", "Kayzen", "MSM"], ssp: "Mopub", domain: "app.baseapp.tech" },
      { trafficType: "RON", dsp: ["Apptimus", "Bucksense", "Kayzen", "MSM"], ssp: "Non Mopub", domain: "go.birdcage.site" },
      { trafficType: "RON", dsp: ["Apptimus", "Bucksense", "Kayzen", "MSM"], ssp: "New SSP TEST", domain: "app.buzzfree.live" },
      { trafficType: "Sport", dsp: ["Apptimus", "Bucksense", "Kayzen", "MSM"], ssp: "Mopub", domain: "fiff5.xyz" },
      { trafficType: "WL", dsp: ["Apptimus"], ssp: "Pubnative", domain: "runfast.site" },
      { trafficType: "WL", dsp: ["Apptimus"], ssp: "Fyber", domain: "meer.life" },
      { trafficType: "WL", dsp: ["Apptimus"], ssp: "Mopub", domain: "coco-4.site" },
      { trafficType: "WL", dsp: ["Apptimus"], ssp: "Other", domain: "vway.site" },
      { trafficType: "WL", dsp: ["Bucksense"], ssp: "Appodeal", domain: "flecko.site" },
      { trafficType: "WL", dsp: ["Bucksense"], ssp: "Mopub", domain: "dooloo.site" },
      { trafficType: "WL", dsp: ["Bucksense"], ssp: "Opera", domain: "kite2.site" },
      { trafficType: "WL", dsp: ["Bucksense"], ssp: "Loopme", domain: "fores.rocks" },
      { trafficType: "WL", dsp: ["Kayzen"], ssp: "Fyber", domain: "tobo55.space" },
      { trafficType: "WL", dsp: ["MSM"], ssp: "Mopub", domain: "dawrs.online" },
      ],
      versions: [
        {
          title: "V1",
          domains: [
            "app.buzzfree.live",
          ],
          path: "/bannertag/script.js?camp_id={camp_id}&tid={tid}",
          prefix: "sub"
        },
        {
          title: "V2",
          domains: [
          ],
          path: "/tag_banner/1/script.js?camp_id={camp_id}&tid={tid}",
          prefix: "pub"
        },
        {
          title: "V3",
          domains: [
            "zite.today",
            "tobo55.space",
          ],
          path: "/img/display.js?camp_id={camp_id}&tid={tid}",
          prefix: "sub"
        },
        {
          title: "V4",
          domains: [
          ],
          path: "/i/img.js?camp_id={camp_id}&tid={tid}",
          prefix: "sub"
        },
        {
          title: "V5",
          domains: [
            "dooloo.site",
            "serls.online",
            "ro33.life"
          ],
          path: "/tag/app.js?c1d={camp_id}&tgi={tid}",
          prefix: "subid"
        },
        {
          title: "V6",
          domains: [
            "runfast.site",
            "w4w4.site",
            "rafraf.live",
          ],
          path: "/r/app.js?cpa={camp_id}&tii={tid}",
          prefix: "sub"
        },
        {
          title: "V7",
          domains: [
            "pfofo.site",
            "boshes.site",
            "besty.link",
            "chickfly.link",
            "elqueck.net",
            "forkon.link",
            "frolo.net",
            "lengo.link",
            "play54.net",
            "roopi.link",
            "rossel.link",
            "sonic2.net",
            // 2
            "came4.site",
            "egrat.online",
            "dawggy.live",
            "elor.site",
            "brunno.live",
            // 3
            "splan.life",
            "ruth3.life",
            "reciate.live",
            "ishe.live",
            "frozo.live",
            // 4
            "genu5.life",
            "integ.live",
            "mmary.live",
            "torii.fun",
            // 5
            "adir.live",
            "maafan.store",
            "kaki.website",
            "gavno.space",
            "dballz.live",
            "yokneham.live",
            // 6
            "vivaukrine.live",
            "yavne.live",
            "avibeeter.online",
            "ronilayla.xyz",
            "zazibazazi.world",
            "kaftoradomgadol.life",
            //7
            "hev-ron.online",
            "haderah.online",
            "jib-rish.online",
            "tzila.site",
            "shubidubi.space",
            "shu-la.site",
            "rexionfire.online",
            "offra.space",
            "neshamami.site",
            "mishula.site",
            "loeele.space",
            "lailas.online",
            "hatzorhaglilit.online",
            "hajonz.online",
            "googis.site",
            "ei-lat.site",
            "dganya.online",
            "ashdodairharesha.space",
            "andish.site",
            "mishmish.life",
            "olaf.live",
            "pishpish.life",
            "sigaria.live",
          ],
          path: "/bd/sc.js?cid={camp_id}&td={tid}",
          prefix: "sub"
        },
        {
          title: "Testing",
          domains: [
            "app.bamba.fun"
          ],
          path: "/display_image/3/script.js?camp_id={camp_id}&tid={tid}",
          prefix: "sub"
        },
      ]
    },
    others: {
      rolesList: null
    },
    affiseVars: {
      vars: []
    },
    sourcesControl: {
      advs: null
    },
    blFiles: null
  },
  actions: {
    getRolesList(context) {
      return new Promise((res, rej) => {
        let state = context.state
        if(state.others.rolesList) {
          return res(state.others.rolesList.slice(0))
        }
        Vue.http.get('admin/roles')
          .then(r => r.json())
          .then(roles => {
            state.others.rolesList = roles; res(roles.slice(0))
          })
      })
    },
    getPermsList(context) {
      return new Promise((res, rej) => {
        let state = context.state
        if(state.others.permsList) {
          return res(Object.assign({}, state.others.permsList))
        }
        Vue.http.get('admin/perms')
          .then(r => r.json())
          .then(perms => {
            let obj = {}
            perms.forEach(r => {
              obj[r.id] = r.name
            })
            state.others.permsList = obj; res(Object.assign({}, obj))
          })
      })
    },
    [act.GET_NEXT_ID] (context) {
      return Promise.resolve('el-' + (++context.state.elementId) )
    },
    [act.GET_DASHBOARD_ROLES] (context) {
      return new Promise((res, rej) => {
        if(context.state.dashboardRoles) {
          return res(context.state.dashboardRoles)
        }
        Vue.http.post('app/modules/users/api/roles')
          .then(r => r.json())
          .then(r => r.data)
          .then(roles => {
            roles.items = {}
            roles.forEach(role => {
                roles.items[''+role.id] = role.display_name
            })
            context.state.dashboardRoles = roles
            res(roles)
          })
      })
    },
    [act.GET_COUNTRIES_LIST] (context) {
      return new Promise((res, rej) => {
        if(context.state.countriesList) {
          return res(context.state.countriesList)
        }
        rej("No countries list")
      })
    },
    [act.GET_LANGUAGES] (context) {
      return new Promise((res, rej) => {
        if(context.state.languagesList) {
          return res(context.state.languagesList)
        }
        Vue.http.get('api/languages/minimal')
          .then(r => r.json())
          .then(r => r.data)
          .then(languages => {
            context.state.languagesList = languages
            res(languages)
          })
      })
    },
    [act.GET_PUB_IDS] (context) {
      return new Promise((res, rej) => {
        if(context.state.pubIds) {
          return res(context.state.pubIds)
        }
        Vue.http.post('app/modules/users/api/pubids')
          .then(r => r.json())
          .then(r => r.data)
          .then(pubIds => {
            pubIds.items = {}
            pubIds.forEach(p => {
              pubIds.items[''+p.pub_id] = p.pub_id + ` (${p.type}, ${!p.used ? 'free' : ''})`
            })
            context.state.pubIds = pubIds
            res(pubIds)
          })
      })
    },
    ["GET_TM_TEMPLATES"] (context) {
      return new Promise((res, rej) => {
        if(context.state.tagMahal.templates) {
          return res(context.state.tagMahal.templates)
        }
        Vue.http.get(resources.Management.tmTemplates)
          .then(r => r.json())
          .then(t => {
            t = t || []
            context.state.tagMahal.templates = t
            res(t)
          })
      })
    },
    ["GET_TM_DOMAIN_PREFIXES"] (context) {
      return new Promise((res, rej) => {
        if(context.state.tagMahal.domainPrefixes) {
          return res(context.state.tagMahal.domainPrefixes)
        }
        Vue.http.get(resources.Management.tmDomainPrefixes)
          .then(r => r.json())
          .then(t => {
            t = t || []
            context.state.tagMahal.domainPrefixes = t
            res(t)
          })
      })
    }

    // [act.GET_USERS] (context) {
    //   return new Promise((res, rej) => {
    //     if(context.state.usersList) {
    //       return res(context.state.usersList)
    //     }
    //     Vue.http.post('api/users')
    //       .then(r => r.json())
    //       .then(r => r.data)
    //       .then(countries => {
    //         context.state.countriesList = countries
    //         res(countries)
    //       })
    //   })
    // }
  }
})

export default store
