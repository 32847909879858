<template lang="html">
  <div class="all">
    <h3 class="h3-12">Domains Prefix Management</h3>
    <ul>
      <li v-for='t in data' class="template-item">
        <span class="t-name">{{t.prefix}}</span>
        <input readonly class="def long t-url-input" :value='t.domain'>
        <i class="fa fa-trash trash-btn" aria-hidden="true" @click='deleteDomainPrefixModal = t'></i>
      </li>
    </ul>

    <form class="n-form-2" @submit.prevent='addTemplate'>
      <strong class="addtemplatetext">Add domain prefix: </strong>
      <input v-model='form.prefix' class="def long" placeholder="Prefix">
      <input v-model='form.domain' class="def long t-url-input" placeholder="Domain">
      <button type="submit" class="btn btn-primary">Add</button>
    </form>

    <Modal v-if='deleteDomainPrefixModal'>
      <span>
        Are you sure that you want to delete prefix "{{deleteDomainPrefixModal.prefix}}"?
      </span>
      <div class="modal-btns">
        <div class="managment-btn-section">
          <button class='btn _btn-danger delbtn-2345' @click='del(deleteDomainPrefixModal)'>Delete</button>
          <button class='btn _btn-white' @click='deleteDomainPrefixModal = false'>Cancel</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    let emptyForm = {
      prefix: null,
      domain: null
    }

    return {
      deleteDomainPrefixModal: null,
      emptyForm,
      form: Object.assign({}, emptyForm),
      data: []
    }
  },
  created() {
    this.$store.dispatch("GET_TM_DOMAIN_PREFIXES")
      .then(dp => {
        this.data = dp
      })
  },
  methods: {
    del(t) {
      this.$http.delete(this.resources.Management.tmDelDomainPrefix(t.id))
        .then(() => {
          this.data.splice(this.data.indexOf(t), 1)
        })
      this.deleteDomainPrefixModal = null
    },
    addTemplate() {
      let form = this.form
      this.$validate.tmDomainPrefix(form)
        .then(() => {
          this.$http.post(this.resources.Management.tmDomainPrefixes, form)
          .then(r => r.json())
          .then(template => {
            this.form = Object.assign({}, this.emptyForm)
            this.data.push(template)
            this.notifySuccess("Templated added")
          })
        })
        .catch(err => {
          this.notifyError(err.msg)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.template-item {
  display: flex;
  margin-bottom: 5px;
  padding: 3px 0px;
  align-items: center;
}
.template-item span {
  margin-right: 16px;
}
.t-name {
  /* font-weight: bold; */
  font-size: 16px;
  width: 150px;
}
.t-url {

}
.t-url-input {
  flex: 1;
  font-size: 14px;
  margin-right: 10px;
}
.n-form-2 {
  border-top: 1px solid #dedede;
  padding: 5px;
  margin-top: 17px;
  display: flex;
  align-items: center;
  padding-top: 14px;
  margin-top: 21px;
}
.n-form-2 * {
  margin-right: 6px;
}
.addtemplatetext {
  width: 156px;
}
.delbtn-2345 {
  margin-right: 6px;
}
.var-2 {
  font-size: 20px;
}
.ex-23 {
  margin-top: 30px;
}
</style>
