<template lang="html">
  <form class="form-box-1" @submit.prevent='submit'>
    <div class="fields-23">
      <FormField addMargin='5px' :inline='1' :value='form.externalID' placeholder="ID / Title" labelText='ID / Title' :onUpdate='e => { form.externalID = e }' />

        <FormField addMargin='5px' :inline='1' :def='0' placeholder="Country" labelText='Country'>
          <select v-model='form.country' class="def select-234">
            <option value="">No filter</option>
            <option v-for='(title, option) in countryOptions' :value='option'>{{title}}</option>
          </select>
        </FormField>

        <FormField addMargin='5px' :inline='1' :def='0' placeholder="Os" labelText='Os'>
          <select v-model='form.os' class="def select-234">
            <option value="">No filter</option>
            <option v-for='(title, option) in osOptions' :value='option'>{{title}}</option>
          </select>
        </FormField>

        <FormField addMargin='5px' :inline='1' :value='form.inPixel' placeholder="in offer url" labelText='In Offer URL' :onUpdate='e => { form.inPixel = e }' />
        <FormField addMargin='5px' :inline='1' :value='form.inOfferName' placeholder="in offer name" labelText='In Offer Name' :onUpdate='e => { form.inOfferName = e }' />
        <!-- <FormField addMargin='5px' :inline='1' :def='0' placeholder="Type" labelText='Type'>
          <select v-model='form.type' class="def select-234">
            <option value="">No filter</option>
            <option v-for='(title, option) in typeOptions' :value='option'>{{title}}</option>
          </select>
        </FormField> -->
    </div>

    <div class="btns">
      <div class="control-btn">
        <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>
        <button type="button" class="btn _btn-white" @click='resetFilter'>Reset Filter</button>
      </div>
    </div>
  </form>
</template>

<script>
import * as act from "@/act-types";

export default {
  props: {
    onSubmit: {}
  },
  data() {
    let emptyForm = {
      externalID: null,
      country: null,
      os: null,
      inPixel: null,
      inOfferName: null,
      type: "stag"
    }
    return {
      emptyForm,
      form: Object.assign({}, emptyForm),
      processing: false,
      countryOptions: [],
      osOptions: {
        ios: "iOS",
        android: "Android"
      },
      typeOptions: {
        stag: "Script Tag",
        internal: "Internal Placement",
        external: "External Placement"
      }
    };
  },
  methods: {
    submit() {
      this.processing = true
      this.onSubmit(this.form)
      .then(() => {
        this.processing = false
      })
      .catch(() => {
        this.processing = false
      })
    },
    resetFilter() {
      this.form = Object.assign({}, this.emptyForm)
    }
  },
  created() {
    this.$store.dispatch(act.GET_COUNTRIES_LIST).then(countries => {
      this.countryOptions = Object.assign({}, {'WW': 'World Wide'}, countries)
    })

    let query = this.$route.query
    let editPlacementID = query.placement_id
    if(editPlacementID) {
      this.form.externalID = editPlacementID
      this.submit()
    }
  }
};
</script>

<style lang="css" scoped>
.form-box-1 {
}
.fields-23 {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
</style>
