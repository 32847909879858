<template lang="html">
  <div class="all">
    <router-view v-if='loaded' />
    
  </div>
</template>

<script>
import utils from '../utils/utils.smartlinks.js'
export default {
  data() {
    return {
      loaded: false
    }
  },
  created: async function () {
    let state = this.$store.state
    let campId = this.$route.params.campId
    let camp = state.smartlinks.campaigns.find(v => v.id == campId)
    try{
      const r = await this.$http.get(this.resources.SmartLinksCampaigns.smartLinksCampaignData(campId))
      var placements = await r.json()
      placements = placements || []
      placements = placements.map(v => utils.formPlacement(v))
      placements = this.organizeByOrder(placements, camp?.placements_order)
      camp.placements = placements
      this.loaded = true
    }catch(err){

    }
  },
  methods: {
  }
}
</script>

<style lang="css">
</style>
