<template>
<div>
    <h1>{{this.group.name}}</h1>
    <h2 >{{"Group is"}}<span :class="{active: this.group.status === 'ACTIVE', paused: this.group.status === 'PAUSED' }">{{this.group.status === 'ACTIVE' ? ' ACTIVE' :  ' PAUSED'}}</span></h2>
<div v-if="data.length > 0 ">
    <table class="table">
        <thead>
            <tr>
                <th scope="col"><a href="javascript:void(0)" @click="sortByCol('camp_id')">#</a></th>
                <th scope="col"><a href="javascript:void(0)" @click="sortByCol('name')">Campaign Name</a></th>
                <th scope="col">Status</th>
                <th scope="col">In Force</th>
                <th scope="col">Link To Camp</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in data" :key="row.id">
                <th>{{ row.camp_id }}</th>
                <th>{{ row.name }}</th>
                <td>{{ row.camp_status }}</td>
                <td>{{ row.group_forced ? 'YES' : 'NO' }}</td>
                <td>
                    <a v-if="row.is_vta" :href="`/apps/smartlinksVTA/campaigns/${row.camp_id}/`">{{ `${row.camp_id} ${row.name}` }}</a>
                    <a v-else :href="`/apps/smartlinks/campaigns/${row.camp_id}/`">{{  `${row.camp_id} ${row.name}`  }}</a>
                </td>

            </tr>
        </tbody>
    </table>
</div>
<div v-else>
<h3> There are no related offers to show</h3>
</div>
</div>
</template>

<script>
export default {
    data() {
        return {
            groupsId: 0,
            res: {},
            group: {},
            data: [],
            nameBreakDownSort: {column: 'name', order: 'down'}

        }
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        this.groupsId = urlParams.get('id');
        this.getGroupRelatedOffers();
    },
    methods: {
        async getGroupRelatedOffers() {
            try {
                const data = await this.$http.get(this.resources.SmartLinksCampaigns.fetchGroupRelatedCamps(this.groupsId))
                this.res = await data.json();
                if (this.res.res != 'OK') {
                    this.notifyErorr()
                }
                this.data = this.res.data
                this.group = this.res.group
            } catch (err) {

            }
        },
        sortByCol(column) {
            let sortData = this.nameBreakDownSort
            if (typeof sortData.column === 'undefined') {
                return
            }

            if (sortData.column === column) {
                if (sortData.order === 'up') {
                    sortData.order = 'down'
                } else {
                    sortData.order = 'up'
                }

            } else {
                sortData.column = column
                sortData.order = 'up'
            }

            if (sortData.order === 'down') {
                this.data.sort((a, b) => (a[column] < b[column]) ? 1 : -1)
            } else {
                this.data.sort((a, b) => (a[column] > b[column]) ? 1 : -1)
            }
        }
    },

}
</script>

<style scoped>
h2 {
    margin-bottom: 10%;
}

.paused {
    color: red;
}

.active {
    color: green;
}
</style>
