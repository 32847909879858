import { render, staticRenderFns } from "./Cross-Table.vue?vue&type=template&id=7bd5645c&scoped=true&lang=html&"
import script from "./Cross-Table.vue?vue&type=script&lang=js&"
export * from "./Cross-Table.vue?vue&type=script&lang=js&"
import style0 from "./Cross-Table.vue?vue&type=style&index=0&id=7bd5645c&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bd5645c",
  null
  
)

export default component.exports