<template>
  <textarea v-model="internalValue"></textarea>
</template>

<script>
export default {
  props: {
    value: { type: String, default: "" },
    max: { type: Number, default: 250 },
  },
  computed: {
    internalValue: {
      get() {
        if (this.value.length >= this.max) {
          return this.value.substr(0, this.max);
        }
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
