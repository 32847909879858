<template lang="html">
  <div class="all">
    <form class="form-42" @submit.prevent='onSubmit'>
      <div class="form-fields-1">
        <div class="form-inputs">
          <label >Title:</label>
          <input class="def" v-model='form.title'>
        </div>

        <div class="form-inputs">
          <label >Advertiser ID:</label>
          <input class="def" v-model='form.advId'>
        </div>

        <div class="form-inputs">
          <label >Payout:</label>
          <input class="def" v-model.number='form.payout'>
        </div>
        <div class="div-2514">
          <div class="t-p-2">
            <label >Tracking Pattern:</label>
            <textarea v-model='form.manualPatterns' placeholder="Manual pattern:replace" class="text-13"></textarea>
          </div>
          <div class="t-p-6">
            <label  class="r-512">Running Times:</label>
            <textarea class="text-12" v-model='form.timings'></textarea>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-success">{{editMode ? "Submit Changes": "Create"}}</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    onCreate: {},
    onEdit: {},
    data: {}
  },
  data() {
    let defaultPatternsObject = {
      "\\d*{pid}_{sub2}":"[[TIMESTAMP]]{pid}_{sub2}",
      "\\d*{pid}([A-Z]+_[^&]*)":"[[TIMESTAMP]]{pid}$1"
    }
    let defaultPatterns = []
    for(let key in defaultPatternsObject) {
      let str = `${key}:${defaultPatternsObject[key]}`
      defaultPatterns.push(str)
    }
    defaultPatterns = defaultPatterns.join("\n")

    return {
      form: {
        title: null,
        advId: null,
        payout: null,
        patterns: [],
        timings: "",
        manualPatterns: defaultPatterns
      },
    }
  },
  created() {
    Object.assign(this.form, this.data)
  },
  computed: {
    editMode() {
      return !!this.data
    }
  },
  methods: {
    createVar(form) {
      this.$http.post(this.resources.affiseVars.vars, form)
        .then(r => r.json())
        .then(id => {
          form.id = id
          this.onCreate && this.onCreate(form)
          this.form = {
            title: null,
            advId: null,
            payout: null,
            patterns: [],
            timings: "",
            manualPatterns: ""
          }
          this.notifySuccess("Created new variable")
        })
        .catch(err => {
          console.log(err);
          this.notifyError("Error occured")
        })
    },
    editVar(form) {
      this.$http.put(this.resources.affiseVars.varById(form.id), form)
        .then(r => r.json())
        .then(affiseVar => {
          Object.assign(this.data, form)

          if(this.data.timings) {
            this.data.timings = this.data.timings.map(t => t.substr(0,5)).join("\n")
          }

          this.onEdit && this.onEdit(this.data)
          this.notifySuccess("Edited Variable")
          // this.form = { title: null, advId: null, payout: null}
        })
        .catch(err => {
          console.log(err);
          this.notifyError("Error occured")
        })
    },
    onSubmit() {
      let form = Object.assign({}, this.form)
      form.timings = form.timings.replace(/[^\d:]+/g, ",").split(",").filter(v => v !== "")
      if(form.manualPatterns) {
        form.patterns = form.manualPatterns.replace(/\r?\n/g, ",").split(",")
        form.patterns = form.patterns.map(v => { let t = v.split(":"); return {replaceWith: t[1], search: t[0]}})
      }
      Promise.resolve() // this.$validate.stags.link(this.form)
      .then(() => {
        if(this.editMode) {
          this.editVar(form)
        } else {
          this.createVar(form)
        }
      })
      .catch(err => {
        this.notifyError(err.msg)
      })

    }
  }
}
</script>

<style lang="css" scoped>
.form-fields-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}
.form-fields-1 > div {
  display: flex;
  justify-content: space-between;
  width: 550px;
  margin-bottom: 7px;
}
.form-42 {
  margin: 22px 0;
}
.t-p-2 {
  display: flex;
  flex-direction: column;
}
.p-242 {
  margin-top: 7px;
}
.text-12 {
  border: 1px solid gainsboro;
  resize: none;
  height: 160px;
  width: 53px;
  padding: 6px 13px 0 28px;
  box-sizing: content-box;
}
.text-13 {
  border: 1px solid gainsboro;
  resize: none;
  height: 160px;
  width: 400px;
  padding: 6px 0px 0 9px;
  box-sizing: content-box;
  margin-top: 7px;
  font-size: 14px;
}
.div-2514 {
  display: flex;
  justify-content: space-between;
}
.t-p-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 170px;
}
.r-512 {
  margin-bottom: 3px;
}
.input-52 {
  margin-top: 11px;
}
.lab-2 {
  background: #e8e8e8;
  padding: 2px;
  border-radius: 2px;
}
</style>
