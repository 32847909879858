<template>
    <div class="container mt-5">

        <div class="modal_container">
            <b-modal v-model="modalShow" class="domain_modal" :id="modalId" size="md" @hide="onHide">
                <template id="bootstrap-overrides" #modal-header clas><span id="add-title"
                        v-if="operation === 'add' ">Add new Permission:</span>
                    <div class="edit-title" v-if="operation === 'edit' "><span id="space-header">
                            Make changes to:</span> <span id="modal-header-red" class="text-red"><b>{{text}}</b></span>
                    </div>
                </template>
                <div class="modal-inputs">
                    <div class="modal-input-container">
                        <label class="modal_label-md">Name</label>
                        <input type="text" v-model="nameListener" class="def input-c-141 modal_input"
                            :placeholder="operation === 'add' ? 'Enter name ' : currentPerm.name " required>
                        <span class="validation-sapn" v-if="msg.name && operation === 'add'">{{msg.name}}</span>
                    </div>
                    <div class="modal-input-container">
                        <label class="modal_label-md">Description</label>
                        <b-form-textarea class="modal_input" id="textarea-small" v-model="descriptionListener" size="sm"
                            :placeholder="operation === 'add' ? 'Enter permission description' : currentPerm.description ">
                        </b-form-textarea>
                    </div>
                </div>

                <template busy="true" v-slot:modal-footer>
                    <div class="footer-container" v-if="operation === 'edit'">
                        <b-button id="modal-del-btn" variant="danger" @click="deletePerm(currentPerm)">
                            Delete
                        </b-button>
                        <div class="unseen-element"></div>
                        <b-button variant="success" @click="submitEditChanges(currentPerm) ">
                            Save
                        </b-button>
                        <b-button @click="cancel()">
                            Cancel
                        </b-button>
                    </div>

                    <div v-if="operation === 'add'">
                        <b-button type="submit" variant="primary" @click="submitAddChanges(currentPerm)">Submit
                        </b-button>
                        <b-button @click="cancel()">
                            Cancel
                        </b-button>
                    </div>

                </template>
            </b-modal>
        </div>
    </div>
</template>


<script>
    import utils from '../../views/apps/smartlinks/utils/utils'


    export default {

        props: {
            operation: String,
            currentPerm: {},
            modalId: String,
            modalShow: Boolean,
            text: String,
            selectedPermissionsObj: {},
            permsList: [],
            opCode: Number,
        },
        data() {
            return {
                show: true,
                loaded: false,
                index: 0,
                approvedStatusToggle: 0,
                permsList: [],
                selectedPermissions: this.selectedPermissionsObj,
                optionsPermissions: [],
                nameListener: '',
                emailListener: '',
                passwordListener: '',
                msg: [],
                descriptionListener: '',
            }
        },
        methods: {
            cancel() {
                this.$emit('update:modalShow', false)
            },
            onHide(event) {
                if (event.trigger === "backdrop") {
                    this.$emit('update:modalShow', false)
                    event.preventDefault();
                    this.handleBackdrop();
                }
            },
            onSubmit(event) {
                event.preventDefault()
            },
            onReset(event) {
                event.preventDefault()
                this.modalName = ''

                utils.clearValidationState();
            },
            resetValues() {
                this.nameListener = ''
            },
            submitEditChanges: async function (perm) {
                this.$emit('update:currentPerm', newPerm)
                this.$emit('update:modalShow', false)
                let newPerm = {
                    ...perm,
                    description: this.descriptionListener == '' ? perm.description : this.descriptionListener,
                    name: this.nameListener == '' ? perm.name : this.nameListener,
                }
                try {
                    const data = await this.$http.put(this.resources.Admin.editPerm(newPerm.id), newPerm)
                    this.notifySuccess(`Permission has been edited`)
                    this.resetValues()
                } catch (err) {
                    this.notifyError("Could not edit permission.")
                }
            },
            submitAddChanges: async function (perm) {

                if (this.nameListener == '') {
                    this.notifyError("New permission must have a name")
                } else {
                    let newPerm = {
                        ...perm,
                        description: this.descriptionListener == '' ? "none" : this.descriptionListener,
                        name: this.nameListener,
                    }
                    try {
                        const data = await this.$http.post(this.resources.Admin.newPerm, newPerm)
                        this.notifySuccess(`New permission '${newPerm.name}' has been created`)
                        this.$emit('update:currentPerm', newPerm)
                        this.$emit('update:modalShow', false)

                        this.resetValues();
                    } catch (err) {
                        this.notifyError("Could not insert permission.")
                    }



                }
            },
            deletePerm: async function (perm) {

                try {

                    const r = await this.$http.delete(this.resources.Admin.deletePerm(perm.id))
                    this.notifySuccess(`Permission ${perm.name} has been deleted`)
                    this.resetValues();
                    this.$emit('update:modalShow', false)
                    this.$emit('update:currentPerm', {})
                } catch (err) {
                    this.notifyError(`Could not delete permission ${perm.name}.`)
                }

            },
        }
    }
</script>

<style scoped>
    .modal_label-md {
        font-weight: bold;
        width: 30%;

    }

    .validation-sapn {
        padding-top: 0px;
        margin-top: 0px;
        font-size: 10px;
        color: red;
        padding-left: 10px;
    }

    .unseen-element {
        width: 60%;
    }

    .text-red {
        color: red;
    }

    .modal_input {
        width: 200px;
    }

    .modal_container {
        display: flex;
    }

    .modal_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        column-gap: 10px;
    }

    .footer-container {
        width: 150%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-input-container {
        display: flex;
        flex-wrap: initial;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
        border-bottom: 1px solid gainsboro;
        padding-bottom: 15px;

    }

    #space-header {
        padding-right: 10px;
    }

    #boxes {
        column-count: 3;
    }
</style>