<template>
  <div class="all">
    <b-table id="bootsrap-table" sticky-header="500px" striped hover :fields="fields" :items="bundleList" fixed
      responsive :busy="isBusy">

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(Remove)="data" class="delete-tab">
        <button id="delete_btn" @click='deleteFromBl(data.item)'>
          <b-icon-trash></b-icon-trash>
        </button>
      </template>
    </b-table>

    <div class="middle-container"></div>

    <b-container fluid>
      <b-row class="my-1">
        <b-col sm="3">
          <label id="bundle-input" for="input-none">New bundle id:</label>
        </b-col>
        <b-col sm="3">
          <b-form-input id="input-none" :state="null" v-model="bundlesInput" placeholder="New Bundle ID"></b-form-input>
        </b-col>
        <b-col sm="3">
          <b-form-input id="input-none" :state="null" v-model="nameInput" placeholder="New Bundle Name"></b-form-input>
        </b-col>
        <b-col sm="3">
          <b-button class="add-btn" variant="outline-primary" @click="submitBundles">Add</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fields: [{
          key: 'Remove',
          thStyle: {
            width: "2%"
          },
        }, {
          key: 'value',
          sortable: true,
          thStyle: {
            width: "10%"
          },
}, {
          key: 'app_name',
          label: 'App Name',
          sortable: true,
          thStyle: {
            width: "15%"
          }
}
],
        bundleList: [],
        count: 0,
        nameInput:"",
        bundlesInput: "",
        isBusy: false,
      }
    },
    created: async function () {
      this.isBusy = !this.isBusy
      await this.getData()
    },
    methods: {
      getData: async function () {
        try {
          const data = await this.$http.get(this.resources.ScriptTags.bundleIdBl)
          const r = await data.json()
          this.bundleList = r
          this.isBusy = !this.isBusy
        } catch (err) {
          this.notifyErorr("Couls not fetch bundles")
        }
      },
      deleteFromBl: async function (bundle) {
        this.isBusy = !this.isBusy
        try {
          const data = await this.$http.delete(this.resources.ScriptTags.deleteBundleIdBl(bundle.id))
          await this.getData();

        } catch (err) {
          this.notifyError(`Bundle ${bundle.value} faild deletion `)
        }

      },
      submitBundles: async function () {
        this.isBusy = !this.isBusy
        let newBundle = {
          value: this.bundlesInput,
          app_name: this.nameInput
        }

        try {
          const data = await this.$http.post(this.resources.ScriptTags.bundleIdBl, newBundle)

          await this.getData();
          this.bundlesInput = ""
          this.nameInput = ""
        } catch (err) {
          this.notifyError(`Bundle ${vals} faild registration `)
        }

      },
    }
  }
</script>
<style scoped>
  .middle-container {
    margin-top: 10%;
  }

  .add-btn {
    border-radius: 10%;
  }

  #delete_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  #bundle-input {
    font-size: large;
    padding-top: 2.5%;
  }
</style>