<template lang="html">
  <h1>Page not found.</h1>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
