<template>
    <div>
        <div>
            <a :href="'/man/bundle-pool-edit/' + list_id">Back to bundle list</a>
        </div>
        <div>Bundle List - Bulk Upload</div>
        <div>
            <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
            <span>
                Make sure you have only <strong>3 columns</strong>  in the file icluding only <strong>numbers, text and _ - .</strong> (underscore, dash, dot)
            </span>
        </div>
        <div v-if="process_status === 'loading'">
            Loading...
        </div>
        <div v-if="process_status === 'done'">
            Done!
        </div>
        <div v-if="process_status === 'error'" style="padding-top: 20px; color: red;">
            Please make sure the data is valid and try again
        </div>
        <div style="padding-top: 20px" v-if="process_status === '' || process_status === 'error'">
           <form>
                <div>
                    <input type="file" ref="csv_file" /><br/>
                </div>
                <div style="padding-top: 10px;">
                    <a class="btn btn-primary" href="javascript:void(0);" @click="uploadAndLoad()">Upload</a>
                </div>
           </form>
           <div style="padding-top: 20px;">
                <a href="/examples/bundles_bulk_example.csv">Download example CSV</a>
           </div>
        </div>
    </div>
</template>

<script>
import {
    defineComponent
} from '@vue/composition-api'
export default {
    data() {
        return {
            list_id: this.$route.params.id,
            rows: [],
            list_data: {
                list_name: '',
                data_to_send: '',
            },
            process_status: ''
        }
    },
    created() {
        console.log('created')
        this.getListData()
    },
    methods: {
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getListData, {
                list_id: this.list_id,
            })
            for (let k in data.body.data.bundles) {
                data.body.data.bundles[k].edit = false
            }
            this.list_data = data.body.data.list_data
            this.rows = data.body.data.bundles
        },
        async uploadAndLoad() {
            if (typeof this.$refs.csv_file.files[0] === 'undefined') {
                this.notifyError('Please select a file')
                return
            }
            this.process_status = 'loading'
            const formData = new FormData();
            formData.append('csv_file', this.$refs.csv_file.files[0]);
            const headers = { 'Content-Type': 'multipart/form-data' };
            const data = await this.$http.post(this.resources.Management.bundlesBulkUpload + '?list_id=' +  this.list_id, formData)
            if (data.body.includes('"res":"NOK"')) {
                this.process_status = 'error'
            } else {
                this.process_status = 'done'
            }
        }
    },

}
</script>

<style scoped>

</style>
