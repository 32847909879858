<template>
    <div>
        <div v-if="is_loading">
            <b> Loading ...</b>
        </div>
        <div v-else>
            <form class="report-form-1" @submit.prevent=''>
                <DateRange :onChange='onDatesChange' :from_='reportForm.startDate' :to_='reportForm.endDate' />
                <div class="div-1412">
                    <button  @click='fetchDomains' class="btn btn-success">Update Stats</button>
                </div>
            </form>
            <br>
            <br>
            <div>
                <a href="/dom/domains-edit?id=0">Create new domain</a>
            </div>
            <DomainsDataTable :rows_data="domainlist" :tableHeight="'1000px'"></DomainsDataTable>
        </div>
    </div>
</template>
<script>
import DomainsDataTable from './components/DomainsDataTable.vue';
import moment from 'moment';
export default {
    components: {
        DomainsDataTable
    },
    data() {
        let today = new Date()
        let yesterday = today.setDate(today.getDate() - 1)

        return {
            traffic_types: [],
            dsps: [],
            ssps: [],
            stags_versions: [],
            cdns: [],
            info_data: '',
            is_loading: false,
            domainlist: [],
            reportForm: {
                startDate: yesterday,
                endDate: today
            },
        };
    },
    async created() {
        this.is_loading = true;
        await this.getOptions();
        await this.fetchDomains();
        this.is_loading = false;
    },
    methods: {
        async fetchDomains() {
            try {
                if (!this.isEndDateValid(this.reportForm.startDate, this.reportForm.endDate)) {
                    this.notifyError("End date must be on or after start date.");
                    return;
                }
                // add the option to fetch the domains requests by filtering the date
                const r = await this.$http.post(this.resources.Domains.getSmartlinksDomains, {
                    start_date:  this.formatDateFromMilliseconds(this.reportForm.startDate),
                    end_date:  this.formatDateFromMilliseconds(this.reportForm.endDate),
                });
                const data = await r.json();
                
                for (const k in data) {
                    if (data[k].status.length > 0) {
                        if (data[k].status == 'active') {
                            data[k].status = 'Active';
                        } else if (data[k].status == 'paused') {
                            data[k].status = 'Paused';
                        } else {
                            data[k].status = 'Pending';
                        }
                        data[k].name = data[k].name.trim();
                        data[k].type = data[k].type.charAt(0).toUpperCase() + data[k].type.slice(1);
                        data[k].dsp = data[k].dsp.charAt(0).toUpperCase() + data[k].dsp.slice(1);
                        data[k].ssp = data[k].dsp.charAt(0).toUpperCase() + data[k].ssp.slice(1);
                        data[k].tmt_status = data[k].tmt_status.toUpperCase();
                        data[k].adjust_status = data[k].adjust_status.toUpperCase();
                        data[k].type = data[k].type === 'Cdn' ? 'CDN' : data[k].type;
                    }
                    // bind cdn name to cdn id
                    if (data[k].cdn) {
                        let cdnObj = this.cdns.filter(cdn => {
                            return parseInt(cdn.value) === data[k].cdn;
                        });
                        data[k].cdn_name = typeof cdnObj[0] !== 'undefined' ? cdnObj[0].text : 'none'
                    } else {
                        data[k].cdn_name = '-'
                    }
                    // modify name
                    if (!data[k].name.includes('https://')) {
                        data[k].name = 'https://' + data[k].name;
                    }
                    // format date
                    data[k].date = moment(data[k].date).format('DD.MM.YYYY');
                    // link to edit
                    data[k].href = '/dom/domains-edit?id=' + data[k].id + '&type='  + data[k].type;
                    
                    // set comment by type
                    switch(data[k].type) {
                        case 'serving':
                            data[k].comments = data[k].serving_comments;
                            break;
                        case 'postback':
                            data[k].comments = data[k].affise_tracking_comments;
                            break;
                        case 'cdn':
                            data[k].comments = data[k].cdn_comments;
                            break;
                        case 'affise_trackin':
                            data[k].comments = data[k].dsp_comments;
                            break;
                        case 'admin':
                            data[k].comments = data[k].admin_comments;
                            break;
                        default:
                            data[k].comments = data[k].serving_comments;
                    }
                }
                this.domainlist = data;
                // add href
                // bind and add the cdn name by its id
                // add colums
            
                
            } catch (err) {
                console.log(err);
                this.notifyError("Could not fetch domains");
            }
        },
        async getOptions() {        
            const r = await this.$http.get(this.resources.Domains.getDomainsOptions, {});
            this.ssps = r.data.ssps;
            this.dsps = r.data.dsps;
            this.traffic_types = r.data.traffic_types;
            this.stags_versions = r.data.stags_versions;
            this.cdns = r.data.cdns;
        },
        onDatesChange(v) {
            let {from, to} = v
            this.reportForm.startDate = from
            this.reportForm.endDate = to
        },
        isEndDateValid(startDateStr, endDateStr) {
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);

            // Check if dates are valid
            if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                console.log("Invalid date(s) provided.");
                return false;
            }

            // Check if the end date is on or after the start date
            if (endDate >= startDate) {
                return true;
            } else {
                return false;
            }
        },
        formatDateFromMilliseconds(milliseconds) {
            const date = new Date(milliseconds);

            const year = date.getFullYear();
            let month = date.getMonth() + 1; // Months are 0-indexed
            let day = date.getDate();

            // Pad the month and day with a leading zero if they are single digit
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;

            return `${year}-${month}-${day}`;
        },
    },

}
</script>
<style scoped>
</style>