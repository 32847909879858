import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=2de6c029&scoped=true&lang=html&"
import script from "./Spinner.vue?vue&type=script&lang=js&"
export * from "./Spinner.vue?vue&type=script&lang=js&"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=2de6c029&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de6c029",
  null
  
)

export default component.exports