<template lang="html">
    <b-modal v-model="showInfoModal" title="Event Name"  @hide=" $emit('update:is-show', false)" hide-footer>
      <div>
        Event name as it appears in Affise payout screen
      </div>
    </b-modal> 
</template>
<script>
    
export default {
    
    props: ['isShow'],
    data() {
        return {

        }
    },
    created(){

    },
    computed: {
        showInfoModal: {
            get: function() {
                return this.isShow
            },
            set: function() {

            }
        }
    },
    methods: {

    },    
}
</script>
<style scoped>

</style>