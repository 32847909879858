<template lang="html">
  <div class="all-section">
    <form class="form-section">
      <div class="d121">
        <label  class="la1">Placements:</label>
        <div class="p-ids-2">
          <span v-for='p in placements'>{{p.externalID}}</span>
        </div>
      </div>
      <hr>
      <h5>Pixels</h5>
      <PixelsForm :emptyPixels='1' :pixels='form.pixels' :errorInPixelIndex='errorInPixelIndex' />
      <hr>
      <div class="btns">
        <button type="button" class="btn btn-primary" @click='submit' :disabled='isProcessing'>Submit</button>
        <button type="button" class="btn btn-primary" @click='onSubmit(0)' :disabled='isProcessing'>Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import PixelsForm from './PixelsForm.placements'
import placementUtils from './utils.placements'

export default {
  components: {PixelsForm},
  props: {
    placements: {},
    onSubmit: {}
  },
  data() {
    return {
      form: {
        pixels: []
      },
      isProcessing: false,
      errorInPixelIndex: null
    }
  },
  methods: {
    buildPixelURL: placementUtils.buildPixelURL,
    formatPixels: placementUtils.formatPixels,
    formatPlacementIDs(s) {
      if(!s) {
        return []
      }
      return new Set(s.split(/\r\n|\r|\n/).filter(v=>v))
    },
    _afterValidation(pixels) {
      let proms = []
      this.placements.forEach(p => {
        let pDup = Object.assign({}, p)
        p.pixels.forEach(px => px.template_vars = JSON.stringify(px.template_vars))
        pDup.pixels = p.pixels.concat(pixels)
        let prom = this.$http.put(this.resources.Management.tmEditPlacement(p.id),
            pDup)
            .then(r => r.json())
            .then(pl => {
              p.pixels = placementUtils.parsePixels(pl.pixels)
            })
        proms.push(prom)
      })

      Promise.all(proms)
      .then(() => {
        this.onSubmit()
      })
      .catch(err => {
        this.notifyError("Error occured")
      })
      .then(() => {
        this.isProcessing = false
        this.onSubmit(1)
      })
    },
    submit() {
      let form = this.form
      let {placementIDsStr, pixels} = form
      let placementIDs = this.formatPlacementIDs(placementIDsStr)

      pixels = this.formatPixels(pixels)
      form = Object.assign({}, form, {pixels})
      this.$validate
        .tmPixels(form)
        .then(e => this._afterValidation(pixels))
        .catch(err => {
          let obj = err.object
          if(obj.hasOwnProperty("$arrayIndex")) {
            this.errorInPixelIndex = obj.$arrayIndex
          }
          this.notifyError(err.msg)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.te1212 {
  height: 250px;
  resize: none;
  width: 400px;
}
.d121 {
  display: flex;
}
.la1 {
  margin-right: 15px;
}
.p-ids-2 {
  display: flex;
  flex-wrap: wrap;
}
.p-ids-2 > * {
  border: 1px solid gainsboro;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #001500;
  background: #e8e8e8;
}
</style>
