<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <h2>Bundles WL Pool</h2>
        <div>
        <div v-if="success">
            Bulk Added Successfully.
        </div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-if="showForm">
            <div v-if="inputErr">
                Please check your input...
            </div>
            <form @submit="addBulk">
                <div>
                    <textarea v-model="bundlesRawData" style="width: 100%; resize: none; height: 300px;"></textarea>
                </div>
             
                <div style="text-align: center;">
                    <input @click="addBulk" class="btn" type="submit" value="Add Bulk" />
                </div>
            </form>
            <div>
                Please add bundles separated by commas or enters.
            </div>
        </div>
    </div>
        <div class="add-form">
            <b-form class="pid-add-form mt-5">
                <b-row class="pid-add">
                    <b-col>
                        <b-form-group label="Filter by Bundle:">
                            <b-form-input v-model="filter_var" type="text"
                                placeholder="Enter Bundle"></b-form-input>
                                <div style="font-size: 9px;">
                                    Can add multiple, separated by commas. Example: 144, 145, 165
                                </div>
                                <br/>
                                <b-button @click="filterChange" id="input-5" type="submit" variant="success">Search</b-button>
                        </b-form-group>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-form>

            <b-form style="padding-left: 15px;">
                <b-row>
                    <b-form-group>
                        Showing {{ currentArr.length }} rows out of {{ totalBundles }} rows.
                    </b-form-group>
                </b-row>
            </b-form>
        </div>

        <div class="pagination">
            <div  :class='{"pagination-square": true, "pagination-selected-page": selectedPage === i-1 }' v-for="i in numberOfPages " @click="replaceCurrentPage(i-1)">{{ i }}</div>
        </div>
        <div>
            <h3>Current Page: {{ selectedPage + 1 }}</h3>
        </div>
 
        <div v-if="currentArr.length > 0" class="mt-5">
            <table class="table table-condense w-auto smal">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Bundle ID</th>
                        <th scope="col">Date Added</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in currentArr">
                        <td>{{ row.id }}</td>
                        <td>{{ row.bundle_id }}</td>
                        <td>{{ row.date_added }}</td>
                        <td>  
                            &nbsp;
                            <a href="javascript:void(0);" v-on:click="deleteBundle(row.id)">Delete</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            form: {
                bundle: ''
            },
            filter_var: '',
            bundlesRawData: '',
            showForm: true,
            loading: false,
            inputErr: false,
            success: false,
            currentArr: [],
            selectedPage: 0,
            numberOfPages: 0,
            totalBundles: 0,
        }
    },
    async created() {
        await this.getBundlesData()
    },
    methods: {
        async replaceCurrentPage(i) {
            var r
            if (this.filter_var !== '') {
                r = await this.$http.post(this.resources.Management.filterBundles, {
                    str: this.filter_var + '',
                    current_page: i
                })
            } else {
                r = await this.$http.post(this.resources.Management.getBundlesWl, {
                    current_page: i,
                })
            }
           
            let data = await r.json()
            this.numberOfPages = data.pagination_data.number_of_pages
            this.totalBundles = data.pagination_data.total_bundles
            this.currentArr = data.data
            this.selectedPage = i
        },
        async getBundlesData() {
            const r = await this.$http.post(this.resources.Management.getBundlesWl, {
                current_page: 0,
            })
            let data = await r.json()
            this.numberOfPages = data.pagination_data.number_of_pages
            this.totalBundles = data.pagination_data.total_bundles
            this.currentArr = data.data
            this.selectedPage = 0
        },
        async deleteBundle(id) {
            const data = await this.$http.post(this.resources.Management.deleteBundleWl, {
                id: String(id)
            })
            
            await this.filterChangeNoEvent()
        },
        async filterChangeNoEvent() {
            if (this.filter_var === '') {
               this.getBundlesData()
            }

            const r = await this.$http.post(this.resources.Management.filterBundles, {
                    str: this.filter_var + '',
                    current_page: 0
            })
            let data = await r.json();
            this.numberOfPages = data.pagination_data.number_of_pages
            this.totalBundles = data.pagination_data.total_bundles
            this.currentArr = data.data
            this.selectedPage = 0
        },
        async filterChange(e) {
            e.preventDefault()
            if (this.filter_var === '') {
               this.getBundlesData()
            }

            const r = await this.$http.post(this.resources.Management.filterBundles, {
                    str: this.filter_var + '',
                    current_page: 0
            })
            let data = await r.json();
            this.numberOfPages = data.pagination_data.number_of_pages
            this.totalBundles = data.pagination_data.total_bundles
            this.currentArr = data.data
            this.selectedPage = 0
        },
        async addBulk(e) {
            this.success = false
            e.preventDefault()
            let isDataValid = await this.validateBulk()
            if (!isDataValid.res) {
                this.notifyError(isDataValid.msg)
                return
            } 
            
            this.loading = true
            this.showForm = false
            const data = await this.$http.post(this.resources.Management.addBundlesBulk, {
                bundles_raw_data: String(this.bundlesRawData),
            })
            if (data.body.res !== 'OK') {
                this.showForm = true
                this.loading = false
                this.inputErr = true
                this.success = false
                this.notifyError('Please remove dupliactions and try again.')
                return
            } else 
            await this.getBundlesData()
            this.showForm = false
            this.loading = false
            this.inputErr = false
            this.success = true
            this.bundlesRawData = ''
            this.showForm = true

        },
        async validateBulk() {
            let mixedSeperator = /\r|\n/.exec(this.bundlesRawData) && this.bundlesRawData.includes(',');
            
            if (mixedSeperator) {
                return {
                    res: false,
                    msg: 'Bundles seperator must be uniform. Please use one seperator only - Enter or a Comma.'
                }
            }

            let data = []
            if (/\r|\n/.exec(this.bundlesRawData)) {
                data = this.bundlesRawData.split(/\r?\n/)
            } else {
                data = this.bundlesRawData.split(',')
            }

           
            if (data.length > 2000) {
                return {
                    res: false,
                    msg: 'Bundles length can not exeed 2000 ids.'
                }
            }

            for (const k in data) {
                if (data[k].trim() === '' || data[k].trim() === ' ') {
                    return {
                        res: false,
                        msg: 'Bundles can\'t be empty.'
                    }
                }
            }

            for (let i = 0; i < data.length; i++) {
                for (let j = i + 1; j < data.length; j++) {
                    if (i === j ) {
                        continue
                    }
                    if (data[i] == data[j]) {
                        return {
                            res: false,
                            msg: 'Bundles can\'t be inserted twice.'
                        }
                    }
                }
            }
            
            return {
                res: true    
            }
        }
    },
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
    .hide_mobile_only {
        display: none;
    }
}

.pagination-square{
    padding: 5px;
    cursor: pointer;
}

.pagination-selected-page{
    font-weight: bolder;
    color: blue;
}
</style>
