<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager-stags-versions">Back to Stag Versions</a>
        </div>
        <h2>Data Manager</h2>
        <div style="width: 700px;">
            <form @submit="saveOsVersion">
                Stag Version:<br />
                <b-form-input id="input-2" placeholder="" v-model="edit_stag_version.stags_versions_name"></b-form-input><br/>
                Script Tag:<br />
                <textarea class="subs_textarea" v-model="edit_stag_version.script_tag"></textarea><br />
                Script Path:<br />
                <b-form-input id="input-2" placeholder="" v-model="edit_stag_version.stags_script_path"></b-form-input><br/>
                <b-button id="input-5" type="submit" variant="success">Save</b-button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            edit_stag_version: {
                id: 0,
                stags_versions_name: '',
                script_tag: '',
                stags_script_path: ''
            }
        }
    },
    async created() {
        const stags_version_id = parseInt(this.$route.params.id)
        if (stags_version_id !== 0) {
            const data = await this.$http.post(this.resources.Management.tgGetStagVersion, {
                id: stags_version_id
            })
            this.edit_stag_version = data.body.data
        }
    },
    methods: {
        async saveOsVersion(e) {
            e.preventDefault()
            const data = await this.$http.post(this.resources.Management.tgStagVersionSave, {
                id: this.edit_stag_version.id,
                stags_versions_name: this.edit_stag_version.stags_versions_name,
                script_tag: this.edit_stag_version.script_tag,
                stags_script_path: this.edit_stag_version.stags_script_path
            })

            this.notifySuccess('Saved')
            if (this.edit_stag_version.id === 0) {
                window.location = '/apps/script-tags/data-manager-stags-versions-edit/' + data.body.id
            }
        },
    },
}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.subs_textarea {
    resize: none;
    width: 100%;
    height: 200px;
    border: 1px solid #c2cfd6;
}
</style>