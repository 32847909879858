<template lang="html">
    <div class="all">
      <router-view v-if='loaded' />
      
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loaded: false
      }
    },
    created: async function () {
      let groupId = this.$route.params.groupId
      //fetch scheduler + blacklisting of chosen group id 
      try{
        const r = await this.$http.get(this.resources.SmartLinksCampaigns.fetchGroupSchedulerById(groupId))
        var scheduler = await r.json()
        if (typeof this.$store.state.smartlinks.groups === 'undefined') {
          let state = this.$store.state
          state.smartlinks = {}
          const r = await this.$http.get(this.resources.SmartLinksCampaigns.getSlGroups);
          const data = await r.json();
          state.smartlinks.groups = data
        }
        var group =  this.$store.state.smartlinks.groups.find(v => v.id == groupId)
        group.scheduler =  scheduler
        this.loaded = true 
      }catch(err){
  
      }
    },
    methods: {
    }
  }
  </script>
  
  <style lang="css">
  </style>
  