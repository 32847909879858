<template>
    <div>
        <a href="/apps/smartlinks/global-offers">Back to global offers</a>
        <div v-if="globalOffersRelated.length > 0">
            <ul >
            <li class="offer-list">
                Offer ID - Offer Name, Camp ID - Camp Name
            </li>
            <li class="offer-list" v-for="row in globalOffersRelated">
                {{  `#${row.offer_id} - ${row.offer_name} |  #${row.camp_id} - ${row.camp_name}` }} | <input v-model="row.update" type="checkbox" name="acceptRules" class="inline checkbox" value="true">
            </li>
        </ul>
        
        <button class="btn-update"  @click="updateSelectedOffers">Update all selected</button>
        </div>
        <div v-else class="offer-list">
            <br>
            <br>
           * No related offers
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            globalOffersRelated: [],
            globalOffersRelatedSelected: [],
            globalOfferId: 0,
        }
    },
    async created() {
        await this.fetchRelatedOffers()
    },
    methods: {
        async fetchRelatedOffers() {
            const offerId = this.$route.query.id
            const data = await this.$http.post(this.resources.GlobalOffers.fetchRelatedOffers(offerId))
            if (data.body.res === 'OK') {
                this.globalOffersRelated = data.body.data.map(obj => {
                   return {
                        ...obj,
                        update: true
                   }
                })
            }
        },
        async updateSelectedOffers() {
            let ok = confirm("Are you sure you want to update all checked offers ?")
            if (ok) {
                let selectedIds = []
                for (const k in this.globalOffersRelated) {
                    if (this.globalOffersRelated[k].update) {
                        selectedIds.push(this.globalOffersRelated[k].offer_id)
                    }
                } 
                if (selectedIds.length > 0) {
                    const data =  await this.$http.post(this.resources.GlobalOffers.resetGlobalOfferRelatedSetting, selectedIds)
                    if (data.body.res === 'OK') {
                        this.notifySuccess("Offers has been updated")
                    } else {
                        this.notifyError("Offer did not load from default due to an error.")
                    }  
                }
            }
        }
    }
}
</script>
<style scoped>

.offer-list{
    padding: 20px;
    font-size: 20px;
}
.btn-update {
    margin-left: 20px;
}
</style>