<template lang="html">
<div class="all">
  <h1>Blacklisting</h1>

  <ResizableDataTable v-if="page_loaded" :rows_data.sync="files" :fields="fields" :show_c.sync="show_col" :nonIntFields="nonIntFields" :dateFileds="dateFields" :download="downloadFile" :delete="delBlFile" :show_columns_name="'blacklisting'"></ResizableDataTable>

  <form  novalidate v-if="isInitial || isSaving">
      <div class="tooltip-header">
        <span v-if="openTooltip" class="open-tooltip">Upload CSV file with one column, no headline, only Device ID</span>
        <b-icon icon="info-circle" @mouseover="mouseove" @mouseout="mouseove"></b-icon>
      </div>
      <h1>Upload File</h1>
        <div class="dropbox">
          <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            accept=".csv" class="input-file">
            <p v-if="isInitial">
              Drag your file here to begin<br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading file... please do not close this window.
            </p>
        </div>
  </form>

  <div v-if="isSuccess">
    <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
    <p>
      <a href="javascript:void(0)" @click="reset()">Upload again</a>
    </p>
    <ul class="list-unstyled">
      <li v-for="item in uploadedFiles">
        <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
      </li>
    </ul>
  </div>
<!--FAILED-->
  <div v-if="isFailed">
    <h2>Uploaded failed.</h2>
    <p>
      <a href="javascript:void(0)" @click="reset()">Try again</a>
    </p>
    <pre>{{ uploadError }}</pre>
  </div>
</div>

</template>

<script>
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

import moment from 'moment'
import ResizableDataTable from '../../../../../components/shared/ResizableDataTable.vue';

export default {
    name: 'app',
    data() {
        return {
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'myFile',
            openTooltip: false,
            files: null,
            offersUsingBlfile: null,
            fields: [],
            show_col: {},
            page_loaded: true,
            nonIntFields: [],
            dateFields: [],

        };
    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        }
    },
    async created() {
      this.page_loaded = false;
      await this.getData();

      // bind params, links, render..
      this.fields = [
        {
          name: 'id',
          label: 'ID',
          sortable: true,
          toggle_column: 1,
          show_c: true
        },
        {
          name: 'title',
          label: 'Title',
          sortable: true,
          toggle_column: 2,
          show_c: true
        },
        {
          name: 'date_uploaded',
          label: 'Date Uploaded',
          sortable: true,
          toggle_column: 3,
          show_c: true
        },
        {
          name: 'kb_size',
          label: 'Size (kb)',
          sortable: true,
          toggle_column: 4,
          show_c: true
        },
      ]

      for (const field of this.fields) {
        this.show_col[field.toggle_column] = true;
      }

      this.nonIntFields = ['title'];
      this.dateFields = ['date_uploaded'];
      this.page_loaded = true;
    },
    methods: {
        async getData() {
          try {
            let r = await this.$http.get(this.resources.blacklisting.getFiles);
            let files = await r.json();
            
            files.forEach(f => {
              f.date_uploaded = moment(f.date_uploaded).format("DD.MM.YYYY HH:mm");
              f.download = true;
            
            });
            let sortedFiles = files.sort((a, b) => (a.title > b.title) ? 1 : -1);
            this.files = this.$store.state.blFiles = sortedFiles;
          } catch (err) {
            console.log(err);
          }
        },
        mouseove(e) {
            this.openTooltip = !this.openTooltip;
        },
        async delBlFile(v) {
          if (!window.confirm(`Do you wish to delete the file: "${v.title}"?`)) {
              return;
          }
          this.page_loaded = false;
          try {
            let res = await this.$http.delete(this.resources.blacklisting.deleteFile(v.id));
            if (res.status == 460) {
              let b = res.body;
              this.offersUsingBlfile = b;
              this.notifyError("Error occured");
              return;
            }
            await this.getData();
            this.page_loaded = true;
          } catch (err) {
            this.notifyError("Error occured");
          }
        },
        downloadFile(v) {
            if (!window.confirm(`Do you wish to download the file: "${v.title}"?`)) {
                return;
            }

            let root;
            (root = this.$http.options.root) && (root = root[root.length - 1] === '/' ? root : root + '/');
            let url = root + this.resources.blacklisting.downloadFile(v.filename);
            url += "?file_title=" + v.title;
            window.location.replace(url);
        },
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;
            this.$http.post(this.resources.blacklisting.fileUpload, formData)
                .then(r => r.json())
                .then(file => {
                if (!this.files) {
                    this.files = [];
                }
                this.files.push(file);
                this.currentStatus = STATUS_INITIAL;
                this.notifySuccess("File has been uploaded");
            })
                .catch(err => {
                console.log(err);
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;
            });
        },
        filesChange(fieldName, fileList) {
            if (fileList.length !== 1) {
                return;
            }
            let file = fileList[0];
            let fileName = file.name;
            if (this.files && this.files.find(v => v.title === fileName)) {
                this.notifyError(`File with the same name already uploaded: "${fileName}"`);
                return;
            }
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            this.save(formData);
        }
    },
    mounted() {
        this.reset();
    },
    components: { ResizableDataTable }
}

</script>

<style lang="css" scoped>

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  position: relative;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
.tooltip-header {
  position: relative;
}

.open-tooltip {
  border:3px solid grey;display:inline-block;
  font-size:.875em;
  padding:.75em;
  position:absolute;
  text-align:center; 
  box-shadow:  0 0 15px 5px #888787;
/*   width: 50px; */
  background: white;
  position: left-top;
  top: -50px;
}

span {
  position: absolute;
  top: 31rem;
  left: -2rem;
  border: 2px dashed blue;
}

</style>
