import { render, staticRenderFns } from "./Home.smartlinksV2.vue?vue&type=template&id=24fe85f3&scoped=true&"
import script from "./Home.smartlinksV2.vue?vue&type=script&lang=js&"
export * from "./Home.smartlinksV2.vue?vue&type=script&lang=js&"
import style0 from "./Home.smartlinksV2.vue?vue&type=style&index=0&id=24fe85f3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fe85f3",
  null
  
)

export default component.exports