<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager-dsps">Back to DSPs</a>
        </div>
        <h2>Data Manager</h2>
        <div style="width: 700px;">
            <form @submit="saveDsp">
                DSP Name:<br />
                <b-form-input id="input-2" placeholder="" v-model="edit_dsp.dsp_name"></b-form-input>
                Sub2:<br />
                <b-form-input id="input-2" placeholder="" v-model="edit_dsp.dsp_sub2"></b-form-input>
                Subs String:<br />
                <textarea class="subs_textarea" v-model="edit_dsp.dsp_subs_str"></textarea><br />
                <b-button id="input-5" type="submit" variant="success">Save</b-button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            edit_dsp: {
                id: 0,
                dsp_name: '',
                dsp_sub2: '',
                dsp_subs_str: ''
            }
        }
    },
    async created() {
        await this.tgGetDsp()
    },
    methods: {
        async saveDsp(e) {
            console.log('saveDsp')
            e.preventDefault()
            const data = await this.$http.post(this.resources.Management.tgSaveDsp, {
                edit_dsp: this.edit_dsp
            })
            if (data.body.id != 0) {
                window.location = '/apps/script-tags/data-manager-edit-dsp/' + data.body.id
            } else {
                this.notifySuccess('Saved')
            }
        },
        async tgGetDsp() {
            if (this.$route.params.id !== "0") {
                const data = await this.$http.post(this.resources.Management.tgGetDsp, {
                    id: parseInt(this.$route.params.id)
                })
                this.edit_dsp.id = data.body.dsp_entity.id
                this.edit_dsp.dsp_name = data.body.dsp_entity.dsp_name
                this.edit_dsp.dsp_sub2 = data.body.dsp_entity.dsp_sub2
                this.edit_dsp.dsp_subs_str = data.body.dsp_entity.dsp_subs_str
            }
        }
    },
}
</script>

<style scoped>
.input-cont {
    padding-bottom: 10px;
}

.subs_textarea {
    resize: none;
    width: 100%;
    height: 400px;
}
</style>