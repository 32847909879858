export default {
  buildPixelURL(pixelForm) {
    let { template, template_vars } = pixelForm
    if(!template) {
      return null
    }
    let {pid, offerid} = template_vars
    // if (!pubID || !offerID) {
    //   return null
    // }
    let url = (template.url || "")
      .replace("[[pid]]", pid)
      .replace("[[offerid]]", offerid)

    return url
  },
  formatPixels(pixels) {
    if(!pixels || !Array.isArray(pixels)) { return [] }
    let pixelsFormatted = Array(pixels.length)

    pixels.forEach((p, i) => {
      let r = {
        name: p.name,
        cap: Number(p.cap)
      }
      if (p.template) {
        r.url = this.buildPixelURL(p)
        r.template_id = p.template.id
        r.template_vars = JSON.stringify(p.template_vars)
      } else {
        r.url = p.url && p.url.replace(/\s/g, '')
      }

      pixelsFormatted[i] = r
    })

    return pixelsFormatted
  },
  parsePixels(p) {
    p.forEach(p2 => {
      if(p2.template_vars) {
        try {
          p2.template_vars = JSON.parse(p2.template_vars)
        } catch(error){}

        if (typeof p2.template_vars !== "object") {
          p2.template_vars = {}
        }
        if(!p2.template_vars.hasOwnProperty("pid")) {
          p2.template_vars.pid = ""
        }
        if(!p2.template_vars.hasOwnProperty("offerid")) {
          p2.template_vars.offerid = ""
        }
      }
    })
    return p
  }
}
