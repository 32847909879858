<template>
    <div>
        <div  v-if="!loading">
            <div class="add-btn" @click="go_to_add">
                <div class="add-btn-l">
                    <b-button id="plus-btn" size="sm" variant="outline-primary" class="mb-2">
                        <b-icon font-scale="3" icon="plus" aria-label="Help"></b-icon>
                    </b-button>
                </div>
                <div class="add-btn-l">
                    <div class="add-btn-l-i">
                        Add New Group
                    </div>
                </div>
            </div>

            <div class="filter-form-container">
                <form class="" @submit.prevent=''>
                    <h5>Search</h5>
                    <div class="form-inputs">
                        <label class="search-label">By Campaign ID:</label>
                        <input ref="filter-sl-id" class="def filter-select filter-input" v-model='filterForm.camp'>
                    </div>
                </form>
            </div>
            <div class="filter-form-container">
                <form class="" @submit.prevent=''>
                    <h5>Filter</h5>
                    <div class="form-inputs">
                        <b-form-select class="def filter-select" v-model="filter_value" v-if="ma_list.length !== 0" :options="ma_list" @change="filterTableRows"></b-form-select>
                        <button v-if="filter_value !== null" @click="clearTableRowFilter">Clear</button>
                    </div>
                </form>
            </div>
            <ResizableDataTable  v-if="!loading && rows_data.length > 0" :rows_data="rows_data" :fields="fields" :show_c.sync="show_col" :delete="deleteGroup" :nonIntFields="nonIntFields" :edit_label="'Edit Group'"  :show_columns_name="'dsp-automator'" :dateFileds="dateFields"></ResizableDataTable>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import ResizableDataTable from '../../components/shared/ResizableDataTable.vue';
export default {
    components: {
        ResizableDataTable
    },
    data() {
        return {
            loading: false,
            filterForm: {
                camp: null
            },
            filter_value: '',
            ma_list: [],
            origin_list: [],
            rows_data: [],
            show_col: {
                id: true,
                name: true,
                csm_name: true,
                actions: true
            },
            fields: [],
            nonIntFields: [],
            dateFields: [],

        }
    },
    async created() {
        this.loading = true;
        await this.getData();
        this.fields = [
            {
                name: 'name',
                label: 'Group Name',
                sortable: true,
                toggle_column: 1,
                show_c: true
            },
            {
                name: 'geo',
                label: 'Geo',
                sortable: true,
                toggle_column: 2,
                show_c: true
            },
            {
                name: 'os',
                label: 'OS',
                sortable: true,
                toggle_column: 3,
                show_c: true
            },
            {
                name: 'ma_name',
                label: 'Marketing Analyst',
                sortable: true,
                toggle_column: 4,
                show_c: true
            },
            {
                name: 'description',
                label: 'Description',
                sortable: true,
                toggle_column: 5,
                show_c: true
            },
            {
                name: 'last_edit',
                label: 'Last Edit',
                sortable: true,
                toggle_column: 6,
                show_c: true
            },
            {
                name: 'last_dsp_update',
                label: 'Last DSP Update',
                sortable: true,
                toggle_column: 7,
                show_c: true
            }
        ]
        this.nonIntFields = ['name', 'geo', 'os', 'ma_name', 'description']
        this.dateFields= [ 'last_edit', 'last_dsp_update']
        for (const field of this.fields) {
           this.show_col[field.toggle_column] = true;
        }
        this.loading = false;
    },
    watch: {
        ["filterForm.camp"](val) {
            this.searchByCamp()
        }
    },
    methods: {
        async getData() {
            try {
                /* add edit links */
                let res = await this.$http.get(this.resources.Management.getSchedulerList)
                if (res.data.res !== 'OK') {
                    this.notifyError('Can\'t fetch data');
                    this.loading = false;
                    return;
                }

                console.log(res.data.combined_data.scheduler_list);
                for (const k in res.data.combined_data.scheduler_list) {
                    res.data.combined_data.scheduler_list[k].edit = '/media-analyst/dsp-schedule/edit?id=' + res.data.combined_data.scheduler_list[k].id
                }
                this.origin_list = res.data.combined_data.scheduler_list;
                this.rows_data = this.origin_list;

                this.ma_list = res.data.combined_data.ma_list.map(row => {
                    return {
                        value: row.media_analyst_manager,
                        text: row.media_analyst_manager
                    }
                
                });
                this.ma_list.push({
                    value: 'None',
                    text: 'None'
                });
            } catch (err) {
                console.log(err);
            }
        },
        go_to_add() {
            window.location.href = '/media-analyst/dsp-schedule/edit?id=0';
        },
        async clearTableRowFilter() {
            this.selected_list = [];
            this.filter_value = null;
            this.current_list = [];
            this.rows_data = this.origin_list;
        },
        async filterTableRows() {
            this.rows_data = this.origin_list;
            let filtered_rows = []
            for (const k in this.rows_data) {
                if (this.rows_data[k].ma_name === this.filter_value) {
                    filtered_rows.push(this.rows_data[k]);
                
                }
            }
            this.rows_data = filtered_rows;
        },
        async deleteGroup(row) {
            if (!confirm(`Are you sure you want to delete this group ${row.name} ? `)) {
                return;
            }
            this.loading = true;
            const id = row.id;
            console.log(id);
            console.log(this.resources.Management.deleteScheduler(id));
            try {
                const res = await this.$http.delete(this.resources.Management.deleteScheduler(id), {
                    id: id
                });
                if (res.body.res.includes('OK')) {
                    this.notifySuccess('Group has been deleted');
                    await this.getData();
                }

                this.loading = false;
            } catch (err) {
                console.log(err);
                this.notifyError('Group can\'t be deleted due to an error.');
            }
        },
        async searchByCamp() {
            let camp = this.filterForm.camp;
            if (camp === null || camp === '') {
                this.rows_data = this.origin_list;
                return;
            }
            let filtered_rows = []
            for (const k in this.rows_data) {
               let lists = ['apptimus_cmps_str', 'bucksense_cmps_str', 'kayzen_cmps_str']
               for (const l of lists) {
                   if (this.rows_data[k][l].includes(camp)) {
                       filtered_rows.push(this.rows_data[k]);
                       break;
                   }
               }
            
            }

            this.rows_data = filtered_rows;
        }
    },

}
</script>
<style scoped>
.add-btn {
    cursor: pointer;
    display: inline-block;
}
.add-btn-l {
    display: inline-block;
    vertical-align: top;
    padding-top: 15px;
    padding-right: 10px;
}
.add-btn-l-i {
    padding-top: 12px;
}
.add-btn-r {
    vertical-align: top;
    display: inline-block;
}

.filter-select {
    width: 100%;
}

.search-label {
    margin-bottom: 5px;
}

.filter-input {
    height: 30px;
}

</style>