<template>
    <div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td>
                            CSM
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.csm }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model="row.csm" placeholder="Operation Manager" />
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRow(row.id)">Edit</a> <a class="btn btn-primary" href="javascript:void(0);" @click="deleteEntity(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.csm" placeholder="Operation Manager" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newRow: {
                id: 0,
                csm: '',
            },
            rows: [],
            showNewRow: true,
            isAdmin: true
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    methods: {
        async save(e) {
            e.preventDefault();
            const rowReq = this.newRow
            const data = await this.$http.post(this.resources.Management.saveCsm, {
                ...this.newRow
            });
            this.newRow.csm = "";
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getCsmList, {});
            for (let k in data.body.data) {
                data.body.data[k].edit = false
            }
            this.rows = data.body.data
        },
        async deleteEntity(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteCsm, {
                id,
            });
            console.log(data.body);
            if (data.body.res === 'OK') {
                this.getListData();
            } else {
                console.log(data.body.msg);
                this.notifyError( 'CSM id '+ id + ' ' + data.body.msg)
            }
            
        },
        async editRow(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id === id) {
                    editRowData = this.rows[k];
                }
            }
            
            this.showNewRow = true;
            const data = await this.$http.post(this.resources.Management.saveCsm, {
                ...editRowData
            });
            this.getListData()
            
        },
    },
}
</script>

<style scoped>
.link-div {
    padding-top: 40px;
    text-align: center;
}
</style>
