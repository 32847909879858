<template lang="html">
  <div class="sc-3658">
    <div class="box-5678">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.sc-3658 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: #19191938;
}
.box-5678 {
  min-width: 250px;
  min-height: 100px;
  background: #ffffff;
  padding: 16px;
  border-radius: 4px;
  color: #292929;
  margin: 3% auto 0;
  max-width: 930px;
  border: 1px solid #a2a2a2;
  font-size: 18px;
  font-family: arial;
  line-height: initial;
  position: relative;
  max-height: 87vh;
  overflow-y: auto;
}
</style>
