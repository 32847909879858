<template>

    <div class="add-domain-btn">
        <b-button id="plus-btn" size="sm" variant="outline-primary" class="mb-2"
            v-if='userHasPermission("domains.edit")' @click="addDomain">
            <b-icon font-scale="3" icon="plus" aria-label="Help"></b-icon>
        </b-button>
        <div>

        </div>

        <div class="sl-list" v-if='domainlist'>
            
            <div v-if='domainlist'>
                <b-table id="bootsrap-table" sticky-header="700px" striped hover :fields="fields" :items="domainlist"
                    fixed responsive :tbody-tr-class="rowClass" :busy="isBusy" style="font-size: 12px;">
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template v-slot:head()="data">
                        <span id="tooltip-bootsrap-template" v-b-tooltip.hover :title='data.field.tooltip'>
                            {{ data.label }}
                        </span>
                    </template>
                    <template v-slot:cell(edit)="data" class="edit-row-btn">
                        <button id="edit_btn" @click='editDomainItem(data.item)'>
                            <b-icon icon="pencil"></b-icon>

                        </button>
                    </template>
                </b-table>
            </div>

            <Modal class="modal-edit" :modalShow.sync=modalShowEdit :showDismissibleAlert=showDismissibleAlert
                :operation="'edit'" v-if="modalShowEdit" :currentDom.sync=currentDomain :text=modalText :domainsOptions=domainsOptions></Modal>
            <Modal class="modal-add" :modalShow.sync=modalShowAdd :operation="'add'" v-if="modalShowAdd"
                :currentDom.sync=currentDomain :text="''" :domainsOptions=domainsOptions></Modal>
        </div>
    </div>
</template>

<script>
    import Modal from '../../components/domain/modal.vue'

    const DOMAIN_STATUS_ACTIVE = "ACTIVE"

    export default {
        components: {
            Modal
        },
        data() {
            return {
                fields: [{
                        key: 'Edit',
                        thStyle: { width: "60px" },
                    }, {
                        key: 'id',
                        sortable: true,
                        tooltip: 'Id',
                        thStyle: { width: "60px" },
                    }, {
                        key: 'name',
                        sortable: true,
                        tooltip: 'Name',
                    }, {
                        key: 'status',
                        sortable: true,
                        tdClass: (value) => {
                            if (value == 'ACTIVE') {
                                return 'text-green'
                            } else {
                                return 'text-red'
                            }
                        },
                        tooltip: 'Status',
                        thStyle: { width: "100px" },
                    }, {
                        key: 'requests',
                        sortable: true,
                        tooltip: 'Request',
                        thStyle: { width: "110px" },
                    }, {
                        key: 'ssl_start_date',
                        label: 'SSL Starts Day',
                        sortable: true,
                        tooltip: 'SSL Start Date',
                    }, {
                        key: 'ssl_end_date',
                        label: 'SSL End Date',
                        sortable: true,
                        tooltip: 'SSL End Date',
                    },
                    {
                        key: 'comments',
                        label: 'Comments',
                    },
                    {
                        key: 'traffic_type',
                        label: 'Traffic Type',
                    },
                    {
                        key: 'dsp',
                        label: 'DSP',
                    },
                    {
                        key: 'ssp',
                        label: 'SSP',
                    }
                ],
                isBusy: false,
                modalShowEdit: false,
                modalShowAdd: false,
                domainlist: [],
                currentDomain: {},
                date: null,
                reportLoading: false,
                sort: {
                    field: "status",
                    asc: true
                },
                domainsOptions: {
                    dsps: [],
                    ssps: [],
                    traffic_types: [],
                    stags_versions: [],
                    cdns: []
                }
            }
        },
        filters: {
            numFormat(v) {
                let i = isNaN(v) ? 0 : v
                return(Number(i).toLocaleString())
                },
            percentageFormat(v) {
                if (!v) {
                    v = 0
                }
                return v.toFixed(0) + "%"
            },
        },
        beforeUpdate() {
            this.sortReport()
        },
        mounted() {
            setInterval(this.onReportSubmit, 60000)
        },
        destroyed() {
            clearInterval(this.intervalData)
        },
        watch: {
            currentDomain(newVal, oldVal) {
                if (this.modalShowAdd == false && this.modalShowEdit == false) {
                    this.isBusy = !this.isBusy
                    setTimeout(this.getData, 4500)
                }
            },
        },
        methods: {
            getData: async function () {
                try {
                    const r = await this.$http.get(this.resources.Domains.getDomains, {
                        params: {
                            start_date: '',
                            end_date: ''
                        }
                    })
                    const data = await r.json()
                    this.isBusy = !this.isBusy
                    this.domainlist = data
                    this.sortReport()
                } catch (err) {
                    this.notifyError("Could not fetch domains")
                }
                await this.getOptions()
            },
            addDomain() {
                this.modalShowAdd = !this.modalShowAdd;
                this.currentDomain = {
                    status: DOMAIN_STATUS_ACTIVE,
                    id: '',
                    date: '',
                    name: '',
                    ssl_end_date: '',
                    ssl_start_date: '',
                    comments: '',
                    version: 'V2',
                    selected_ssp: '',
                    selected_dsp: '',
                    selected_traffic_type: ''
                }
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.status === 'awesome') return 'table-success'
            },
            sortReport() {
                let domains = this.domainlist
                let sort = this.sort
                if (!sort.field)
                    return

                let sortField = sort.field
                domains.sort((a, b) => {
                    if (!["id", "name", "status", "ssl_start_date", "ssl_end_date"].includes(sortField)) {
                        a = a.stats
                        b = b.stats
                    }
                    a = a[sortField] || 0
                    b = b[sortField] || 0

                    let f
                    if (a > b) {
                        f = 1
                    } else if (a < b) {
                        f = -1
                    } else {
                        f = 0
                    }
                    if (!sort.asc) {
                        f = f * -1
                    }
                    return f
                })
            },
            onReportSubmit: async function () {
                if (this.reportLoading) {
                    return
                }
                var flag = false
                this.reportLoading = true
                try {
                    const r = await this.$http.get(this.resources.Domains.getDomains, {
                        params: {
                            start_date: '',
                            end_date: ''
                        }
                    })
                    const data = await r.json()
                    flag = true
                    this.domainlist = data
                    this.sortReport()
                } catch (err) {
                    if (flag)
                        return
                    else
                        this.notifyError("Could not fetch domains")
                }

                this.reportLoading = false
                await this.getOptions()
            },

            editDomainItem(domain) {
                this.currentDomain = domain
                this.modalShowEdit = !this.modalShowEdit
                this.date = this.currentDomain.ssl_start_date.toString()
                this.modalText = ` ${domain.name}  ?`
            },
            async getOptions() {        
                const r = await this.$http.get(this.resources.Domains.getDomainsOptions, {})
                this.domainsOptions.ssps = r.data.ssps
                this.domainsOptions.dsps = r.data.dsps
                this.domainsOptions.traffic_types = r.data.traffic_types
                this.domainsOptions.stags_versions = r.data.stags_versions
                this.domainsOptions.cdns = r.data.cdns
            }
        },
        created() {
            this.onReportSubmit()
        },
    }
</script>

<style scoped>
    #edit_btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    #modal-del-btn {
        margin-right: 65%;
    }

    label {
        margin-left: 12%;
    }
</style>