<template lang="html">
<div class="all">
    <h4>New Smart Link</h4>
    <Campaign :onSubmit='submit' :isVta='isVta' />
</div>
</template>

<script>
import Campaign from './components/Campaign'
export default {
    props: {
        isVta: Boolean
    },
    components: {
        Campaign
    },
    methods: {
        submit: async function (form) {
            const route = this.$route.fullPath
            try {
                const r = await this.$http.post(this.resources.SmartLinksCampaigns.postCampaign, form)
                const newId = await r.json()
                let camps = this.$store.state.smartlinks
                form.id = newId
                form.stats = {}
                form.status = 'ACTIVE'
                form.group_id = 0
                if (!camps.campaigns) {
                    camps.campaigns = [form]
                } else {
                    camps.campaigns.unshift(form)
                }
                this.$router.push({
                    name: route.includes("VTA") ? "SmartLinksVTAId" : "SmartLinksId",
                    params: {
                        campId: newId
                    }
                })

            } catch (err) {

            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
