<template lang="html">
  <div class="all">
    <h3 class="h3-12">Offers Templates Management</h3>
    <ul>
      <li v-for='t in data' class="template-item">
        <span class="t-name">{{t.name}}</span>
        <input class="def long t-url-input" v-model='t.url'>
        <i class="fa fa-trash trash-btn" aria-hidden="true" @click='deleteTemplateModal = t'></i>
        <i class="fa fa-pencil-square-o edit-btn" aria-hidden="true" @click='updateTemplateModal = t'></i>
      </li>
    </ul>

    <form class="n-form-2" @submit.prevent='addTemplate'>
      <strong class="addtemplatetext">Add new template: </strong>
      <input v-model='form.name' class="def long" placeholder="Name">
      <input v-model='form.url' class="def long t-url-input" placeholder="URL">
      <button type="submit" class="btn btn-primary">Add</button>
    </form>

    <hr>
    <section class="">
      <h3>Variables</h3>
      <ul>
        <li class="var-2" v-for='v in vars'>
          [[{{v}}]]
        </li>
      </ul>

      <div class="ex-23">
        <span>Example URL: </span><br>
        <span>https://example.com/click?pid=[[pid]]&offer_id=[[offerid]]&sub1=%%_CB_%%&sub2=%%_PID_%%&sub3=%%_PLMNTID_%%&sub4=%%_PA4_%%&sub6=bo06333770</span>
      </div>
    </section>

    <Modal v-if='deleteTemplateModal'>
      <span>
        Are you sure that you want to delete template "{{deleteTemplateModal.name}}"?
      </span>
      <div class="modal-btns">
        <div class="control-btn">
          <button class='btn _btn-danger delbtn-2345' @click='del(deleteTemplateModal)'>Delete</button>
          <button class='btn _btn-white' @click='deleteTemplateModal = false'>Cancel</button>
        </div>
      </div>
    </Modal>

    <Modal v-if='updateTemplateModal'>
      <span>
        Are you sure that you want to update template "{{updateTemplateModal.name}}"?
      </span>
      <p class="new-url-t">Url after update: <span class="new-url-s-2">{{updateTemplateModal.url}}</span></p>

      <span class="sp-241">This action will update existing offers with this template as well</span>
      <div class="modal-btns">
        <div class="managment-btn-section">
          <button class='btn btn-primary delbtn-2345' @click='update(updateTemplateModal)'>Save</button>
          <button class='btn _btn-white' @click='updateTemplateModal = false'>Cancel</button>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    let emptyForm = {
      name: null,
      url: null
    }

    return {
      deleteTemplateModal: null,
      updateTemplateModal: null,
      emptyForm,
      form: Object.assign({}, emptyForm),
      originalTemplates: [],
      data: [],
      vars: [
        "pid", "offerid"
      ]
    }
  },
  created() {
    this.$store.dispatch("GET_TM_TEMPLATES")
      .then(templates => {
        this.originalTemplates = templates
        this.data = JSON.parse(JSON.stringify(templates))
      })
  },
  methods: {
    update(t) {
      this.$http.put(this.resources.Management.tmTemplate(t.id), t)
        .then(() => {
          this.originalTemplates.find(v => v.id===t.id).url = t.url
        })
        .catch(err => {
          this.notifyError("Error: could not update template")
        })
        .then(() => {
          this.updateTemplateModal = null
        })
    },
    del(t) {
      this.$http.delete(this.resources.Management.tmTemplate(t.id))
        .then(() => {
          this.data.splice(this.data.indexOf(t), 1)
        })
        .catch(err => {
          this.notifyError("Error: could not delete template")
        })
        .then(() => {
          this.deleteTemplateModal = false
        })
    },
    addTemplate() {
      let form = this.form
      this.$validate.tmTemplate(form)
        .then(() => {
          this.$http.post(this.resources.Management.tmTemplates, form)
          .then(r => r.json())
          .then(template => {
            this.form = Object.assign({}, this.emptyForm)
            this.data.push(template)
            this.notifySuccess("Templated added")
          })
        })
        .catch(err => {
          this.notifyError(err.msg)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.template-item {
  display: flex;
  margin-bottom: 5px;
  padding: 3px 0px;
  align-items: center;
}
.template-item span {
  margin-right: 16px;
}
.t-name {
  /* font-weight: bold; */
  font-size: 16px;
  width: 150px;
}
.t-url {

}
.t-url-input {
  flex: 1;
  font-size: 14px;
  margin-right: 10px;
}
.n-form-2 {
  border-top: 1px solid #dedede;
  padding: 5px;
  margin-top: 17px;
  display: flex;
  align-items: center;
  padding-top: 14px;
  margin-top: 21px;
}
.n-form-2 * {
  margin-right: 6px;
}
.addtemplatetext {
  width: 156px;
}
.delbtn-2345 {
  margin-right: 6px;
}
.var-2 {
  font-size: 20px;
}
.ex-23 {
  margin-top: 30px;
}
.edit-btn {
  margin-left: 5px;
}
.new-url-t {
  margin-top: 10px;
  font-size: 13px;
}
.new-url-s-2 {

}
.sp-241 {
  margin-top: 16px;
  font-weight: bold;
  font-size: 13px;
  display: block;
}
</style>
