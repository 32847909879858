<template lang="html">
  <div class="form-12">
    <div :class="{c24341: isFloating}">
      <div class="info-form">
        <div class="fields-sec">
          <FormField :disabled='(!!placement && !form.stag) || !userHasPermission("placements.edit")' :inline='1'
            :value='form.externalID' :placeholder='!isInternal ? "Placement ID" : "Placement Title"'
            :labelText='!isInternal ? "Placement ID" : "Placement Title"' :onUpdate='e => { form.externalID = e }' />

          <div class="inline">
            <FormField  :inline='1' :def='0' placeholder="Country" labelText='Country'>
              <select v-model='form.country' class="def select-234 country-div"
                :disabled='!newPlacement && !userHasPermission("placements.maxedit")' @change="getMediaAnalyst">
                <option v-for='(title, option) in countryOptions' :value='option'>{{title}}</option>
              </select>
            </FormField>

            <FormField class="os-div" :inline='1' :def='0' placeholder="Os" labelText='Os'>
              <select v-model='form.os' class="def select-234" @change="getMediaAnalyst"
                :disabled='!newPlacement && !userHasPermission("placements.maxedit")'>
                <option v-for='(title, option) in osOptions' :value='option'>{{title}}</option>
              </select>
            </FormField>
            <div class="media-analyst-div-cont">
              <div class="media-analyst-div" v-if="media_analyst !== ''">
                MA: <span class="media-analyst-span">{{ media_analyst }}</span>
              </div>
              <div class="media-analyst-div" v-if="om_manager !== ''">
                Ops: <span>{{ om_manager }}</span>
              </div>
            </div>
          </div>
          <FormField :inline='1' :def='0' placeholder="Notes" labelText='Notes'>
            <textarea :disabled='!userHasPermission("placements.edit")' class="def textarea-1"
              v-model='form.notes'></textarea>
          </FormField>

          <FormField :def='0' :inline='1' placeholder="Default Template" labelText='Default Template'>
            <select :disabled='!userHasPermission("placements.edit")' v-if='templates'
              v-model='form.default_template_id' class="def" name="">
              <option>Choose Template</option>
              <template v-if='!form.stag'>
                <option v-for='option in templates' :value='option.id'>{{option.name}}</option>
              </template>
              <template v-else>
                <option v-for='option in stagTemplates' :value='option.id'>{{option.name}}</option>
              </template>
            </select>
          </FormField>

          <FormField :disabled='!userHasPermission("placements.edit")' :inline='1' :value='form.default_pid || null'
            placeholder="Default PID" labelText='Default PID' :onUpdate='e => { form.default_pid = Number(e) }' />

          <template v-if='form.stag'>
            <FormField :inline='1' :def='0' placeholder="Banner" labelText='Banner'>
              <select :disabled='!userHasPermission("placements.edit")' v-model.number='form.banner'
                class="def select-234">
                <option v-for="item in stagsBanners" :value='item.id'>{{item.value}}</option>
              </select>
            </FormField>

            <FormField :inline='1' :def='0' placeholder="Os Version" labelText='Os Version'>
              <select :disabled='!newPlacement && !userHasPermission("placements.maxedit")' v-model='form.os_version'
                class="def select-234">
                <option v-for='(title) in osVersions' :value='title'>{{title === "all" ? title : ">="+title}}</option>
              </select>
            </FormField>

            <div class="form-inputs">
              <FormField :inline='1' :def='0' labelText='Hours (GMT)'>
                <div v-for="i in arr24h">
                  <div class="hourly_cb">
                    <input type="checkbox" :value="i.v" @click="hour_cb_click" :checked="i.checked"/>{{ i.v }}  
                  </div>
                </div>
              </FormField>
            </div>

            <FormField :inline='1' :def='0' labelText='Use Global Bundle Whitelisting (sub4)'>
              <input type="checkbox" v-model="form.is_global_bundle_wl" :value="true"/> 
            </FormField>

            <FormField v-if="!form.is_global_bundle_wl" :inline='1' :def='0' placeholder="Bundle Whitelisting" labelText='Bundle Whitelisting (sub4)'>
              <textarea :disabled='!userHasPermission("placements.edit")' class="def textarea-1"
                v-model='form.bundle_wl'></textarea>
            </FormField>

          </template>
        </div>
      </div>

      <div v-if='!noPixels'>
        <strong class="form-h7 pixels-h7" v-show='form.pixels && form.pixels.length > 0'>Pixels</strong>
        <PixelsForm :scriptTag='!placement || placement.stag' :defaultPid='form.default_pid'
          :defaultTemplateId='form.default_template_id' :errorInPixelIndex='errorInPixelIndex' :pixels='form.pixels'
          :emptyPixels='!placement ? 1 : 0' />
      </div>
      <div class="dv232" v-if='!newPlacement && !form.internal'>
        <button type="button" class="btn _btn-white add-pixel-btn" title="Import pixels" :disabled="processing"
          @click='syncPixels'>
          <i class="fa fa-repeat" aria-hidden="true"></i>
          Import Pixels
        </button>
      </div>

    </div>


    <div class="btns btns-20" v-if='userHasPermission("placements.edit")'>
      <div class="form-inputs">
        <router-link v-if='placement && placement.stag' class="btn _btn-white"
          :to="{ name: 'ScriptTagsCamp', params: {campId: placement.id} }">Edit Links Order</router-link>
        <button v-if='placement' type="button" class="btn _btn-white" @click='onCancel'>Cancel Changes</button>
        <button type="button" class="btn _btn-primary" :disabled="processing"
          @click='submit'>{{placement ? "Save Changes" : "Create"}}</button>
      </div>
      <div>
        <button v-if='placement && userHasPermission("placements.delete")' type="button" class="btn _btn-danger del-btn"
          @click='deletePlacementModal=form'>Delete Placement</button>
        <button v-if='placement && placement.stag' type="button" class="btn _btn-white"
          @click='duplicatePlacementModal=form'>Duplicate Placement</button>
      </div>
    </div>

    <Modal v-if='deletePlacementModal'>
      <span>
        Are you sure that you want to delete placement '{{deletePlacementModal.externalID}}'?
      </span>

      <span class="warning" v-show='!deletePlacementModal.stag'>
        *This action will not delete the placement from Tag-Mahal.
      </span>
      <div class="modal-btns">
        <div class="control-btn">
          <button class='btn _btn-danger delbtn-2345' @click='delPlacement'>Delete</button>
          <button class='btn _btn-white' @click='deletePlacementModal = false'>Cancel</button>
        </div>
      </div>
    </Modal>

    <Modal v-if='duplicatePlacementModal'>
      <span>
        Are you sure that you want to duplicate placement {{duplicatePlacementModal.externalID}}?
      </span>
      <div class="modal-btns">
        <div class="control-btn">
          <button class='btn _btn-white' @click='duplicatePlacement'>Duplicate</button>
          <button class='btn _btn-white' @click='duplicatePlacementModal = false'>Cancel</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import * as act from "@/act-types";
  import fn from './mockplacementinsertsfunc.js'
  import PixelsForm from './PixelsForm.placements'
  import placementUtils from './utils.placements'
  import _ from 'lodash';
  export default {
    props: {
      isFloating: {
        default: false
      },
      noPixels: {},
      placement: {},
      onSubmit: {
        default () {
          return e => {};
        }
      },
      onDuplicatePlacement: {
        default () {
          return e => {};
        }
      },
      onCancel: {
        default () {
          return e => {};
        }
      },
      onDelete: {
        default () {
          return e => {};
        }
      }
    },
    components: {
      PixelsForm
    },
    data() {
      let pixelForm = {
        name: null,
        cap: null,
        url: null,
        template: "",
        pubID: null,
        offerID: null,
        showURLType: "custom",
        htmlID: 0
      }

      let emptyPlacementForm = {
        externalID: null,
        domain: null,
        country: null,
        os: null,
        osVersion: null,
        notes: null,
        targeting: null,
        internal: true,
        stag: true,
        pixels: [],
        default_pid: null,
        default_template_id: null,
        os_version: null,
        hour_start: null,
        hour_end: null,
        hour_start_2: null,
        hour_end_2: null,
        bundle_wl: null,
        activeHours: [],
        is_global_bundle_wl: true,
      }

      let stagVersions = this.$store.state.stags.versions
      return {
        processing: false,
        emptyPlacementForm,
        deletePlacementModal: null,
        duplicatePlacementModal: null,
        pixelForm,
        form: {...emptyPlacementForm},
        countryOptions: [],
        osOptions: {
          ios: "iOS",
          android: "Android"
        },
        stagVersions,
        iosVersionsFull: [],
        androidVersionsFull:[],
        pixelTemplateOptions: [],
        domainPrefixes: [],
        errorInPixelIndex: null,
        templates: null,
        stagTemplates: null,
        activeHours: [],
        media_analyst: '',
        om_manager: '',
      }
    },
    computed: {
      iosVersions:{
        get(){ return this.iosVersionsFull.map((val) => val.version) },
        set(){ return []}
      },
      androidVersions:{
        get(){ return   this.androidVersionsFull.map((val) => val.version) },
        set(){ return []}
      },
      stagDomainOptions() {
        return this.stagVersions[1].domains
      },
      osVersions() {
        return this.form.os === 'ios' ? this.iosVersions : this.form.os === 'android' ? this.androidVersions : []
      },
      stagsBanners() {
        if (this.$store.state.stags.banners !== undefined && this.$store.state.stags.banners !== null) {
          let newObject = []
          let bannerObject = this.$store.state.stags.banners
          Object.keys(bannerObject).forEach(function(key) {
            let ob = {
              id: key,
              value: bannerObject[key]
            }
            newObject.push(ob)
          });
                  
          var sortedObjs = _.sortBy(newObject, 'value');

          return sortedObjs
        }
      },
      newPlacement() {
        return !this.placement
      },
      isInternal() {
        return this.placement ? this.placement.internal : !this.noPixels
      },
      formExternalID() {
        return this.form.externalID
      },
      arr24h() {
        let t = []
        for (let i = 0; i < 24; i++) {
          if (typeof this.form.activeHours === 'undefined' || this.form.activeHours === null) {
            t.push({'v' : i, 'checked': false})
            continue
          }
          if (this.form.activeHours.includes(i + "")) {
            t.push({'v' : i, 'checked': true})
          } else {
            t.push({'v' : i, 'checked': false})
          }
        }
        return t
        }
    },
    methods: {
      async getMediaAnalyst() {
        if (this.form.country !== null && this.form.os !== null) {
          const data = await this.$http.post(this.resources.Management.getMediaAnalystByOsCountry, {
              os: this.form.os,
              geo: this.form.country
          })

          const res = await data.json();
          if (res.res === 'OK') {
            this.media_analyst = res.data.media_analyst_manager;
            this.om_manager = res.data.om_manager;
          }
        }
      },
      formatPixels: placementUtils.formatPixels,
      buildPixelURL: placementUtils.buildPixelURL,
      getStagTemplates() {
        let stagTemplatesIDs = {
          29: true,
          28: true, // for production
          8: true,
          9: true // for development
        }
        let a = this.templates.filter(v => stagTemplatesIDs[v.id])
        return a
      },
      emptyForm() {
        this.form = {...this.emptyPlacementForm}
        if (!this.noPixels) {
          this.form.pixels = []
        }
      },
      getVersions: async function(){
        try {
            const r = await this.$http.get(this.resources.Management.getOsVersions("ios"))
            const ios = await r.json()
            this.iosVersionsFull = ios

            const i = await this.$http.get(this.resources.Management.getOsVersions("android"))
            const android = await i.json()
            this.androidVersionsFull = android
          } catch (err) {
            this.notifyError("can't fetch os versions")
          }
        },
      duplicatePlacement: async function () {
        try {
          const r = await this.$http.post(this.resources.Management.tmDuplicatePlacement(this.placement.id))
          const data = await r.json()
          this.duplicatePlacementModal = null
          this.onDuplicatePlacement(data)
        } catch {
          this.notifyError(`Duplicate for placement ${this.placement.id} is unavailable due to an error`)
        }
      },
      delPlacement: async function () {
        try {
          const data = await this.$http.delete(this.resources.Management.tmEditPlacement(this.placement.id))
          this.deletePlacementModal = null
          this.onDelete()
        } catch (err) {
          this.notifyError(`Deletion of placement ${this.placement.id} is unavailable due to an error`)
        }
      },
      updateForm(placement) {
        this.form = window.deepClone(placement)
        this.form.activeHours = this.placement.activeHours ?  this.placement.activeHours : []
        let pixels = this.form.pixels
        pixels &&
          pixels.forEach(p => {
            this.$set(p, "showURLType", "custom")
            this.$set(p, "htmlID", this.getHtmlId())
          })
      },
      syncPixels: async function () {
        this.processing = true
        try {
          const r = await this.$http.get(this.resources.Management.tmTMPixels(this.placement.id))
          let pixels = r.data
          pixels && pixels.forEach(p => {
            this.$set(p, "showURLType", "custom")
            this.$set(p, "htmlID", this.getHtmlId())
          })
          this.form.pixels = r.data
          this.processing = false
        } catch (err) {
          this.notifyError("Error: could not sync pixels")
        }
      },
      submit: async function () {
        this.errorInPixelIndex = null
        let form = {...this.form}
        let pixels = form.pixels
        
        form.hour_end = 0
        form.hour_end_2 = 0
        form.hour_start = 0
        form.hour_start_2 = 0
        form.pixels = this.formatPixels(pixels)

        let validation = this.isInternal ? "tmInternalPlacement" : "tmPlacement"
        try {
          await this.$validate[validation](form)
          if (this.placement) {
            if (this.placement.country !== form.country || this.placement.os !== form.os) {
              let hasConfirmed = window.confirm(
                "You changed the Country/Os on this placement. Are you sure about it?")
              if (!hasConfirmed) {
                return
              }
            }
            this.processing = true
            form.activeHours = this.uniq(this.activeHours) 
            const r = await this.$http.put(this.resources.Management.tmEditPlacement(this.placement.id), form)
            const placement = await r.json()
            Object.assign(this.placement, form)
            this.placement.pixels = placementUtils.parsePixels(placement.pixels)

            this.onSubmit()
            this.processing = false
          } else {
            form.internal = !this.noPixels
            if (form.pixels.length === 1) {
              let px0 = form.pixels[0]
              if (!px0.name) {
                form.pixels = []
              }
            }
            form.activeHours = this.activeHours
            const r = await this.$http.post(this.resources.Management.tmPlacements, form)
            const p = await r.json()
            this.emptyForm()
            this.onSubmit(p)
            this.processing = false
          }
        } catch (err) {
          console.error(err);
          let obj = err.object
          if (obj.hasOwnProperty("$arrayIndex")) {
            this.errorInPixelIndex = obj.$arrayIndex
          }
          this.processing = false
          let msg = err.msg
          this.notifyError(msg)
        }
      },
      hour_cb_click(e) {
        // need to validate that the value isnt already in the array
        if (e.target.checked) {
          if (!this.activeHours.includes(e.target.value)) {
            this.activeHours.push(e.target.value)
          }
         
        } else {
          const index = this.activeHours.indexOf(e.target.value)
          if (index > -1) {
            this.activeHours.splice(index, 1)
          }
        }
        this.form.activeHours  = this.activeHours
      },
      uniq(a) {
        return a.sort().filter(function(item, pos, arr) {
          return !pos || item != arr[pos - 1]
        })
      }
    },

    watch: {
      formExternalID(val) {
        if (!val || val.length < 2) {
          return
        }
        let dp = this.domainPrefixes.find(v => val.startsWith(v.prefix))
        if (dp) {
          this.form.domain = dp.domain
        }
      }, 
      placement(v) {
        this.updateForm(v)
      }
    },
    created: async function () {
      if (this.placement) {
        this.updateForm(this.placement)
        this.activeHours = this.form.activeHours
      }
      this.getVersions()
      
      this.$store.dispatch("GET_TM_TEMPLATES").then(templates => {
        this.templates = templates
        this.stagTemplates = this.getStagTemplates()
      })
      setTimeout(() => {
        if (this.templates !== null){
          this.templates.filter(obj => {
            if (obj.name === 'Internal SL')
              this.form.default_template_id = obj.id
              return 
          })
      }
      }, 2000)
     
      if (this.noPixels) {
        this.emptyPlacementForm.pixels = []
        this.form.pixels = []
      } else {
        this.form.stag = true
      }
      
    
      
      try {
        const countries = await this.$store.dispatch(act.GET_COUNTRIES_LIST)
        this.countryOptions = { 'WW': 'World Wide', ...countries}

        const dp = await this.$store.dispatch("GET_TM_DOMAIN_PREFIXES")
        this.domainPrefixes = dp
        await this.getMediaAnalyst()
      } catch (err) {
        return
      }
    },
  }
</script>

<style lang="css" scoped>
  .warning {
    color: crimson;
  }

  .info-form {
    border: 1px solid gainsboro;
    padding: 8px 11px;
    background: white;
  }

  .h24 {
    font-size: 17px;
    margin-bottom: 12px;
  }

  .fields-sec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .fields-sec>* {
    margin-right: 10px;
  }

  .btns-20 {
    margin-top: 20px;
  }

  .form-h7 {
    font-size: 11px;
  }

  .select-234 {
    font-size: 16px;
  }

  .del-btn {
    margin-right: 5px;
  }

  .delbtn-2345 {
    margin-right: 6px;
  }

  .form-12 {
    max-width: 900px;
    background: white;
    padding: 6px;
    border: 1px solid gainsboro;
  }

  .textarea-1 {
    width: 600px;
    height: 100px;
    resize: none;
  }

  .dv232 {
    display: flex;
    margin-top: 6px;
  }

  .c24341 {
    max-height: 75vh;
    overflow-y: auto;
  }

  .banner-link {
    width: 450px;
  }

  .hourly_cb {
    padding-right: 4px;
  }

  .country-div {
    width: 250px;
  }

  .inline {
    display: inline-flex;
  }

  .os-div {
    margin-left: 10%;
  }

  .media-analyst-div {
    display: block;
  }

  .media-analyst-span {
    margin-right: 5px;
  }

  .media-analyst-div-cont {
    width: 400px;
  }

</style>