<template>
  <router-link tag="li" class="nav-item nav-dropdown" v-if='hasPermission' :to="url" disabled>
    <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i :class="icon"></i> {{name}}</div>
    <ul class="nav-dropdown-items">
      <slot></slot>
    </ul>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    }
  },
  computed: {
    hasPermission() {
      let route = this.$router.resolve(this.url).route
      let routeMeta = route.meta
      if(!routeMeta) {
        return true
      }
      let routePermission = routeMeta.permission
      if(!routePermission) {
        return true
      }
      return this.userHasPermission(routePermission)
    }
  }
}
</script>
