<template lang="html">
  <router-view v-if='loaded' />
</template>

<script>
export default {
  data() {
    return {
      loaded: false
    }
  },
  methods: {
    fetchCampaigns: async function() {
      try{
        let state = this.$store.state
        state.smartlinks = {}
        const r = await this.$http.get(this.resources.SmartLinksCampaigns.getCampaigns)
        const smartlinks = await r.json()
        state.smartlinks.campaigns = smartlinks
      }catch(err){
        this.notifyError("could not fetch campaigns")
      }
    },
    fetchBlfiles: async function() {
      try{
        let state = this.$store.state
        state.smartlinks = {}
        const r = await this.$http.get(this.resources.blacklisting.getFiles)
        const blFiles = await r.json()
        state.blFiles = blFiles
      }catch(err){
        this.notifyError("could not fetch campaigns blacklist files")
      }
    }
  },
  created() {
    let state = this.$store.state
    let fetchList = []
    if (!state.smartlinks.campaigns) {
      fetchList.push(this.fetchCampaigns)
    }

    if (!state.blFiles) {
      fetchList.push(this.fetchBlfiles)
    }

    if(fetchList.length === 0) {
      this.loaded = true
      return
    }

    Promise.all(fetchList.map(v=>v()))
      .then(() => {
        this.loaded = true
      })
  }
}
</script>

<style>
.field-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 15px;
}
.field-container:last-child {
  border-bottom: 0;
}
.field-container > * {
  margin-right: 9px;
}
.field-container > *:nth-child(1) {
  width: 303px;
}
.field-container > *:hover {
}
.smartlinks-toggle-form-display-button {
  margin: 10px 0;
}
.pub-id-input {
  max-width: 400px;
}
</style>
