<template>
<div class="form">
    <div>
        <div class="add-form">
            <b-form class="pid-add-form" @submit="newPid" @reset="onReset">
                <b-row>
                    <b-col cols="8">
                        <b-form-group id="input-group-1" label="Choose list to display:" label-for="input-6">
                            <b-form-select size="lg" id="input-6" v-model="form.list" :options="pidListsNames" required @change="filterList(form.list)"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-1 " class="empty-label" label="*" label-for="input-5">
                            <b-button id="white-text-btn" variant="primary" @click="show= !show">
                                Add Pid List
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <h1> {{ form.list }} </h1>
                    </b-col>
                </b-row>
                <b-row class="pid-add">
                    <b-col>
                        <b-form-group id="input-group-1" label="Pid:" label-for="input-2">
                            <b-form-input id="input-2" type="number" v-model.number="form.pid" placeholder="Enter Pid" number required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-1" label="Comment:" label-for="input-4">
                            <b-form-input id="input-4" v-model="form.text" placeholder="Enter Pid Comment"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-1 " class="empty-label" label="*" label-for="input-5">
                            <b-button id="input-5" type="submit" variant="success">Add New Pid</b-button>
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-form>
        </div>
    </div>
    <b-table striped hover :items="filteredPidList" :fields="fields" fixed responsive :busy="isBusy">
        <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </template>
        <template v-slot:cell(text)="row">
            <b-row>
                <b-col>
                    <div @input="onInput" :contenteditable="row.item.isEditable" :class='{editable: row.item.isEditable===true, uneditable: row.item.isEditable===false}'>
                        {{ row.item.text }}
                    </div>
                </b-col>
                <b-col v-if="row.item.isEditable">
                    <b-button variant="success" v-if="row.item.isEditable" @click="updateComment(row.item)"> Add </b-button>
                    <b-button variant="secondary" v-if="row.item.isEditable" @click="resetComment(row.item)"> Cancel </b-button>
                </b-col>
            </b-row>
        </template>

        <template v-slot:cell(delete)="row" class="edit-row-btn">
            <button id="edit_btn" @click="deletePid(row.item)">
                <b-icon icon="trash"></b-icon>
            </button>
        </template>
        <template v-slot:cell(edit)="row" class="edit-row-btn">
            <button v-if="!row.item.isEditable" id="edit_btn" @click="row.item.isEditable = true">
                <b-icon icon="pencil"></b-icon>
            </button>
            <button v-else id="edit_btn" class="paused">
                <b-icon icon="pencil"></b-icon>
            </button>
        </template>
    </b-table>

    <b-modal v-model="show" :id="infoModal.id" title="New List" @hide="resetInfoModal">
        <div>
            <b-form-group id="input-group-1" label="Pid List Name:" label-for="input-3">
                <b-form-input id="input-3" v-model="pidListName" placeholder="Enter Name"></b-form-input>
            </b-form-group>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <b-button variant="primary" size="sm" class="float-right" @click="show=false">
                    Close
                </b-button>
                <b-button ref="btn-close" variant="success" size="sm" class="float-right" @click="newPidList">
                    Submit
                </b-button>
            </div>
        </template>
    </b-modal>
    <b-button v-if="form.list" variant="danger" @click="deleteList(form.list)">Delete Current List</b-button>
</div>
</template>

<script>
import {
    defineComponent
} from '@vue/composition-api'
export default {
    data() {
        return {
            pidList: null,
            filteredPidList: this.pidList,
            fields: [{
                    key: 'delete',
                    label: 'Delete',
                    thStyle: {
                        width: "7%"
                    },
                },
                {
                    key: 'edit',
                    label: 'Edit',
                    thStyle: {
                        width: "7%"
                    },
                },
                {
                    key: 'pid',
                    sortable: false,
                    thStyle: {
                        width: "10%"
                    },
                },
                {
                    key: 'text',
                    label: 'Comments',
                    sortable: true,
                }
            ],
            comment: '',
            isBusy: false,
            infoModal: {
                id: 'info-modal',
                title: 'New List',
                content: ''
            },
            pidListsNamesToId: null,
            pidListsNames: null,
            rememberList: null,
            form: {
                pid: null,
                list: "default",
                text: '',
            },
            show: false,
            newList: false,
            pidListName: '',

        }
    },
    created() {
        this.getPid()
        this.getPidToListName()
    },
    methods: {
        onInput(e) {
            this.comment = e.target.innerText
        },
        resetComment(item) {
            item.isEditable = !item.isEditable
            this.comment = ''
        },
        filterList(value) {
            this.filteredPidList = this.pidList.filter(item => {
                if (item.list_name === value)
                    return item
            })
        },
        deleteList: async function (value) {
            this.isBusy = true
            let list_obj = this.pidListsNamesToId.filter(item => {
                if (item.list_name === value) {
                    return item
                }
            })
            try {
                await this.$http.delete(this.resources.Management.deletePidList(list_obj[0].list_id))
                this.notifySuccess(`Pid List ${value} was succesfully deleted`)
                this.form.list = 'default'
                this.getPidToListName()
                this.getPid();
            } catch (err) {
                this.notifyError(`Pid List ${value} was not deleted by an error`)
            }

        },
        newPidList: async function (event) {
            event.preventDefault()
            this.isBusy = true
            try {
                this.$bvModal.hide(this.infoModal.id)
                await this.$http.post(this.resources.Management.newPidlist, {
                    list_name: this.pidListName
                })
                this.form.list = this.pidListName
                this.pidListName = ''
                this.getPidToListName();
                this.filterList(this.form.list);
                this.isBusy = false
            } catch {
                this.notifyError(`Error in creating new Pid list`)
            }
        },
        updateComment: async function (item) {
            item.isEditable = !item.isEditable
            this.isBusy = true
            try {
                await this.$http.put(this.resources.Management.updatePidComment(item.id), {
                    ..._.omit(item, ['text', 'isEditable', 'pid']),
                    text: this.comment,
                    type: '',
                    version: '',
                    pid: item.pid + ''
                })
                this.comment = ''
                this.getPid()
            } catch (err) {
                this.notifyError(`Pid ${item.pid} failed in editing comment`)
            }
        },
        info(item) {
            this.show = true
            this.infoModal.title = `Edit Pid: ${item.pid}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, "#btnShow")
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        newPid: async function (event) {
            event.preventDefault()
            this.isBusy = true
            if (this.form.list) {
                this.rememberList = this.form.list
            }
            let list_obj = this.pidListsNamesToId.filter(item => {
                if (item.list_name === this.form.list) {
                    return item
                }
            })

            try {
                await this.$http.post(this.resources.Management.newPid, {
                    list_id: list_obj[0].list_id,
                    pid: this.form.pid + "",
                    version: '',
                    type: '',
                    text: this.form.text + "",
                })
                this.onReset(event)
                this.getPid();
                this.isBusy = false

            } catch (err) {
                console.log(err);
                this.notifyError(`Error saving new pid ${this.form.pid}`)
            }
        },
        getPid: async function () {
            if (this.form.list) {
                this.rememberList = this.form.list
            }
            try {
                const r = await this.$http.get(this.resources.Management.pidList)
                let data = await r.json()

                this.pidList = data.map(item => ({
                    ...item,
                    isEditable: false
                }));

                if (this.rememberList) {
                    this.filterList(this.rememberList)
                } else {
                    this.filterList("default")
                }

                this.isBusy = false
            } catch (err) {
                this.notifyError("Could not fetch Pid's")
            }
        },
        getPidToListName: async function () {
            try {
                const r = await this.$http.get(this.resources.Management.pidListIdToName)
                let data = await r.json()

                this.pidListsNamesToId = data
                this.pidListsNames = this.pidListsNamesToId.map(item => {
                    return item.list_name
                })
            } catch (err) {
                this.notifyError("Could not fetch Pid's names")
            }
        },
        deletePid: async function (item) {
            this.isBusy = true
            try {
                await this.$http.delete(this.resources.Management.deletePidFromList(item.id))
                this.notifySuccess(`Pid ${item.pid} was succesfully deleted from list ${item.list_name}`)
                this.getPid();
            } catch (err) {
                this.notifyError(`Pid ${item.pid} was not deleted from list ${item.list_name}`)
            }
        },
        onReset(event) {
            event.preventDefault()
            this.form.pid = null
            this.form.text = ''

        }
    }
}
</script>

<style scoped>
#edit_btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.pid-add-form,
.add-list-btn,
.pid-add-form {
    margin-bottom: 5%;
}

.add-form {
    width: 50%;
}

#white-text-btn {
    color: white;
}

.editable {
    border: 2px solid dodgerblue;
    background-color: white;
    border-radius: 5%;
    height: 90%;
    background-origin: border-box;
    background-clip: content-box, border-box, padding-box;
    background-size: cover;
    box-sizing: border-box;
    box-shadow: 0 0 3px 3px dodgerblue;
    padding-left: 2%;
    background-image: linear-gradient(to bottom, white 0%, white 100%), linear-gradient(to bottom, white 0%, white 100%);
}

.uneditable {
    border: none transparent;
    background-color: transparent;
}

textarea:focus,
input:focus {
    outline: none;
}

.paused {
    pointer-events: none;
    opacity: 50%;
}

.empty-label {
    color: transparent;
}

* {
    box-sizing: border-box;
}

h1 {
    margin: 5% 0% 5% 0;
}
</style>
