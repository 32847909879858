<template>
    <div>
        <div>
            <a href="/apps/script-tags/data-manager">Back to data manager</a>
        </div>
        <div style="width: 100%;">
            <form @submit="save">
                <table style="width: 100%;">
                    <tr style="text-decoration: underline;">
                        <td>
                            #
                        </td>
                        <td>
                            Bundle ID
                        </td>
                        <td>
                            App Name
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr v-for="row of rows">
                        <td>
                            {{ row.id }}
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.bundle_id }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model.trim="row.bundle_id" placeholder="iOS - Without id" />
                        </td>
                        <td>
                            <div v-if="!row.edit">{{ row.app_name }}</div>
                            <b-form-input v-if="row.edit" type="text" v-model.trim="row.app_name" placeholder="AF App Name Manager" />
                        </td>
                        <td>
                            <div v-if="!row.edit">
                                <a v-if="isAdmin" class="btn btn-primary" href="javascript:void(0);" @click="editRow(row.id)">Edit</a> <a class="btn btn-primary" href="javascript:void(0);" @click="deleteEntity(row.id)" v-if="isAdmin">Delete</a>
                            </div>
                            <div v-if="row.edit">
                                <a class="btn btn-primary" href="javascript:void(0);"
                                    @click="saveEditRow(row.id)">Save</a>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="isAdmin && showNewRow">
                        <td>

                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.bundle_id" placeholder="iOS - Without id" />
                        </td>
                        <td>
                            <b-form-input type="text" v-model="newRow.app_name" placeholder="AF App Name Manager" />
                        </td>
                        <td>
                            <input type="submit" class="btn btn-success" value="Add" />
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newRow: {
                id: 0,
                bundle_id: '',
                app_name: '',
            },
            rows: [],
            showNewRow: true,
            isAdmin: true
        };
    },
    async created() {
        const permList = await this.$store.state.user.perms;
        if (Object.values(permList).indexOf("bundles.admin") > -1) {
            this.isAdmin = true;
        }
        this.getListData();
    },
    methods: {
        async save(e) {
            e.preventDefault();
            const rowReq = this.newRow
            let validateRes = await this.validateRowData(this.newRow.bundle_id, this.newRow.app_name)
            if (validateRes !== "") {
                this.notifyError(validateRes)
                return 
            }
            const data = await this.$http.post(this.resources.Management.saveAfAppName, {
                ...this.newRow
            });
            this.newRow.bundle_id = "";
            this.newRow.app_name = "";
            this.getListData();
        },
        async getListData() {
            const data = await this.$http.post(this.resources.Management.getAfAppName, {});
            for (let k in data.body.data) {
                data.body.data[k].edit = false
            }
            this.rows = data.body.data
        },
        async deleteEntity(id) {
            id = parseInt(id);
            const data = await this.$http.post(this.resources.Management.deleteAfAppName, {
                id,
            });
            this.getListData();
        },
        async editRow(id) {
            this.showNewRow = false;
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    this.rows[k].edit = true;
                }
                else {
                    this.rows[k].edit = false;
                }
            }
        },
        async saveEditRow(id) {
            let editRowData = {};
            for (let k in this.rows) {
                if (this.rows[k].id == id) {
                    editRowData = this.rows[k];
                }
            }
            
            this.showNewRow = true;
            const data = await this.$http.post(this.resources.Management.saveAfAppName, {
                ...editRowData
            });
            this.getListData()
            
        },
        async validateRowData(dm_bundle_id, dm_app_name) {
            if (dm_app_name == "") {
                return "App Name value can't be empty";
            }
            // if (!this.validateStr(dm_app_name)) {
            //     return "Please enter a valid App Name value";
            // }
            if (dm_bundle_id == "") {
                return "Bundle ID can't be empty";
            }
            // if (!this.validateStr(dm_bundle_id)) {
            //     return "Please enter a valid Bundle ID name";
            // }
            return "";
        },
        validateStr(str) {
            const re = /([A-Za-z0-9-_]+)/;
            if (re.test(str)) {
                return true;
            }
            return false;
        },
    },
    components: {  }
}
</script>

<style scoped>
.link-div {
    padding-top: 40px;
    text-align: center;
}
</style>
