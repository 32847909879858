<template>
<div>
    <h1>Offer Search</h1>

    <div class="filter-form">
        <form @submit="searchRelevantOffers">
            <b-row>
                <b-col>
                    <b-form-group id="input-group-1" label="Filter Offer By Name:" label-for="input-2">
                        <b-form-input type="text" placeholder="Filter by name" v-model="filterByName"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="input-group-1" label="Filter Offer By Affise ID:" label-for="input-2">
                        <b-form-input type="number" placeholder="Filter by affise ID" v-model.number="filterById"  @keyup="watchVal"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="input-group-1" label="Filter Global Offer By Internal ID:" label-for="input-2">
                        <b-form-input type="number" placeholder="Filter Global Offer by Internal ID" v-model.number="filterGlobalById"  @keyup="watchVal"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button @click="searchRelevantOffers" id="input-5" type="submit" variant="success">Search</b-button>
                </b-col>
            </b-row>
        </form>
    </div>
    <div class="spinner" v-if='loading'>
        <div class="loader"></div>
    </div>
    <div v-if="offers.length > 0 && !loading" class="mt-5">
        <table class="table table-condense w-auto small">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Offer Name</th>
                    <th scope="col">Affise ID</th>
                    <th>Offer Page Link</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in offers" :key="row.id">
                    <th>{{ row.id }}</th>
                    <td>{{ row.name }}</td>
                    <td>{{ row.affise_id }}</td>
                    <td v-if="row.camp_id && row.camp_id !== 0">
                        <!-- v-if vta offer -->
                        <a :href="`/apps/smartlinks/campaigns/${row.camp_id}/placements/${row.placement_id}/offer/${row.id}`">{{ `SL/${row.camp_id}-${row.sl_name.String}` }}</a> 
                    </td>
                    <td v-else>
                        <!-- v-if vta offer -->
                        <a  v-if="row.ctr_id != 0" :href="`/apps/smartlinks/global-offers-edit?id=${row.id}&isVta=1`">{{ `Offer/${row.id}` }}</a> 
                        <!-- v-else -->
                        <a v-else :href="`/apps/smartlinks/global-offers-edit?id=${row.id}&isVta=0`">{{ `Offer/${row.id}` }}</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="spinner" v-if="offers.length == 0">
        <h3>No results</h3>
    </div>
</div>
</template>

<script>
import Spinner from '../../../../components/shared/Spinner.vue'
import {
    defineComponent
} from '@vue/composition-api'
import {
    method
} from 'lodash'

export default ({
    data() {
        return {
            filterByName: '',
            filterById: 0,
            filterGlobalById: 0,
            offers: [],
            loading: false,
        }
    },
    methods: {
        async searchRelevantOffers(e) {
            try {
                e.preventDefault();

                if (this.filterById !== 0 && this.filterByName !== '' || this.filterById !== 0 && this.filterGlobalById !== 0 ||
                 this.filterByName !== '' && this.filterGlobalById !== 0 ) {
                    this.notifyError('Please use only one filter each serach.');
                    return
                 }

                this.loading = !this.loading
                const response = await this.$http.post(this.resources.SmartLinksCampaigns.filterOffersByNameOrID, {
                    name: this.filterByName,
                    affise_id: this.filterById.toString(),
                    global_offer_id: this.filterGlobalById.toString()
                })
                const data = await response.json()
                this.offers = data
                this.loading = !this.loading

            } catch (err) {
                this.notifyError(err)
            }
        },
        async watchVal(e) {
            if (e.target.value === '') {
                if (this.filterById === '') {
                    this.filterById = 0;
                } else {
                    this.filterGlobalById = 0;
                }
            }
        }
    }
})
</script>

<style scoped>
.spinner {
    margin-top: 5%;
}

.loader {

    margin: auto;
    border: 20px solid #EAF0F6;
    border-radius: 50%;
    border-top: 15px solid lightskyblue;
    width: 100px;
    height: 100px;
    animation: spinner 4s linear infinite;

}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
