<template lang="html">
  <i class="spinner fa fa-circle-o-notch fa-spin"></i>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.spinner {
  font-size: 28px;
}
</style>
