<template>
<div>

    <div class="vws-table-rule">

        <div class="vws-table-rule-body" id='schelude' ref="draggableArea">

            <div ref="ruleTime" class="vws-rule-time" key="day">
                <div ref="ruleTimeTime" class="vws-time-list vws-rule-time-time vws-time-rule">{{"Select GMT" }}</div>
                <div v-for="(day, daynum) in dayTable" :key="daynum" ref="ruleTimeItem" :class="{'vws-time-list vws-rule-time-item parent': true, 'active': checkFullDay(daynum)}" @click="toggleFullDay(daynum, checkFullDay(daynum))">
                    <span>{{day}}</span>
                </div>
            </div>

            <div ref="ruleTime" class="vws-rule-time" v-for="(t, idx) in timeArray" :key="idx">
                <div ref="ruleTimeWeek" :class="{
                    'vws-time-list vws-rule-time-week parent': true, 
                    'active': checkFullWeek(idx)
                  }" @click="toggleWeek(idx, checkFullWeek(idx))">
                    <span>{{t}}</span>
                </div>
                <div v-for="(day, daynum) in dayTable" :key="daynum" ref="ruleTimeItem" :class="{
                      'vws-time-list vws-rule-time-item': true, 
                      'active': timetable[daynum].find(el => el == idx ) != undefined ? true:false
                    }" @click="toggleDay(daynum, idx)">
                    <span>{{`${day} ${t}`}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            timeArray: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
            dayTable: ['Sunday', 'Monday', 'Tuesday', 'Wedenesday', 'Thursday', 'Friday', 'Saturday'],
            timeArrayLength: 24,
            steps: 60,
            timetable: {
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: []
            },
        }
    },
    mounted() {
        if (this.value) {
            this.timetable = this.value;
        }
    },
    created() {
        if (this.value) {
            this.timetable = this.value;
        }
    },
    watch: {
        value(val) {
            if (val) {
                this.timetable = val;
            }
        }
    },
    methods: {
        toggleFullDay(day, status) {
            for (let t = 0; t < (this.timeArrayLength / (this.steps / 60)); t++) {
                let indexDay = this.timetable[day].findIndex(el => el == t);
                if (indexDay != -1) {
                    if (status) {
                        this.timetable[day].splice(indexDay, 1);
                    }
                } else {
                    this.timetable[day].push(t);
                }
            }
            this.$emit('update:value', this.timetable);
        },
        toggleWeek(time, status) {
            for (const key in [0, 1, 2, 3, 4, 5, 6]) {
                let indexDay = this.timetable[key].findIndex(el => el == time);
                if (indexDay != -1) {
                    if (status) {
                        this.timetable[key].splice(indexDay, 1);
                    }
                } else {
                    this.timetable[key].push(time);
                }
            }
            this.$emit('update:value', this.timetable);
        },
        setDay(day, time, value, num) {
            if (day < 0) {
                return
            }

            let indexDay = this.timetable[day].findIndex(el => el == time);
            if (value) {
                if (indexDay != -1) {

                } else {
                    this.timetable[day].push(time);
                }
            } else {
                if (indexDay != -1) {
                    this.timetable[day].splice(indexDay, 1);
                } else {

                }
            }
            this.$emit('update:value', this.timetable);
        },
        setFullDay(day, value) {
            if (typeof this.timetable[day] !== 'undefined') {
                for (let t = 0; t < (this.timeArrayLength / (this.steps / 60)); t++) {
                    let indexDay = this.timetable[day].findIndex(el => el == t);
                    if (value) {
                        if (indexDay != -1) {} else {
                            this.timetable[day].push(t);
                        }
                    } else {
                        if (indexDay != -1) {
                            this.timetable[day].splice(indexDay, 1);
                        } else {}
                    }
                }
            }
        },
        setWeek(time, value) {
            for (const key in [0, 1, 2, 3, 4, 5, 6]) {
                let indexDay = this.timetable[key].findIndex(el => el == time);
                if (value) {
                    if (indexDay != -1) {} else {
                        this.timetable[key].push(time);
                    }
                } else {
                    if (indexDay != -1) {
                        this.timetable[key].splice(indexDay, 1);
                    } else {}
                }
            }
            this.$emit('update:value', this.timetable);
        },
        toggleDay(day, time) {
            let indexDay = this.timetable[day].findIndex(el => el == time);

            if (indexDay != -1) {
                this.timetable[day].splice(indexDay, 1);
            } else {
                this.timetable[day].push(time);
            }
            this.$emit('update:value', this.timetable);
        },
        checkFullWeek(time) {
            for (const key in this.timetable) {
                if (this.timetable[key].find(el => el == time) == undefined) {
                    return false;
                }
            }
            return true;
        },
        checkFullDay(day) {
            for (let t = 0; t < (this.timeArrayLength / (this.steps / 60)); t++) {
                if (this.timetable[day].find(el => el == t) == undefined) {
                    return false;
                }
            }
            return true;
        },
    }
}
</script>

<style scoped>
.vws-table-rule {
    border-collapse: separate;
}

.vws-table-rule-heading,
.vws-rule-time {
    display: flex;
    flex-wrap: wrap;
}

.vws-table-rule-heading div,
.vws-rule-time .vws-time-list {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;

     border-style: solid;
     border-width: 0.5px;
     margin: 0.5px; 
   
    font-size: 14px;

}

.vws-time-list{

}

.vws-time-rule {
    width: 100px;
    display: block ruby;
    text-align: center;
}

.vws-rule-time-week,
.vws-rule-time-item {
    width: 25px;
    cursor: pointer;
}

.vws-rule-time-week,
.vws-rule-time-item {
    background: #BFC6C9;
}

.vws-rule-time-week.active,
.vws-rule-time-item.active {
    background: #84c9fc;
}

.vws-rule-time-week:hover,
.vws-rule-time-item:hover,
.vws-rule-time-week.active:hover,
.vws-rule-time-item.active:hover {
    background: #84dafc7a;
}

.vws-rule-time-item span {
    display: none;

}

.vws-rule-time-item .parent {
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 800;
    height: 4px;
}

.parent span {
    display: block;
    text-align: center;
    color: #000;

}

.vws-rule-time-item.active span {
    display: block;
    text-align: center;
    color: #000;

}

body, .vws-table-rule-body{
    line-height: 2 !important; 
}



/*  end */
</style>
